import { useState } from "react";
import { connect } from "react-redux";
import { MdAddCircleOutline } from "react-icons/md";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { addSampleQuestion } from "../../../../redux/actions/adminActions";

const AddQuestion = ({ history, location, addSampleQuestion }) => {
	const [data, setData] = useState({
		title: "",
		bmTitle: "",
		type: "",
		subtitle: "",
		subbmTitle: "",
		subquestion: "",
		subtype: "",
	});

	console.log(data);


	const [answers, setAnswers] = useState({});
	const [subAnswers, setSubAnswers] = useState({});

	const handleChange = e => {
		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleAddAnswer = () => {
		const ansLength = Object.values(answers).length + 1;
		setAnswers({
			...answers,
			[`ans${ansLength}`]: {
				title: "",
				bmTitle: ""
			}
		});
	};

	const handleAnswerChange = e => {
		setAnswers({
			...answers,
			[e.target.id]: {
				...answers[e.target.id],
				[e.target.name]: e.target.value
			}
		});
	};

	const handleAddSubAnswer = () => {
		const ansLength = Object.values(subAnswers).length + 1;
		setSubAnswers({
			...subAnswers,
			[`ans${ansLength}`]: {
				subtitle: "",
				subbmTitle: ""
			}
		});
	};

	const handleSubAnswerChange = e => {
		setSubAnswers({
		  ...subAnswers,
		  [e.target.id]: {
			...subAnswers[e.target.id],
			[e.target.name]: e.target.value
		  }
		});
	  };
	  

	const handleSubmit = e => {
		e.preventDefault();
		let pass = true;
		if (data.type === "rate") {
		if (data.subquestion === true) {
			if (data.subtype === "open") {
			if (!data.title || !data.bmTitle || !data.type || !data.subtitle || !data.subbmTitle || !data.subtype) pass = false;
			if (!Object.values(answers).length) pass = false;
			for (let key in answers) {
				if (!answers[key].title) pass = false;
				if (!answers[key].bmTitle) pass = false;
			}
			}
			else {
			if (!data.title || !data.bmTitle || !data.type || !data.subtitle || !data.subbmTitle || !data.subtype) pass = false;
			if (!Object.values(answers).length) pass = false;
			for (let key in answers) {
				if (!answers[key].title) pass = false;
				if (!answers[key].bmTitle) pass = false;
			}
			for (let key in subAnswers) {
				if (!subAnswers[key].subtitle) pass = false;
				if (!subAnswers[key].subbmTitle) pass = false;
			}
		}
		}
		else {
			if (!data.title || !data.bmTitle || !data.type) pass = false;
			if (!Object.values(answers).length) pass = false;
			for (let key in answers) {
				if (!answers[key].title) pass = false;
				if (!answers[key].bmTitle) pass = false;
			}
		}
	}
	else {
		if (!data.title || !data.bmTitle || !data.type) pass = false;
		if (!Object.values(answers).length) pass = false;
		for (let key in answers) {
			if (!answers[key].title) pass = false;
			if (!answers[key].bmTitle) pass = false;
		}
	}

		if (!pass) alert("Please fill in all the fields in the form");
		else {
			const sendThis = {
				title: data.title,
				bmTitle: data.bmTitle,
				subtitle: data.subtitle,
				subbmTitle: data.subbmTitle,
				type: data.type,
				subtype: data.subtype,
				answers: Object.values(answers),
				subAnswers: Object.values(subAnswers)
			};
			addSampleQuestion(sendThis, () => history.push("/admin/sample/survey/dashboard"));
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Add Question</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>

							<div className="form-group mt-3">
								<label htmlFor="type">Type of question</label>
								<select id="type" name="type" className="form-select" value={data.type} onChange={handleChange} required>
									<option value="" disabled>
										Select Type
									</option>
									<option value="multiple">MCQ</option>
									<option value="rate">Rate</option>
									<option value="dropdown">Dropdown</option>
									<option value="open">Open Question</option>
								</select>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="title">Question Title</label>
								<input
									type="text"
									name="title"
									id="title"
									placeholder="Question Title"
									className="form-control"
									value={data.title}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="bmTitle">Question Title (BM)</label>
								<input
									type="text"
									name="bmTitle"
									id="bmTitle"
									placeholder="Question Title (BM)"
									className="form-control"
									value={data.bmTitle}
									onChange={handleChange}
									required
								/>
							</div>

							<label>Tips: For question with specific brand, please replace the brand name with %brandName%</label><br></br>
							<label>Example: Why do you like to use %brandName%</label>

							<div className="form-group mt-3">
								<label htmlFor="answers">Answers: </label>
								<br />
								<button type="button" className="btn btn-primary" onClick={handleAddAnswer}>
									<MdAddCircleOutline className="me-2" />
									Add Choice
								</button>

								<div>
									{Object.keys(answers).map((key, ind) => (
										<div className="d-flex" style={{ width: "50%" }} key={key}>
											<input
												type="text"
												className="form-control mt-2"
												id={key}
												name="title"
												value={answers[key].title}
												onChange={handleAnswerChange}
												placeholder={`Choice ${ind + 1} (ENGLISH)`}
												required
											/>

											<input
												type="text"
												className="form-control mt-2"
												id={key}
												name="bmTitle"
												value={answers[key].bmTitle}
												onChange={handleAnswerChange}
												placeholder={`Pilihan ${ind + 1} (BAHASA)`}
												required
											/>
										</div>
									))}
								</div>
							</div> 

							{ data.type === "rate" ? (
								
								<div>
								
								<div className="form-group mt-3">
									<label htmlFor="subquestion">Got Sub Question? </label>
									<select id="subquestion" name="subquestion" className="form-select" value={data.subquestion} onChange={handleChange} required>
										<option value="" disabled>
											Got subquestion?
										</option>
										<option value="true">Yes</option>
										<option value="false">No</option>
									</select>
								</div>

								{ data.subquestion === "true" ? (
								<div className="form-group mt-3">
									<label htmlFor="subtype">Open or close question? </label>
									<select id="subtype" name="subtype" className="form-select" value={data.subtype} onChange={handleChange} required>
										<option value="" disabled>
											Open or close question?
										</option>
										<option value="open">Open</option>
										<option value="close">Close</option>
									</select>
								</div>
								) : null }
	
								{ data.subquestion === "true" && data.type === "rate" && data.subtype === "close" ? (
								<div>
								<div className="form-group mt-3">
									<label htmlFor="title">Sub Question Title</label>
									<input
										type="text"
										name="subtitle"
										id="subtitle"
										placeholder="Sub Question Title"
										className="form-control"
										value={data.subtitle}
										onChange={handleChange}
										required
									/>
								</div>
	
								<div className="form-group mt-3">
									<label htmlFor="subbmTitle">SubQuestion Title (BM)</label>
									<input
										type="text"
										name="subbmTitle"
										id="subbmTitle"
										placeholder="Question Title (BM)"
										className="form-control"
										value={data.subbmTitle}
										onChange={handleChange}
										required
									/>
								</div>

								<div className="form-group mt-3">
								<label htmlFor="subAnswers">Answers: </label>
								<br />
								<button type="button" className="btn btn-primary" onClick={handleAddSubAnswer}>
									<MdAddCircleOutline className="me-2" />
									Add Choice
								</button>

								<div>
									{Object.keys(subAnswers).map((key, ind) => (
										<div className="d-flex" style={{ width: "50%" }} key={key}>
											<input
												type="text"
												className="form-control mt-2"
												id={key}
												name="subtitle"
												value={subAnswers[key].subtitle}
												onChange={handleSubAnswerChange}
												placeholder={`Choice ${ind + 1} (ENGLISH)`}
												required
											/>

											<input
												type="text"
												className="form-control mt-2"
												id={key}
												name="subbmTitle" // corrected attribute name
												value={subAnswers[key].subbmTitle}
												onChange={handleSubAnswerChange}
												placeholder={`Pilihan ${ind + 1} (BAHASA)`}
												required
											/>

										</div>
									))}
								</div>
							</div>

								</div>
								) : data.subquestion === "true" && data.type === "rate" && data.subtype === "open" ? (
								<div>
								<div className="form-group mt-3">
									<label htmlFor="title">Sub Question Title</label>
									<input
										type="text"
										name="subtitle"
										id="subtitle"
										placeholder="Sub Question Title"
										className="form-control"
										value={data.subtitle}
										onChange={handleChange}
										required
									/>
								</div>
	
								<div className="form-group mt-3">
									<label htmlFor="subbmTitle">SubQuestion Title (BM)</label>
									<input
										type="text"
										name="subbmTitle"
										id="subbmTitle"
										placeholder="Question Title (BM)"
										className="form-control"
										value={data.subbmTitle}
										onChange={handleChange}
										required
									/>
								</div>

								</div>
								): null}
								</div>
									
								) : null}

							<div className="form-group text-center mt-5">
								<Loader component={<input type="submit" value="SUBMIT" className="btn btn-primary form-control" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		addSampleQuestion: (data, callback) => dispatch(addSampleQuestion(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(AddQuestion);
