import { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  clearError,
  getMysteryBox,
  scanCode,
} from "../../redux/actions/userActions";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import uploadReceiptBtnEn from "../../assets/newUploadReceiptButton.png";
import uploadReceiptBtnBm from "../../assets/newBmUploadReceiptButton.png";
import giftBox from "../../assets/giftBox.png";
import mysteryBoxBanner from "../../assets/bigNewMysteryBoxBanner.png";
import bmMysteryBoxBanner from "../../assets/bmMysteryBoxBanner.png";
import { useLocation } from "react-router-dom";
import Slider from "react-slick/lib/slider";

const VerifyCodeSuccess = ({
  code,
  setCode,
  history,
  user,
  scanCode,
  page,
  clearError,
}) => {
  const location = useLocation();
  const staty = location.state;
  const { t } = useTranslation();
  const [showModal, setShow] = useState(true);
  const [isCampaignLive, setIsCampaignLive] = useState(false);
  const dispatch = useDispatch();
  const language = useSelector((state) => state?.pageReducer?.language);

  // useEffect(() => {
  // 	window.Insider.eventManager.dispatch("init-manager:re-initialize");
  // }, [code, page.isLoading]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (code && code.length === 10) {
      scanCode(
        { code: code.toUpperCase(), id: user.id },
        (gainedPoints, code_group, brand, format, size) => {
          window.Insider.track("events", [
            {
              event_name: "uc_registration",
              // timestamp: new Date().toISOString(),
              event_params: {
                point_value: gainedPoints,
                code_group,
                unique_code: code.toUpperCase(),
                brand,
                format,
                c_size: size,
              },
            },
          ]);
          setShow(gainedPoints);
        }
      );
      (async () => {
        const userDate = new Date();
        const resMB = await dispatch(getMysteryBox());
        if (resMB) {
          const endDate = resMB.data?.campaign?.endDate
            ? new Date(resMB.data?.campaign?.endDate)
            : null;
          if (endDate && userDate > endDate) {
            setIsCampaignLive(false);
          } else {
            setIsCampaignLive(true);
          }
        } else {
          setIsCampaignLive(false);
        }
      })();
    } else {
      alert(t("atleastten"));
    }
  };

  return (
    <div className="text-center" style={{ paddingBottom: "100px" }}>
      <p className="addToCartTitle">{t("registercode")}</p>
      <p className="pickSampleModalText mt-5 pb-3">
        {t("youcanalso")}
        {/* <br /> {t("theuc")} */}
      </p>
      <div>
        <form onSubmit={handleSubmit} id="ucForm">
          <div id="ucInputContainer">
            <input
              id="ucInput"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value.toUpperCase())}
              placeholder={t("uq")}
              maxLength={10}
              required
            />
          </div>
          <div className="text-center my-5">
            <p className="uc-tnc">{t("ucTNCterm")}</p>
            <p className="uc-tnc">
              {t("receiptUploadTnc")}
              <br />
              {t("receiptUploadTnc2")}
            </p>
            <Loader
              component={
                <input
                  type="submit"
                  value={t("submit")}
                  id="ucSubmit"
                  className="pickAddressConfirmButton"
                />
              }
            />
          </div>
        </form>
        {/* <h5 style={{ margin: "0", fontSize: "12px", paddingBottom: "0" }} className="pickSampleModalText mt-2">{t("ucTNCterm")}</h5>
				<h6 style={{ margin: "0", fontSize: "12px", paddingBottom: "0" }} className="pickSampleModalText mt-2">{t("ucTNC")}</h6>
				<h6 style={{ margin: "0", fontSize: "12px", paddingTop: "0" }} className="pickSampleModalText">{t("ucTNC2")}</h6> */}
        <div className="px-4">
        <Slider
            autoplay={true}
            autoplaySpeed={4000}
            easing="ease-in"
            dots={true}
            className="slider-upload-receipt-banner"
          >
            <div className="upreceipt-slider-div">
              <img
                src={language === "en" ? mysteryBoxBanner : bmMysteryBoxBanner}
                alt="mystery-box-banner"
                className="mystery-box-banner"
              ></img>
            </div>
            <div className="upreceipt-slider-div">
              <img
                src={
                  language === "en" ? uploadReceiptBtnEn : uploadReceiptBtnBm
                }
                alt="for-all-drypers-baby-care-products-only"
                className="mt-3"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </Slider>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => {
          clearError();
          setShow(false);
        }}
        centered
        backdrop="static"
      >
        <Modal.Body className="text-center">
          {page.error ? (
            <div>
              <p className="addToCartTitle">{t("error")}</p>
              <p className="mt-3 pickSampleModalText">{page.error}</p>

              <div>
                <button
                  className="pickAddressConfirmButton"
                  onClick={() => {
                    clearError();
                    setShow(false);
                  }}
                >
                  OKAY
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <img
                  src={giftBox}
                  alt="gift-box"
                  className="img-fluid mod-image"
                />
              </div>
              <p
                className="addToCartTitle"
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginTop: "6px",
                  color: "#223C96",
                  fontFamily: "Knewave",
                }}
              >
                {t("newRegistered")}
              </p>
              <p className="mt-3 pickSampleModalText">
                {t("youHaveReceived") + staty?.gainedPoints + t("smallPoints")}
                <br />
                {staty?.campaignLive ? t("scanCodeMyBoxMessage") : null}
              </p>

              <div>
                <button
                  id="after-redeem-ok"
                  className="pickAddressConfirmButton"
                  onClick={() => {
                    clearError();
                    setShow(false);
                    history.push("/scancode");
                  }}
                >
                  OKAY
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={page.error} onHide={() => clearError()} centered>
        <Modal.Body className="text-center">
          <div>
            <p className="addToCartTitle">{t("error")}</p>
            <p className="mt-3 pickSampleModalText">{page.error}</p>
          </div>

          <div>
            <button
              className="pickAddressConfirmButton"
              onClick={() => clearError()}
            >
              OKAY
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    page: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    scanCode: (data, callback) => dispatch(scanCode(data, callback)),
    clearError: (data) => dispatch(clearError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCodeSuccess);
