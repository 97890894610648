import { Link } from "react-router-dom";
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Footer from "../../../component/dashboard/Footer";
//Redux
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

const BabyInformation = ({ history, user }) => {
	const { t } = useTranslation();
	return (
		<div id="baby-info">
			<Header />
			<div className="content">
				<ContentHeader />
				<div className="text-center">
					<h1 className="dbc-title">{t('babyinfo')}</h1>
				</div>

				<div className="pickAddressHolder">
					{user && user.babyInfos && user.babyInfos.length
						? user.babyInfos.map(baby => (
								<div key={baby.id} className="pickAddressContainer">
									<div style={{width: '85%'}} className="p-2">
										<h5 className="m-0 p-0">{baby.name || "-"}</h5>
										<h6 className="m-0 p-0">{baby.gender ? baby.gender.toUpperCase() : "-"}</h6>
										<h6 className="m-0 p-0">{new Date(baby.birthDate).toLocaleDateString("en-GB")}</h6>
									</div>
									<Link style={{ textDecoration: "none" }} to={{ pathname: "/addbabyinfo", state: {babyId: baby.id, babyData: baby} }}>
 									<p className="dbc-title mt-4">Edit</p>
									</Link>
								</div>
						  ))
						: null}

					<div className="text-end">
						<Link style={{ textDecoration: "none" }} to={{ pathname: "/addbabyinfo" }}>
							<p className="addToCartText fw-bold">+ {t('addchild')}</p>
						</Link>
					</div>
				</div>

				<div className="text-center my-5">
					<button type="button" className="btn confirm-button" onClick={() => history.push("/editprofile")}>
						{t('back')}
					</button>
				</div>

				<div className="desktop-only">
					<Footer />
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user
	};
};

export default connect(mapStateToProps)(BabyInformation);
