// import React, { useEffect, useState } from 'react';
// import {Link} from 'react-router-dom';
// Components
import Accordion from "../../../component/dashboard/Accordion";
// Assets
// import img2 from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/wayto/2.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function WaysToCollect() {
	const { t } = useTranslation();
	return (
		<div>
			{/* <h1 className="addToCartTitle">Ways to Collect More Points</h1> */}
			<div className="tierBenefitsAccordionBox">
				<Accordion title={t("waystotitle1")} icon="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/qr-code-scan.png">
					<ul>
						<li>{t("waysto1")}</li>
						<li>
							{t("waysto2")}
							<br />
							<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/wayto/1.png" alt="way" style={{ width: "auto", maxHeight: "280px", margin: "auto" }}></img>
						</li>
						{/* <li>
							{t("waysto3")}
							<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/wayto/2.png" alt="way" style={{ width: "auto", maxHeight: "280px", margin: "auto" }}></img>
						</li> */}
						<li>
							{t("waysto4")}
							<br />
							<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/wayto/3.png" alt="way" style={{ width: "100%", maxWidth: "500px", margin: "auto" }}></img>
						</li>
						<li>
							{t("waysto5")} <Link to="/productsinfo">{t("waysto6")}</Link>
						</li>
					</ul>
				</Accordion>
				<Accordion title={t("waystotitle2")} icon="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/finish.png">
					<h3>{t("waysto7")}</h3>
					<ol>
						<li>{t("waysto8")}</li>
						<li>{t("waysto9")}</li>
						<li>{t("waysto10")}</li>
						<li>{t("waysto11")}</li>
					</ol>
				</Accordion>
				<Accordion title={t("waystotitle3")} icon="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-dbc-forum.png">
					<h3>{t("waysto12")}</h3>
					<h4>{t("waysto13")}</h4>
					<ol>
						<li>{t("waysto14")}</li>
						<li>{t("waysto15")}</li>
						<li>{t("waysto16")}</li>
					</ol>
				</Accordion>
			</div>
		</div>
	);
}

export default WaysToCollect;
