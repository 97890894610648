import { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { MDBDataTableV5 } from "mdbreact";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import Loader from "../../../component/Loader";
import { massCreditDashboardColumns } from "../../../config/mdbColumns";

const MassCreditDashboard = ({ history, loading, loaded, token }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		loading();
		axios
			.get("/drypers-baby-club/api/admin/fetch/dashboard/massCredit", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			})
			.then(res => {
				setData(res.data);
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
	}, []);

	const tableData = useMemo(() => {
		return {
			columns: massCreditDashboardColumns,
			rows: data.map(x => ({
				...x,
				button: (
					<button
						type="button"
						className="btn btn-primary"
						onClick={() => history.push({ pathname: "/admin/massCredit/details", details: x.details })}
					>
						Details
					</button>
				)
			}))
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					{/* contents */}
					<h3>Mass Credit / Refund Dashboard</h3>

					<button type="button" className="mt-3 btn btn-primary" onClick={() => history.push("/admin/massCredit/new")}>
						Add Points
					</button>

					<div className="mt-5 card">
						<div className="card-header">
							<h5 className="card-title">History</h5>
						</div>

						<div className="card-body text-center">
							<Loader component={<MDBDataTableV5 data={tableData} striped hover responsive bordered />} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		token: state.userReducer.token
	};
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MassCreditDashboard);
