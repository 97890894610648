import { useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { addItem, addCategory } from "../../../../redux/actions/adminActions";

const AddItem = ({ location, history, addItem, addCategory }) => {
	const [data, setData] = useState({
		itemId: "",
		itemName: "",
		uom: "",
		points: 0,
		inventory: 0,
		description: "",
		bmName: "",
		bmDescription: "",
		itemBrand: "",
	});
	const [file, setFile] = useState(null);
	const [bmFile, setBmFile] = useState(null);
	const [include, setInclude] = useState([]);
	const [show, setShow] = useState(false);
	const [category, setCategory] = useState(location.category);
	const [newCategory, setNewCategory] = useState("");

	const handleChange = e => {
		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleCheckbox = e => {
		if (include.includes(e.target.value)) {
			setInclude(include.filter(x => x !== e.target.value));
		} else {
			setInclude([...include, e.target.value]);
		}
	};

	const isChecked = val => {
		if (include.includes(val)) return true;
		else return false;
	};

	const handleImage = e => setFile(e.target.files[0]);

	const handleBmFile = e => setBmFile(e.target.files[0]);

	const addNewCategory = e => {
		if (newCategory) {
			const name = newCategory;
			addCategory({ name }, (err, data) => {
				if (err) alert(err);
				else {
					setCategory([...category, data]);
					setNewCategory("");
					setShow(false);
				}
			});
		} else {
			setShow(false);
		}
	};

	const handleSubmit = e => {
		e.preventDefault();
		const { itemId, itemName, uom, points, inventory, description, bmName, bmDescription, itemBrand } = data;
		if (itemId && itemName && uom && points && description && file && bmName && bmDescription && bmFile && itemBrand) {
			console.log({
				itemId,
				itemName,
				uom,
				points,
				inventory,
				description,
				file,
				include,
				bmName,
				bmDescription,
				bmFile,
				itemBrand,
			});
			const formData = new FormData();
			formData.append("itemId", itemId);
			formData.append("itemName", itemName);
			formData.append("uom", uom);
			formData.append("points", points);
			formData.append("inventory", inventory);
			formData.append("description", description);
			formData.append("file", file);
			formData.append("categories", include);
			formData.append("bmName", bmName);
			formData.append("bmDescription", bmDescription);
			formData.append("bmFile", bmFile);
			formData.append("itemBrand", itemBrand);

			addItem(formData, err => {
				if (err) alert(err);
				else history.push("/admin/catalog/dashboard");
			});
		}
	};
	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Add Item</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="category">Included In:</label>

								<div>
									{category.length ? (
										category.map(x => (
											<div key={x.id} className="form-check form-check-inline">
												<input
													type="checkbox"
													className="form-check-input"
													id={x.id}
													name={x.id}
													value={x.id}
													onChange={handleCheckbox}
													checked={isChecked(x.id)}
												/>
												<label htmlFor={x.id}>{x.name}</label>
											</div>
										))
									) : (
										<p>- Add more category -</p>
									)}
								</div>

								<button type="button" className="btn btn-info" onClick={() => setShow(true)}>
									Add new category
								</button>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="itemId">
									Item ID <small>(Article No)</small>
								</label>
								<input
									type="text"
									name="itemId"
									id="itemId"
									className="form-control"
									placeholder="Item ID"
									value={data.itemId}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="itemName">Item Name</label>
								<input
									type="text"
									name="itemName"
									id="itemName"
									className="form-control"
									placeholder="Item Name"
									value={data.itemName}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="uom">Unit of Measurement</label>
								<input
									type="text"
									name="uom"
									id="uom"
									className="form-control"
									placeholder="Unit of Measurement"
									value={data.uom}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="points">Points for redemption</label>
								<input type="number" name="points" id="points" className="form-control" min={0} value={data.points} onChange={handleChange} required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="inventory">Inventory stock</label>
								<input type="number" name="inventory" id="inventory" className="form-control" min={0} value={data.inventory} onChange={handleChange} required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="description">Item Description</label>
								<textarea name="description" id="description" className="form-control" value={data.description} onChange={handleChange} required></textarea>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="itemImage">Item Image</label>
								<input type="file" name="file" id="file" className="form-control" onChange={handleImage} accept="image/*" required />
							</div>

							<hr className="my-5" />

							<div className="form-group">
								<label htmlFor="bmName">Item Name (BAHASA)</label>
								<input
									type="text"
									name="bmName"
									id="bmName"
									className="form-control"
									placeholder="Item Name"
									value={data.bmName}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="bmDescription">Item Description (BAHASA)</label>
								<textarea
									name="bmDescription"
									id="bmDescription"
									className="form-control"
									value={data.bmDescription}
									onChange={handleChange}
									required
								></textarea>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="itemImage">Item Image (BAHASA)</label>
								<input type="file" name="bmFile" id="bmFile" className="form-control" onChange={handleBmFile} accept="image/*" required />
							</div>

							<hr className="my-5" />

							<div className="form-group">
								<label htmlFor="itemBrand">Item Brand</label>
								<input
									type="text"
									name="itemBrand"
									id="itemBrand"
									className="form-control"
									placeholder="Item Brand"
									value={data.itemBrand}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3 text-center">
								<Loader component={<input type="submit" value="SUBMIT" className="form-control btn btn-primary" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
			<Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
				<Modal.Header>
					<Modal.Title>Add Format Choice</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div className="form-group">
						<label htmlFor="newCategory">New Category</label>
						<input
							type="text"
							id="newCategory"
							name="newCategory"
							placeholder="eg: share a little comfort/Baby Product"
							className="form-control"
							value={newCategory}
							onChange={e => setNewCategory(e.target.value)}
							required
						/>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<button type="button" className="btn btn-secondary" onClick={() => setShow(false)}>
						Cancel
					</button>

					<Loader
						component={
							<button type="button" className="btn btn-primary" onClick={addNewCategory}>
								Confirm
							</button>
						}
					/>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		addItem: (data, callback) => dispatch(addItem(data, callback)),
		addCategory: (data, callback) => dispatch(addCategory(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(AddItem);
