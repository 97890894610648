import { useState } from "react";
import { MdNavigateNext } from "react-icons/md";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp  } from "react-icons/io";

function Accordion({ title, children, icon, simple }) {
	const [isOpen, setOpen] = useState(false);

	if (!simple) {
		return (
			<div className={`accordionItem ${isOpen && "accordionItem--opened"}`} onClick={() => setOpen(!isOpen)}>
				<div className={`accordionItem__line_${isOpen ? "open" : "close"}`}>
					<div className="flexContainer">
						<div className="accordionLeft">
							<img className="accordionIcon" src={icon} alt="accordion pic"></img>
						</div>
						<div className="accordionRight">
							<h1 className={`accordionItem__title_${isOpen ? "open" : "close"}`}>{title}</h1>
						</div>
					</div>
					<span className={`accordionItem__icon_${isOpen ? "open" : "close"}`} />
				</div>
				<div className="accordionItem__inner">
					<div className="accordionItem__content">
						<p className="accordionItem__paragraph">{children}</p>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className={`simpleAccordion ${isOpen && "isOpen"}`} onClick={() => setOpen(!isOpen)}>
				<div className="userProfileBox">
					<p className="m-0 p-0">{title}</p>
					{/* <MdNavigateNext className="m-0 p-0 next-button" /> */}
					{isOpen ? <IoIosArrowUp className="m-0 p-0" /> : <IoIosArrowDown className="m-0 p-0" />}
				</div>

				<div className="accordion-content">{children}</div>
			</div>
		);
	}
}

export default Accordion;
