import React from "react";
import { Link } from "react-router-dom";
// Components
import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
import ContentHeader from "../../../component/dashboard/ContentHeader";
// Assets
// import scan from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/qr-code-scan.png"
import { MdNavigateNext } from "react-icons/md";
//Redux
import { connect } from "react-redux";
import { logout } from "../../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

const UserProfile = ({ user, logout }) => {
	const { t } = useTranslation();
	return (
		<div>
			<Header />
			<div className="content" style={{ paddingBottom: "150px" }}>
				<ContentHeader />

				<div className="dashboardProfileContainer">
					<div className="dashboardProfilePicContainer">
						<img className="dashboardProfilePic img-fluid" src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/Frame-12.png" alt="pfpic"></img>
					</div>

					<div className="dashboardProfileContent">
						<div className="dashboardProfile" style={{ height: "100%" }}>
							<div className="user-profile-container">
								<h3 id="username" className="username mobile">
									{user.name.toUpperCase()}
								</h3>
								<h2 id="username" className="username desktop">
									{user.name.toUpperCase()}
								</h2>
								{/* <h3 id="user-tier" className="username">
									{user.tier.toUpperCase()} TIER
								</h3>
								<p className="user-milestone">100 MORE POINTS TO PLATINUM</p> */}
								<div className="desktop-user-info">
									<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star.png" alt="point star" className="img-fluid" />
									<h3>{user.name}</h3>
									<div className="dot-separator"></div>
									{/* <h3>{user.tier.toUpperCase()}</h3> */}
								</div>
							</div>

							<Link className="user-point-container" to="/pointhistory" style={{ textDecoration: "none" }}>
								<p className="user-point-label">YOUR POINTS</p>
								<h4 id="user-point">{user.totalPoints}</h4>
							</Link>
						</div>
					</div>
				</div>

				<div className="userProfileContainer">
					<Link to="/editprofile" style={{ textDecoration: "none" }}>
						<div className="userProfileBox">
							<p className="m-0 p-0">{t("personalinfo")}</p>
							<MdNavigateNext className="m-0 p-0 next-button" />
						</div>
					</Link>

					<Link to="/pointhistory" style={{ textDecoration: "none" }}>
						<div className="userProfileBox">
							<p className="m-0 p-0">{t("pointshistory")}</p>
							<MdNavigateNext className="m-0 p-0 next-button" />
						</div>
					</Link>

					<Link to="/pointshop" style={{ textDecoration: "none" }}>
						<div className="userProfileBox">
							<p className="m-0 p-0">{t("rewards")}</p>
							<MdNavigateNext className="m-0 p-0 next-button" />
						</div>
					</Link>

					{/* <Link to="/pointshop" style={{ textDecoration: "none" }}>
						<div className="userProfileBox">
							<p className="m-0 p-0">Quests</p>
							<MdNavigateNext className="m-0 p-0 next-button" />
						</div>
					</Link> */}

					<Link to="/referandearn" style={{ textDecoration: "none" }}>
						<div className="userProfileBox">
							<p className="m-0 p-0">{t("refernearn")}</p>
							<MdNavigateNext className="m-0 p-0 next-button" />
						</div>
					</Link>

					<Link to="/help" style={{ textDecoration: "none" }}>
						<div className="userProfileBox">
							<p className="m-0 p-0">{t("help")}</p>
							<MdNavigateNext className="m-0 p-0 next-button" />
						</div>
					</Link>
				</div>

				{/* <div className="text-center">
					<button type="button" onClick={() => logout()} className="userProfileButton">
						{t("logoutcap")}
					</button>
				</div> */}
			</div>
			<Footer />
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(logout())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
