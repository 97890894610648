import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
//Redux
import { connect } from "react-redux";
import { getArticle } from "../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

function Article({ article, getArticle, language }) {
	const { t } = useTranslation();
	const [showFullDescription, setShowFullDescription] = useState(false);

	const handleSliderChange = () => {
		setShowFullDescription(false);
	  };

	useEffect(() => {
		if(!article) getArticle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
			<div>
				<Slider autoplay={true} autoplaySpeed={6000} easing="ease-in" dots={true} afterChange={handleSliderChange}>
					{article && article.length ? (
						article
							.filter(x => x.active && x.active === true)
							.map(x => (
									<div className="dashboardNewsBox">
										<div className="text-center">
										<h3 className="wishing-tree-subtitle">{x.title[language]}</h3>
										</div>
										<img src={x.image} alt={x.title} className="dashboardNewsImage"></img>
										<p style={{ textAlign: "center" }}>
											{x.description[language].length > 200
												? showFullDescription
												? x.description[language]
												: x.description[language].substring(0, 200) + "..."
												: x.description[language]}
											{x.description[language].length > 200 && !showFullDescription && (
												<div
												onClick={() => setShowFullDescription(true)}
												style={{ marginLeft: "10px", cursor: "pointer", color: "blue" }}
												>
												{t("seemoreprocap")}
												</div>
											)}
										</p>
									</div>
							))
					) : (
						<></>
					)}
				</Slider>
			</div>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		getArticle: data => dispatch(getArticle(data))
	};
};

const mapStateToProps = state => {
	return {
		article: state.userReducer.article,
		language: state.pageReducer.language
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);
