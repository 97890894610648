import { useState } from "react";
import { connect } from "react-redux";
// import { MdAddCircleOutline } from "react-icons/md";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { editDiaper } from "../../../../redux/actions/adminActions";

const EditSample = ({ location, history, editDiaper }) => {
	const [data, setData] = useState({
		brand: location.data.brandId,
		format: location.data.formatId,
		size: location.data.sizeId,
		itemId: location.data.itemId,
		itemName: location.data.itemName.en,
		uom: location.data.uom,
		inventory: location.data.inventory,
		description: location.data.description.en,
		bmName: location.data.itemName.bm,
		bmDescription: location.data.description.bm
	});

	const [file, setFile] = useState(null);
	const [bmFile, setBmFile] = useState(null);

	const handleChange = e => {
		if (e.target.id === "brand") {
			setData({
				...data,
				format: "",
				size: "",
				[e.target.id]: e.target.value
			});
		} else if (e.target.id === "format") {
			setData({
				...data,
				size: "",
				[e.target.id]: e.target.value
			});
		} else {
			setData({
				...data,
				[e.target.id]: e.target.value
			});
		}
	};

	const handleUpload = e => {
		if (e.target.files[0]) {
			setFile(e.target.files[0]);
		}
	};

	const handleBmUpload = e => {
		if (e.target.files[0]) {
			setBmFile(e.target.files[0]);
		}
	};

	const handleSubmit = e => {
		e.preventDefault();
		const id = location.data.id;
		const { brand, format, size, itemId, itemName, uom, inventory, description, bmName, bmDescription } = data;
		if (file && bmFile) {
			if (brand && format && size && itemId && itemName && uom && description && bmName && bmDescription) {
				const formData = new FormData();
				formData.append("brand", brand);
				formData.append("format", format);
				formData.append("size", size);
				formData.append("itemId", itemId);
				formData.append("itemName", itemName);
				formData.append("uom", uom);
				formData.append("inventory", inventory);
				formData.append("description", description);
				formData.append("file", file);
				formData.append("id", id);
				formData.append("bmName", bmName);
				formData.append("bmDescription", bmDescription);
				formData.append("bmFile", bmFile);

				editDiaper(formData, err => {
					if (err) alert(err);
					else history.push("/admin/sample/dashboard");
				});
			}
		} else {
			if (brand && format && size && itemId && itemName && uom && description) {
				const data = {
					brand,
					format,
					size,
					itemId,
					itemName,
					uom,
					inventory,
					description,
					id,
					bmName,
					bmDescription
				};

				editDiaper(data, err => {
					if (err) alert(err);
					else history.push("/admin/sample/dashboard");
				});
			}
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Edit Sample</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="brand">Brand</label>
								<select id="brand" name="brand" className="form-select" value={data.brand} onChange={handleChange} required>
									<option disabled value="">
										Select Brand
									</option>

									{location.brands.map(brand => (
										<option key={brand.id} value={brand.id}>
											{brand.name}
										</option>
									))}
								</select>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="format">Format</label>
								<select id="format" name="format" className="form-select" value={data.format} onChange={handleChange} required>
									<option disabled value="">
										Select Format
									</option>

									{data.brand
										? location.brands
												.filter(brand => brand.id === data.brand)[0]
												.formats.map(format => (
													<option key={format.id} value={format.id}>
														{format.name.toUpperCase()}
													</option>
												))
										: null}
								</select>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="size">Size</label>
								<select id="size" name="size" className="form-select" value={data.size} onChange={handleChange} required>
									<option disabled value="">
										Select Size
									</option>

									{data.brand && data.format
										? location.brands
												.filter(brand => brand.id === data.brand)[0]
												.sizes.filter(size => size.formatId === data.format)
												.map(size => (
													<option
														key={size.id}
														value={size.id}
														disabled={location.diapers.filter(x => x.sizeId === size.id).length && size.id !== location.data.sizeId}
													>
														{size.name.toUpperCase() + " (" + size.description + ")"}
													</option>
												))
										: null}
								</select>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="itemId">Item ID</label>
								<input
									type="text"
									id="itemId"
									name="itemId"
									placeholder="Item ID"
									className="form-control"
									value={data.itemId}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="itemName">Item Name</label>
								<input
									type="text"
									id="itemName"
									name="itemName"
									placeholder="Item Name (eg: 'DryPantz (Size L) - 48pcs')"
									className="form-control"
									value={data.itemName}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="uom">Unit of Measurement</label>
								<input
									type="text"
									id="uom"
									name="uom"
									placeholder="uom (eg: 'pcs')"
									className="form-control"
									value={data.uom}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="inventory">
									Inventory{" "}
									<small>
										<strong>(* increase to topup/restock)</strong>
									</small>
								</label>
								<input
									type="number"
									id="inventory"
									name="inventory"
									className="form-control"
									value={data.inventory}
									onChange={handleChange}
									min={location.data.redeemed}
									required
								/>
								<hr />
								<p>Current Redeemed: {location.data.redeemed}</p>
								<p>Current Remaining: {location.data.remaining}</p>
								<p>New Remaining: {data.inventory - location.data.redeemed}</p>
								<hr />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="description">Item Description</label>
								<textarea
									id="description"
									name="description"
									placeholder="Item Description"
									className="form-control"
									value={data.description}
									onChange={handleChange}
									required
								></textarea>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="itemName">
									Item Image{" "}
									<small>
										<strong>* Upload to replace current image, need to upload bm as well</strong>
									</small>
								</label>
								<input type="file" id="file" name="file" className="form-control" onChange={handleUpload} accept="image/*" />
							</div>

							<hr className="my-5" />

							<div className="form-group">
								<label htmlFor="bmName">Item Name</label>
								<input
									type="text"
									id="bmName"
									name="bmName"
									placeholder="Item Name (eg: 'DryPantz (Saiz L) - 48pcs')"
									className="form-control"
									value={data.bmName}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="bmDescription">Item Description</label>
								<textarea
									id="bmDescription"
									name="bmDescription"
									placeholder="Item Description (BAHASA)"
									className="form-control"
									value={data.bmDescription}
									onChange={handleChange}
									required
								></textarea>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="itemName">
									Item Image{" "}
									<small>
										<strong>* Upload to replace current BM image</strong>
									</small>
								</label>
								<input type="file" id="bmFile" name="bmFile" className="form-control" onChange={handleBmUpload} accept="image/*" />
							</div>

							<div className="mt-3 form-group text-center">
								<Loader component={<input type="submit" value="SUBMIT" className="btn btn-primary form-control fw-bold" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		editDiaper: (data, callback) => dispatch(editDiaper(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(EditSample);
