import {
  AUTH_USER,
  REGISTER_USER,
  AUTH_ADMIN,
  SCAN_CODE,
  GET_REWARD,
  ADD_ADDRESS,
  FORCE_ADDRESS,
  ADD_BABYINFO,
  EDIT_BABYINFO,
  FORCE_BABYINFO,
  TRACK_ORDER,
  POINT_HISTORY,
  GET_INBOX,
  REMOVE_INBOX,
  READ_MESSAGE,
  ADD_TO_CART,
  SEND_ORDER,
  ADD_FEEDBACK,
  GET_SAMPLE,
  GET_QUESTION,
  GET_NEWS,
  GET_ARTICLE,
  GET_JOURNEY,
  GET_BLOG,
  GET_QUEST,
  GET_CONTEST,
  GET_WISHINGTREE,
  GET_VOUCHER,
  GET_DONATE,
  GET_PREGNANT,
  REDEEM_VOUCHER,
  DONATE_POINTS,
  DONATE_WISHER,
  GET_BRAND_INFO,
  LOG_OUT,
  FETCH_SAMPLE,
  EDIT_CART,
  EDIT_PROFILE,
  ALREADY_LOGIN,
  JUST_LOGIN,
  FORCE_EDIT,
  SEND_PREGNANT,
  ADD_CAMPAIGN_ID,
} from "../types";

const initState = {
  isAuthenticated: false,
  user: null,
  address: null,
  type: null,
  news: null,
  catalogue: null,
  pregnant: null,
  voucher: null,
  donate: null,
  samples: null,
  order: null,
  questions: [],
  brand: null,
  token: localStorage.getItem("dbc-token"),
  cart: {},
  justLoggedIn: false,
  campaignId: null,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case AUTH_USER:
      localStorage.setItem("dbc-token", action.payload.token);
      return {
        ...state,
        user: action.payload.data,
        isAuthenticated: true,
        type: "user",
        token: action.payload.token,
      };
    case ADD_CAMPAIGN_ID:
      return {
        ...state,
        campaignId: action.payload,
      };
    case REGISTER_USER:
      localStorage.setItem("dbc-token", action.payload.token);
      return {
        ...state,
        user: action.payload.data,
        isAuthenticated: true,
        type: "user",
        token: action.payload.token,
      };
    case AUTH_ADMIN:
      localStorage.setItem("dbc-token", action.payload.token);
      return {
        ...state,
        user: action.payload.data,
        token: action.payload.token,
        type: action.payload.role,
        isAuthenticated: true,
      };
    case SCAN_CODE:
      return {
        ...state,
        user: {
          ...state.user,
          totalPoints: state.user.totalPoints + action.payload.points,
          points: [...state.user.points, ...action.payload.data],
        },
      };
    case GET_REWARD:
      return {
        ...state,
        catalogue: action.payload,
      };
    case GET_PREGNANT:
      return {
        ...state,
        pregnant: action.payload,
      };
    case GET_VOUCHER:
      return {
        ...state,
        voucher: action.payload,
      };
    case GET_DONATE:
      return {
        ...state,
        donate: action.payload,
      };
    case GET_NEWS:
      return {
        ...state,
        news: action.payload,
      };
    case GET_ARTICLE:
      return {
        ...state,
        article: action.payload,
      };
    case GET_JOURNEY:
      return {
        ...state,
        journey: action.payload,
      };
    case GET_BLOG:
      return {
        ...state,
        blog: action.payload,
      };
    case GET_QUEST:
      return {
        ...state,
        quest: action.payload,
      };
    case GET_CONTEST:
      return {
        ...state,
        contest: action.payload,
      };
    case GET_WISHINGTREE:
      return {
        ...state,
        wishingtree: action.payload,
      };
    case ADD_TO_CART:
      if (!state.cart[action.payload.id]) {
        return {
          ...state,
          cart: {
            ...state.cart,
            [action.payload.id]: action.payload,
          },
        };
      } else {
        return {
          ...state,
          cart: {
            ...state.cart,
            [action.payload.id]: {
              ...state.cart[action.payload.id],
              quantity: state.cart[action.payload.id].quantity + 1,
            },
          },
        };
      }
    case EDIT_CART:
      return {
        ...state,
        cart: action.payload,
      };
    case ADD_ADDRESS:
      return {
        ...state,
        user: {
          ...state.user,
          addresses: [...state.user.addresses, action.payload],
        },
      };
    case FORCE_ADDRESS:
      return {
        ...state,
        user: {
          ...state.user,
          addresses: [...state.user.addresses, action.payload],
        },
      };
    case SEND_ORDER:
      return {
        ...state,
        user: {
          ...state.user,
          totalPoints: state.user.totalPoints - action.payload.points,
          points: [...state.user.points, action.payload.point],
          redemptions: [...state.user.redemptions, action.payload.order],
        },
        // cart: {}
      };
    case SEND_PREGNANT:
      return {
        ...state,
        user: {
          ...state.user,
          totalPoints: state.user.totalPoints - action.payload.points,
          points: [...state.user.points, action.payload.point],
          redemptions: [...state.user.redemptions, action.payload.order],
        },
        // cart: {}
      };
    case "CLEAR_CART":
      return {
        ...state,
        cart: {},
      };
    case FETCH_SAMPLE:
      return {
        ...state,
        samples: {
          ...action.payload,
          data: action.payload.data.filter((x) => x.active),
        },
      };
    case GET_SAMPLE:
      return {
        ...state,
        order: action.payload,
        user: {
          ...state.user,
          samples: [...state.user.samples, action.payload],
          allowRequest: false,
        },
      };
    case GET_QUESTION:
      return {
        ...state,
        questions: action.payload,
      };
    case ADD_FEEDBACK:
      return {
        ...state,
        user: {
          ...state.user,
          promptFeedback: false,
        },
      };
    case REDEEM_VOUCHER:
      return {
        ...state,
        user: {
          ...state.user,
          vouchers: [...state.user.vouchers, action.payload.voucher],
          points: [...state.user.points, action.payload.point],
          totalPoints: state.user.totalPoints + action.payload.points,
        },
      };
    case DONATE_POINTS:
      return {
        ...state,
        user: {
          ...state.user,
          totalPoints: state.user.totalPoints + action.payload.points,
        },
      };
    case DONATE_WISHER:
      return {
        ...state,
        user: {
          ...state.user,
          totalPoints: state.user.totalPoints + action.payload.points,
        },
      };
    case ADD_BABYINFO:
      return {
        ...state,
        user: {
          ...state.user,
          babyInfos: [...state.user.babyInfos, action.payload],
        },
      };
    case EDIT_BABYINFO:
      return {
        ...state,
        user: {
          ...state.user,
          babyInfos: state.user.babyInfos.map((babyInfo) => {
            if (babyInfo.id === action.payload.id) {
              return {
                ...babyInfo,
                name: action.payload.name,
                birthDate: action.payload.birthDate,
                gender: action.payload.gender,
              };
            } else {
              return babyInfo;
            }
          }),
        },
      };
    case FORCE_BABYINFO:
      return {
        ...state,
        user: {
          ...state.user,
          babyInfos: [...state.user.babyInfos, action.payload],
        },
      };
    case TRACK_ORDER:
      return {
        ...state,
        tracking: action.payload,
      };
    case POINT_HISTORY:
      return {
        ...state,
        point: action.payload,
      };
    case GET_INBOX:
      return {
        ...state,
        inbox: action.payload,
      };
    case READ_MESSAGE:
      return {
        ...state,
        inbox: action.payload,
      };
    case REMOVE_INBOX:
      return {
        ...state,
        inbox: action.payload,
      };
    case GET_BRAND_INFO:
      return {
        ...state,
        brand: action.payload,
      };
    case EDIT_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          name: action.payload.name,
          race: action.payload.race,
          nationality: action.payload.nationality,
        },
      };
    case FORCE_EDIT:
      return {
        ...state,
        user: {
          ...state.user,
          name: action.payload.name,
          race: action.payload.race,
          nationality: action.payload.nationality,
          pregnancyStage: action.payload.pregnancyStage,
          edb: action.payload.edb,
          totalPoints: state.user.totalPoints + action.payload.points,
          points: [...state.user.points, action.payload.newPoint],
          forceUpdated: true,
        },
      };
    case JUST_LOGIN:
      return {
        ...state,
        justLoggedIn: true,
      };
    case ALREADY_LOGIN:
      return {
        ...state,
        justLoggedIn: false,
      };
    case LOG_OUT:
      localStorage.removeItem("dbc-token");
      return {
        isAuthenticated: false,
        user: null,
        address: null,
        type: null,
        news: null,
        article: null,
        journey: null,
        blog: null,
        quest: null,
        contest: null,
        wishingtree: null,
        catalogue: null,
        voucher: null,
        samples: null,
        donate: null,
        order: null,
        questions: [],
        brand: null,
        token: null,
        cart: {},
        justLoggedIn: false,
        campaign: null,
      };
    default:
      return state;
  }
};

export default userReducer;
