import { useTranslation } from "react-i18next";

const NeverBe = () => {
	const { t } = useTranslation();
	const gotoLazada = () => (window.location.href = "https://www.lazada.com.my/shop/drypers/");

	const gotoShopee = () => (window.location.href = "https://shopee.com.my/vindaofficialstore");

	const gotoVinda = () => (window.location.href = "https://vindamall.com/shop/baby.html");

	return (
		<div className="dashboardNeverBeBackground dashboardNeverBeContainer">
			<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/profile.png" className="img-fluid smiley" alt="smiley face" />
			<h1 className="dashboardNeverBeTitle" style={{ textTransform: "none" }}>
				{t("neverbe")}
			</h1>
			<h1 className="dashboardNeverBeText" style={{ textTransform: "none" }}>
				{t("shoponline")}
			</h1>
			<img className="img-fluid" src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/never-be-product.png" alt="neverbeproduct"></img>

			<div className="dashboardNeverBeButtonBox">
				<div className="dashboardNeverBeButton" onClick={gotoVinda}>
					<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/button-orange-frame.png" className="img-fluid" alt="orange box" />
					<p className="m-0 p-0 dashboardNeverBeButtonText">VINDAMALL</p>
				</div>
				<div className="dashboardNeverBeButton" onClick={gotoLazada}>
					<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/button-orange-frame.png" className="img-fluid" alt="orange box" />
					<p className="m-0 p-0 dashboardNeverBeButtonText">LAZADA</p>
				</div>
				<div className="dashboardNeverBeButton" onClick={gotoShopee}>
					<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/button-orange-frame.png" className="img-fluid" alt="orange box" />
					<p className="m-0 p-0 dashboardNeverBeButtonText">SHOPEE</p>
				</div>
			</div>
		</div>
	);
};

export default NeverBe;
