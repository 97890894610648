// import globe from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/globe.png";
// import chatBubble from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/chatBubble.png";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
	const { t } = useTranslation();
	return (
		<div id="contact-section">
			<h1 className="fw-bold">{t("contactus")}</h1>

			<div className="contact-box-container">
				{/* <div className="contact-box">
					<div className="contact-img-container">
						<img src={globe} alt="globe" className="img-fluid contact-img" />
					</div>
					<div className="contact-description-container">
						<p className="m-0 p-0 contact-description">{t("prodcampclub")}</p>
						<p className="m-0 p-0 contact-link">https://www.drypers.com.my/footer/contact-us/</p>
					</div>
				</div> */}

				<div className="contact-box">
					<div className="contact-img-container">
						<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/envelope.png" alt="globe" className="img-fluid contact-img" />
					</div>
					<div className="contact-description-container">
						<p className="contact-description m-0 p-0">
							{t("proreleemail")}:{" "}
							<a href="mailto:my.drypers@vinda.com" className="contact-link m-0 p-0">
								my.drypers@vinda.com
							</a>
						</p>
						<p className="contact-description m-0 p-0">
							{t("campreleemail")}:{" "}
							<a href="mailto:my.drypersbabyclub@vinda.com" className="contact-link m-0 p-0">
								my.drypersbabyclub@vinda.com
							</a>
						</p>
						<p className="contact-description m-0 p-0">
							{t("clubreleemail")}:{" "}
							<a href="mailto:campaigns@vinda.com" className="contact-link m-0 p-0">
								campaigns@vinda.com
							</a>
						</p>
					</div>
				</div>

				<div className="contact-box">
					<div className="contact-img-container">
						<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/phone.png" alt="globe" className="img-fluid contact-img" />
					</div>
					<div className="contact-description-container">
						<p className="m-0 p-0 contact-description">
							{t("foranyfurther")}
							<br />
							{t("time")}
							{/* <br /> <span className="m-0 p-0 contact-link">1-800-88-9988</span> {t('time')} */}
							{/* <br /> {t('oremailat')} <span className="m-0 p-0 contact-link">my.drypersbabyclub@vinda.com</span> */}
						</p>
					</div>
				</div>

				{/* <div className="contact-box">
					<div className="contact-img-container">
						<img src={chatBubble} alt="globe" className="img-fluid contact-img" />
					</div>
					<div className="contact-description-container">
						<p className="m-0 p-0 contact-description">
							{t("chatbot")} <br />
							{t("integratewih")}
						</p>
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default ContactUs;
