// import desktopHeader from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/desktopTopBG.png";
import desktopHeader from "../../assets/NewHeader.jpeg"

const WishingTreeHeader = ({ title }) => {
	return (
		<>
			<div className="top-header">{title ? <h2>{title}</h2> : null}</div>
			{/* <div className="top-header">
				<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/fit-a-thon-font.png" alt="fit a thon title" style={{height:"52px"}} />
			</div> */}
			<img
				src={desktopHeader}
				alt="head"
				className="img-fluid desktop-top-header"
			/>
		</>
	);
};

export default WishingTreeHeader;
