import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { addBlog } from "../../../../redux/actions/adminActions";

const AddBlog = ({ addBlog, history }) => {
	const [data, setData] = useState({
		title: "",
        venue: "",
		description: "",
		descriptionBm: "",
	});


	const [files, setFiles] = useState([]);
	console.log(files);
	const [imgPreviews, setImgPreviews] = useState([]);

	console.log(files);
	console.log(files.length);
	
	const handleChange = e => {
		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleImages = (e) => {
		const fileList = e.target.files;
		const previewArr = [];

		for (let i = 0; i < fileList.length; i++) {
			previewArr.push(URL.createObjectURL(fileList[i]));
		}

		setFiles([...files, ...fileList]);
		setImgPreviews([...imgPreviews, ...previewArr]);
	};
	  
	  
	const handleSubmit = e => {
		e.preventDefault();
		if (data.title && data.description && files.length && data.venue && data.descriptionBm) {
		  const formData = new FormData();
		  formData.append("title", data.title);
		  formData.append("venue", data.venue);
		  formData.append("description", data.description);
		  formData.append("descriptionBm", data.descriptionBm);
	  
		  for (let i = 0; i < files.length; i++) {
			formData.append("files", files[i]);
		  }
	  
		  addBlog(formData, err => {
			if (err) alert(err);
			else history.push("/admin/blog/dashboard");
		  });
		} else {
		  alert("Please fill in all the fields in the form");
		}
	  };
	  

	// const handleSubmit = e => {
	// 	e.preventDefault();
	// 	if (data.title && data.description && files.length && data.venue) {
	// 		const formData = new FormData();
	// 		formData.append("title", data.title);
	// 		formData.append("venue", data.venue);
	// 		formData.append("description", data.description);

	// 		for (let i = 0; i < files.length; i++) {
	// 			formData.append("files", files[i]);
	// 		}

	// 		addBlog(formData, err => {
	// 			if (err) alert(err);
	// 			else history.push("/admin/blog/dashboard");
	// 		});
	// 	} else {
	// 		alert("Please fill in all the fields in the form");
	// 	}
	// };
	  

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3 className="Wishes-Dashboard-Title">Add Blog</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div style={{display: "flex"}} className="form-group">
								<label className="Wishes-Label" htmlFor="title">Title</label>
								<input
									type="text"
									id="title"
									name="title"
									className="form-control"
									placeholder="Charity Name ( Example: Ramadan Charity )"
									value={data.title}
									onChange={handleChange}
									required
									style={{
										border: "2.6402px solid #67BFFF",
										borderRadius: "15.8412px"
									}}
								/>
							</div>

                            <div style={{display: "flex"}} className="form-group mt-3">
								<label className="Wishes-Label" htmlFor="venue">Venue</label>
								<textarea
									name="venue"
									id="venue"
									className="form-control"
									placeholder="Venue Name ( Example: Rumah Hope )"
									value={data.venue}
									onChange={handleChange}
									required
									style={{
										border: "2.6402px solid #67BFFF",
										borderRadius: "15.8412px"
									}}
								></textarea>
							</div>

							<div style={{display: "flex"}} className="form-group mt-3">
								<label className="Wishes-Label" htmlFor="description">Description</label>
								<textarea
									name="description"
									id="description"
									className="form-control"
									placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit , sed do eiusmod tempor incididunt ut labore"
									value={data.description}
									onChange={handleChange}
									required
									style={{
										border: "2.6402px solid #67BFFF",
										borderRadius: "15.8412px"
									}}
								></textarea>
							</div>

							<div style={{display: "flex"}} className="form-group mt-3">
								<label className="Wishes-Label" htmlFor="descriptionBm">Description BM</label>
								<textarea
									name="descriptionBm"
									id="descriptionBm"
									className="form-control"
									placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit , sed do eiusmod tempor incididunt ut labore"
									value={data.descriptionBm}
									onChange={handleChange}
									required
									style={{
										border: "2.6402px solid #67BFFF",
										borderRadius: "15.8412px"
									}}
								></textarea>
							</div>

							<div style={{ display: "flex" }} className="form-group mt-3">
								<label className="Wishes-Label" htmlFor="files">Images</label>
								<div className="form-control"
									style={{
										border: "2.6402px solid #67BFFF",
										borderRadius: "15.8412px"
									}}>
									<div style={{ display: "flex", flexWrap: "wrap" }}>
										{imgPreviews.map((preview, index) => (
											<div className="image-upload-box" key={index}>
												<img src={preview} alt="preview" />
											</div>
										))}
										<div className="image-upload-box" onClick={() => document.getElementById("files").click()}>
											+
										</div>
									</div>
									<input type="file" name="files" id="files" className="form-control" accept="image/*" multiple onChange={handleImages}
										required style={{
											border: "2.6402px solid #67BFFF",
											borderRadius: "15.8412px",
											display: "none",
										}} />
								</div>
							</div>

							 <div className="form-group mt-5 text-center">
								<Loader component={ 
								<input type="submit" value="submit" className="form-control btn btn-primary" />} />
							 </div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		addBlog: (data, callback) => dispatch(addBlog(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(AddBlog);

