// import React, { useEffect, useState } from 'react';
// import {Link} from 'react-router-dom';
// Components
import Header from "../../component/dashboard/Header";
// import Accordion from "../../component/dashboard/Accordion";
import WaysToCollect from "./Point/WaysToCollect";
import Footer from "../../component/dashboard/Footer";
// Assets
// import scan from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/qr-code-scan.png";
// import friend from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/laugh.png";
// import flag from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/finish.png";
// import { BsTelephoneFill, BsFacebook } from "react-icons/bs";
// import { IoIosMail } from "react-icons/io";
// import { IoLocationSharp } from "react-icons/io5";
// import { AiFillTwitterCircle } from "react-icons/ai";
// import { FaWhatsappSquare } from "react-icons/fa";
import ContentHeader from "../../component/dashboard/ContentHeader";
import { useTranslation } from 'react-i18next';

function HelpInfo() {
	const { t } = useTranslation();
	return (
		<div>
			<Header />
			<div className="content" style={{ paddingBottom: "150px" }}>
				<ContentHeader />
				<div className="text-center">
					<h1 className="dbc-title">{t('waysto')}</h1>
				</div>

				<WaysToCollect />

				{/* <div className="helpInfoBottomBox">
					<div>
						<h1 className="contactUsTitle">Contact Us</h1>
						<h1 className="contactUsText">Contact us and our team will get back to you as soon as possible</h1>
					</div>
					<div className="contactUsHolder">
						<div className="contactUsBox flexContainer">
							<div>
								<BsTelephoneFill style={{ fontSize: "1.5rem", fill: "#0062BF" }}></BsTelephoneFill>
							</div>
							<div>
								<h1 className="contactUsInfo">+01 23456789</h1>
							</div>
						</div>
						<div className="contactUsBox flexContainer">
							<div>
								<IoIosMail style={{ fontSize: "2rem", fill: "#0062BF" }}></IoIosMail>
							</div>
							<div>
								<h1 className="contactUsInfo">my.dryper@vinda.com</h1>
							</div>
						</div>
						<div className="contactUsBox flexContainer">
							<div>
								<IoLocationSharp style={{ fontSize: "2rem", fill: "#0062BF" }}></IoLocationSharp>
							</div>
							<div>
								<h1 className="contactUsInfo">Office Location</h1>
							</div>
						</div>
					</div>
					<div className="socialIconBox flexContainer">
						<div className="socialIcon">
							<BsFacebook style={{ fontSize: "1.5rem", fill: "#0062BF" }}></BsFacebook>
						</div>
						<div className="socialIcon">
							<AiFillTwitterCircle style={{ fontSize: "1.5rem", fill: "#0062BF" }}></AiFillTwitterCircle>
						</div>
						<div className="socialIcon">
							<FaWhatsappSquare style={{ fontSize: "1.5rem", borderRadius: "15px", fill: "#0062BF" }}></FaWhatsappSquare>
						</div>
					</div>
				</div> */}
			</div>
			<Footer />
		</div>
	);
}

export default HelpInfo;
