import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
//Redux
import { connect } from "react-redux";
import { getBlog } from "../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

function Blog({ blog, getBlog, language }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!blog) getBlog();
  }, []);

  const [visible, setVisible] = useState([]);

  const showModal = (index, value) => {
    const newVisible = [...visible];
    newVisible[index] = value;
    setVisible(newVisible);
  };

  return (
    <div className="text-center">
      {blog && blog.length ? (
        blog
        .filter(x => x.active && x.active === true)
        .map((x, index) => (
          <div key={index} style={{marginTop: "20px", padding: "25px", background: "white", boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)", borderRadius: "10px", margin: "25px"}}>
            <h1 className="blog-title"
             onClick={() => showModal(index, true)}
            >{x.title}</h1>
            <h5 className="date-venue-text" style={{cursor: "pointer"}}>{t("venueBlog")}: {x.venue}</h5>
            <h3 className="charity-description" style={{cursor: "pointer"}}>{x.description[language]}</h3>
            <div style={{ display: "flex" }}>
            <div style={{ width: "50%", textAlign: "center" }}>
              <img style={{ width: "99%", height: "100%",  objectFit: "cover", borderRadius: "20px 0px 0px 20px" }} src={x.images[0].url} alt={`Image 1`} />
            </div>
            <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
              <div style={{ width: "100%", textAlign: "center", marginBottom: "5px" }}>
                <img style={{ width: "99%", borderRadius: "0px 20px 0px 0px" }} src={x.images[1].url} alt={`Image 2`} />
              </div>
              <div style={{ width: "100%", textAlign: "center", position: "relative" }}>
              <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: "0" }}></div>
                <img onClick={() => showModal(index, true)} style={{ width: "99%", borderRadius: "0px 0px 20px 0px" }} src={x.images[2].url} alt={`Image 3`} />
                {x.images.length > 3 && (
                  <div onClick={() => showModal(index, true)} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <p style={{ color: "white", fontSize: "30px", fontWeight: "bold", padding: "10px", borderRadius: "5px" }}>+{x.images.length - 3}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
            <Modal show={visible[index]} onHide={() => showModal(index, false)} centered>
            <Modal.Header closeButton>
            </Modal.Header>
              <Modal.Body className="text-center">
                <h1 className="dbc-title">{x.title}</h1>
                <h5 className="date-venue-text">{t("venueBlog")}: {x.venue}</h5>
                <h3 className="charity-description">{x.description[language]}</h3>
                {visible[index] && (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {x.images.map((image, i) => (
                      <div key={i} style={{ width: "50%", textAlign: "center" }}>
                      <img className="blog-modal" src={image.url} alt={`Modal Image ${i + 1}`} />
                      </div>
                    ))}
                  </div>
                )}
              </Modal.Body>
            </Modal>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}


const mapDispatchToProps = dispatch => {
	return {
		getBlog: data => dispatch(getBlog(data))
	};
};

const mapStateToProps = state => {
	return {
		blog: state.userReducer.blog,
		language: state.pageReducer.language
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
