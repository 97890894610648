const InteractiveBox = ({ imgSrc, iconSrc, overlayTitle, overlayDescription, mainDescription }) => {
	return (
		<div className="interactive-box">
			<img src={imgSrc} alt="interactive background" className="img-fluid interactive-image" />

			<div className="interactive-token-container">
				<img src={iconSrc} alt="token" className="img-fluid interactive-token" />
			</div>

			<div className="interactive-main-description">
				<p className="m-0 p-0">{mainDescription}</p>
			</div>

			<div className="interactive-overlay">
				<img src={iconSrc} alt="interactive icon" className="img-fluid overlay-image" />
				<h3 className="dbc-title overlay-title">{overlayTitle}</h3>
				<p className="overlay-description">{overlayDescription}</p>
			</div>
		</div>
	);
};

export default InteractiveBox;
