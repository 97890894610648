import React from "react";
// import moment from "moment";
// import { Link } from 'react-router-dom'
// Components
import Header from "../../component/dashboard/Header";
import Footer from "../../component/dashboard/Footer";
import ContentHeader from "../../component/dashboard/ContentHeader";
// Assets
// import pointstaricon from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star-2.png";
import { useTranslation } from "react-i18next";

function Inbox() {
	const { t } = useTranslation();
	return (
		<div>
			<Header />
			<div className="content">
				<ContentHeader title={t("inboxlow")} />

				<div className="text-end pe-5">
					<p>{t("clearall")}</p>
				</div>

				{/* <div className="inboxBox">
					<div className="inboxContainer">
						<div className="inboxImageContainer">
							<img className="inboxImage" src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star-2.png" alt="pointstar"></img>
						</div>

						<div className="inboxTextContainer">
							<h5 className="inboxTitleText">{t('letcelebrate')}</h5>
							<p className="inboxTitleDesc">{t('uploadpicture')}</p>
						</div>

						<div className="inboxDateContainer">
							<h6 className="inboxDate m-0 p-0">{moment().format("DD MMM")}</h6>
						</div>
					</div>
				</div> */}
			</div>
			<Footer></Footer>
		</div>
	);
}

export default Inbox;
