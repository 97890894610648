import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { addWishingTree } from "../../../../redux/actions/adminActions";
import avatar1 from "../../../../assets/Avatar1.png"
import avatar2 from "../../../../assets/Avatar2.png"
import avatar3 from "../../../../assets/Avatar3.png"
import avatar4 from "../../../../assets/Avatar4.png"
import avatar5 from "../../../../assets/Avatar5.png"
import avatar6 from "../../../../assets/Avatar6.png"

const AddWishingTree = ({ addWishingTree, history }) => {
	const [data, setData] = useState({
		name: "",
        wishes: "",
		// points: "",
		selectedAvatar: "",
		age: "",
		bmWishes: "",
	});


	const [avatars, setAvatars] = useState([
		{ id: 1, src: avatar1  },
		{ id: 2, src: avatar2  },
		{ id: 3, src: avatar3 },
		{ id: 4, src: avatar4  },
		{ id: 5, src: avatar5  },
		{ id: 6, src: avatar6 },
	  ]);

	const handleChange = e => {
		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleAvatarSelect = (src) => {
		setData({
			...data,
			selectedAvatar: src,
		});
	};
	

	const handleSubmit = (e) => {
		e.preventDefault();
		if (
		  data.name &&
		  data.wishes &&
		  data.selectedAvatar &&
		  data.age &&
		  data.bmWishes
		//   data.points
		) {
		  const formData = new FormData();
		  formData.append("name", data.name);
		  formData.append("wishes", data.wishes);
		  formData.append("age", data.age);
		  formData.append("bmWishes", data.bmWishes)
		//   formData.append("points", data.points);
		  formData.append("selectedAvatar", data.selectedAvatar);
		  addWishingTree(formData, (err) => {
			if (err) alert(err);
			else history.push("/admin/wishingtree/dashboard");
		  });
		} else {
		  alert("Please fill in all the fields in the form");
		}
	  };

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3 className="Wishes-Dashboard-Title">Add Wishing Tree</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>


							<div style={{ display: "flex" }} className="form-group mt-3">
							<label className="Wishes-Label" htmlFor="avatar">
								Avatar
							</label>
							<div className="form-control" style={{ border: "2.6402px solid rgb(103, 191, 255)", display: "flex" ,borderRadius: "15.8412px" }}>
								{avatars.map((avatar) => (
								<div
									key={avatar.id}
									className="d-flex align-items-center mr-3"
									onClick={() => handleAvatarSelect(avatar.src)}
									style={{ cursor: "pointer" }}
								>
									<div
									style={{
										width: "100px",
										height: "100px",
										backgroundImage: `url(${avatar.src})`,
										backgroundPosition: "center",
										backgroundSize: "cover",
										borderRadius: "50%",
										border: data.selectedAvatar === avatar.src ? "2.6402px solid #67BFFF" : "none",
									}}
									></div>
								</div>
								))}
							</div>
							</div>



							<div style={{display: "flex"}} className="form-group mt-3">
								<label className="Wishes-Label" htmlFor="name">Name</label>
								<input
									type="text"
									id="name"
									name="name"
									className="form-control"
									placeholder="Full Name ( Example: Azim )"
									value={data.name}
									onChange={handleChange}
									required
									style={{
										border: "2.6402px solid #67BFFF",
										borderRadius: "15.8412px"
									}}
								/>
							</div>

							<div style={{display: "flex"}} className="form-group mt-3">
								<label className="Wishes-Label" htmlFor="name">Age</label>
								<input
									type="text"
									id="age"
									name="age"
									className="form-control"
									placeholder="Age ( Example: 9 )"
									value={data.age}
									onChange={handleChange}
									required
									style={{
										border: "2.6402px solid #67BFFF",
										borderRadius: "15.8412px"
									}}
								/>
							</div>

                            <div style={{display: "flex"}} className="form-group mt-3">
								<label className="Wishes-Label" htmlFor="wishes">Wished</label>
								<textarea
									name="wishes"
									id="wishes"
									className="form-control"
									placeholder="Item Name ( Example: Remote Control Car )"
									value={data.wishes}
									onChange={handleChange}
									required
									style={{
										border: "2.6402px solid #67BFFF",
										borderRadius: "15.8412px"
									}}
								></textarea>
							</div>

							<div style={{display: "flex"}} className="form-group mt-3">
								<label className="Wishes-Label" htmlFor="bmWishes">Wished (BM)</label>
								<textarea
									name="bmWishes"
									id="bmWishes"
									className="form-control"
									placeholder="Item Name ( Example: Remote Control Car )"
									value={data.bmWishes}
									onChange={handleChange}
									required
									style={{
										border: "2.6402px solid #67BFFF",
										borderRadius: "15.8412px"
									}}
								></textarea>
							</div>

							{/* <div style={{display: "flex"}} className="form-group mt-3">
								<label className="Wishes-Label" htmlFor="points">Points</label>
								<textarea
									name="points"
									id="points"
									className="form-control"
									placeholder="Number of Points: ( Example: 500 )"
									value={data.points}
									onChange={handleChange}
									required
									style={{
										border: "2.6402px solid #67BFFF",
										borderRadius: "15.8412px"
									}}
								></textarea>
							</div> */}

							<div className="form-group mt-5 text-center">
								<Loader component={<input type="submit" value="SUBMIT" className="form-control btn btn-primary" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		addWishingTree: (data, callback) => dispatch(addWishingTree(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(AddWishingTree);
