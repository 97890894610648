// import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
// Components
import Header from "../../component/dashboard/Header";
import Footer from "../../component/dashboard/Footer";
// Assets
// import { AiOutlineSearch } from "react-icons/ai";
import ContentHeader from "../../component/dashboard/ContentHeader";
import Accordion from "../../component/dashboard/Accordion";
import { useTranslation } from "react-i18next";
import TNC_EN from "../../assets/files/TNC_EN_sso.pdf"
import TNC_BM from "../../assets/files/TNC_BM_sso.pdf"
import { connect } from "react-redux";

function Help({language}) {
	const { t } = useTranslation();
	return (
		<div>
			<Header />
			<div className="content" style={{ paddingBottom: "150px" }}>
				<ContentHeader title={t("help")} />
				<div className="desktop-only text-center fat-stroke">
					<h1 className="dbc-title" style={{ color: "white", marginTop: "30px" }}>
						{t("help")}
					</h1>
				</div>

				{/* <div class="searchBarWrapper">
					<div class="searchBar">
						<input id="searchQueryInput" type="text" name="searchQueryInput" placeholder={t("search")} value="" />
						<button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
							<svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
								<path
									fill="#666666"
									d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
								/>
							</svg>
							<AiOutlineSearch className="m-0 p-0" />
						</button>
					</div>
				</div> */}

				{/* <div className="helpTitleContainer">
					<h1 className="dashboardProfileLevel">{t("faq")}</h1>
				</div> */}
				{/* <div className="helpTipsBox flexContainer">
					<Link to="/faq/how-to-collect-points" style={{ textDecoration: "none" }}>
						<div className="helpTipsContain">
							<h1 className="helpTipsTitle">{t("howtoredeempoints")}</h1>
						</div>
					</Link>
					<Link to="/faq/drypers-sample-request" style={{ textDecoration: "none" }}>
						<div className="helpTipsContain">
							<h1 className="helpTipsTitle">{t("whatrewards")}</h1>
						</div>
					</Link>
					<Link to="/faq/resend-activation-link" style={{ textDecoration: "none" }}>
						<div className="helpTipsContain">
							<h1 className="helpTipsTitle">{t("findouthowcan")}</h1>
						</div>
					</Link>
				</div> */}

				<div className="helpTitleContainer">
					<h1 className="dashboardProfileLevel">{t("browseby")}</h1>
				</div>

				<div className="userProfileContainer mt-0">
					<Accordion simple={true} title={t("helptitle1")}>
						<p>{t("help1")}</p>
						<p>{t("help2")}</p>
						<ul>
							<li>{t("help3")}</li>
							<li>{t("help4")}</li>
							<li>{t("help5")}</li>
							<li>{t("help6")}</li>
						</ul>
						<p>{t("help7")}</p>
						<p>{t("tnca")}</p>
					</Accordion>
					<Accordion simple={true} title={t("helptitle2")}>
						<p>{t("help8")}</p>
						<p>{t("help9")}</p>
						<ol>
							<li>{t("help10")}</li>
							<li>{t("help11")}</li>
							<li>{t("help12")}</li>
							<li>{t("help13")}</li>
							<li>{t("help14")}</li>
						</ol>
					</Accordion>
					<Accordion simple={true} title={t("helptitle3")}>
						<p>{t("help15")}</p>
						<p>{t("help9")}</p>
						<ol>
							<li>{t("help16")}</li>
							<li>{t("help17")}</li>
							<li>{t("help18")}</li>
							<li>{t("help19")}</li>
						</ol>
					</Accordion>
					<Accordion simple={true} title={t("helptitle4")}>
						<p>{t("help20")}</p>
						<p>{t("help9")}</p>
						<ol>
							<li>{t("help21")}</li>
							<li>{t("help22")}</li>
							<li>{t("help23")}</li>
							<li>{t("help24")}</li>
							<li>{t("help25")}</li>
						</ol>
					</Accordion>
					<Accordion simple={true} title={t("helptitle6")}>
						<ol>
							<li>{t("helpsso")}</li>
							<ul>
								<li>{t("helpsso2")}</li>
							</ul>
							<li>{t("helpsso3")}</li>
							<ul>
								<li>{t("helpsso4")}</li>
							</ul>
							<li>{t("helpsso5")}</li>
							<ul>
								<li>{t("helpsso6")}</li>
							</ul>
							<li>{t("helpsso7")}</li>
							<ul>
								<li>{t("helpsso8")}</li>
							</ul>
							<li>{t("helpsso9")}</li>
							<ul>
								<li>{t("helpsso10")}</li>
							</ul>
							<li>{t("helpsso11")}</li>
							<ul>
								<li>{t("helpsso12")}</li>
							</ul>
							<li>{t("helpsso13")}</li>
							<ul>
								<li>{t("helpsso14")}</li>
							</ul>
							<li>{t("helpsso15")}</li>
							<ul>
								<li>{t("helpsso16")}</li>
							</ul>
							<li>{t("helpsso17")}</li>
							<ul>
								<li>{t("helpsso18")}</li>
							</ul>
							<li>{t("helpsso19")}</li>
							<ul>
								<li>{t("helpsso20")}</li>
							</ul>
							<li>{t("helpsso21")}</li>
							<ul>
								<li>{t("helpsso22")}<a href={language === 'en' ? TNC_EN : TNC_BM} target="_blank" style={{ textDecoration: "underline", color: "blue" }}>{t("helpsso22b")}</a>{t("helpsso22c")}</li>
							</ul>
						</ol>
					</Accordion>

				</div>
			</div>
			<Footer />
		</div>
	);
}

// export default Help;

const mapStateToProps = state => {
	return {
		language: state.pageReducer.language
	};
};

export default connect(mapStateToProps, null)(Help);
