import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { MdManageAccounts, MdStars, MdAccountCircle, MdReceiptLong } from "react-icons/md";
import { AiOutlineScan, AiFillGift } from "react-icons/ai";
import { FiActivity } from "react-icons/fi";
import { GiSwapBag } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import axios from "axios";
import { connect } from 'react-redux'

const AdminSidebar = ({type}) => {
	const goToDinsights = async () => {
		try {
			const { data } = await axios.get("/drypers-baby-club/api/receipt/d-insights-token");
			if (data && data.data)
				window.location.href = `https://d-insights.thedgroup.com.my/admin/login/dbc?token=${data.data}`;
		} catch (error) {
			if (error?.response?.data?.error) alert(error.response.data.error);
			else alert(error.message);
		}
	};

	return (
		<div id="sidebar-container">
			<ProSidebar breakPoint="sm" id="react-pro-sidebar">
				<SidebarHeader>
					<h2>image here</h2>
				</SidebarHeader>

				<SidebarContent>
					<Menu iconShape="round" popperArrow={true} innerSubMenuArrows={true}>
						{type && type === "superadmin" ? null : <MenuItem icon={<AiOutlineScan />}>
							Scan <Link to="/admin/scan" />
						</MenuItem>}

						{type && type === "superadmin" ? null : <MenuItem icon={<AiFillGift />}>
							PointShop <Link to="/admin/pointshop"></Link>
						</MenuItem>}

						{type && type === "superadmin" ? null : <MenuItem icon={<MdStars />}>
							PointHistory <Link to="/admin/pointhistory" />
						</MenuItem>}

						{/* <MenuItem icon={<AiFillMessage />}>
							Inbox <Link to="/admin/inbox" />
						</MenuItem> */}

						<MenuItem icon={<MdAccountCircle />}>
							Accounts <Link to="/admin/account" />
						</MenuItem>

						{/* <MenuItem icon={<IoMdAlert />}>
							Quests <Link to="/admin/account" />
						</MenuItem> */}

						{type && type === "superadmin" ? null : <MenuItem icon={<GiSwapBag />}>
							Free Sample <Link to="/admin/freesample" />
						</MenuItem>}

						{type && type === "superadmin" ? null : <SubMenu title="Contest Management" icon={<FiActivity />} defaultOpen={false}>
							<MenuItem>
								Contest <Link to="/admin/fitathon" />
							</MenuItem>

							<MenuItem>
								Manage <Link to="/admin/contest/dashboard" />
							</MenuItem>
						</SubMenu>}

						{/* <MenuItem icon={<MdOutlineForum />}>
							Forum <Link to="/admin/account" />
						</MenuItem> */}

						{type && type === "superadmin" ? null : <SubMenu title="Wishing Tree" icon={<MdManageAccounts />} defaultOpen={false}>
							<MenuItem>
								Wisher <Link to="/admin/wishingtree/dashboard" />
							</MenuItem>

							<MenuItem>
								Article <Link to="/admin/article/dashboard" />
							</MenuItem>

							<MenuItem>
								Blog <Link to="/admin/blog/dashboard" />
							</MenuItem>

							<MenuItem>
								Journey <Link to="/admin/journey/dashboard" />
							</MenuItem>
						</SubMenu>}

						{type && type === "superadmin" ? null : <SubMenu title="Manage" icon={<MdManageAccounts />} defaultOpen={false}>
							<MenuItem>
								Brands <Link to="/admin/brand/dashboard" />
							</MenuItem>

							<MenuItem>
								Sample <Link to="/admin/sample/dashboard" />
							</MenuItem>

							<MenuItem>
								Sample Survey <Link to="/admin/sample/survey/dashboard" />
							</MenuItem>

							<MenuItem>
								Generate Scan Codes <Link to="/admin/scancode/dashboard" />
							</MenuItem>

							<MenuItem>
								Point Shop <Link to="/admin/catalog/dashboard" />
							</MenuItem>

							<MenuItem>
								Vouchers <Link to="/admin/voucher/dashboard" />
							</MenuItem>

							<MenuItem>
								Share a Little Comfort <Link to="/admin/donate/dashboard" />
							</MenuItem>

							<MenuItem>
								News <Link to="/admin/news/dashboard" />
							</MenuItem>

							<MenuItem>
								Mass Credit/Refund Points <Link to="/admin/massCredit/dashboard" />
							</MenuItem>

							<MenuItem>
								Promoter <Link to="/admin/promoter/dashboard" />
							</MenuItem>

							<MenuItem>
								Pregnant Offer <Link to="/admin/pregnant/dashboard" />
							</MenuItem>

							{/* <MenuItem>
								Contest Management <Link to="/admin/contest/dashboard" />
							</MenuItem> */}
						</SubMenu>}

						{type && type === "superadmin" ? null : <MenuItem icon={<MdReceiptLong />} onClick={goToDinsights}>
							Receipt Validation
						</MenuItem>}

						{type && type === "superadmin" ? null : <MenuItem icon={<FaUsers />}>
							Bulk Create User
							<Link to="/admin/bulkCreate/users/dashboard" />
						</MenuItem>}
					</Menu>
				</SidebarContent>
			</ProSidebar>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		type: state.userReducer.type
	}
}

export default connect(mapStateToProps)(AdminSidebar);
