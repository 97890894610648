import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Components
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Loader from "../../../component/Loader";
import Footer from "../../../component/dashboard/Footer";
import DatePicker from "react-datepicker";
import { MdNavigateNext } from "react-icons/md";
import { Modal } from "react-bootstrap";
//Redux
import { connect } from "react-redux";
import { editProfile, clearError } from "../../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

const EditProfile = ({ user, editProfile, pageReducer, clearError, history }) => {
	const { t } = useTranslation();
	const [data, setData] = useState({
		name: user.name,
		race: user.race,
		nationality: user.nationality,
		pregnancyStage: user.pregnancyStage,
		edb: new Date(user.edb)
	});

	const [canEdit, setCanEdit] = useState(false);

	useEffect(() => {
		const latestUpdate = new Date(user.pregnancyStageUpdatedAt || "1990-01-01");
		const after6Months = new Date(latestUpdate.getTime());
		after6Months.setMonth(latestUpdate.getMonth() + 6);
		const now = new Date();
		console.log(now);
		console.log(after6Months);
		setCanEdit(now >= after6Months);
	}, [user.pregnancyStageUpdatedAt]);

	const [inputError, setInputError] = useState({
		name: null
	});

	const [success, setSuccess] = useState(false);

	const handleChange = e => {
		setData({ ...data, [e.target.name]: e.target.value });
		if (e.target.name === "name") {
			const nameRegex = /^[a-zA-Z ]*$/;
			if (!nameRegex.test(e.target.value)) {
				setInputError({
					...inputError,
					name: "You have entered an invalid name"
				});
			} else {
				setInputError({
					...inputError,
					name: null
				});
			}
		}
	};

	const handleSubmit = e => {
		e.preventDefault();

		// console.log(data.name !== user.name);
		// console.log(data.race !== user.race);
		// console.log(data.nationality !== user.nationality);
		// console.log(data.pregnancyStage !== user.pregnancyStage);

		// console.log(data.name, user.name);
		// console.log(data.race, user.race);
		// console.log(data.nationality, user.nationality);
		// console.log(data.pregnancyStage, user.pregnancyStage);
		if (
			data.name !== user.name ||
			data.race !== user.race ||
			data.nationality !== user.nationality ||
			data.pregnancyStage !== user.pregnancyStage ||
			new Date(data.edb).toString() !== new Date(user.edb).toString()
		) {
			if (data.pregnancyStage === "pregnant") {
				const sendThis = {
					id: user.id,
					name: data.name,
					race: data.race,
					nationality: data.nationality,
					pregnancyStage: data.pregnancyStage,
					edb: data.edb
				};
				editProfile(sendThis, () => setSuccess(true));
			} else {
				const sendThis = {
					id: user.id,
					name: data.name,
					race: data.race,
					nationality: data.nationality,
					pregnancyStage: data.pregnancyStage,
					edb: null
				};
				editProfile(sendThis, () => setSuccess(true));
			}
		} else history.push("/userprofile");
	};

	const handleMobileCode = num => {
		if (num && num.length > 8 && num[0].toString() !== "0") {
			return "+";
		} else {
			return "+6";
		}
	};

	return (
		<div>
			<Header />
			<div className="content">
				<ContentHeader />

				<form onSubmit={handleSubmit} className="vinda-form" style={{ paddingBottom: "150px" }}>
					<div className="form-group vinda-form-group">
						<label htmlFor="name" className="vinda-form-label">
							{t("name")}
						</label>
						<input
							type="text"
							name="name"
							id="name"
							className="form-control vinda-form-input fw-bold no-border"
							placeholder={t("fullname")}
							value={data.name}
							onChange={handleChange}
							required
						/>
						{inputError.name ? <span style={{ color: "red" }}>{inputError.name}</span> : null}
					</div>

					<div className="form-group vinda-form-group">
						<label htmlFor="number" className="vinda-form-label">
							{t("phonenumber")}
						</label>

						<div className="input-group">
							<span className="input-group-text no-border fw-bold">{handleMobileCode(user.number)}</span>
							<input
								type="text"
								name="number"
								id="number"
								className="form-control vinda-form-input fw-bold no-border"
								placeholder={t("phonenumber")}
								value={user.number}
								readOnly
								disabled
								required
							/>
						</div>
					</div>

					<div className="form-group vinda-form-group">
						<label htmlFor="email" className="vinda-form-label">
							{t("email")}
						</label>
						<input
							type="email"
							name="email"
							id="email"
							className="form-control vinda-form-input no-border fw-bold"
							placeholder={t("emailaddress")}
							value={user.email}
							readOnly
							disabled
							required
						/>
					</div>

					<div className="form-group vinda-form-group">
						<label htmlFor="race" className="vinda-form-label">
							{t("race")}
						</label>
						<select
							name="race"
							id="race"
							className="form-select vinda-form-input no-border fw-bold"
							value={data.race}
							onChange={handleChange}
							required
						>
							<option disabled value="">
								{t("race")}
							</option>
							<option value="malay">Malay</option>
							<option value="chinese">Chinese</option>
							<option value="indian">Indian</option>
							<option value="other">{t("others")}</option>
						</select>
					</div>

					<div className="form-group vinda-form-group">
						<label htmlFor="email" className="vinda-form-label">
							{t("nationality")}
						</label>
						{/* <input type="text" name="nationality" id="nationality" className="form-control vinda-form-input no-border fw-bold" placeholder="Nationality" value={data.nationality} onChange={handleChange} required /> */}
						<select
							name="nationality"
							id="nationality"
							className="form-select vinda-form-input no-border fw-bold"
							value={data.nationality}
							onChange={handleChange}
							required
						>
							<option disabled value="">
								Nationality
							</option>
							<option value="malaysian">Malaysian</option>
							<option value="non-malaysian">Non-Malaysian</option>
						</select>
					</div>

					<div className="form-group vinda-form-group">
						<label className="vinda-form-label" htmlFor="pregnancyStage">
							{t("currentstage")}
						</label>

						<select
							id="pregnancyStage"
							name="pregnancyStage"
							className="form-select vinda-form-input no-border fw-bold"
							value={data.pregnancyStage}
							onChange={handleChange}
							disabled={!canEdit}
							required
						>
							<option disabled value="">
								{t("currentstage")}
							</option>
							<option value="trying">{t("trying")}</option>
							<option value="pregnant">{t("pregnant")}</option>
							<option value="oneChild">{t("oneChild")}</option>
							<option value="moreChild">{t("moreChild")}</option>
						</select>
					</div>
					{data.pregnancyStage === "pregnant" && canEdit ? (
						<div className="form-group vinda-form-group">
							<label className="vinda-form-label" htmlFor="pregnancyStage">
								{t("edb")}
							</label>
							<DatePicker
								selected={data.edb}
								// maxDate={new Date(moment().subtract(15, "years"))}
								// includeDateIntervals={[{ start: new Date(moment().subtract(100, "years")), end: new Date(moment().subtract(15, "years")) }]}
								onChange={date => setData({ ...data, edb: date })}
								id="edb"
								name="edb"
								className="form-control vinda-form-input no-border fw-bold"
								placeholderText={t("edb")}
								showYearDropdown
								showMonthDropdown
								dropdownMode="select"
								dateFormat="dd/MM/yyyy"
								disabled={!canEdit}
								required
							/>
						</div>
					) : null}

					<div className="form-group vinda-form-group userProfileContainer mt-4 mb-5">
						<Link to={{ pathname: "/viewAddress", type: "user" }} style={{ textDecoration: "none" }}>
							<div className="userProfileBox">
								<p className="m-0 p-0">{t("myaddress")}</p>
								<MdNavigateNext className="m-0 p-0 next-button" />
							</div>
						</Link>

						<Link to="/viewbabyinfo" style={{ textDecoration: "none" }}>
							<div className="userProfileBox">
								<p className="m-0 p-0">{t("babyinfo")}</p>
								<MdNavigateNext className="m-0 p-0 next-button" />
							</div>
						</Link>
					</div>

					<div className="form-group vinda-form-group text-center">
						<Loader
							component={
								<input
									type="submit"
									value={
										data.name !== user.name ||
										data.race !== user.race ||
										data.nationality !== user.nationality ||
										data.pregnancyStage !== user.pregnancyStage ||
										new Date(data.edb).toString() !== new Date(user.edb).toString()
											? t("save")
											: t("back")
									}
									className="vinda-form-submit btn"
								/>
							}
						/>
					</div>
				</form>

				<Footer />
			</div>
			<Modal show={pageReducer.error} onHide={() => clearError()} centered>
				<Modal.Body className="text-center">
					<div>
						<p className="addToCartTitle">{t("error")}</p>
						<p className="mt-3 pickSampleModalText">{pageReducer.error}</p>
					</div>

					<div>
						<button className="pickAddressConfirmButton" onClick={() => clearError()}>
							OKAY
						</button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal show={success} onHide={() => {setSuccess(false); history.push("/userprofile")}} centered>
				<Modal.Body className="text-center">
					<div>
						<p className="addToCartTitle">{t("done")}</p>
						<p className="mt-3 pickSampleModalText">{t("profileUpdated")}</p>
					</div>

					<div>
						<button className="pickAddressConfirmButton" onClick={() => {setSuccess(false); history.push("/userprofile")}}>
							OKAY
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		pageReducer: state.pageReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		editProfile: (data, callback) => dispatch(editProfile(data, callback)),
		clearError: () => dispatch(clearError())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
