import { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import { AiOutlinePlus, AiFillEdit } from "react-icons/ai";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";
import Loader from "../../../component/Loader";

const DiaperDashboard = ({ history, loading, loaded }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		loading();
		axios
			.get("/drypers-baby-club/api/admin/fetch/dashboard/brand")
			.then(res => {
				setData(res.data);
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addDiaper = () => history.push("/admin/brand/add");

	const viewDetails = brand => history.push({ pathname: "/admin/brand/details", data: brand });

	const handleEdit = brand => history.push({ pathname: "/admin/brand/edit", data: brand });

	return (
		<div className="admin-page">
			<AdminSidebar />
			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					{/* contents */}
					<h3>Manage Brands</h3>

					<div className="mt-5 diaper-dashboard">
						<Loader
							component={
								data && data.length
									? data.map(brand => (
											<div key={brand.id} className="diaper-box">
												<AiFillEdit className="edit-icon" onClick={() => handleEdit(brand)} />
												<div onClick={() => viewDetails(brand)}>
													<img src={brand.imageUrl} alt="brand" className="img-fluid" />
													<p className="mt-2">
														<strong>{brand.name}</strong>
													</p>
												</div>
											</div>
									  ))
									: null
							}
						/>

						<div className="diaper-box add-diaper" onClick={addDiaper}>
							<AiOutlinePlus />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(DiaperDashboard);
