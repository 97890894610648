import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import Loader from "../../../component/Loader";
import { editAccount } from "../../../redux/actions/adminActions";

const EditAccount = ({ history, location, editAccount }) => {
	const [data, setData] = useState({
		name: location.data.name,
		email: location.data.email,
		number: location.data.number
	});

	const [error, setError] = useState([]);

	const handleChange = e => {
		if (e.target.id === "name") {
			const nameRegex = /^[a-zA-Z ]*$/;
			if (!nameRegex.test(e.target.value)) {
				const exist = error.find(x => x === "Invalid name format");
				if (!exist) setError([...error, "Invalid name format"]);
			} else {
				setError(error.filter(x => x !== "Invalid name format"));
			}
		} else if (e.target.id === "number") {
			const numberRegex = /^(01)[0-46-9]([0-9]){7,8}$/;
			if (!numberRegex.test(e.target.value)) {
				const exist = error.find(x => x === "Invalid phone number format");
				if (!exist) setError([...error, "Invalid phone number format"]);
			} else {
				setError(error.filter(x => x !== "Invalid phone number format"));
			}
		} else if (e.target.id === "email") {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!emailRegex.test(e.target.value)) {
				const exist = error.find(x => x === "Invalid email address format");
				if (!exist) setError([...error, "Invalid email address format"]);
			} else {
				setError(error.filter(x => x !== "Invalid email address format"));
			}
		}

		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleSubmit = e => {
		e.preventDefault();
		const { name, email, number } = data;
		if (name && email && number && location.data.id) {
			const sendThis = {
				id: location.data.id,
				name,
				email,
				number
			};
			console.log(sendThis);
			editAccount(sendThis, err => {
				if (err) alert(err);
				else {
					history.push("/admin/account");
				}
			});
		} else {
			alert("Empty field detected");
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Edit User Account</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="name">Name</label>
								<input
									type="text"
									id="name"
									name="name"
									className="form-control"
									placeholder="user name"
									value={data.name}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group">
								<label htmlFor="email">Email</label>
								<input
									type="text"
									id="email"
									name="email"
									className="form-control"
									placeholder="user Email"
									value={data.email}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="number">Number</label>
								<input
									type="text"
									name="number"
									id="number"
									className="form-control"
									placeholder="user Number"
									value={data.number}
									onChange={handleChange}
									required
								></input>
							</div>

							<div className="form-group mt-5 text-center">
								<Loader
									component={
										<input
											type="submit"
											value={error.length ? error.join(", ") : "SUBMIT"}
											className={`form-control btn btn-${error.length ? "danger" : "primary"}`}
											disabled={!!error.length}
										/>
									}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		editAccount: (data, callback) => dispatch(editAccount(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(EditAccount);
