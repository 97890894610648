// import React, { useEffect, useState } from 'react';
// import {Link} from 'react-router-dom';
// Components
import Header from "../../../component/dashboard/Header";
// Assets
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Footer from "../../../component/dashboard/Footer";
import { MdOutlineContentCopy } from "react-icons/md";
//Redux
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import TNC_EN from "../../../assets/files/TNC_EN.pdf"
import TNC_BM from "../../../assets/files/TNC_BM.pdf"

function ReferAndEarn({ user, language }) {
	const { t } = useTranslation();
	const copyCodeToClipboard = code => {
		navigator.clipboard.writeText(code);
	};

	const shareCode = code => {
		const shareData = {
			title: "Use my refer code to register into this programme.",
			text: code
		};
		if (navigator.share) {
			navigator
				.share({
					title: shareData.title,
					text: shareData.text
				})
				.then(() => console.log("Successful share"))
				.catch(error => console.log("Error sharing", error));
		} else {
			console.log("Web Share API is not supported in your browser.");
		}
	};

	return (
		<div>
			<Header />
			<div className="content">
				<ContentHeader title={t("refernearn")} />
				<div className="referEarnImageBox">
					<img className="referEarnImage" src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/PointShop.png" alt="gift"></img>
				</div>
				<div className="referEarnTextBox">
					{ user.tier === "Gold" ? 
					(<h3 className="referEarnText">{t("refernow")}</h3>) :
					user.tier === "Platinum" ?
					(<h3 className="referEarnText">{t("refernowPlatinum")}</h3>) :
					<h3 className="referEarnText">{t("refernowDiamond")}</h3>
					}
					<h1 className="referEarnCode">
						{user.referralCode}{" "}
						<MdOutlineContentCopy onClick={() => copyCodeToClipboard(user.referralCode)} style={{ fill: "#FEA30B" }}></MdOutlineContentCopy>
					</h1>
				</div>
				{
					user.allowRefer === false ?
					(<div style={{display: "flex", textAlign: "center", "maxWidth": "500px", margin: "25px auto", padding: "0 40px"}}><h5>{t("referralError")}</h5></div>):
					null
				}
				<div className="referEarnButtonBox">
					<button onClick={() => shareCode(user.referralCode)} className="pickAddressConfirmButton">
						{t("share")}
					</button>
				</div>

				<div className="desktop-only my-5 text-center">
					<p>{t("desktopShare")}</p>
				</div>

				<div className="my-5 pb-5 pt-3 px-4">
					<p>
						<strong>
							{t("terms")} &amp; {t("condition")}
						</strong>
					</p>
					<ul>
						<li>{t("refert1")}</li>
						<li>{t("refert2")}</li>
						<li>{t("refert3")}</li>
						<li>{t("refert4")}</li>
						<li>{t("refert5")}</li>
						<li>{t("refert6")} <a href={language === 'en' ? TNC_EN : TNC_BM} target="_blank" style={{ textDecoration: "underline", color: "blue" }}>{t("refert7")}</a> {t("refert8")}</li>
					</ul>
				</div>
			</div>
			<Footer />
		</div>
	);
}

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		language: state.pageReducer.language
	};
};

export default connect(mapStateToProps, null)(ReferAndEarn);
