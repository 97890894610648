import React from "react";
import { Redirect } from "react-router-dom";
import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
import { connect } from "react-redux";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import { useTranslation } from "react-i18next";

const SampleDetail = ({ location, sample, history, language }) => {
	const { t } = useTranslation();
	const handleConfirm = () =>
		history.push({
			pathname: "/viewAddress",
			type: "sample",
			data: sample.data.filter(
				x => x.brandId === location.data.brandId && x.formatId === location.data.formatId && x.sizeId === location.data.sizeId && x.active
			)[0]
		});

	if (
		!location.data ||
		!location.data.brandId ||
		!location.data.formatId ||
		!location.data.sizeId ||
		!sample ||
		!sample.data ||
		!sample.data.filter(
			x => x.brandId === location.data.brandId && x.formatId === location.data.formatId && x.sizeId === location.data.sizeId && x.active
		)[0]
	) {
		return <Redirect to="/picksample" />;
	} else {
		return (
			<div>
				<Header />
				<div className="content">
					<ContentHeader title="Free Sample" />
					<div className="text-center desktop-only mt-4">
						<h1 className="dbc-title">Free Sample</h1>
					</div>

					<div className="text-center px-3">
						<img
							style={{ maxWidth: "300px" }}
							src={
								sample.data.filter(
									x =>
										x.brandId === location.data.brandId &&
										x.formatId === location.data.formatId &&
										x.sizeId === location.data.sizeId &&
										x.active
								)[0].itemImage[language]
							}
							alt="sample"
							className="img-fluid"
						/>
						<h1 className="dbc-title mt-3">
							{
								sample.data.filter(
									x =>
										x.brandId === location.data.brandId &&
										x.formatId === location.data.formatId &&
										x.sizeId === location.data.sizeId &&
										x.active
								)[0].itemName[language]
							}
						</h1>
					</div>

					<div className="px-5 mt-5">
						<p style={{ whiteSpace: "pre-line", textAlign: "justify", color: "#223C96", lineHeight: "1.5em", fontWeight: "500" }}>
							{
								sample.data.filter(
									x =>
										x.brandId === location.data.brandId &&
										x.formatId === location.data.formatId &&
										x.sizeId === location.data.sizeId &&
										x.active
								)[0].description[language]
							}
						</p>
					</div>

					<div className="text-center my-5 px-5">
						<button
							type="button"
							className="my-5 btn fw-bold"
							style={{ color: "white", backgroundColor: "#FEA30B", width: "100%", maxWidth: "400px" }}
							onClick={handleConfirm}
						>
							{t("getfree")}
						</button>
					</div>

					<div className="desktop-only">
						<Footer />
					</div>
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		sample: state.userReducer.samples,
		language: state.pageReducer.language
	};
};

export default connect(mapStateToProps)(SampleDetail);
