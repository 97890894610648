import moment from "moment";
import { Link } from "react-router-dom";
// Components
import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
import ItemPreview from "../../../component/dashboard/ItemPreview";
// Assets
//Redux
import { connect } from "react-redux";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import CollectPoints from "../../../component/dashboard/CollectPoints";
import { useTranslation } from "react-i18next";

const PointHistory = ({ user, history }) => {
	const { t } = useTranslation();
	const renderColor = num => {
		if (num < 0) {
			return {
				color: "red"
			};
		}
	};

	return (
		<div>
			<Header />
			<div className="content" style={{ paddingBottom: "150px" }}>
				<ContentHeader title="Points" />
				<div className="dashboardProfileContainer">
					<Link className="dashboardProfilePicContainer" to="/userprofile">
						<img className="dashboardProfilePic img-fluid" src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/Frame-12.png" alt="pfpic"></img>
					</Link>

					<div className="dashboardProfileContent">
						<div className="dashboardProfile" style={{ height: "100%" }}>
							<div className="user-profile-container">
								<h3 id="username" className="username mobile">
									{user.name.toUpperCase()}
								</h3>
								<h2 id="username" className="username desktop">
									{user.name.toUpperCase()}
								</h2>
								{/* <h3 id="user-tier" className="username">
									{user.tier.toUpperCase()} TIER
								</h3>
								<p className="user-milestone">100 MORE POINTS TO PLATINUM</p> */}
								<div className="desktop-user-info">
									<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star.png" alt="point star" className="img-fluid" />
									<h3>{user.name}</h3>
									<div className="dot-separator"></div>
									{/* <h3>{user.tier.toUpperCase()}</h3> */}
								</div>
							</div>

							<Link className="user-point-container" to="/pointhistory" style={{ textDecoration: "none" }}>
								<p className="user-point-label">{t("yourpoints")}</p>
								<h4 id="user-point">{user.totalPoints}</h4>
							</Link>
						</div>
					</div>
				</div>

				<div className="pointHistoryTitleContainer">
					<h1 className="dbc-title">{t("pointshistory")}</h1>
					{user && user.points && user.points.length
						? user.points.slice(0, 3).map(point => (
								<div key={point.id} className="pointHistoryRecordHold">
									<div>
										<h6 className="m-0 p-0 pointHistoryRecordDate">{moment(new Date(point.createdAt)).format("DD MMM YYYY")}</h6>
										<h5 className="m-0 p-0 mt-2 pointHistoryRecordItem">{t(point.source)}</h5>
									</div>
									<h5 className="m-0 p-0 pointHistoryRecordPoint" style={renderColor(point.points)}>
										{point.points}
									</h5>
								</div>
						  ))
						: null}

					<div className="text-center">
						<button type="button" onClick={() => history.push("/viewpoints")} className="pointHistorySeeMoreButton">
							{t("seemore")}
						</button>
					</div>
				</div>

				<ItemPreview history={history} title={t("latestreward")} />
				<CollectPoints />
			</div>
			<Footer />
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user
	};
};

export default connect(mapStateToProps, null)(PointHistory);
