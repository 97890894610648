import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { editArticle } from "../../../../redux/actions/adminActions";

const EditArticle = ({ history, location, editArticle }) => {
	const [data, setData] = useState({
		title: location.data.title.en,
		description: location.data.description.en,
		titleBM: location.data.title.bm,
		descriptionBM: location.data.description.bm
	});
	const [file, setFile] = useState(null);
	const handleChange = e => {
		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleImage = e => setFile(e.target.files[0]);

	const handleSubmit = e => {
		e.preventDefault();
		const { title, description, titleBM, descriptionBM } = data;
		if (title && description && titleBM && descriptionBM) {
			if (file) {
				// have image
				const formData = new FormData();
				formData.append("id", location.data.id);
				formData.append("title", title);
				formData.append("description", description);
				formData.append("titleBM", titleBM);
				formData.append("descriptionBM", descriptionBM);
				formData.append("file", file);
				editArticle(formData, err => {
					if (err) alert(err);
					else {
						history.push("/admin/article/dashboard");
					}
				});
			} else {
				// no image
				const sendThis = {
					id: location.data.id,
					title,
					description,
					titleBM,
					descriptionBM
				};
				console.log(sendThis);
				editArticle(sendThis, err => {
					if (err) alert(err);
					else {
						history.push("/admin/article/dashboard");
					}
				});
			}
		} else {
			alert("Empty field detected");
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Edit Article</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="title">Title</label>
								<input
									type="text"
									id="title"
									name="title"
									className="form-control"
									placeholder="Article Title"
									value={data.title}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="description">Description</label>
								<textarea
									name="description"
									id="description"
									className="form-control"
									placeholder="Article Description"
									value={data.description}
									onChange={handleChange}
									required
								></textarea>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="file">
									Image{" "}
									<small>
										<strong>Upload to replace current image, BM required too</strong>
									</small>
								</label>
								<input type="file" name="file" id="file" className="form-control" accept="image/*" onChange={handleImage} />
							</div>

							<hr className="my-5" />

							<div className="form-group">
								<label htmlFor="titleBM">Title (Bahasa)</label>
								<input
									type="text"
									id="titleBM"
									name="titleBM"
									className="form-control"
									placeholder="Article Title"
									value={data.titleBM}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="descriptionBM">Description (Bahasa)</label>
								<textarea
									name="descriptionBM"
									id="descriptionBM"
									className="form-control"
									placeholder="Article Description"
									value={data.descriptionBM}
									onChange={handleChange}
									required
								></textarea>
							</div>

							<div className="form-group mt-5 text-center">
								<Loader component={<input type="submit" value="SUBMIT" className="form-control btn btn-primary" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		editArticle: (data, callback) => dispatch(editArticle(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(EditArticle);
