import React from "react";
import { Link } from "react-router-dom";
// Assets
// import help from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-help.png";
import { useTranslation } from "react-i18next";

const Explore = () => {
	const { t } = useTranslation();
	return (
		<div>
			<h2 className="dbc-title ms-5 mb-4">{t("exploredbc")}</h2>
			<div className="dashboard-menu-container" style={{ justifyContent: "space-evenly" }}>
				<Link style={{ textDecoration: "none" }} to={{ pathname: "/scancode", isDesktop: true }}>
					<div className="dashboard-menu-item">
						<p className="dashboard-menu-title">{t("keyinunique")}</p>
						<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-scan-code.png" alt="menu icon" className="img-fluid dashboard-menu-icon" />
					</div>
				</Link>

				<Link style={{ textDecoration: "none" }} to="/pointshop">
					<div className="dashboard-menu-item">
						<p className="dashboard-menu-title">{t("pointshop")}</p>
						<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/PointShop.png" alt="menu icon" className="img-fluid dashboard-menu-icon" />
					</div>
				</Link>

				<Link style={{ textDecoration: "none" }} to="/dashboard">
					<div className="dashboard-menu-item">
						<p className="dashboard-menu-title">{t("myquest")}</p>
						<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-my-quest.png" alt="menu icon" className="img-fluid dashboard-menu-icon" />
					</div>
				</Link>

				<Link style={{ textDecoration: "none" }} to="/dashboard">
					<div className="dashboard-menu-item">
						<p className="dashboard-menu-title">{t("dbcforum")}</p>
						<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-dbc-forum.png" alt="menu icon" className="img-fluid dashboard-menu-icon" />
					</div>
				</Link>

				<Link style={{ textDecoration: "none" }} to="/picksample">
					<div className="dashboard-menu-item">
						<p className="dashboard-menu-title">{t("freesample")}</p>
						<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-free-sample.png" alt="menu icon" className="img-fluid dashboard-menu-icon" />
					</div>
				</Link>
			</div>
		</div>
	);
};

export default Explore;
