import { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import axios from "axios";
import { connect } from "react-redux";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
// import { AiOutlinePlus } from "react-icons/ai";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";
import Loader from "../../../component/Loader";
import { diaperDashboardColumns } from "../../../config/mdbColumns";

const SampleDashboard = ({ history, loading, loaded }) => {
	const [brands, setBrands] = useState([]);
	const [diapers, setDiapers] = useState([]);
	const [selected, setSelect] = useState("");

	useEffect(() => {
		loading();
		axios
			.get("/drypers-baby-club/api/admin/fetch/dashboard/sample")
			.then(res => {
				console.log(res.data);
				setBrands(res.data.brands);
				setDiapers(res.data.data);
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleEdit = data => history.push({ pathname: "/admin/sample/edit", brands, data, diapers });

	const handleActive = e => {
		loading();
		axios
			.post("/drypers-baby-club/api/admin/setting/hide/sample", { id: e.target.id })
			.then(() => {
				setDiapers(diapers.map(diaper => (diaper.id === e.target.id ? { ...diaper, active: !diaper.active } : diaper)));
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
	};

	const tableData = {
		columns: diaperDashboardColumns,
		rows: selected
			? diapers
					.filter(diaper => diaper.brandId === selected)
					.map(diaper => {
						return {
							...diaper,
							image: <img src={diaper.itemImage.en} alt="diaper" className="img-fluid" style={{ height: "80px", width: "auto" }} />,
							itemName: diaper.itemName.en,
							description: diaper.description.en,
							bmName: diaper.itemName.bm,
							bmDescription: diaper.description.bm,
							bmImage: <img src={diaper.itemImage.bm} alt="diaper" className="img-fluid" style={{ height: "80px", width: "auto" }} />,
							edit: (
								<button type="button" className="btn btn-info" onClick={() => handleEdit(diaper)}>
									Edit
								</button>
							),
							showItem: <input type="checkbox" id={diaper.id} name={diaper.id} onChange={handleActive} checked={diaper.active} />
						};
					})
			: diapers.map(diaper => {
					return {
						...diaper,
						image: <img src={diaper.itemImage.en} alt="diaper" className="img-fluid" style={{ height: "80px", width: "auto" }} />,
						itemName: diaper.itemName.en,
						description: diaper.description.en,
						bmName: diaper.itemName.bm,
						bmDescription: diaper.description.bm,
						bmImage: <img src={diaper.itemImage.bm} alt="diaper" className="img-fluid" style={{ height: "80px", width: "auto" }} />,
						edit: (
							<button type="button" className="btn btn-info" onClick={() => handleEdit(diaper)}>
								Edit
							</button>
						),
						showItem: <input type="checkbox" id={diaper.id} name={diaper.id} onChange={handleActive} checked={diaper.active} />
					};
			  })
	};

	const buttonStyle = val => {
		if (val === selected) {
			return { backgroundColor: "#154387", color: "white", minWidth: "100px", border: "1px black solid", marginRight: "5px" };
		} else {
			return { backgroundColor: "white", minWidth: "100px", border: "1px black solid", marginRight: "5px" };
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />
			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					{/* contents */}
					<h3>Sample Dashboard</h3>

					<div className="mt-5">
						<div>
							<button type="button" className="btn me-2" style={buttonStyle("")} onClick={() => setSelect("")}>
								All
							</button>

							{brands.map(brand => (
								<button key={brand.id} type="button" className="btn me-2" style={buttonStyle(brand.id)} onClick={() => setSelect(brand.id)}>
									{brand.name}
								</button>
							))}
						</div>

						<div className="card mt-5">
							<div className="card-header d-flex justify-content-between">
								<h5 className="card-title">Diapers</h5>
								<button
									type="button"
									className="btn btn-info"
									onClick={() => history.push({ pathname: "/admin/sample/add", brands, diapers })}
								>
									Add Sample Diaper
								</button>
							</div>

							<div className="card-body text-center">
								<Loader component={<MDBDataTableV5 data={tableData} striped hover responsive bordered />} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(SampleDashboard);
