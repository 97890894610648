import { useState } from "react";
import Header from "../../component/dashboard/Header";
import Footer from "../../component/dashboard/Footer";
import TesseractCam from "../../component/scanCode/TesseractCam";
import VerifyCodeSuccess from "../../component/scanCode/VerifyCodeSuccess";
import ContentHeader from "../../component/dashboard/ContentHeader";
import { useTranslation } from "react-i18next";

const ScanCodeSuccess = ({ history, location }) => {
  const { t } = useTranslation();
  const [isScanning, setScanning] = useState(false);
  const [code, setCode] = useState("");
  return (
    <div>
      <Header />
      <div className="content" style={{ paddingBottom: "150px" }}>
        <ContentHeader title={t("scanuniquecode")} />
        <div>
          {isScanning ? (
            <TesseractCam
              setCode={setCode}
              setScanning={setScanning}
              code={code}
            />
          ) : (
            <VerifyCodeSuccess
              code={code}
              setCode={setCode}
              history={history}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ScanCodeSuccess;
