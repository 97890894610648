import React, { useMemo } from "react";
import { MDBDataTableV5 } from "mdbreact";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
// import { AiOutlinePlus } from "react-icons/ai";
import Loader from "../../../component/Loader";
import { pointuserColumns } from "../../../config/mdbColumns";
// import DatePicker from "react-datepicker";
// import { Button } from "react-bootstrap";

function PointUserReport({ location }) {
	// const [startDate, setStartDate] = useState("");
	// const [endDate, setEndDate] = useState("");

	// const handleFilter = () => {
	// 	if (endDate === "" || startDate === "") {
	// 		alert("Please fill up both field.");
	// 	} else {
	// 		if (Date.parse(endDate) <= Date.parse(startDate)) {
	// 			alert("End date should be greater than start date");
	// 		} else {
	// 			// resultUserData = data.filter(a => {
	// 			// 	var date = new Date(a.date);
	// 			// 	return date >= startDate && date <= endDate;
	// 			// });
	// 			// console.log(resultUserData);
	// 			// setData(resultUserData);
	// 		}
	// 	}
	// };

	// const handleReset = () => {
	// 	if (location.data && location.data.id) {
	// 		// setData(location.data.data);
	// 	} else {
	// 		history.push("/admin/pointhistory");
	// 	}
	// };

	// const handlePointIn = () => {

	// 	// setData(location.data.data.filter(data => data.points > 0));

	// };

	// const handlePointOut = () => {

	// 	// setData(location.data.data.filter(data => data.points < 0));

	// };

	const tableData = useMemo(
		() => ({
			columns: pointuserColumns,
			rows:
				location.data && location.data.data && location.data.data.length
					? location.data.data.map(x => ({ name: location.data.name, date: new Date(x.createdAt).toLocaleString("en-GB"), points: x.points, source: x.source }))
					: []
		}),
		[location.data]
	);

	return (
		<div>
			<div className="admin-page">
				<AdminSidebar />
				<div className="admin-body">
					<AdminTopbar />

					<div className="admin-content">
						{/* contents */}
						<h3>Point User Report of {location.data.name}</h3>

						<div className="mt-2">
							{/* <div className="admin-date flex-container">
								<DatePicker selected={startDate} onChange={date => setStartDate(date)} isClearable placeholderText="Start Date" />
								<DatePicker selected={endDate} onChange={date => setEndDate(date)} isClearable placeholderText="End Date" />
								<Button className="mr-2" onClick={() => handleFilter()}>
									Filter
								</Button>
								<Button onClick={() => handleReset()}>Reset</Button>
							</div> */}
							{/* <div className="admin-date" style={{ marginLeft: "35px" }}>
								<Button className="mr-2" onClick={() => handlePointIn()}>
									Point In
								</Button>
								<Button onClick={() => handlePointOut()}>Point Out</Button>
							</div> */}

							<div className="card-body text-center">
								<Loader component={<MDBDataTableV5 data={tableData} searchTop searchBottom={false} striped hover responsive bordered />} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PointUserReport;
