// Components
import Header from "../../../component/dashboard/Header";
import { MdOutlineContentCopy } from "react-icons/md";
import { Redirect } from "react-router-dom";
// Assets
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Footer from "../../../component/dashboard/Footer";
//Redux
import { connect } from "react-redux";
import { voucherUse } from "../../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

const VoucherOrder = ({ user, location, voucherUse }) => {
	const { t } = useTranslation();
	const useNow = () => {
		const voucherName = user.vouchers.find(x => x.id === location.data);
		if (!user.vouchers.filter(x => x.id === location.data)[0].used) {
			const userId = user.id;
			const id = location.data;
			const sendThis = {
				id,
				userId
			};

			if (voucherName.name === "Pinkfong Bounce Wonderland Ticket") {
				voucherUse(sendThis, () => (window.location.href = "https://www.ticket2u.com.my/event/33221/bounce-wonderland"));
			} else {
				voucherUse(sendThis, () => (window.location.href = "https://www.vindamall.com"));
			}
		} else {
			if (voucherName.name === "Pinkfong Bounce Wonderland Ticket") {
				window.location.href = "https://www.ticket2u.com.my/event/33221/bounce-wonderland";
			} else {
				window.location.href = "https://www.vindamall.com";
			}
		}
	};

	if (!user || !location.data) {
		return <Redirect to="/dashboard" />;
	} else {
		return (
			<div id="voucher-page">
				<Header />
				<div className="content">
					<ContentHeader />

					<div className="voucher-content">
						<div className="voucher-data-container">
							<div className="voucher-image-container">
								<img src={user.vouchers.filter(x => x.id === location.data)[0].voucherImage} alt="voucher" className="img-fluid voucher-image" />
							</div>

							<div className="voucher-data">
								<h5 className="dbc-title">{user.vouchers.filter(x => x.id === location.data)[0].name}</h5>
								<div className="inline-point">
									<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star-2.png" alt="pointstar" className="img-fluid" />
									<h5 className="m-0 p-0 ms-2">{user.vouchers.filter(x => x.id === location.data)[0].points} Points</h5>
								</div>
							</div>
						</div>

						<div className="desktop-only text-end">
							<button className="btn my-5 usenow-button desktop" onClick={useNow}>
								USE NOW
							</button>
						</div>

						<div className="voucher-details-container">
							<div className="voucher-number-container">
								<h5 style={{ color: "black" }}>{t("vouchernum")}</h5>

								<div className="d-flex align-items-center justify-content-center">
									<h1 className="voucher-code m-0 p-0">{user.vouchers.filter(x => x.id === location.data)[0].code}</h1>
									<MdOutlineContentCopy className="m-0 p-0 ms-3 voucher-code" onClick={() => navigator.clipboard.writeText(user.vouchers.filter(x => x.id === location.data)[0].code)} />
								</div>
							</div>

							<div className="voucher-faq text-start">
								<h5 className="fw-bold">{t("howtouse")}</h5>
								{user.vouchers.filter(x => x.id === location.data)[0].name !== "Pinkfong Bounce Wonderland Ticket" ?
								( <ol>
									<li>
										Visit{" "}
										<span style={{ color: "#0663c1", textDecoration: "underline" }} onClick={() => (window.location.href = "https://www.vindamall.com")}>
											www.vindamall.com
										</span>
									</li>
									<li>Login / Create Account</li>
									<li>Add the product you would like to purchase to the cart</li>
									<li>Click Check Out</li>
									<li>Apply voucher code</li>
									<li>Make the payment</li>
								</ol> ) : 
								<div>
									<p style={{textAlign: 'left'}}>{t("pinkFongStep1")}</p>
									<ul className="text-start">
										<li>{t("pinkFongStep2")}</li>
										<li>{t("pinkFongStep3")}<span style={{cursor: 'pointer'}} className="link-primary fw-bold text-decoration-underline" onClick={() => (window.location.href = "https://www.ticket2u.com.my/event/33221/bounce-wonderland")}>{t("pinkFongStep3a")}</span>{t("pinkFongStep3b")}</li>
									</ul>
									<p style={{textAlign: 'left', margin: '0'}}>{t("pinkFongStep4")}</p>
									<p style={{textAlign: 'left', margin: '0'}}>{t("pinkFongStep5")}</p>
									<p style={{textAlign: 'left', margin: '0'}}>{t("pinkFongStep6")}</p>
									<p style={{textAlign: 'left', margin: '0'}}>{t("pinkFongStep7")}</p>
									</div>
								}

							</div>
							<button className="btn my-5 usenow-button mobile" onClick={useNow}>
								USE NOW
							</button>
						</div>
					</div>

					<Footer />
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		voucherUse: (data, callback) => dispatch(voucherUse(data, callback))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherOrder);
