import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Gold from "../../../assets/GoldDiapers.svg";
import Platinum from "../../../assets/PlatinumDiapers.svg";
import Diamond from "../../../assets/DiamondDiapers.svg";
import WelcomeImage from "../../../assets/WelcomeTier.png";
import WelcomeGoldImage from "../../../assets/welcomeGoldImage.svg"
import WelcomeImage2 from "../../../assets/WelcomeImage2.svg"
import ReferralImage from "../../../assets/ReferralTier.svg";
import BirthdayImage from "../../../assets/BirthdayTier.svg";
import RefereeImage from "../../../assets/refereeUC.svg"
import BronzeCoin from "../../../assets/BronzeCoin.svg";
import SilverCoin from "../../../assets/SilverCoin.svg";
import GoldCoin from "../../../assets/GoldCoin.svg";
import tierArrow from "../../../assets/tierArrow.png"

const Tier = ({ user }) => {
  const { t } = useTranslation();

  const highTierPoints = user.tierSetting[0].highTierPoints;
	const tierPercentage = (user.totalPointsInPastYear / highTierPoints) * 100;
  const pointToGo = highTierPoints - user.totalPointsInPastYear + 1;
  const nextTier = user.tierSetting[0].nextTier;
  const userTier = user.tier;
  const userReset = user.tierResetDate;
  const dateComponents = userReset.split('-');
  const userResetFormatted = `${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`;
  let points = user.totalPoints;
	let formattedTotalPoint = points.toLocaleString(); // This adds commas to the number



  const [activeTab, setActiveTab] = useState(userTier);
  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };
  return (
    <div>
      <Header />
      <div className="content" style={{ paddingBottom: "150px" }}>
        <ContentHeader title={t("tier")} />
        <div>
        <div
          className="dashboardProfileContainer"
          style={{
            boxShadow: "0px 3px 7px rgba(0, 0, 0, 0.31)",
            borderRadius: "10px",
            width: "90%",
            margin: "auto",
            padding: "10px",
            display: "block",
            height: "auto"
          }}
        >
          <div style={{display: "flex"}}>
          <div className="dashboardProfileContent">
            <div className="dashboardProfile" style={{display: "contents"}}>
              <div style={{display: "flex"}}>
              <div className="user-profile-container">
                <h3 id="username" className="username">
                  {user.name.toUpperCase()}
                </h3>
                {/* <h2 id="username" className="username desktop">
                  {t("welcome")}, {user.name.split(" ")[0].toUpperCase()}!
                </h2> */}
								{/* <Link to="/tierHistory">
								<h3 id="user-tier" className="username">
									{user.tier.toUpperCase()} TIER
								</h3>
								</Link> */}
                {/* <p className="user-milestone">100 MORE POINTS TO PLATINUM</p> */}
                {/* <div className="desktop-user-info">
                  <img
                    src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star.png"
                    alt="point star"
                    className="img-fluid"
                  />
                  <h3>{user.name}</h3>
                  <div className="dot-separator"></div>
                  <h3>{user.tier.toUpperCase()}</h3>
                </div> */}
              </div>

              <Link
                className="user-point-container"
                to="/pointhistory"
                style={{ textDecoration: "none" }}
              >
                <p style={{textAlign: "right"}} className="user-point-label">{t("yourpoints")}</p>
                <h4 id="user-point">{formattedTotalPoint}</h4>
              </Link>
              </div>

              <div className="tier" style={{width: "100%", height: "100%", margin: "0", marginTop: "10px"}}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={user.tier === "Diamond" ? Diamond : user.tier === "Platinum" ? Platinum : Gold} style={{ width: "25px", marginRight: "5px" }} />
                <div className="tier-title">
                  {user.tier}
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={user.tier === "Platinum" && nextTier === "Diamond" ? Diamond : user.tier === "Diamond" && nextTier === "Diamond" ? null : nextTier === "Platinum" ? Platinum : Gold} style={{ width: "25px", marginRight: "5px" }} />
                {/* <img style={{marginLeft: "10px"}} src={tierArrow} /> */}
                <div className="tier-title">
                  {user.tier === "Platinum" && nextTier === "Diamond" ? "Diamond" : user.tier === "Diamond" && nextTier === "Diamond" ? null : nextTier === "Platinum" ? "Platinum" : "Gold"}
                </div>
              </div>
            </div>
            <ProgressBar now={user.tier === "Diamond" ? 100 : tierPercentage} />
							{ user.tier !== "Diamond" ? (
							<div class="upgrade-date-text" style={{textAlign: "initial", marginTop: "10px"}}>{pointToGo} {t("pointReach")} {nextTier} {t("tierBy")} {userResetFormatted} </div>
							) : (
                <div class="upgrade-date-text" style={{textAlign: "initial", marginTop: "10px"}}>{t("tierHighest")}{userResetFormatted}</div>
              )
							}
              </div>

            </div>
          </div>
          </div>
				</div>
        </div>
        {/* <div>
          <div className="tier-status mt-4">{t("tierStatus")}</div>
          { user.tier !== "Diamond" ?
           ( <div class="tier-description">{pointToGo} {t("pointReach")} {nextTier} by 4/8/2023 </div> ):
           null
          }
        </div> */}
        <div className="tier-container">
          {/* //tiering benefits */}
          <div className="tier-benefits">{t("tierBenefit")}</div>

		  <div>
      <div className="nav nav-tabs" style={{margin: "auto", justifyContent: "center", flexWrap: "unset", borderBottom: "none", textAlign: "center"}}>
        <div
          className={`nav-link ${activeTab === 'Gold' ? 'active' : ''}`}
          onClick={() => handleTabChange('Gold')}
          style={{borderColor: "none"}}
        >
          <img className="navbar-tier-gold" src={Gold} alt="Gold" />Gold            
        </div>
        <div
          className={`nav-link ${activeTab === 'Platinum' ? 'active' : ''}`}
          onClick={() => handleTabChange('Platinum')}
          style={{borderColor: "none"}}
        >
          <img className="navbar-tier" src={Platinum} alt="Gold" />Platinum
        </div>
        <div
          className={`nav-link ${activeTab === 'Diamond' ? 'active' : ''}`}
          onClick={() => handleTabChange('Diamond')}
          style={{borderColor: "none"}}
        >
          <img stye={{width: "52px"}} className="navbar-tier" src={Diamond} alt="Diamond" />Diamond
        </div>
      </div>

    </div>
        </div>
        <div className="tier-container" style={{boxShadow: "none"}}>

      <div className="tab-content" style={{border: "none"}}>
        {activeTab === 'Gold' && (
          <div className="tab-pane active" style={{border: "none"}} role="tabpanel">
            <div style={{display: "flex"}}> 
                <div className="tier-points-container">
                  <div >
                    <img style={{marginRight: "15px" , width: "100px"}} src={Gold} alt="Gold" />
                  </div>
                  <div>
                    <div className="points-required" style={{marginTop: "20px"}}>
                    {t("tierPointRequired")}
                    </div>
                    <div className="points-range">0 - 3,999 {t("tierPoints")}</div>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <div className="tier-benefits" style={{marginTop: "20px"}}>{t("goldTierPrivilege")}</div>
                  <div class="privileges-container">
                    <div class="reward-item">
                      <img src={WelcomeGoldImage} alt="welcome" />
                      <div>
                      <div class="reward-tier-description">2x Points</div>
                      <div class="reward-subtier-description">
                        {t("firstCodeTier")}
                      </div>
                      </div>
                    </div>
                    <div class="reward-item">
                      <img style={{width: "75px"}} src={BirthdayImage} alt="birthday" />
                      <div>
                      <div class="reward-tier-description">100</div>
                      <div class="reward-subtier-description">
                        {t("tierBirthday")}
                      </div>
                      </div>
                    </div>
                    <div class="reward-item">
                      <img src={ReferralImage} alt="referral" />
                      <div>
                      <div class="reward-tier-description">500</div>
                      <div class="reward-subtier-description">
                        {t("tierReferral")}
                      </div>
                      </div>
                    </div>
                    <div class="reward-item">
                      <img src={RefereeImage} alt="referral" />
                      <div>
                      <div class="reward-tier-description">100</div>
                      <div class="reward-subtier-description">
                        {t("tierReferee")}
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multiplier-container">
                <div><img className="miltiplierImg" src={BronzeCoin} alt="Bronze" /></div>
              <div>
                <div className="multiplier-text"> {t("tierMultiplier")}</div>
                <div className="tier-multiplier-example">{t("tierGoldEg")}</div>
              </div>
              </div>
          </div>
        )}
        {activeTab === 'Platinum' && (
          <div className="tab-pane active" style={{border: "none"}} role="tabpanel">
                          <div style={{display: "flex"}}>
                <div className="tier-points-container">
                  <div>
                    <img style={{marginRight: "15px", width: "100px"}} src={Platinum} alt="Platinum" />
                  </div>
                  <div>
                    <div className="points-required" style={{marginTop: "20px"}}>
                      {t("tierPointRequired")}
                    </div>
                    <div className="points-range">4,000 - 8,999 {t("tierPoints")}</div>
                  </div>
                </div>
              </div>

              <div>
			  <div>
                  <div className="tier-benefits" style={{marginTop: "20px"}}>{t("platinumTierPrivilege")}</div>
                  <div class="privileges-container">
                    <div class="reward-item">
                      <img src={WelcomeImage2} alt="welcome" />
                      <div>
                      <div class="reward-tier-description">500</div>
                      <div class="reward-subtier-description">
                        {t("tierWelcome")}
                      </div>
                      </div>
                    </div>
                    <div class="reward-item">
                      <img style={{width: "75px"}} src={BirthdayImage} alt="birthday" />
                      <div>
                      <div class="reward-tier-description">200</div>
                      <div class="reward-subtier-description">
                        {t("tierBirthday")}
                      </div>
                      </div>
                    </div>
                    <div class="reward-item">
                      <img src={ReferralImage} alt="referral" />
                      <div>
                      <div class="reward-tier-description">550</div>
                      <div class="reward-subtier-description">
                        {t("tierReferral")}
                      </div>
                      </div>
                    </div>
                    <div class="reward-item">
                      <img src={RefereeImage} alt="referral" />
                      <div>
                      <div class="reward-tier-description">150</div>
                      <div class="reward-subtier-description">
                        {t("tierReferee")}
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
			  </div>
        <div className="multiplier-container">
                <div><img className="miltiplierImg" src={SilverCoin} alt="Silver" /></div>
                <div>
                <div className="multiplier-text"> {t("tierMultiplier")}</div>
                <div className="tier-multiplier-example">{t("tierPlatinumEg")}</div>
                </div>
              </div>
          </div>
        )}
        {activeTab === 'Diamond' && (
          <div className="tab-pane active" style={{border: "none"}} role="tabpanel">
                          <div style={{display: "flex"}}>
                <div className="tier-points-container">
                  <div>
                    <img style={{marginRight: "15px", width: "100px"}} src={Diamond} alt="Diamond" />
                  </div>
                  <div>
                    <div className="points-required" style={{marginTop: "20px"}}>
                      {t("tierPointRequired")}
                    </div>
                    <div className="points-range">9,000 {t("pointRange")}</div>
                  </div>
                </div>
              </div>

              <div>
			  <div>
                  <div className="tier-benefits" style={{marginTop: "20px"}}>{t("diamondTierPrivilege")}</div>
                  <div class="privileges-container">
                    <div class="reward-item">
                      <img src={WelcomeImage2} alt="welcome" />
                      <div>
                      <div class="reward-tier-description">800</div>
                      <div class="reward-subtier-description">
                        {t("tierWelcome")}
                      </div>
                      </div>
                    </div>
                    <div class="reward-item">
                      <img style={{width: "75px"}} src={BirthdayImage} alt="birthday" />
                      <div>
                      <div class="reward-tier-description">300</div>
                      <div class="reward-subtier-description">
                        {t("tierBirthday")}
                      </div>
                      </div>
                    </div>
                    <div class="reward-item">
                      <img src={ReferralImage} alt="referral" />
                      <div>
                      <div class="reward-tier-description">600</div>
                      <div class="reward-subtier-description">
                        {t("tierReferral")}
                      </div>
                      </div>
                    </div>
                    <div class="reward-item">
                      <img src={RefereeImage} alt="referral" />
                      <div>
                      <div class="reward-tier-description">200</div>
                      <div class="reward-subtier-description">
                        {t("tierReferee")}
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
			  </div>
        <div className="multiplier-container">
                <div><img className="miltiplierImg" src={GoldCoin} alt="Diamond" /></div>
              <div>
                <div className="multiplier-text"> {t("tierMultiplier")}</div>
                <div className="tier-multiplier-example">{t("tierDiamondEg")}</div>
              </div>
              </div>
          </div>
        )}
      </div>

      <Link to="/tierHistory"  style={{ textDecoration: "none" }}><div className="tier-benefits mt-3">{t("viewTierHistory")}</div></Link>
      <hr style={{ border: "none", borderBottom: "1px solid black", bottom: "-5px", width: "100%" }} />
      <Link to="/tierHelpCenter"  style={{ textDecoration: "none" }}><div className="tier-benefits mt-3">{t("tierHelpCenter")}</div></Link>

        </div>

      </div>
      <Footer />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tier);
