import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Redirect } from "react-router-dom";
// Components
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Loader from "../../../component/Loader";
import Footer from "../../../component/dashboard/Footer";
// Assets
import feedbackSmile from "../../../assets/feedbackSmile.png";
//Redux
import { connect } from "react-redux";
import { addFeedback, clearError, getQuestion, getBrandInfo } from "../../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

const tapeIds = [
	"2741a862-621a-4d4c-ad76-5465b0feee9c",
	"f7432c14-bd60-48ed-8398-70e3cb1276e4",
	"68fd8074-0eb1-42ff-9aca-505ddc1f7cf0",
	"b12e48c0-ad2d-404d-827c-fbd5f41887df",
	"463bbc64-8a55-4551-8784-561910b4f991",
	"e0309525-5d5c-4eda-818e-19950a4170db",
	"047eeb46-d6c6-4694-8405-df418b257ea8"
];

const Feedback = ({
	location,
	user,
	history,
	pageReducer,
	clearError,
	question,
	getQuestion,
	sendFeedback,
	getBrandInfo,
	brandData
}) => {
	const { t } = useTranslation();
	// const [feedback, setFeedback] = useState("");
	// questions
	const [questions, setQuestions] = useState([]);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [answers, setAnswers] = useState({ main: {}, sub: {} });
	const [successModal, setSuccess] = useState(false);
	// const [selectedChoice, setSelectedChoice] = useState(null);
	const [displaySubsampleQuestions, setDisplaySubsampleQuestions] = useState(false);
	const [openQuestion, setOpenQuestion] = useState("");
	const [formatId, setFormatId] = useState(null);
	const [gotInput, setGotInput] = useState(false);
	const [other, setOther] = useState("");
	const lastSample = user.samples.filter(sample => sample.received === null)[0];
	const lastOrder = lastSample.orderItems[0];
	const brandId = lastOrder.brandId;
	const [done, setDone] = useState(false);

	useEffect(() => {
		if (!brandData) getBrandInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filteredBrand = brandData.find(brand => brand.id === brandId);
	// console.log(filteredBrand.name);
	// const brandName = filteredBrand ? filteredBrand.name : "Brand not found";

	useEffect(() => {
		// check which order
		if (location.received === null || !user) history.push("/dashboard");
		else {
			const thisSample = user.samples.filter(sample => sample.received === null)[0];
			if (!thisSample) history.push("/dashboard");
			else {
				// check if is received
				if (location.received) {
					// fetch questions
					const orderItem = thisSample.orderItems[0];
					if (!orderItem) history.push("/dashboard");
					else {
						const brandId = orderItem.brandId;
						const sizeId = orderItem.sizeId;
						getQuestion({ brandId, sizeId });
						setFormatId(orderItem.formatId);
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const id = user.id;
		const orderId = user.samples.filter(sample => sample.received === null)[0].id;
		const received = location.received;
		if (!received && received !== null && received === false) {
			const sendThis = {
				id,
				orderId,
				received
			};
			sendFeedback(sendThis);
		}
	}, []);

	const handleContinue = () => {
		const id = user.id;
		const orderId = user.samples.filter(sample => sample.received === null)[0].id;
		const received = location.received;
		if (received && received === true) {
			// answers
			const mainAnswers = Object.values(answers.main);
			const subAnswers = Object.values(answers.sub);

			if (
				!!mainAnswers.find(
					ans =>
						ans.questionId === "c5bb120c-507d-490a-90a7-5ade93ff8faa" &&
						ans.choiceId === "fdc3aa28-210a-4510-b419-06dc1f17362c"
				) &&
				!other
			)
				alert("Please specify the reason");
			else {
				if (mainAnswers.length === questions.length) {
					// if (displaySubsampleQuestions) {
					// // Display subsample questions
					// if (currentQuestion < questions.length - 1) {
					// 	setCurrentQuestion(currentQuestion + 1);
					// }
					// } else {
					// Move to the next sample question
					const sendThis = {
						id,
						orderId,
						received,
						mainAnswers,
						subAnswers,
						openQuestion,
						other
					};
					// console.log(sendThis);
					sendFeedback(sendThis, () => setSuccess(true));
					// }
				} else {
					alert("Please answer all the questions");
				}
			}
		} else {
			history.push("/dashboard");
		}
	};

	const handleBack = () => {
		// if (currentQuestion > 0) {
		setCurrentQuestion(0);
		setQuestions(question);
		setAnswers({ main: {}, sub: {} });
		setDisplaySubsampleQuestions(false);
		setOpenQuestion("");
		setOther("");
		setGotInput(false);
		// }
	};

	const replacementText = filteredBrand.name;

	const modifiedQuestionTitle = useMemo(() => {
		if (location && location.received && questions && questions.length) {
			const originalTitle = questions[currentQuestion].title[pageReducer.language];
			const formattedTitle = originalTitle.replace("%brandName%", replacementText);

			return formattedTitle.split("\\n").map((line, index) => (
				<React.Fragment key={index}>
					<br />
					{line}
					<br />
				</React.Fragment>
			));
		} else {
			return null;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questions, currentQuestion, pageReducer.language]);

	const questionChoices = useMemo(() => {
		if (
			location &&
			location.received &&
			questions &&
			questions.length &&
			questions[currentQuestion] &&
			questions[currentQuestion].choices &&
			questions[currentQuestion].choices.length
		) {
			return questions[currentQuestion].choices;
		} else {
			return [];
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questions, currentQuestion]);

	const questiontype = useMemo(() => {
		if (
			location &&
			location.received &&
			questions &&
			questions.length &&
			questions[currentQuestion] &&
			questions[currentQuestion].choices &&
			questions[currentQuestion].choices.length
		) {
			return questions[currentQuestion].type;
		} else {
			return null;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questions, currentQuestion]);

	const subquestiontype = useMemo(() => {
		if (
			location &&
			location.received &&
			questions &&
			questions.length &&
			questions[currentQuestion] &&
			questions[currentQuestion].choices &&
			questions[currentQuestion].choices.length
		) {
			return questions[currentQuestion].subtype;
		} else {
			return null;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questions, currentQuestion]);

	// const tabClassName = val => {
	// 	if (val === currentQuestion) {
	// 		return "pickSampleQATabs current";
	// 	} else if (answers.main[val]) {
	// 		return "pickSampleQATabs answered";
	// 	} else {
	// 		return "pickSampleQATabs";
	// 	}
	// };

	const choiceClassName = val => {
		if (answers.main[currentQuestion] && answers.main[currentQuestion].choiceId === val) {
			return "pickSampleQAAnswer chosen";
		} else {
			return "pickSampleQAAnswer";
		}
	};

	const handleChoose = (choiceId, isSubanswer = false) => {
		const questionId = questions[currentQuestion].id;
		const subquestionId = isSubanswer ? questions[currentQuestion].subsampleQuestions[0].id: null;
		const updatedAnswers = isSubanswer
			? { ...answers, sub: { ...answers.sub, [currentQuestion]: { subquestionId, choiceId } } }
			: { ...answers, main: { ...answers.main, [currentQuestion]: { questionId, choiceId } } };

		setAnswers(updatedAnswers);

		const selectedChoice = questionChoices.find(choice => choice.id === choiceId);
		if (selectedChoice && selectedChoice.title.en < 4) {
			if (currentQuestion === 6) {
				setCurrentQuestion(currentQuestion + 1);
			} else {
				setDisplaySubsampleQuestions(true);
			}
		} else {
			setDisplaySubsampleQuestions(false);
			let newQuestions = question;
			if (questionId === "731d4c45-2b01-48b4-84db-4074e00ef717") {
				// first question
				if (choiceId === "6a1b9d14-f05b-4a5e-9efc-78de203e8c96") {
					// yes, skip 3
					newQuestions = question.filter(question => question.id !== "9855d889-8d98-44e9-8d7b-06a1b18f7507");
					setQuestions(newQuestions);
				}
				if (choiceId === "b563d22c-e4ad-4ec6-bffc-f969fc24b740") {
					// no, skip 2 and 4
					newQuestions = question.filter(
						question =>
							question.id !== "58eda495-6b2c-448c-9a8e-2f98294b8a76" &&
							question.id !== "c5bb120c-507d-490a-90a7-5ade93ff8faa"
					);
					setQuestions(newQuestions);
				}
			}

			if (questionId === "58eda495-6b2c-448c-9a8e-2f98294b8a76") {
				// second question
				if (choiceId === "d64bdbca-4a9c-450e-a1de-fbc59263556d") {
					// yes, skip 3 and 4
					newQuestions = question.filter(
						question =>
							question.id !== "9855d889-8d98-44e9-8d7b-06a1b18f7507" &&
							question.id !== "c5bb120c-507d-490a-90a7-5ade93ff8faa"
					);
					setQuestions(newQuestions);
				}
				if (choiceId === "a97cedb6-a621-40b6-883c-26780b945bdb") {
					// no, skip 3
					newQuestions = question.filter(question => question.id !== "9855d889-8d98-44e9-8d7b-06a1b18f7507");
					setQuestions(newQuestions);
				}
			}

			if (questionId === "c5bb120c-507d-490a-90a7-5ade93ff8faa") {
				// last question
				if (choiceId === "fdc3aa28-210a-4510-b419-06dc1f17362c") setGotInput(true);
				else setGotInput(false);
			}

			if (newQuestions.length !== question.length && currentQuestion < newQuestions.length - 1)
				setCurrentQuestion(currentQuestion + 1);
			// if (currentQuestion < questions.length - 1) {
			// 	if (
			// 		questionId === "731d4c45-2b01-48b4-84db-4074e00ef717" &&
			// 		choiceId === "b563d22c-e4ad-4ec6-bffc-f969fc24b740"
			// 	)
			// 		setCurrentQuestion(2);
			// 	else setCurrentQuestion(currentQuestion + 1);
			// }
		}
	};

	useEffect(() => {
		const element = document.getElementById("FeedbackSection");
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	useEffect(() => {
		if (question && question.length && !done) {
			setQuestions(question);
			setDone(true);
		}
	}, [question, done]);

	useEffect(() => {
		if (formatId && questions && questions.length && tapeIds && tapeIds.includes(formatId))
			setQuestions(
				questions.map(question =>
					question.id !== "9855d889-8d98-44e9-8d7b-06a1b18f7507"
						? question
						: {
								...question,
								choices: question.choices.filter(choice => choice.id !== "3422b09d-1d06-49f8-8a3f-e4858e537ca3")
						  }
				)
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formatId]);

	if (location.received === null || !user) {
		return <Redirect to="/dashboard" />;
	} else {
		return (
			<div>
				<Header />
				<div className="content">
					<ContentHeader />

					<div className="feedback-container" id="FeedbackSection">
						{!location.received ? (
							<div style={{ display: "flex", alignItems: "center", flexDirection: "column", textAlign: "center" }}>
								<img src={feedbackSmile} />
								<p>{t("thankyouFeedback")}</p>
								<p>{t("thankyouContact")}</p>
								<div className="text-center"></div>
							</div>
						) : (
							<div className="text-center">
								{/* <div className="tab-container">
									{questions.map((q, i) => (
										<div key={q.id} className={tabClassName(i)} onClick={() => setCurrentQuestion(i)}></div>
									))}
								</div> */}
								{/* <div className="tab-container">
									{questions.map((q, i) => (
										<div
											key={q.id}
											className={tabClassName(i)}
											onClick={() => {
												if (i !== 6) {
													setCurrentQuestion(i);
												}
											}}
										></div>
									))}
								</div> */}

								<h3 className="dbc-title m-0 p-0">{modifiedQuestionTitle}</h3>

								<div className="mt-5">
									{questiontype === "dropdown" ? (
										<select
											className="form-select"
											value={answers.main[currentQuestion] ? answers.main[currentQuestion].choiceId : ""}
											onChange={e => handleChoose(e.target.value)}
										>
											<option value="">{t("selectOption")}</option>
											{questionChoices.map(choice => (
												<option key={choice.id} value={choice.id} onClick={() => handleChoose(choice.id)}>
													{choice.title[pageReducer.language]}
												</option>
											))}
										</select>
									) : questiontype === "rate" ? (
										<select
											className="form-select"
											value={answers.main[currentQuestion] ? answers.main[currentQuestion].choiceId : ""}
											onChange={e => handleChoose(e.target.value)}
										>
											<option value="">{t("selectOption")}</option>
											{questionChoices
												.sort((a, b) => a.id - b.id) // Sort the questionChoices array based on choice.id in ascending order
												.map(choice => (
													<option key={choice.id} value={choice.id} onClick={() => handleChoose(choice.id)}>
														{choice.title[pageReducer.language]}
													</option>
												))}
										</select>
									) : (
										questionChoices.map(choice => (
											<div
												key={choice.id}
												className={`${choiceClassName(choice.id)} pickSampleQAAnswer`}
												onClick={() => handleChoose(choice.id)}
											>
												{choice.title[pageReducer.language]}
											</div>
										))
									)}
								</div>
								{displaySubsampleQuestions &&
									(questiontype === "rate" && subquestiontype === "close" ? (
										<div>
											{questions[currentQuestion].subsampleQuestions.map(subQuestion => (
												<div key={subQuestion.id}>
													<h3 className="dbc-title m-0 p-0 mt-3 mb-3">{subQuestion.title[pageReducer.language]}</h3>
													{subQuestion.choices
														.filter(choice => {
															return !(
																["Drypers DryPantz", "Drypers Touch Pants"].includes(filteredBrand.name) &&
																(choice.title[pageReducer.language] === "Diaper Tape" ||
																	choice.title[pageReducer.language] === "Pita Pelekat")
															);
														})
														.map(choice => (
															<div
																key={choice.id}
																className={`${choiceClassName(choice.id)} pickSampleQAAnswer`}
																onClick={() => handleChoose(choice.id, true)}
															>
																{choice.title[pageReducer.language]}
															</div>
														))}
												</div>
											))}
										</div>
									) : questiontype === "rate" && subquestiontype === "open" ? (
										<div>
											{questions[currentQuestion].subsampleQuestions.map(subQuestion => (
												<div>
													<h3 className="dbc-title m-0 p-0 mt-3 mb-3">{subQuestion.title[pageReducer.language]}</h3>
													<textarea
														id="openQuestion"
														name="openQuestion"
														className="form-control feedbackForm"
														value={openQuestion}
														onChange={e => setOpenQuestion(e.target.value)}
														required
														style={{ height: "100px" }}
													></textarea>
												</div>
											))}
										</div>
									) : null)}

								{gotInput ? (
									<input
										id="other"
										name="other"
										className="form-control mt-2"
										value={other}
										onChange={e => setOther(e.target.value)}
										placeholder={t("pleaseSpecify")}
										required
									/>
								) : null}

								<div className="text-center">
									<Loader
										component={
											<button
												type="button"
												className="btn continue-button"
												style={{ marginTop: "20px", marginBottom: "-15px" }}
												onClick={handleBack}
											>
												{t("back")}
											</button>
										}
									/>
								</div>
								<div className="text-center">
									<Loader
										component={
											<button type="button" className="btn continue-button" onClick={handleContinue}>
												{t("continue")}
											</button>
										}
									/>
								</div>
							</div>
						)}
					</div>

					<div className="mt-5 desktop-only">
						<Footer />
					</div>
				</div>

				<Modal show={pageReducer.error} onHide={() => clearError()} centered>
					<Modal.Body className="text-center">
						<div>
							<p className="addToCartTitle">{t("error")}</p>
							<p className="mt-3 pickSampleModalText">{pageReducer.error}</p>
						</div>

						<div>
							<button className="pickAddressConfirmButton" onClick={() => clearError()}>
								OKAY
							</button>
						</div>
					</Modal.Body>
				</Modal>

				<Modal show={successModal} onHide={() => history.push("/dashboard")} centered>
					<Modal.Body className="text-center">
						<div className="text-center">
							<img
								className="img-fluid text-center"
								src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/asset-12-3.png"
								alt="love"
							></img>
							<br />
							<h1 className="addToCartTitle">{t("tq")}</h1>
							<p className="pickSampleModalText">
								{location.received ? (
									<>{t("wehavereceive2")}</>
								) : (
									<>
										{t("wehavereceive")}
										<br />
										{location.received ? t("tqlocation") : t("tqnotlocation")}
									</>
								)}
							</p>
							<div>
								<button className="pickAddressConfirmButton" onClick={() => history.push("/dashboard")}>
									OKAY
								</button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		pageReducer: state.pageReducer,
		question: state.userReducer.questions,
		brandData: state.userReducer.brand
	};
};

const mapDispatchToProps = dispatch => {
	return {
		clearError: () => dispatch(clearError()),
		getQuestion: data => dispatch(getQuestion(data)),
		sendFeedback: (data, callback) => dispatch(addFeedback(data, callback)),
		getBrandInfo: () => dispatch(getBrandInfo())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
