// import { useState } from "react";
import { connect } from "react-redux";
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Footer from "../../../component/dashboard/Footer";
import { useTranslation } from "react-i18next";
// import bluebackground from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/order-delivery-address-box.png";
import { useEffect, useState } from "react";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import morePointPdf from "../../../assets/files/pointsGuide.pdf";
import sadFace from "../../../assets/contestSadFace.png";

const Activity = ({ user, history, location, language }) => {
	const { t } = useTranslation();
    const contest = location.state.contest;
	const [num, setNum] = useState(1);
	const [showModal, setShowModal] = useState(false);
	const [showModalTwo, setShowModalTwo] = useState(false);
	const contestId = contest.id;
	const filteredContests = user.contest.filter(contest => contest.id === contestId);
	const userJoined = filteredContests.reduce((total, contest) => total + contest.number, 0);
	const totalEntries = contest.entriesPerPerson - userJoined
	const [image, setImage] = useState(null)

	const handleIncrease = () => {
		if (num < totalEntries) {
			setNum(num + 1);
		}
	};

	const handleDecrease = () => {
		if (num > 1) {
			setNum(num - 1);
		}
	};

	const checkJoin = () => {
		if (userJoined + num > contest.entriesPerPerson) {
			setShowModalTwo(true);
		} else if (num * contest.pointsPerEntry > user.totalPoints) {
			setShowModal(true);
		} else {
			history.push({ pathname: "/submit", state: { contest, number: num } });
		}
	};
// console.log(language)
// console.log(contest)

useEffect(() => {
	if (language === 'bm') {
		setImage(contest.image.bm)
	} else if (language === 'en') {
		setImage(contest.image.en)
	}
}, [language])

	return (
		<div>
			<Header />
			<div className="content">
				<ContentHeader title="Contest" />
                
				<div className="activity-header">
					<div className="activity-header-container">
						<img src={image} alt="activity one" className="activity-header-image" />
					</div>
				</div>
				<div className="activity-content-blue-background"></div>
				<div className="activity-content">
					<h1 className="activity-content-title">{contest.name[language]}</h1>

                        <div className="activity-content-image">
                            <div className="activity-content-image-box">

						{contest.prizes.map((prize, index) => (
                                <div key={index} className="activity-content-prize-box main-prize">
                                    <div className="text-center my-prize-container" style={{ position: "relative" }}>
                                        <h1 className="activity-content-prize-first-title">{prize.title}</h1>
                                        <img src={prize.imageUrl} alt="activity one prize" className="activity-content-first-prize-image" />
                                        <h6 className="activity-content-prize-detail">
                                            <span className="activity-content-prize-number">{prize.amount}</span> {prize.item}
                                        </h6>
                                    </div>
                                </div>
						))}
                            </div>
                        </div>
					{/* Map contest steps here */}
					<div className="activity-content-step-container mt-5">
                        <h5 className="activity-content-step-title">{t("stepToJoin")}:</h5>
                        {contest.steps.map((step, index) => (
                            <div key={index} className="activity-content-step">
                                <h4 className="activity-content-step-number">{index + 1}</h4>
                                <h5 className="activity-content-step-text">
                                    {step.instructions[language]} <span className="activity-content-step-text-small">{step.description[language]}</span>
                                </h5>
                            </div>
                        ))}
                    </div>
					{ contest.pointRequired === true ? (
					<div>
					<h2 className="activity-content-select-entry">{t("selectYourEntry")}</h2>
 					<h4 className="activity-content-note">*NOTE: 1 ENTRY = {contest.pointsPerEntry} POINTS USED*</h4>
					</div>
					) : null
					}
					{ contest.pointRequired === true ? (
					<div className="activity-content-point-container">
						<h2 className="activity-content-your-point">{t("yourpoints")}</h2>
						<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star.png" alt="pointer star" className="activity-content-point-star" />
						<h2 className="activity-content-point-value">{user.totalPoints}</h2>
					</div>
					) : null
					}
					{ contest.entriesPerPerson === "1" ? (
					<div className="activity-content-entry-container">
						<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star-2.png" alt="pointer star 2" className="activity-content-point-star-two" />
						<h4 className="activity-content-entry-text">{t("entry")}</h4>
						<AiFillCaretLeft className="activity-content-entry-incdec-btn" onClick={handleDecrease} />
						<h3 className="activity-content-entry-num">{num}</h3>
						<AiFillCaretRight className="activity-content-entry-incdec-btn" onClick={handleIncrease} />
					</div> ) :
					null
					}

					<h3 className="activity-content-join-btn" onClick={checkJoin}>
						{t("joinNow")}
					</h3>
					<Link to="/contest">
						<h6 className="activity-content-back-btn">{t("back")}</h6>
					</Link>
					<Modal show={showModal} onHide={() => setShowModal(false)} centered>
						<Modal.Body>
							<div className="activity-content-modal-image">
								<img src={sadFace} alt="baby face one" style={{ width: "100%" }} />
							</div>
							<h1 className="activity-modal-title">{t("notEnoughPoint")}</h1>
							<h6 className="activity-modal-title">
								{t("clickHere")}{" "}
								<Link to={morePointPdf} target="_blank" style={{ textDecoration: "none" }}>
									{t("heretag")}
								</Link>
							</h6>
							<h6 className="activity-modal-go-btn" onClick={() => setShowModal(false)}>
								{t("goBack")}
							</h6>
						</Modal.Body>
					</Modal>
					<Modal show={showModalTwo} onHide={() => setShowModalTwo(false)} centered>
						<Modal.Body>
							<div className="activity-content-modal-image-group">
								<img src={sadFace} alt="baby face one" style={{ width: "100%" }} />
							</div>
							<h1 className="activity-modal-title">{t("reachedMaximum")}</h1>
							<h6 className="activity-modal-go-btn" onClick={() => setShowModalTwo(false)}>
								{t("goBack")}
							</h6>
						</Modal.Body>
					</Modal>
				</div>
			</div>
			<Footer />
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		language: state.pageReducer.language
	};
};

export default connect(mapStateToProps)(Activity);
