import React from "react";
import "./MysteryBoxComponent.css";
import mysteryBoxLogo from "../../assets/newMysteryBoxLogo.png";
import weeklyPrizeLogo from "../../assets/newWeeklyPrizeEntry.png";
import grandPrizeLogo from "../../assets/newGrandPrizeEntry.png";
import howToWinContainer from "../../assets/newDesktopHowToWinContainer.png";
import bmHowToWinContainer from "../../assets/newBmDesktopHowToWinContainer.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import mobileHowToWinContainer from "../../assets/newHowToWinContainer.png";

function MysteryBoxHowToWin() {
  const { t } = useTranslation();
  const language = useSelector((state) => state?.pageReducer?.language);
  return (
    <div className="how-to-win-component-container">
      <img
        className="mobile-how-to-win-container"
        src={mobileHowToWinContainer}
      ></img>
      <img
        src={language === "en" ? howToWinContainer : bmHowToWinContainer}
        className="how-to-win-component-container-image"
        alt="how-to-win-component-container"
      ></img>
      <div className="how-to-win-details-container">
        <h4 className="how-to-win-title">{t("submission")}</h4>
        <ol className="how-to-win-step-by-steps">
          <li className="how-to-win-step">{t("howToWin1")}  </li>
          <li className="how-to-win-step">
            {t("howToWin2")} <br></br>* {t("howToWin2Part2")}
          </li>
          <li className="how-to-win-step">
            {language === "en" ? (
              <>
                {"For "}
                <strong className="how-to-win-bold">each submission</strong>
                {", you’ll receive:"}
              </>
            ) : (
              <>
                <strong className="how-to-win-bold">Setiap penghantaran</strong>
                {" yang sah akan layak untuk:"}
              </>
            )}
          </li>
          <div className="how-to-win-reward-lists">
            <div className="how-to-win-reward-list-container">
              <img
                src={mysteryBoxLogo}
                className="how-to-win-reward-list-image"
                alt="mysterybox-logo"
              ></img>
              <p className="how-to-win-reward-list-text">
                1 X {t("howToWinPrize1")}
              </p>
            </div>
            <div className="how-to-win-reward-list-container">
              <img
                src={weeklyPrizeLogo}
                className="how-to-win-reward-list-image"
                alt="weekly-prize-logo"
              ></img>
              <p className="how-to-win-reward-list-text">
                1 X {t("howToWinPrize2")}
              </p>
            </div>
            <div className="how-to-win-reward-list-container">
              <img
                src={grandPrizeLogo}
                className="how-to-win-reward-list-image"
                alt="grand-prize-logo"
              ></img>
              <p className="how-to-win-reward-list-text">
                1 X {t("howToWinPrize3")}
              </p>
            </div>
          </div>
          <li className="how-to-win-step">{t("howToWin4")}</li>
        </ol>
      </div>
    </div>
  );
}

export default MysteryBoxHowToWin;
