import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";

const Loader = ({ component, isLoading }) => {
	if (isLoading) {
		return <Spinner animation="grow" variant="primary" />;
	} else {
		return component;
	}
};

const mapStateToProps = state => {
	return {
		isLoading: state.pageReducer.isLoading
	};
};

export default connect(mapStateToProps)(Loader);
