import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
// import Loader from "../../../../component/Loader";

// import { addBrand } from "../../../../redux/actions/adminActions";

const BrandDetails = ({ location, history }) => {
	const addDetails = () => history.push({ pathname: "/admin/brand/assign", data: location.data });
	const sizeTemplate = ["nb", "s", "m", "l", "xl", "xxl"];
	const sortFunc = (a, b) => sizeTemplate.indexOf(a.name) - sizeTemplate.indexOf(b.name);
	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>
						<strong>{location.data.name}</strong>
					</h3>

					<div className="mt-5">
						<div>
							<img src={location.data.imageUrl} alt="brand" className="img-fluid" />
							<p>
								Description: <strong>{location.data.description}</strong>
							</p>
							<p>
								Created At: <strong>{location.data.createdAt}</strong>
							</p>
							<p>
								Updated At: <strong>{location.data.updatedAt}</strong>
							</p>
						</div>

						<div>
							<p>
								Formats and sizes:{" "}
								<button type="button" className="btn btn-primary ms-2" onClick={addDetails}>
									Add Format/Sizes
								</button>
							</p>

							<div id="diaper-formats-sizes">
								{location.data.format.length ? (
									location.data.format.map(x => {
										return (
											<div className="card" key={x.id}>
												<div className="card-header">
													<h5 className="card-title">{x.name.toUpperCase()}</h5>
												</div>

												<div className="card-body">
													<ul className="list-group">
														{x.size.sort(sortFunc).map(y => (
															<li key={y.id} className="list-group-item">
																{y.name.toUpperCase()}
															</li>
														))}
													</ul>
												</div>
											</div>
										);
									})
								) : (
									<p>Add format and sizes</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BrandDetails;
