import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { getRewards, getVoucher, getDonates, getPregnant } from "../../redux/actions/userActions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ItemPreview = ({ catalog, getRewards, history, title, voucher, donates, getVoucher, getDonates, language, getPregnant, pregnant }) => {
	const { t } = useTranslation();
	useEffect(() => {
		if (!catalog) getRewards();
		if (!voucher) getVoucher();
		if (!donates) getDonates();
		if (!pregnant) getPregnant();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const truncateText = text => (text && text.length > 40 ? text.substr(0, 40) + "..." : text);

	const allProcessedItem = useMemo(() => {
		let allItems = [];

		if (catalog && catalog.data && catalog.data.length) {
			for (let i = 0; i < catalog.data.length; i++) {
				const item = catalog.data[i];
				const type = "item";
				const { id, itemName, points, itemImage, remaining } = item;
				if (remaining > 0) {
					const itemObj = {
						data: item,
						type,
						id,
						itemName: itemName[language],
						points,
						itemImage: itemImage[language]
					};

					allItems.push(itemObj);
				}
			}
		}

		if (voucher && voucher.length) {
			for (let i = 0; i < voucher.length; i++) {
				const item = voucher[i];
				const type = "voucher";
				const { id, name, points, imageUrl, available } = item;
				if (available > 0) {
					const itemName = name;
					const itemImage = imageUrl;

					const itemObj = {
						data: item,
						type,
						id,
						itemName,
						points,
						itemImage
					};
					allItems.push(itemObj);
				}
			}
		}

		if (donates && donates.length) {
			for (let i = 0; i < donates.length; i++) {
				const item = donates[i];
				const type = "donate";
				const { id, itemImage, itemName, points } = item;

				const itemObj = {
					data: item,
					type,
					id,
					itemName,
					points,
					itemImage
				};

				allItems.push(itemObj);
			}
		}

		function shuffle(array) {
			let currentIndex = array.length,
				randomIndex;

			// While there remain elements to shuffle...
			while (currentIndex !== 0) {
				// Pick a remaining element...
				randomIndex = Math.floor(Math.random() * currentIndex);
				currentIndex--;

				// And swap it with the current element.
				[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
			}

			return array;
		}

		return shuffle(allItems).slice(0, 6);
	}, [catalog, donates, voucher, language]);

	return (
		<div id="item-preview">
			<h2 className="dbc-title">{title}</h2>

			<div className="item-preview-container">
				{allProcessedItem.map(item => (
					<Link
						key={item.id}
						className="item-preview-item"
						to={{ pathname: "/rewarddetail", data: item.data, type: item.type }}
						style={{ textDecoration: "none" }}
					>
						<div className="item-preview-img-container">
							<img src={item.itemImage} alt="item" className="img-fluid item-preview-img" />
						</div>
						<div>
							<p className="m-auto p-0 item-preview-text itemName">{truncateText(item.itemName)}</p>
							<p className="m-0 p-0 item-preview-text itemPoint">
								{item.points} {t("pointscap")}
							</p>
						</div>
					</Link>
				))}
			</div>

			<button className="item-preview-button" onClick={() => history.push("/pointshop")}>
				{t("seemore")}
			</button>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		catalog: state.userReducer.catalogue,
		voucher: state.userReducer.voucher,
		donates: state.userReducer.donate,
		language: state.pageReducer.language,
		pregnant: state.userReducer.pregnant
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getRewards: () => dispatch(getRewards()),
		getVoucher: () => dispatch(getVoucher()),
		getDonates: () => dispatch(getDonates()),
		getPregnant: () => dispatch(getPregnant())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemPreview);
