import React, { useState } from 'react';
import { connect } from "react-redux";
import { editContest } from "../../../../redux/actions/adminActions";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";

const EditContest = ({ editContest, history, location }) => {
  const [name, setName] = useState(location.data.name.en);
  const [startDate, setStartDate] = useState(location.data.startDate);
  const [endDate, setEndDate] = useState(location.data.endDate);
  const [campaignIcon, setCampaignIcon] = useState(null);
  const [campaignIconUrl, setCampaignIconUrl] = useState(null);
  const [files, setFiles] = useState([]);
  const [amounts, setAmounts] = useState( location.data.prizes.map((prize) => prize.amount || null)); // Add this line
  const [imgPreviews, setImgPreviews] = useState([]);  
  const [description, setDescription] =  useState(location.data.description.en);
  const [steps, setSteps] = useState([]);

  //BM
  const [nameBM, setNameBM] = useState(location.data.name.bm);
  const [campaignIconBM, setCampaignIconBM] = useState(null);
  const [campaignIconUrlBM, setCampaignIconUrlBM] = useState(null);
  const [descriptionBM, setDescriptionBM] = useState(location.data.description.bm);
  const [stepsBM, setStepsBM] = useState([]);

  const handleCampaignIconChange = (event) => {
    const file = event.target.files[0];
    setCampaignIcon(file);
    setCampaignIconUrl(URL.createObjectURL(file));
  };

  const handleCampaignIconBMChange = (event) => {
    const file = event.target.files[0];
    setCampaignIconBM(file);
    setCampaignIconUrlBM(URL.createObjectURL(file));
  };

  const handleImages = (e) => {
		const fileList = e.target.files;
		const previewArr = [];

		for (let i = 0; i < fileList.length; i++) {
			previewArr.push(URL.createObjectURL(fileList[i]));
		}

		setFiles([...files, ...fileList]);
		setImgPreviews([...imgPreviews, previewArr]);
    const newAmounts = [];
    const amountInputs = document.getElementsByClassName("amount-input");
  
    for (let i = 0; i < amountInputs.length; i++) {
      const amount = amountInputs[i].value;
      newAmounts.push(amount);
    }
  
    setAmounts([...amounts, ...newAmounts]);
  };

  const editImage = (e, index) => {
		const fileList = e.target.files;
    const newImg = [...imgPreviews];

    if (fileList && fileList.length) {
		newImg[index] = URL.createObjectURL(fileList[0]);
    setImgPreviews([...newImg]);
    }
  }

  const handleAddStep = () => {
    setSteps([...steps, { instruction: '', description: '', order: steps.length + 1 }]);
  };

  const handleAddStepBM = () => {
    setStepsBM([...stepsBM, { instructionBM: '', descriptionBM: '', orderBM: stepsBM.length + 1 }]);
  };

    const handleStepChange = (index, field, value) => {
    const updatedSteps = [...steps];
    updatedSteps[index][field] = value;
    setSteps(updatedSteps);
  };
    
  const handleStepChangeBM = (index, field, value) => {
    const updatedStepsBM = [...stepsBM];
    updatedStepsBM[index][field] = value;
    setStepsBM(updatedStepsBM);
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("handle triggered");
  
    try {

      if (campaignIcon && campaignIconBM) {

      
      const formData = new FormData();
      formData.append("id", location.data.id);
      formData.append('name', name);
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
      formData.append('campaignIcon', campaignIcon);
      formData.append('description', description);
      formData.append('nameBM', nameBM);
      formData.append('descriptionBM', descriptionBM);
      formData.append('campaignIconBM', campaignIconBM);

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
        formData.append("amounts", amounts[i]); 
        }
  
      editContest(formData, err => {
        if (err) {
          alert(err);
        } else {
          history.push("/admin/contest/dashboard");
        }
      });
    } else {
      const formData = new FormData();
      formData.append("id", location.data.id);
      formData.append('name', name);
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
      formData.append('description', description);
      formData.append('nameBM', nameBM);
      formData.append('descriptionBM', descriptionBM);
      formData.append('steps', JSON.stringify(steps));
      formData.append('stepsBM', JSON.stringify(stepsBM));

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
        formData.append("amounts", amounts[i]); 
        }
  
      editContest(formData, err => {
        if (err) {
          alert(err);
        } else {
          history.push("/admin/contest/dashboard");
        }
      });

    }

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="admin-page">
    <AdminSidebar />

    <div className="admin-body">
      <AdminTopbar />

      <div className="admin-content">
          <form onSubmit={handleSubmit}>
            <div>
              <label className='contest-label' htmlFor="name">Campaign Name:</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input" style={{width: '50%'}}  type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder='Campaign Name' required />
            </div>
            <div>
              <label className='contest-label' htmlFor="description">Campaign Description:</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input" style={{width: '50%'}}  type="text" id="description" value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Campaign Description' required />
            </div>
          <div className='mt-3' style={{display: "flex"}}>
            <div>
              <label className='contest-label m-0' htmlFor="startDate">Start Date:</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input" type="date" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
            </div>
            <div style={{marginLeft: '20px'}}>
              <label className='contest-label m-0' htmlFor="endDate">End Date:</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input"  type="date" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} required />
            </div>
          </div>

            <div className="form-group mt-3">
            <label className='contest-label m-0' htmlFor="campaignIcon">Contest Key Visual</label><br></br>
								<div className="form-control"
									style={{
										border: "3px solid #67acde",
										borderRadius: "16px",
                    width: "50%"
									}}>
									<div style={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between' }}>
                  {campaignIconUrl && (
                <img src={campaignIconUrl} alt="Campaign Icon" style={{ width: '200px' }} />
                  )}
										<div style={{border: "1px solid black",}} className="image-upload-box" onClick={() => document.getElementById('campaignIcon').click()}>
											+
										</div>
									</div>
									<input type="file" name="campaignIcon" id="campaignIcon" className="form-control" accept="image/*" onChange={handleCampaignIconChange}
										 style={{
											border: "2.6402px solid black",
											borderRadius: "15.8412px",
											display: "none",
										}} />
								</div>
							</div>



              <div className="form-group mt-3">
            <label className='contest-label m-0'>List of Prizes</label><br></br>
                <div>
                  <div>
                    {imgPreviews.map((preview, index) => (
                      <div style={{border: '3px solid #67acde', width: '50%', borderRadius: '16px'}} className='d-flex'>
                      <div className="image-upload-box" style={{position: 'relative'}} key={index + 1}>
                        {preview ? <>  
                        <input
                    type="file"
                    name="files"
                    id="edit"
                    className="form-control"
                    accept="image/*"
                    onChange={(e) => editImage(e, index)}
                    style={{
                      width: '135px',
                      height: '100px',
                      position: "absolute",
                      opacity: "0"
                    }}
                  /> 
                  <img src={preview} alt="preview" /> 
                  </>
                    :   <div
                      style={{ border: "1px solid black" }}
                      className="image-upload-box"
                      onClick={() => document.getElementById("files").click()}
                    >
                      +
                    </div>}
                        
                        {/* Add the amount input */}
                      </div>
                       <input
                       style={{border: 'none', width: "75%"}}
                       type="text"
                       placeholder="Type of Prizes (Amount)"
                       onChange={(e) => {
                         const newAmounts = [...amounts];
                         newAmounts[index] = e.target.value;
                         setAmounts(newAmounts);
                       }}
                     />
                     </div>
                     ))}
                  <p className='contest-label' onClick={() => document.getElementById("files").click()} style={{textAlign: 'center', width: '50%', color: '#fdac39'}}>+ {imgPreviews.length ? 'Update More Prizes' : 'Update Prize'}</p>
                  </div>
                  <input
                    type="file"
                    name="files"
                    id="files"
                    className="form-control"
                    accept="image/*"
                    multiple
                    onChange={handleImages}
                    // required
                    style={{
                      border: "2.6402px solid black",
                      borderRadius: "15.8412px",
                      display: "none"
                    }}
                  />
                </div>
              </div>

              <div>
              <label className='mt-3 contest-label' htmlFor="steps">Steps To Participate</label><br></br>
              {steps.map((step, index) => (
                <div key={index}>
                  {/* <input
                    type="number"
                    value={step.order}
                    onChange={(e) => handleStepChange(index, 'order', parseInt(e.target.value))}
                    placeholder="Order"
                  /> */}
                  <h6 style={{color: '#647dcc', fontWeight: '600'}}>Step {step.order}</h6>
                  <input                  
                  className="form-control vinda-form-input steps-instruction"
                    type="text"
                    value={step.instruction}
                    onChange={(e) => handleStepChange(index, 'instruction', e.target.value)}
                    placeholder="Instruction"
                  />
                  <input
                  className="form-control vinda-form-input steps-description"
                    type="text"
                    value={step.description}
                    onChange={(e) => handleStepChange(index, 'description', e.target.value)}
                    placeholder="Description"
                  />
                </div>
              ))}
                <p className='contest-label' onClick={handleAddStep} style={{textAlign: 'center', width: '50%', color: '#fdac39'}}>+ {steps.length ? 'Update More Steps' : 'Update Step'}</p>
            </div>

            <div className='mt-3'>
              <label htmlFor="nameBM" className='contest-label'>Name (BM):</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input" style={{width: '50%'}} type="text" id="nameBM" value={nameBM} onChange={(e) => setNameBM(e.target.value)} required />
            </div>  
            <div>
              <label className='contest-label' htmlFor="descriptionBM">Campaign Description:</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input" style={{width: '50%'}}  type="text" id="descriptionBM" value={descriptionBM} onChange={(e) => setDescriptionBM(e.target.value)} placeholder='Campaign Description' required />
            </div>

              <div className="form-group mt-3">
            <label htmlFor="campaignIconBM" className='contest-label m-0'>Contest Key Visual:</label><br></br>
								<div className="form-control"
										style={{
										border: "3px solid #67acde",
										borderRadius: "16px",
                    width: "50%"
									}}>
									<div style={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between'}}>
                  {campaignIconUrlBM && (
                <img src={campaignIconUrlBM} alt="Campaign Icon" style={{ width: '200px' }} />
                  )}
										<div style={{border: "1px solid black",}} className="image-upload-box" onClick={() => document.getElementById('campaignIconBM').click()}>
											+
										</div>
									</div>
									<input type="file" name="campaignIconBM" id="campaignIconBM" className="form-control" accept="image/*" onChange={handleCampaignIconBMChange}
										 style={{
											border: "2.6402px solid black",
											borderRadius: "15.8412px",
											display: "none",
										}} />
								</div>
							</div>
              <div>
            <label htmlFor="stepsBM" className='contest-label'>Steps To Participate (BM):</label><br></br>
              {stepsBM.map((stepBM, index) => (
                <div key={index}>
                  <h6 style={{color: '#647dcc', fontWeight: '600'}}>Step {stepBM.orderBM}</h6>
                  <input
                  className="form-control vinda-form-input steps-instruction"
                    type="text"
                    value={stepBM.instructionBM}
                    onChange={(e) => handleStepChangeBM(index, 'instructionBM', e.target.value)}
                    placeholder="Instruction (BM)"
                  /><br></br>
                  <input
                  className="form-control vinda-form-input steps-description"
                    type="text"
                    value={stepBM.descriptionBM}
                    onChange={(e) => handleStepChangeBM(index, 'descriptionBM', e.target.value)}
                    placeholder="Description (BM)"
                  />
                  {/* <input
                  className="form-control vinda-form-input"
                    type="number"
                    value={stepBM.orderBM}
                    onChange={(e) => handleStepChangeBM(index, 'orderBM', parseInt(e.target.value))}
                    placeholder="Order (BM)"
                  /> */}
                </div>
              ))}
               <p className='contest-label' onClick={handleAddStepBM} style={{textAlign: 'center', width: '50%', color: '#fdac39'}}>+ {steps.length ? 'Update More Steps' : 'Update Step'}</p>
            </div>
            <Loader component={<button className='mt-3 submit-contest' type="submit">Update</button>} />
          </form>

         </div>
			</div>
		</div>
  );
};


const mapDispatchToProps = dispatch => {
  return {
    editContest: (data, callback) => dispatch(editContest(data, callback))
  };
};

export default connect(null, mapDispatchToProps)(EditContest);