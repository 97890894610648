import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { addVoucherType } from "../../../../redux/actions/adminActions";

const AddVoucherType = ({ history, addVoucherType }) => {
	const [data, setData] = useState({
		itemId: "",
		name: "",
		value: 0,
		points: 0
	});

	const [file, setFile] = useState(null);

	const handleChange = e => {
		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleImage = e => setFile(e.target.files[0]);

	const handleSubmit = e => {
		e.preventDefault();
		const { itemId, name, value, points } = data;
		if (itemId && name && value && points && file) {
			const formData = new FormData();
			formData.append("itemId", itemId);
			formData.append("name", name);
			formData.append("value", value);
			formData.append("points", points);
			formData.append("file", file);
			addVoucherType(formData, err => {
				if (err) alert(err);
				else history.push("/admin/voucher/dashboard");
			});
		} else {
			alert("Please fill in all the fields");
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Add Voucher Type</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="itemId">
									Item ID <small>(Article No)</small>
								</label>
								<input type="text" name="itemId" className="form-control" id="itemId" value={data.itemId} onChange={handleChange} required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="name">Voucher Name</label>
								<input type="text" name="name" className="form-control" id="name" value={data.name} onChange={handleChange} required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="value">Voucher Value (RM)</label>
								<input type="number" name="value" className="form-control" id="value" value={data.value} onChange={handleChange} min={0} step={0.01} required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="points">Points for redemption</label>
								<input type="number" name="points" className="form-control" id="points" value={data.points} onChange={handleChange} min={0} required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="file">Voucher Image</label>
								<input type="file" name="file" className="form-control" id="file" accept="image/*" onChange={handleImage} required />
							</div>

							<div className="form-group mt-5 text-center">
								<Loader component={<input type="submit" value="SUBMIT" className="btn btn-primary form-control" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		addVoucherType: (data, callback) => dispatch(addVoucherType(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(AddVoucherType);
