import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getJourney, getWishingTree } from "../../redux/actions/userActions";
import Home from "../../../src/assets/HomeIcon.png"
import Poeple from "../../../src/assets/PeopleIcon.png"
import Star from "../../../src/assets/StarIcon.png"
import Box from "../../../src/assets/BoxIcon.png"

function Journey({ journey, getJourney, wishingtree, getWishingTree }) {
	const { t } = useTranslation();

    const [wishingTree, setWishingTree] = useState([])

    useEffect(() => {
		if(!journey) getJourney();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


    useEffect(() => {
         if(!wishingtree) getWishingTree();
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);


     const [totalPoints, setTotalPoints] = useState(0);

     useEffect(() => {
       if (wishingtree && wishingtree.length) {
         let data = [];
         let pageData = [];
         for (let j = 0 ; j < wishingtree.length ; j++) {
           const wish = wishingtree[j]
           pageData.push(wish);
     
           if (pageData.length === 5) {
             data.push(pageData);
             pageData = []
           }
         }
         if (pageData.length) data.push(pageData);
         setWishingTree(data);
         setTotalPoints(
           wishingtree.reduce((total, wish) => {
             return total + wish.points;
           }, 0)
         );
       }
     
     }, [wishingtree]);

     const formattedPoints = totalPoints.toLocaleString("en-US");
      
	return (
        
        <div className="journey-background">

{journey && journey.length ? (
    journey.map(x => (
        <div>
            <h1 className="our-journey-charity mt-5 pt-5 mb-5" style={{textAlign: "center"}}>{t("ourJourney")}</h1>
            <div className="journey-container">
            <div className="journey-size">
                <div style={{ flex: 1 }}>
                <div className="journey-item">
                    <h2 className="small-journey-text">{t("totalHomeVisit")}</h2>
                    <p className="journey-number"><img style={{height: "50px"}} src={Home}/>{x.home}</p>
                </div>
                </div>
                <div style={{ flex: 1 }}>
                <div className="journey-item">
                    <h2 className="small-journey-text">{t("totalKidsReaches")}</h2>
                    <p className="journey-number"><img style={{height: "50px"}} src={Poeple}/>{x.children}</p>
                </div>
                </div>
                <div style={{ flex: 1 }}>
                <div className="journey-item">
                    <h2 className="small-journey-text">{t("totalPointDonated")}</h2>
                    <p className="journey-number"><img style={{height: "50px"}} src={Star}/>{x.points}</p>
                </div>
                </div>
            </div>
            {/* <div className="journey-size" style={{ display: "flex", flexDirection: "row", margin: "auto" }}>
                <div style={{ flex: 1 }}>
                <div className="journey-item">
                    <h2 className="small-journey-text">{t("totalPointDonated")}</h2>
                    <p className="journey-number"><img style={{width: "25%"}} src={Star}/>{x.points}</p>
                </div>
                </div>
                <div style={{ flex: 1 }}>
                <div className="journey-item">
                    <h2 className="small-journey-text">NO. OF PRODUCT DONATED</h2>
                    <p className="journey-number"><img style={{width: "25%"}} src={Box}/>{x.product}</p>
                </div>
                </div>
            </div> */}
            </div>

        </div>
    ))
) : (
    <></>
)}


     </div>
	);
}

// export default Journey;

const mapDispatchToProps = dispatch => {
	return {
		getJourney: data => dispatch(getJourney(data)),
    getWishingTree: data => dispatch(getWishingTree(data)),
	};
};

const mapStateToProps = state => {
	return {
		journey: state.userReducer.journey,
		language: state.pageReducer.language,
    wishingtree: state.userReducer.wishingtree,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Journey);

