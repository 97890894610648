import { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
// Components
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Footer from "../../../component/dashboard/Footer";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const PickSize = ({ sample, location }) => {
	const { t } = useTranslation();
	const [format, setFormat] = useState("");
	const sizeTemplate = ["nb", "s", "m", "l", "xl", "xxl"];
	const sortFunc = (a, b) => sizeTemplate.indexOf(a.name) - sizeTemplate.indexOf(b.name);

	useEffect(() => {
		if (
			sample &&
			sample.data &&
			sample.brands &&
			sample.availableData &&
			sample.availableData.formats &&
			sample.availableData.formats.length &&
			sample.brands.length &&
			sample.brands.filter(brand => brand.id === location.brandId)[0] &&
			sample.brands.filter(brand => brand.id === location.brandId)[0].formats &&
			sample.brands.filter(brand => brand.id === location.brandId)[0].formats.length
		) {
			setFormat(
				sample.brands.filter(brand => brand.id === location.brandId)[0].formats.filter(format => sample.availableData.formats.includes(format.id))[0].id
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const selectedStyle = val => {
	// 	if (val === format) {
	// 		return {
	// 			backgroundColor: "#FEA30B",
	// 			color: "white"
	// 		};
	// 	} else {
	// 		return {};
	// 	}
	// };

	if (!location.brandId) {
		return <Redirect to="/picksample" />;
	} else {
		return (
			<div>
				<Header />
				<div className="content">
					<ContentHeader title={t("freesamplelow")} />

					<div className="desktop-only text-center mt-4">
						<h1 className="dbc-title">{t("freesamplelow")}</h1>
					</div>

					<div className="size-master-container">
						<img src={location.brandImage} alt="brandImage" className="img-fluid brandImage" />

						{/* <div className="choice-parent mt-2">
							<p className="choice-title">{t("selectformat")}</p>

							<div className="choice-container">
								{location.brandId &&
								sample &&
								sample.data &&
								sample.brands &&
								sample.availableData &&
								sample.availableData.formats &&
								sample.availableData.formats.length &&
								sample.brands.length &&
								sample.brands.filter(brand => brand.id === location.brandId)[0] &&
								sample.brands.filter(brand => brand.id === location.brandId)[0].formats &&
								sample.brands.filter(brand => brand.id === location.brandId)[0].formats.length
									? sample.brands
											.filter(brand => brand.id === location.brandId)[0]
											.formats.filter(format => sample.availableData.formats.includes(format.id))
											.map(format => (
												<button type="button" key={format.id} onClick={() => setFormat(format.id)} style={selectedStyle(format.id)}>
													{format.name.toUpperCase()}
												</button>
											))
									: null}
							</div>
						</div> */}

						<div className="choice-parent mt-4">
							<p className="choice-title" style={{ color: "#223C96" }}>
								Select Size:
							</p>

							<div className="size-container">
								{location.brandId &&
								sample &&
								sample.data &&
								sample.brands &&
								sample.availableData &&
								sample.availableData.sizes &&
								sample.availableData.sizes.length &&
								sample.brands.length &&
								sample.brands.filter(brand => brand.id === location.brandId)[0] &&
								sample.brands.filter(brand => brand.id === location.brandId)[0].sizes &&
								sample.brands.filter(brand => brand.id === location.brandId)[0].sizes.length
									? sample.brands
											.filter(brand => brand.id === location.brandId)[0]
											.sizes.filter(size => sample.availableData.sizes.includes(size.id) && size.brandId === location.brandId && size.formatId === format)
											.sort(sortFunc)
											.map(size => (
												<div className="sample-size-item">
													<Link
														key={size.id}
														to={{ pathname: "/samplesdetail", data: { brandId: location.brandId, formatId: format, sizeId: size.id } }}
														style={{ textDecoration: "none" }}
													>
														<div className="sample-size-item-link">
															<p className="m-0 p-0">
																{size.name.toUpperCase()} ({size.description})
															</p>
														</div>
													</Link>
												</div>
											))
									: null}
							</div>

							<div className="vinda-form my-5">
								<div className="form-group vinda-form-group text-center my-5">
									<Link to="/picksample" type="button" className="vinda-form-submit btn" style={{ textDecoration: "none" }}>
										{t("back")}
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="desktop-only">
						<Footer />
					</div>
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		sample: state.userReducer.samples
	};
};

export default connect(mapStateToProps, null)(PickSize);
