export const itemDashboardColumns = [
	{
		label: "Show Item",
		field: "showItem"
	},
	{
		label: "Item ID",
		field: "itemId"
	},
	{
		label: "Item Name",
		field: "itemName"
	},
	{
		label: "Item Name (BM)",
		field: "bmName"
	},
	{
		label: "Unit of Measurement",
		field: "uom"
	},
	{
		label: "Points",
		field: "points"
	},
	{
		label: "Inventory",
		field: "inventory"
	},
	{
		label: "Redeemed",
		field: "redeemed"
	},
	{
		label: "Remaining",
		field: "remaining"
	},
	{
		label: "Description",
		field: "description"
	},
	{
		label: "Description (BM)",
		field: "bmDescription"
	},
	{
		label: "Item Image",
		field: "itemImage"
	},
	{
		label: "Item Image (BM)",
		field: "bmItemImage"
	},
	{
		label: "Added Date",
		field: "createdAt"
	},
	{
		label: "Updated Date",
		field: "updatedAt"
	},
	{
		label: "Item Brand",
		field: "itemBrand"
	},
	{
		label: "Edit",
		field: "edit"
	}
];

export const pregnantDashboardColumns = [
	{
		label: "Show Item",
		field: "showItem"
	},
	{
		label: "Item ID",
		field: "itemId"
	},
	{
		label: "Item Name",
		field: "itemName"
	},
	{
		label: "Item Name (BM)",
		field: "bmName"
	},
	{
		label: "Unit of Measurement",
		field: "uom"
	},
	{
		label: "Points",
		field: "points"
	},
	{
		label: "Inventory",
		field: "inventory"
	},
	{
		label: "Redeemed",
		field: "redeemed"
	},
	{
		label: "Remaining",
		field: "remaining"
	},
	{
		label: "Description",
		field: "description"
	},
	{
		label: "Description (BM)",
		field: "bmDescription"
	},
	{
		label: "Item Image",
		field: "itemImage"
	},
	{
		label: "Item Image (BM)",
		field: "bmItemImage"
	},
	{
		label: "Added Date",
		field: "createdAt"
	},
	{
		label: "Updated Date",
		field: "updatedAt"
	},
	{
		label: "Item Brand",
		field: "itemBrand"
	},
	{
		label: "Edit",
		field: "edit"
	}
];

export const diaperDashboardColumns = [
	{
		label: "Show Item",
		field: "showItem"
	},
	{
		label: "Item ID",
		field: "itemId"
	},
	{
		label: "Item Name",
		field: "itemName"
	},
	{
		label: "Item Name (bahasa)",
		field: "bmName"
	},
	{
		label: "UOM",
		field: "uom"
	},
	{
		label: "Inventory",
		field: "inventory"
	},
	{
		label: "Redeemed",
		field: "redeemed"
	},
	{
		label: "Remaining",
		field: "remaining"
	},
	{
		label: "Description",
		field: "description"
	},
	{
		label: "Description (Bahasa)",
		field: "bmDescription"
	},
	{
		label: "Image",
		field: "image"
	},
	{
		label: "Image in BM",
		field: "bmImage"
	},
	{
		label: "Created Date",
		field: "createdAt"
	},
	{
		label: "Updated Date",
		field: "updatedAt"
	},
	{
		label: "Brand",
		field: "brand"
	},
	{
		label: "Format",
		field: "format"
	},
	{
		label: "Size",
		field: "size"
	},
	{
		label: "Edit",
		field: "edit"
	}
];

export const voucherDashboardColumns = [
	{
		label: "Show Item",
		field: "showItem"
	},
	{
		label: "Item ID (Article No)",
		field: "itemId"
	},
	{
		label: "Voucher Name",
		field: "name"
	},
	{
		label: "Value",
		field: "value"
	},
	{
		label: "Points",
		field: "points"
	},
	{
		label: "Uploaded Batches",
		field: "batch"
	},
	{
		label: "Total Vouchers",
		field: "total"
	},
	{
		label: "Available Vouchers",
		field: "available"
	},
	{
		label: "Redeemed Vouchers",
		field: "redeemed"
	},
	{
		label: "Used Vouchers",
		field: "used"
	},
	{
		label: "Voucher Image",
		field: "voucherImage"
	},
	{
		label: "Details",
		field: "details"
	}
];

export const voucherBatchColumns = [
	{ label: "Batch ID", field: "id" },
	{ label: "Upload Date", field: "createdAt" },
	{ label: "Number of Codes", field: "total" },
	{ label: "Redeemed Codes", field: "redeemed" },
	{ label: "Used Codes", field: "used" },
	{ label: "Available Codes", field: "available" },
	{ label: "Details", field: "details" }
];

export const voucherDetailsColumns = [
	{ label: "Voucher Code", field: "code" },
	{ label: "Status", field: "status" },
	{ label: "Created Date", field: "createdAt" },
	{ label: "Last status update", field: "updatedAt" },
	{ label: "User Name", field: "name" },
	{ label: "Phone Number", field: "number" },
	{ label: "Email Address", field: "email" }
];

export const codeDashboardColumns = [
	{ label: "Batch ID", field: "id" },
	{ label: "Brand", field: "brand" },
	{ label: "Format", field: "format" },
	{ label: "Size", field: "size" },
	{ label: "Generated Date", field: "createdAt" },
	{ label: "Points", field: "points" },
	{ label: "Quantity", field: "quantity" },
	{ label: "Details", field: "details" },
	{ label: "Download", field: "download" },
	{ label: "Show Item", field: "showItem" },
];

export const codeDetailsColumns = [
	{ label: "Code", field: "code" },
	{ label: "Status", field: "status" },
	{ label: "Scanned Date", field: "scannedDate" }
];

export const newsDashboardColumns = [
	{ label: "Title", field: "title" },
	{ label: "Title (BM)", field: "bmTitle" },
	{ label: "Description", field: "description" },
	{ label: "Description (BM)", field: "bmDescription" },
	{ label: "Image", field: "image" },
	{ label: "Image (BM)", field: "bmImage" },
	{ label: "Link", field: "link" },
	{ label: "Added Date", field: "createdAt" },
	{ label: "Updated Date", field: "updatedAt" },
	{ label: "Show?", field: "active" },
	{ label: "Edit", field: "edit" },
	{ label: "Delete", field: "delete"}
];

export const articleDashboardColumns = [
	{ label: "Title", field: "title" },
	{ label: "Title BM", field: "titleBM" },
	{ label: "Description", field: "description" },
	{ label: "Description BM", field: "descriptionBM" },
	{ label: "Image", field: "image" },
	{ label: "Show?", field: "active" },
	{ label: "Edit", field: "edit" },
];

export const journeyDashboardColumns = [
	{ label: "Home", field: "home" },
	{ label: "Children", field: "children" },
	{ label: "Points", field: "points" },
	{ label: "Product", field: "product" },
	{ label: "Description", field: "description" },
	{ label: "Description BM", field: "descriptionBM"},
	{ label: "ProgressBar Points", field: "progressPoints"},
	{ label: "Edit", field: "edit" },
];

export const blogDashboardColumns = [
	{ label: "Title", field: "title" },
	{ label: "Venue", field: "venue"},
	{ label: "Description", field: "description" },
	{ label: "DescriptionBm", field: "descriptionBm" },
	{ label: "Image", field: "image" },
	{ label: "Show?", field: "active" },
	{ label: "Edit", field: "edit" },
];

export const WishingTreeDashboardColumns = [
	{ label: "Name", field: "name" },
	{ label: "Wishes", field: "wishes"},
	{ label: "BM Wishes", field: "bmWishes"},
	{ label: "Points", field: "points" },
	{ label: "Image", field: "image" },
	{ label: "Show?", field: "active" },
	{ label: "Edit", field: "edit" }
];

export const sampleDashboardColumns = [
	{ label: "NO", field: "no" },
	{ label: "Question Title", field: "title" },
	{ label: "Question Title (BM)", field: "bmTitle" },
	{ label: "Added Date", field: "createdAt" },
	{ label: "Updated Date", field: "updatedAt" },
	{ label: "Choices", field: "choice" },
	{ label: "Choices (BM)", field: "bmChoice" },
	{ label: "Question Type", field: "type"},
	// { label: "Delete", field: "delete" }
];

export const accountColumns = [
	{ label: "Id", field: "id", key: "id" },
	{ label: "Registration Date", field: "registerationdate", key: "registerationdate" },
	{ label: "Full Name", field: "name", key: "name" },
	{ label: "Gender", field: "gender", key: "gender" },
	{ label: "Date of Birth", field: "dob", key: "dob" },
	{ label: "Email", field: "email", key: "email" },
	{ label: "CellPhone", field: "number", key: "number" },
	{ label: "Address 1", field: "address1", key: "address1" },
	{ label: "Address 2", field: "address2", key: "address2" },
	{ label: "PostalCode", field: "postalcode", key: "postalcode" },
	{ label: "City", field: "city", key: "city" },
	{ label: "State", field: "state", key: "state" },
	{ label: "Country", field: "country", key: "country" },
	{ label: "Due Date", field: "duedate", key: "duedate" },
	{ label: "Current Stage", field: "pregnancyStage", key: "pregnancyStage" },
	{ label: "Membership Type", field: "membershiptype", key: "membershiptype" },
	{ label: "Referred By", field: "referBy", key: "referBy" },
	{ label: "Verification Status", field: "verified", key: "verified" },
	{ label: "Button", field: "button", key: "" }
];

export const pointshopColumns = [
	{ label: "OrderNumber", field: "id", key: "id" },
	{ label: "ProductCategory", field: "category", key: "category" },
	{ label: "GivenName", field: "name", key: "name" },
	{ label: "Email", field: "email", key: "email" },
	{ label: "ProductDesc", field: "itemName", key: "itemName" },
	{ label: "Quantity", field: "quantity", key: "quantity" },
	{ label: "Points", field: "points", key: "points" },
	{ label: "ShipToName", field: "shipName", key: "shipName" },
	{ label: "ShipToAddress", field: "address1", key: "address1" },
	{ label: "ShipToAddress2", field: "address2", key: "address2" },
	{ label: "ShipToPostalCode", field: "postcode", key: "postcode" },
	{ label: "ShipToCity", field: "city", key: "city" },
	{ label: "ShipToState", field: "state", key: "state" },
	{ label: "ShipToCountry", field: "country", key: "country" },
	{ label: "ShipToMobileNum", field: "number", key: "number" },
	{ label: "OrderDate", field: "orderDate", key: "orderDate" },
	{ label: "OrderStatus", field: "status", key: "status" }
];

export const pointhistoryColumns = [
	{ label: "Name", field: "name", key: "name" },
	{ label: "Point", field: "points", key: "points" },
	{ label: "Tier", field: "tier", key: "tier" },
	{ label: "Button", field: "button", key: "" }
];

export const pointuserColumns = [
	{ label: "Name", field: "name" },
	{ label: "Date", field: "date" },
	{ label: "Point In/Out", field: "points" },
	{ label: "Point Description", field: "source" }
];

export const admininboxColumns = [
	{ label: "Title", field: "title" },
	{ label: "Date Created", field: "date" },
	{ label: "Information", field: "information" },
	{ label: "Image", field: "image" }
];

export const questColumns = [
	{ label: "Title", field: "title" },
	{ label: "Date Created", field: "date" },
	{ label: "Information", field: "information" },
	{ label: "Image", field: "image" }
];

export const freesampleColumns = [
	{ label: "OrderNumber", field: "id", key: "id" },
	{ label: "GivenName", field: "name", key: "name" },
	{ label: "Email", field: "email", key: "email" },
	{ label: "ProductDesc", field: "itemName", key: "itemName" },
	{ label: "Quantity", field: "quantity", key: "quantity" },
	{ label: "ShipToName", field: "shipName", key: "shipName" },
	{ label: "ShipToAddress", field: "address1", key: "address1" },
	{ label: "ShipToAddress2", field: "address2", key: "address2" },
	{ label: "ShipToPostalCode", field: "postcode", key: "postcode" },
	{ label: "ShipToCity", field: "city", key: "city" },
	{ label: "ShipToState", field: "state", key: "state" },
	{ label: "ShipToCountry", field: "country", key: "country" },
	{ label: "ShipToTelephone", field: "number", key: "number" },
	{ label: "CreatedOn", field: "createdAt", key: "createdAt" },
	{ label: "Order Status", field: "status", key: "status" }
];

export const scanColumns = [
	{ label: "GivenName", field: "name", key: "name" },
	{ label: "Email", field: "email", key: "email" },
	{ label: "Language", field: "language", key: "language" },
	{ label: "Race", field: "race", key: "race" },
	{ label: "UniqueCode", field: "code", key: "code" },
	{ label: "CodeGroup", field: "codeGroup", key: "codeGroup" },
	{ label: "PointValue", field: "point", key: "point" },
	{ label: "CodeRedemptionDate", field: "redeemDate", key: "redeemDate" },
	{ label: "UserRegistrationDate", field: "registerDate", key: "registerDate" },
	// { label: "Address1", field: "line1" },
	// { label: "Address2", field: "line2" },
	// { label: "Address3", field: "line3" },
	// { label: "City", field: "city" },
	// { label: "PostalCode", field: "postcode" },
	// { label: "State", field: "state" },
	{ label: "CellPhone", field: "number", key: "number" },
	{ label: "Current Stage", field: "pregnancyStage", key: "pregnancyStage" }
];
export const donateDashboardColumns = [
	{ label: "Item ID", field: "itemId" },
	{ label: "Item Name", field: "itemName" },
	{ label: "Donate Points", field: "points" },
	{ label: "Image", field: "itemImage" },
	{ label: "Created At", field: "createdAt" },
	{ label: "Updated At", field: "updatedAt" },
	{ label: "Edit", field: "edit" }
];

export const promoterDashboardColumns = [
	{ label: "Promoter Name", field: "name", key: "name" },
	{ label: "Promoter Code", field: "code", key: "code" },
	{ label: "Region", field: "region", key: "region" },
	{ label: "Supervisor Name", field: "supervisorName", key: "supervisorName" },
	{ label: "Post", field: "post", key: "post" },
	{ label: "Phone Number", field: "number", key: "number" },
	{ label: "UTM Link", field: "utmLink", key: "utmLink" },
	{ label: "No of Referred", field: "referred", key: "referred" },
	{ label: "No of Verified Refer", field: "verifiedRefer", key: "verifiedRefer" },
	{ label: "No of Unverified Refer", field: "unverifiedRefer", key: "unverifiedRefer" },
	{ label: "No of users who request free sample", field: "sampleUsers", key: "sampleUsers" },
	{ label: "No of users who registered at least 1 UC", field: "ucUsers", key: "ucUsers" },
	{ label: "Edit", field: "edit" }
];

export const massCreditDashboardColumns = [
	{ label: "Date", field: "createdAt", key: "createdAt" },
	{ label: "Type", field: "type", key: "type" },
	{ label: "View Details", field: "button" }
];

export const activityColumns = [
	{ label: "Name", field: "name", key: "name" },
	{ label: "Phone number", field: "number", key: "number" },
	{ label: "Email", field: "email", key: "email" },
	{ label: "Register Date", field: "registerDate", key: "registerDate" },
	{ label: "Submission date", field: "submissionDate", key: "submissionDate" },
	{ label: "Image", field: "screenshot", key: "screenshot" },
	{ label: "Receipt", field: "receipt", key: "receipt" },
	{ label: "Link", field: "link", key: "link" },
	{ label: "T-shirt size", field: "size", key: "size" },
	{ label: "Status", field: "status", key: "status" }
];

export const contestDashboardColumn = [
	{ label: "image", field: "image" },
	{ label: "image BM", field: "imageBM" },
	{ label: "Name", field: "name" },
	{ label: "Name BM", field: "nameBM" },
	{ label: "Start Date", field: "startDate" },
	{ label: "End Date", field: "endDate" },
	{ label: "point Required", field: "pointRequired"},
	{ label: "description", field: "description"},
	{ label: "description BM", field: "descriptionBM"},
	{ label: "steps", field: "steps"},
	{ label: "steps BM", field: "stepsBM"},
	{ label: "prizes", field: "prizes"},
	{ label: "Show?", field: "active" },
	{ label: "Edit", field: "edit" },
]