// import React, { useEffect, useState } from 'react';
// import {Link} from 'react-router-dom';
import { connect } from "react-redux";
// Components
import Header from "../../component/dashboard/Header";
// import Accordion from "../../component/dashboard/Accordion";
import Footer from "../../component/dashboard/Footer";
// Assets
// import scan from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/qr-code-scan.png";
// import friend from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/laugh.png";
// import flag from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/finish.png";
// import { BsTelephoneFill, BsFacebook } from "react-icons/bs";
// import { IoIosMail } from "react-icons/io";
// import { IoLocationSharp } from "react-icons/io5";
// import { AiFillTwitterCircle } from "react-icons/ai";
// import { FaWhatsappSquare } from "react-icons/fa";
import ContentHeader from "../../component/dashboard/ContentHeader";
import { useTranslation } from "react-i18next";

function HelpInfo2({ isAuthenticated }) {
	const { t } = useTranslation();
	return (
		<div>
			<Header />
			<div className="content" style={{ paddingBottom: "150px" }}>
				<ContentHeader />
				<div className="text-center">
					<h1 className="dbc-title">{t("whatrewards")}</h1>
				</div>

				<div style={{ maxWidth: "1200px", margin: "25px auto", padding: "0 5vw" }}>
					<p>{t("helpinfo1")}</p>
					{/* <h1 className="addToCartTitle">Ways to Collect More Points</h1> */}
					{/* <div className="tierBenefitsAccordionBox">
						<Accordion title={t("whatrewards")} icon={scan}>
							<p>{t("helpinfo1")}</p>
						</Accordion>
					</div> */}
				</div>
			</div>
			{isAuthenticated ? (
				<Footer />
			) : (
				<div className="desktop-only">
					<Footer />
				</div>
			)}
		</div>
	);
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.userReducer.isAuthenticated
	};
};

export default connect(mapStateToProps)(HelpInfo2);
