import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { adminLogin, checkAdminSession } from "../../../redux/actions/adminActions";
import ErrorAlert from "../../../component/ErrorAlert";
import Loader from "../../../component/Loader";

const AdminLogin = ({ adminLogin, checkAdminSession }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => checkAdminSession(), []);

	const handleSubmit = e => {
		e.preventDefault();
		if (username && password) {
			adminLogin({ username, password });
		}
	};

	return (
		<div className="admin-auth-container">
			<div className="admin-auth-box">
				<div className="admin-auth-logo">
					{/* img */}
					<h1>Dryper Baby Club</h1>
				</div>

				<div className="admin-auth">
					<h4>Admin Login</h4>
					<p>
						<small>Please enter your username and password to login.</small>
					</p>

					<ErrorAlert />

					<form onSubmit={handleSubmit} className="mt-5">
						<div className="form-group mt-3">
							<input type="text" className="form-control" name="username" id="username" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} required />
						</div>

						<div className="form-group mt-3">
							<input type="password" className="form-control" name="password" id="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} required />
						</div>

						<div className="form-group mt-3">
							<Loader component={<input type="submit" value="Login" className="form-control btn btn-primary" />} />
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		adminLogin: data => dispatch(adminLogin(data)),
		checkAdminSession: () => dispatch(checkAdminSession())
	};
};

export default connect(null, mapDispatchToProps)(AdminLogin);
