import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { editPromoter } from "../../../../redux/actions/adminActions";

const EditPromoter = ({ editPromoter, history, location }) => {
	const [data, setData] = useState({
		name: location.data.name,
		code: location.data.code,
		region: location.data.region,
		supervisorName: location.data.region,
		post: location.data.post,
		number: location.data.number,
		utmLink: location.data.utmLink
	});

	const handleChange = e => setData({ ...data, [e.target.name]: e.target.value });

	const handleSubmit = e => {
		e.preventDefault();
		if (data.name && data.code) {
			const id = location.data.id;
			const sendThis = {
				id,
				name: data.name,
				code: data.code,
				region: data.region,
				supervisorName: data.supervisorName,
				post: data.post,
				number: data.number,
				utmLink: data.utmLink
			};
			editPromoter(sendThis, err => {
				if (err) alert(err);
				else history.push("/admin/promoter/dashboard");
			});
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />
			<div className="admin-body">
				<AdminTopbar />
				<div className="admin-content">
					<h3>Edit Promoter</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="name">Promoter's Name</label>
								<input type="text" id="name" name="name" className="form-control" value={data.name} onChange={handleChange} required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="code">Promoter's Code</label>
								<input type="text" id="code" name="code" className="form-control" value={data.code} onChange={handleChange} required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="region">Region</label>
								<input
									type="text"
									id="region"
									name="region"
									className="form-control"
									value={data.region}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="supervisorName">Supervisor Name</label>
								<input
									type="text"
									id="supervisorName"
									name="supervisorName"
									className="form-control"
									value={data.supervisorName}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="post">Post</label>
								<input type="text" id="post" name="post" className="form-control" value={data.post} onChange={handleChange} required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="number">Phone Number</label>
								<input
									type="text"
									id="number"
									name="number"
									className="form-control"
									value={data.number}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="utmLink">UTM Link</label>
								<input
									type="text"
									id="utmLink"
									name="utmLink"
									className="form-control"
									value={data.utmLink}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3 px-5 text-center">
								<Loader component={<input type="submit" value="SUBMIT" className="form-control btn btn-primary" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		editPromoter: (data, callback) => dispatch(editPromoter(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(EditPromoter);
