import { useState } from "react";
import { Link } from "react-router-dom";

import { TiSocialFacebook } from "react-icons/ti";
import { RiInstagramLine } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { GoChevronDown } from "react-icons/go";
import { BsYoutube } from "react-icons/bs";
import { useTranslation } from "react-i18next";
//Redux
import { connect } from "react-redux";
import { logout, selectLanguage } from "../../redux/actions/userActions";

function Header({ logout, isAuthenticated, history, selectLanguage, currentLanguage, match, location }) {
	const { t } = useTranslation();
	const [isOpen, setOpen] = useState(false);
	const [query, setQuery] = useState("");
	const [showInput, setShowInput] = useState(false);

	const handleToggle = () => setOpen(!isOpen);
	const toggleClassName = name => (isOpen ? (name ? name + " openSidebar" : "openSidebar") : name);

	const [showDropdown, setShowDropdown] = useState(false);
	const [showDropdown2, setShowDropdown2] = useState(false);
	const [showDropdown3, setShowDropdown3] = useState(false);

	const handleToggle2 = () => {
	  setShowDropdown(!showDropdown);
	};

	const handleToggle3 = () => {
		setShowDropdown2(!showDropdown2);
	  };

	  const handleToggle4 = () => {
		setShowDropdown3(!showDropdown3);
	  };

	const handleDropdownItemClick = (item) => {
		// Handle item selection here
		console.log(`Selected item: ${item}`);
		setShowDropdown(false);
	  };
	

	const goToLinks = val => {
		if (val === "story") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/our-story/`);
		else if (val === "product") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/products/`);
		else if (val === "sustain") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/sustainability/`);
		else if (val === "dbc") return (window.location.href = `https://www.drypers.com.my/drypers-baby-club/${currentLanguage}`);
		else if (val === "latest") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/latest-happenings/`);
		else if (val === "tips") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/parenting-tips/`);
		else if (val === "faq") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/contact-us/`);
		else if (val === "shop") return (window.location.href = `https://www.drypers.com.my/${currentLanguage}`);
		// else if (val === "wishing") return (window.location.href = `/drypers-baby-club/wishingtree`);
		// else if (val === "wishingdonate") return (window.location.href = `/drypers-baby-club/wishingtree/donate`);
		else console.log("no link");
	};

	const menuLink = val => {
		if (val === "wwd") return (window.location.href = `https://drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/drypers-weewee-dry/`);
		else if (val === "drypantz") return (window.location.href = `https://drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/drypers-drypantz/`);
		else if (val === "skinature") return (window.location.href = `https://drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/skinature-by-drypers/`);
		else if (val === "touch") return (window.location.href = `https://drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/drypers-touch/`);
		else if (val === "classic") return (window.location.href = `https://drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/drypers-classic/`);
		else if (val === "new") return (window.location.href = `https://drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/activkidz-by-drypers/`);
		else if (val === "babycare") return (window.location.href = `https://drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/drypers-babycare/`);
		else console.log("no link");
	};

	const handleLanguage = language => {
		if (language !== currentLanguage) {
			selectLanguage(language);
			!isAuthenticated && history.push({ pathname: match.url.split(currentLanguage).join(language) + location.search, success: true });
		}
	};

	const searchQuery = e => {
		e.preventDefault();
		if (query) window.location.href = `https://www.drypers.com.my/?post_type=page&s=${query}`;
	};

	return (
		<div id="header-container">
			<div className="header">
				<div className="inner-header">
					{isAuthenticated ? (
						<Link to="/dashboard">
							<img
								style={{ cursor: "pointer" }}
								src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/club-logo-white.png"
								className="headerLogo"
								alt="dryper-logo"
							></img>
						</Link>
					) : (
						<img
							style={{ cursor: "pointer" }}
							src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/club-logo-white-2.png"
							className="headerLogo"
							alt="dryper-logo"
							onClick={() =>
								currentLanguage === "bm"
									? (window.location.href = "https://drypers.com.my/bm/")
									: (window.location.href = "https://drypers.com.my/")
							}
						></img>
					)}

					<div className="desktop-menu">
						<div className="header-settings">
							<div className="header-submenu language-container">
								<div className="splitter-menu">
									<div className="header-setting-item">
										<p onClick={() => handleLanguage("en")}>EN</p>
									</div>

									<div className="header-separator"></div>

									<div className="header-setting-item">
										<p onClick={() => handleLanguage("bm")}>BM</p>
									</div>
								</div>
							</div>
							<div className="header-submenu search-bar-container">
								<form onSubmit={searchQuery}>
									<input
										type="search"
										id="query"
										name="query"
										className={`search-product-input ${showInput ? "showInput" : ""}`}
										placeholder={t("search")}
										value={query}
										onChange={e => setQuery(e.target.value)}
									/>
									<FaSearch
										className="m-0 p-0 search-product-icon"
										onClick={() => (window.innerWidth < 768 ? setShowInput(!showInput) : console.log("is not mobile"))}
									/>
									<input type="submit" style={{ display: "none" }} value="" hidden />
								</form>
							</div>
							<div className="header-submenu auth-container">
								{isAuthenticated ? (
									<p style={{ cursor: "pointer" }} onClick={() => logout()}>
										{t("logout")}
									</p>
								) : (
									<div className="splitter-menu">
										<Link
											className="header-setting-item"
											to={`/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/signin`}
											style={{ textDecoration: "none", color: "#223C96" }}
										>
											<p>{t("login")}</p>
										</Link>

										<div className="header-separator"></div>

										<Link className="header-setting-item" to={`/${currentLanguage}`} style={{ textDecoration: "none", color: "#223C96" }}>
											<p>{t("signup")}</p>
										</Link>
									</div>
								)}
							</div>
							<div className="header-submenu button-container">
								<div className="sidebarIconToggle" onClick={handleToggle}>
									<div className={toggleClassName("spinner diagonal part-1")}></div>
									<div className={toggleClassName("spinner horizontal")}></div>
									<div className={toggleClassName("spinner diagonal part-2")}></div>
								</div>

								{isAuthenticated ? (
									<Link className="free-samples desktop-only" style={{ textDecoration: "none" }} to="/picksample">
										<p>{t("freesamples")}</p>
									</Link>
								) : (
									<a
										className="free-samples desktop-only"
										style={{ textDecoration: "none" }}
										href={`/drypers-baby-club/${currentLanguage}#FreeSamples`}
									>
										<p>{t("freesamples")}</p>
									</a>
								)}

								{/* <Link
									className="free-samples desktop-only"
									to={isAuthenticated ? "/picksample" : `/${currentLanguage}#FreeSamples`}
									style={{ textDecoration: "none" }}
								>
									<p>{t("freesamples")}</p>
								</Link> */}
							</div>
						</div>

						<div className="header-menu">
							<div className="header-menu-item product-container">
								<p>
									{t("ourstorycap")}&nbsp; <GoChevronDown style={{ fontSize: "20px", fontWeight: "bold" }} />
								</p>
								<div className="hover-underline"></div>

								<ul className="vinda-product-list">
									<li className="vinda-product-item" onClick={() => goToLinks("story")}>
									{t("ourstory")}
										<div className="product-divider"></div>
									</li>
									<li className="vinda-product-item" onClick={() => goToLinks("latest")}>
									{t("latesthappen")}
										<div className="product-divider"></div>
									</li>
									<li className="vinda-product-item" onClick={() => goToLinks("sustain")}>
									{t("sustainable")}
										<div className="product-divider"></div>
									</li>
								</ul>
							</div>
							<div className="header-menu-item product-container">
								<p onClick={() => goToLinks("product")}>
									{t("productscap")} &nbsp; <GoChevronDown style={{ fontSize: "20px", fontWeight: "bold" }} />
								</p>
								<div className="hover-underline"></div>

								<ul className="vinda-product-list">
									<li className="vinda-product-item" onClick={() => menuLink("wwd")}>
										Drypers Wee Wee Dry
										<div className="product-divider"></div>
									</li>
									<li className="vinda-product-item" onClick={() => menuLink("drypantz")}>
										Drypers DryPantz
										<div className="product-divider"></div>
									</li>
									<li className="vinda-product-item" onClick={() => menuLink("touch")}>
										Drypers Touch
										<div className="product-divider"></div>
									</li>
									<li className="vinda-product-item" onClick={() => menuLink("skinature")}>
										Skinature by Drypers
										<div className="product-divider"></div>
									</li>
									<li className="vinda-product-item" onClick={() => menuLink("classic")}>
										Drypers Classic
										<div className="product-divider"></div>
									</li>
									<li className="vinda-product-item" onClick={() => menuLink("babycare")}>
										Drypers BabyCare
										<div className="product-divider"></div>
									</li>
									<li className="vinda-product-item" onClick={() => menuLink("new")}>
										{t("activKidsNav")}
									</li>
								</ul>
							</div>
							{/* <div className="header-menu-item" onClick={() => goToLinks("sustain")}>
								<p>{t("sustaincap")}</p>
								<div className="hover-underline"></div>
							</div> */}
							<div className="header-menu-item" onClick={() => goToLinks("dbc")}>
								<p>{t("dbccap")}</p>
								<div className="hover-underline"></div>
							</div>
							<div className="header-menu-item product-container">
							<Link to="/wishingtree" style={{ textDecoration: "none", color: "#223C96"}}>
								<p>
									SHARE A LITTLE COMFORT &nbsp; <GoChevronDown style={{ fontSize: "20px", fontWeight: "bold" }} />
								</p>
							</Link>
								<div className="hover-underline"></div>

								<ul className="vinda-product-list">
								<Link to="/wishingtree/donate" style={{ textDecoration: "none", color: "#223C96"}}>
									<li className="vinda-product-item">
										Wishing Tree
										<div className="product-divider"></div>
									</li>
								</Link>
								</ul>
							</div>
							{/* <div className="header-menu-item" onClick={() => goToLinks("latest")}>
								<p>{t("latesthapcap")}</p>
								<div className="hover-underline"></div>
							</div> */}
							<div className="header-menu-item" onClick={() => goToLinks("tips")}>
								<p>{t("parentingcap")}</p>
								<div className="hover-underline"></div>
							</div>
							<div className="header-menu-item" onClick={() => goToLinks("faq")}>
								<p>{t("FAQcap")}</p>
								<div className="hover-underline"></div>
							</div>
							<div className="header-menu-item" onClick={() => goToLinks("shop")}>
								<p>{t("shopcap")}</p>
								<div className="hover-underline"></div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<img
				src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/header_divider.png"
				alt="divider"
				className="img-fluid"
				id="header-divider"
			/>

			<div id="sidebarOverlay" className={toggleClassName()} onClick={handleToggle}></div>
			<div id="sidebarMenu" className={toggleClassName()}>
				<img className="sidebarMenuLogo" src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/club-logo-white.png" alt="dryper-logo"></img>
				<ul id="sidebarMenuList">
					<li className="sidebarMenuItem" onClick={handleToggle2}>
						<div className="sidebarMenuItemHeader">
						<span style={{ color: "#223C96" }}>{t("ourstorycap")} &nbsp; <GoChevronDown style={{ fontSize: "20px", fontWeight: "bold" }}/></span>
						<div
							className={`sidebarDropdownArrow ${
							showDropdown ? "rotate-180" : ""
							}`}
						>
							<i className="fas fa-chevron-down"></i>
						</div>
						</div>
						{showDropdown && (
						<div className="sidebarDropdownMenu">
							<div className="sidebar-divider"></div>
							<div
							className="sidebarDropdownItem"
							onClick={() => goToLinks("story")}
							style={{color: "#3838c4", fontSize: "13px"}}
							>
							{t("ourstory")}
							</div>
							<div className="sidebar-divider"></div>
							<div
							className="sidebarDropdownItem"
							onClick={() => goToLinks("latest")}
							style={{color: "#3838c4", fontSize: "13px"}}
							>
							{t("latesthappen")}
							</div>
							<div className="sidebar-divider"></div>
							<div
							className="sidebarDropdownItem"
							onClick={() => goToLinks("sustain")}
							style={{color: "#3838c4", fontSize: "13px"}}
							>
							{t("sustainable")}
							</div>
							<div className="sidebar-divider"></div>
						</div>
						)}
         			 </li>
					  <li className="sidebarMenuItem" onClick={handleToggle3}>
						<div className="sidebarMenuItemHeader">
						<span style={{ color: "#223C96" }}>{t("productscap")} &nbsp; <GoChevronDown style={{ fontSize: "20px", fontWeight: "bold" }}/></span>
						<div
							className={`sidebarDropdownArrow ${
							showDropdown2 ? "rotate-180" : ""
							}`}
						>
							<i className="fas fa-chevron-down"></i>
						</div>
						</div>
						{showDropdown2 && (
						<div className="sidebarDropdownMenu">
							<div className="sidebar-divider"></div>
							<div
							className="sidebarDropdownItem"
							onClick={() => menuLink("wwd")}
							style={{color: "#3838c4", fontSize: "13px"}}
							>
							Drypers Wee Wee Dry
							</div>
							<div className="sidebar-divider"></div>
							<div
							className="sidebarDropdownItem"
							onClick={() => goToLinks("drypantz")}
							style={{color: "#3838c4", fontSize: "13px"}}
							>
							Drypers DryPantz
							</div>
							<div className="sidebar-divider"></div>
							<div
							className="sidebarDropdownItem"
							onClick={() => goToLinks("touch")}
							style={{color: "#3838c4", fontSize: "13px"}}
							>
							Drypers Touch
							</div>
							<div className="sidebar-divider"></div>
							<div
							className="sidebarDropdownItem"
							onClick={() => goToLinks("skinature")}
							style={{color: "#3838c4", fontSize: "13px"}}
							>
							Skinature by Drypers
							</div>
							<div className="sidebar-divider"></div>
							<div
							className="sidebarDropdownItem"
							onClick={() => goToLinks("babycare")}
							style={{color: "#3838c4", fontSize: "13px"}}
							>
							Drypers Classic
							</div>
							<div className="sidebar-divider"></div>
							<div
							className="sidebarDropdownItem"
							onClick={() => goToLinks("classic")}
							style={{color: "#3838c4", fontSize: "13px"}}
							>
							Drypers BabyCare
							</div>
							<div className="sidebar-divider"></div>
							<div
							className="sidebarDropdownItem"
							onClick={() => goToLinks("new")}
							style={{color: "#3838c4", fontSize: "13px"}}
							>
							{t("activKidsNav")}
							</div>
							<div className="sidebar-divider"></div>
						</div>
						)}
         			 </li>
					{/* <li className="sidebarMenuItem" >
						<div className="sidebarMenuItemHeader">
						<span onClick={() => goToLinks("wishing")} style={{ color: "#223C96", fontSize: "12px" }}>SHARE A LITTLE COMFORT &nbsp; <GoChevronDown style={{ fontSize: "20px", fontWeight: "bold" }}/></span>
						</div><div className="sidebar-divider"></div>
         			 </li>
					  <li className="sidebarMenuItem">
					    <div
							className="sidebarDropdownItem"
							onClick={() => goToLinks("wishingdonate")}
							style={{color: "#3838c4", fontSize: "13px", marginTop: "-15px"}}
							>
							Wishing Tree
						</div>
					</li> */} 
					<li className="sidebarMenuItem"  >
						<div className="sidebarMenuItemHeader">
						<Link style={{ textDecoration: "none", color: "#223C96" }} to="/wishingtree">
						<a>SHARE A LITTLE COMFORT &nbsp;</a> 
						</Link>
						<GoChevronDown onClick={handleToggle4} style={{ fontSize: "20px", fontWeight: "bold" }}/>
						<div
							className={`sidebarDropdownArrow ${
							showDropdown3 ? "rotate-180" : ""
							}`}
						>
							<i className="fas fa-chevron-down"></i>
						</div>
						</div>
						{showDropdown3 && (
						<div className="sidebarDropdownMenu">
							<div className="sidebar-divider"></div>
							<Link style={{textDecoration: "none"}} to="/wishingtree/donate">
							<div
							className="sidebarDropdownItem"
							style={{color: "blue", fontSize: "13px"}}
							>
							Wishing Tree
							</div>
							</Link>
						</div>
						)}
         			 </li>
					<li className="sidebarMenuItem" onClick={() => goToLinks("dbc")}>
						{t("dbccap")}
					</li>
					<li className="sidebarMenuItem" onClick={() => goToLinks("latest")}>
						{t("latesthapcap")}
					</li>
					<li className="sidebarMenuItem" onClick={() => goToLinks("tips")}>
						{t("parentingcap")}
					</li>
					<li className="sidebarMenuItem" onClick={() => goToLinks("shop")}>
						{t("shopcap")}
					</li>
					<li className="sidebarMenuItem">
						<Link to={isAuthenticated ? "/picksample" : `/${currentLanguage}`} style={{ textDecoration: "none", color: "#223C96" }}>
							{t("freesample")}
						</Link>
					</li>
					<li className="sidebarMenuItem mt-5">
						<TiSocialFacebook
							onClick={() => (window.location.href = "https://www.facebook.com/DrypersMY/")}
							className="sidebarSocialLogo"
						></TiSocialFacebook>
						<RiInstagramLine
							onClick={() => (window.location.href = "https://www.instagram.com/drypersmy/?hl=en")}
							className="sidebarSocialLogo"
						></RiInstagramLine>
						<BsYoutube
							onClick={() => (window.location.href = "https://www.youtube.com/user/drypersmalaysia")}
							className="sidebarSocialLogo"
						></BsYoutube>
					</li>
					{/* <li className="sidebarMenuItem">{t("settings")}</li> */}
					<button
						onClick={() => (isAuthenticated ? logout() : history.push(`/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/signin`))}
						type="button"
						className="sidebarMenuItem"
					>
						{isAuthenticated ? t("logoutcap") : t("logincap")}
					</button>
				</ul>
			</div>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.userReducer.isAuthenticated,
		currentLanguage: state.pageReducer.language
	};
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(logout()),
		selectLanguage: language => dispatch(selectLanguage(language))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
