import React, { useState } from 'react';
import { connect } from "react-redux";
import { addContest } from "../../../../redux/actions/adminActions";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";

const AddContest = ({ addContest, history }) => {
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [campaignIcon, setCampaignIcon] = useState(null);
  const [campaignIconUrl, setCampaignIconUrl] = useState('');
  const [pointRequired, setPointRequired] = useState(false);
  const [pointsPerEntry, setPointsPerEntry] = useState(0);
  const [entriesPerPerson, setEntriesPerPerson] =  useState('');
  const [linkURL, setlinkURL] = useState(false);
  const [uploadImages, setuploadImages] = useState(false);
  const [uploadReceipts, setuploadReceipts] = useState(false);
  const [steps, setSteps] = useState([]);
  const [files, setFiles] = useState([]);
  const [amounts, setAmounts] = useState([]); // Add this line
  const [imgPreviews, setImgPreviews] = useState([]);
  const [gotDropdown, setGotDropdown] = useState(false);
  const [dropdownOption, setDropdownOption] = useState([]);
  const [dropdownTitle, setDropdownTitle] = useState('');
  const [description, setDescription] = useState('');

  //BM
  const [nameBM, setNameBM] = useState('');
  const [campaignIconBM, setCampaignIconBM] = useState(null);
  const [campaignIconUrlBM, setCampaignIconUrlBM] = useState('');
  const [stepsBM, setStepsBM] = useState([]);
  const [dropdownTitleBM, setDropdownTitleBM] = useState('');
  const [descriptionBM, setDescriptionBM] = useState('');


  const handleCampaignIconChange = (event) => {
    const file = event.target.files[0];
    setCampaignIcon(file);
    setCampaignIconUrl(URL.createObjectURL(file));
  };

  const handleCampaignIconBMChange = (event) => {
    const file = event.target.files[0];
    setCampaignIconBM(file);
    setCampaignIconUrlBM(URL.createObjectURL(file));
  };

  const handleAddStep = () => {
    setSteps([...steps, { instruction: '', description: '', order: steps.length + 1 }]);
  };

  const handleAddStepBM = () => {
    setStepsBM([...stepsBM, { instructionBM: '', descriptionBM: '', orderBM: stepsBM.length + 1 }]);
  };

  const handleAddDropdown = () => {
    setDropdownOption([...dropdownOption, '']);
  };
  
  const handleStepChange = (index, field, value) => {
    const updatedSteps = [...steps];
    updatedSteps[index][field] = value;
    setSteps(updatedSteps);
  };
    
  const handleStepChangeBM = (index, field, value) => {
    const updatedStepsBM = [...stepsBM];
    updatedStepsBM[index][field] = value;
    setStepsBM(updatedStepsBM);
  };

  const handleDropdownChange = (index, value) => {
    const updatedDropdown = [...dropdownOption];
    updatedDropdown[index] = value;
    setDropdownOption(updatedDropdown);
  };

  const handleImages = (e) => {
		const fileList = e.target.files;
		const previewArr = [];

		for (let i = 0; i < fileList.length; i++) {
			previewArr.push(URL.createObjectURL(fileList[i]));
		}

		setFiles([...files, ...fileList]);

		// setImgPreviews(previewArr);

    // if (imgPreviews.length > 1) {
		setImgPreviews([...imgPreviews, previewArr]);
    // }

		// setImgPreviews([...imgPreviews, ...previewArr]);

    // Retrieve the amounts from the amount inputs
    const newAmounts = [];
    const amountInputs = document.getElementsByClassName("amount-input");
  
    for (let i = 0; i < amountInputs.length; i++) {
      const amount = amountInputs[i].value;
      newAmounts.push(amount);
    }
  
    setAmounts([...amounts, ...newAmounts]);
  };

  const editImage = (e, index) => {
		const fileList = e.target.files;
    const newImg = [...imgPreviews];

    if (fileList && fileList.length) {
		newImg[index] = URL.createObjectURL(fileList[0]);
    setImgPreviews([...newImg]);
    }
  }
  

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!name || !startDate || !endDate || !campaignIcon || new Date(startDate) > new Date(endDate) || !nameBM || !campaignIconBM) {
      alert("Please provide a valid name, start date, end date, and campaign icon.");
      return;
    }

    if (new Date(startDate) > new Date(endDate)) {
      alert("Start date cannot be later than end date.")
    }
  
    if (files.length < 1) {
      alert("Please provide at least 2 prize");
      return;
    }

    if (steps.length < 0) {
      alert("Please provide at least 1 step");
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
      formData.append('campaignIcon', campaignIcon);
      formData.append('pointRequired', pointRequired);
      formData.append('pointsPerEntry', pointsPerEntry);
      formData.append('entriesPerPerson', entriesPerPerson);
      formData.append('linkURL', linkURL);
      formData.append('uploadImages', uploadImages);
      formData.append('uploadReceipts', uploadReceipts);
      formData.append('steps', JSON.stringify(steps));
      formData.append('gotDropdown', gotDropdown);
      formData.append('dropdownOption', JSON.stringify(dropdownOption));
      formData.append('description', description);
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
        formData.append("amounts", amounts[i]); 
        }

      formData.append('nameBM', nameBM);
      formData.append('descriptionBM', descriptionBM);
      formData.append('dropdownTitle', dropdownTitle);
      formData.append('dropdownTitleBM', dropdownTitleBM);
      formData.append('campaignIconBM', campaignIconBM);
      formData.append('stepsBM', JSON.stringify(stepsBM));
  
      addContest(formData, err => {
        if (err) {
          alert(err);
        } else {
          history.push("/admin/contest/dashboard");
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="admin-page">
    <AdminSidebar />

    <div className="admin-body">
      <AdminTopbar />

      <div className="admin-content">
          <form onSubmit={handleSubmit}>
            <div>
              <label className='contest-label' htmlFor="name">Campaign Name:</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input" style={{width: '50%'}}  type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder='Campaign Name' required />
            </div>
            <div>
              <label className='contest-label' htmlFor="description">Campaign Description:</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input" style={{width: '50%'}}  type="text" id="description" value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Campaign Description' required />
            </div>
          <div className='mt-3' style={{display: "flex"}}>
            <div>
              <label className='contest-label m-0' htmlFor="startDate">Start Date:</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input" type="date" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
            </div>
            <div style={{marginLeft: '20px'}}>
              <label className='contest-label m-0' htmlFor="endDate">End Date:</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input"  type="date" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} required />
            </div>
          </div>


              {/* <div className='mt-3'>
              <label htmlFor="campaignIcon">Activity Icon/Logo:</label><br />
              <input type="file" id="campaignIcon" accept="image/*" onChange={handleCampaignIconChange} required />
            </div>
            <div className='mt-3'>
              {campaignIconUrl && (
                <img src={campaignIconUrl} alt="Campaign Icon" style={{ width: '200px' }} />
              )}
            </div> */}

            <div className="form-group mt-3">
            <label className='contest-label m-0' htmlFor="campaignIcon">Contest Key Visual</label><br></br>
								<div className="form-control"
									style={{
										border: "3px solid #67acde",
										borderRadius: "16px",
                    width: "50%"
									}}>
									<div style={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between' }}>
                  {campaignIconUrl && (
                <img src={campaignIconUrl} alt="Campaign Icon" style={{ width: '200px' }} />
                  )}
										<div style={{border: "1px solid black",}} className="image-upload-box" onClick={() => document.getElementById('campaignIcon').click()}>
											+
										</div>
									</div>
									<input type="file" name="campaignIcon" id="campaignIcon" className="form-control" accept="image/*" onChange={handleCampaignIconChange}
										required style={{
											border: "2.6402px solid black",
											borderRadius: "15.8412px",
											display: "none",
										}} />
								</div>
							</div>



            <div className="form-group mt-3">
            <label className='contest-label m-0'>List of Prizes</label><br></br>
                <div>
                  <div>
                    {imgPreviews.map((preview, index) => (
                      <div style={{border: '3px solid #67acde', width: '50%', borderRadius: '16px'}} className='d-flex'>
                      <div className="image-upload-box" style={{position: 'relative'}} key={index + 1}>
                        {preview ? <>  
                        <input
                    type="file"
                    name="files"
                    id="edit"
                    className="form-control"
                    accept="image/*"
                    onChange={(e) => editImage(e, index)}
                    style={{
                      width: '135px',
                      height: '100px',
                      position: "absolute",
                      opacity: "0"
                    }}
                  /> 
                  <img src={preview} alt="preview" /> 
                  </>
                    :   <div
                      style={{ border: "1px solid black" }}
                      className="image-upload-box"
                      onClick={() => document.getElementById("files").click()}
                    >
                      +
                    </div>}
                        
                        {/* Add the amount input */}
                      </div>
                       <input
                       style={{border: 'none', width: "75%"}}
                       type="text"
                       placeholder="Type of Prizes (Amount)"
                       onChange={(e) => {
                         const newAmounts = [...amounts];
                         newAmounts[index] = e.target.value;
                         setAmounts(newAmounts);
                       }}
                     />
                     </div>
                     ))}
                  <p className='contest-label' onClick={() => document.getElementById("files").click()} style={{textAlign: 'center', width: '50%', color: '#fdac39'}}>+ {imgPreviews.length ? 'Add More Prizes' : 'Add Prize'}</p>
                  </div>
                  <input
                    type="file"
                    name="files"
                    id="files"
                    className="form-control"
                    accept="image/*"
                    multiple
                    onChange={handleImages}
                    required
                    style={{
                      border: "2.6402px solid black",
                      borderRadius: "15.8412px",
                      display: "none"
                    }}
                  />
                </div>
              </div>

              <div>
              <label className='mt-3 contest-label' htmlFor="steps">Steps To Participate</label><br></br>
              {steps.map((step, index) => (
                <div key={index}>
                  {/* <input
                    type="number"
                    value={step.order}
                    onChange={(e) => handleStepChange(index, 'order', parseInt(e.target.value))}
                    placeholder="Order"
                  /> */}
                  <h6 style={{color: '#647dcc', fontWeight: '600'}}>Step {step.order}</h6>
                  <input                  
                  className="form-control vinda-form-input steps-instruction"
                    type="text"
                    value={step.instruction}
                    onChange={(e) => handleStepChange(index, 'instruction', e.target.value)}
                    placeholder="Instruction"
                  />
                  <input
                  className="form-control vinda-form-input steps-description"
                    type="text"
                    value={step.description}
                    onChange={(e) => handleStepChange(index, 'description', e.target.value)}
                    placeholder="Description"
                  />
                </div>
              ))}
                <p className='contest-label' onClick={handleAddStep} style={{textAlign: 'center', width: '50%', color: '#fdac39'}}>+ {steps.length ? 'Add More Steps' : 'Add Step'}</p>
            </div>

            <div className='mt-3'>
              <label htmlFor="pointRequired" className='contest-label'>Point Needed</label><br></br>
              <label class="container">{pointRequired ? 'Yes' : 'No'}
                <input type="checkbox" checked={pointRequired} onChange={(e) => setPointRequired(e.target.checked)}/>
                <span class="checkmark"></span>
              </label>
              {/* <input  type="checkbox" id="pointRequired" checked={pointRequired} onChange={(e) => setPointRequired(e.target.checked)} /> */}
            </div>
            {pointRequired && (
            <div className='mt-3'>
              <label htmlFor="pointsPerEntry" className='contest-label'>Amount of Point:</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input" style={{width: '50%'}} type="number" id="pointsPerEntry" value={pointsPerEntry} onChange={(e) => setPointsPerEntry(e.target.value)} />
            </div>
            )}
            <div className='mt-3'>
              <label htmlFor="entriesPerPerson" className='contest-label'>Maximum number of entry</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input" style={{width: '50%'}} type="number" id="entriesPerPerson"  value={entriesPerPerson} onChange={(e) => setEntriesPerPerson(e.target.value)} />
            </div>
            <p className='contest-label mt-3'>Select the campaign mechanic</p>
            <div style={{padding: '5px 20px', marginTop: '10px', background: "#ececec"}}>            
            <div className='mt-4'>
                <label class="container">Link URL
                 <input type="checkbox" id="linkURL" checked={linkURL} onChange={(e) => setlinkURL(e.target.checked)} />
                <span class="checkmark"></span>
              </label>
            </div>
            <div>
                <label class="container">Upload Images
                  <input type="checkbox" id="uploadImages" checked={uploadImages} onChange={(e) => setuploadImages(e.target.checked)} />
                <span class="checkmark"></span>
              </label>
            </div>
            <div>
                <label class="container">Upload Receipts
                  <input type="checkbox" id="uploadReceipts" checked={uploadReceipts} onChange={(e) => setuploadReceipts(e.target.checked)} />
                <span class="checkmark"></span>
              </label>
            </div>
            <div>
                <label class="container">Dropdown Option
                  <input type="checkbox" id="gotDropdown" checked={gotDropdown} onChange={(e) => setGotDropdown(e.target.checked)} />
                <span class="checkmark"></span>
              </label>
            </div>
            {gotDropdown && (
            <div>
                 <div className='d-flex' style={{marginTop: '10px', marginBottom: '10px'}}>        
              <label style={{whiteSpace: 'nowrap'}} htmlFor="dropdownTitle">Title: </label><br></br>
                   <input style={{marginLeft: '53px', width: '50%'}}
                className="form-control vinda-form-input contest-blue-input"
                  // key={index}
                  type="text"
                  value={dropdownTitle}
                  onChange={(e) => setDropdownTitle(e.target.value)}
                />
                </div>
                 <div className='d-flex' style={{marginTop: '10px', marginBottom: '10px'}}>        
              <label style={{whiteSpace: 'nowrap'}} htmlFor="dropdownTitleBM">Title (BM): </label><br></br>
                   <input style={{marginLeft: '15px', width: '50%'}}
                className="form-control vinda-form-input contest-blue-input"
                  // key={index}
                  type="text"
                  value={dropdownTitleBM}
                  onChange={(e) => setDropdownTitleBM(e.target.value)}
                />
                </div>
              {dropdownOption.map((dropdown, index) => (            
                <div className='d-flex' style={{marginTop: '10px', marginBottom: '10px'}}>        
              <label style={{whiteSpace: 'nowrap'}} htmlFor="dropdownOption">Option {index +1}: </label><br></br>
                   <input style={{marginLeft: '20px', width: '50%'}}
                className="form-control vinda-form-input contest-blue-input"
                  key={index}
                  type="text"
                  value={dropdown}
                  onChange={(e) => handleDropdownChange(index, e.target.value)}
                />
                </div>
              ))}
               <p className='contest-label' onClick={handleAddDropdown} style={{textAlign: 'center', width: '50%', color: '#fdac39'}}>+ {dropdownOption.length ? 'Add More Option' : 'Add Option'}</p>
            </div>
            )}
          </div>

            <div className='mt-3'>
              <label htmlFor="nameBM" className='contest-label'>Name (BM):</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input" style={{width: '50%'}} type="text" id="nameBM" value={nameBM} onChange={(e) => setNameBM(e.target.value)} required />
            </div>  
            <div>
              <label className='contest-label' htmlFor="descriptionBM">Campaign Description:</label><br></br>
              <input className="form-control vinda-form-input contest-blue-input" style={{width: '50%'}}  type="text" id="descriptionBM" value={descriptionBM} onChange={(e) => setDescriptionBM(e.target.value)} placeholder='Campaign Description' required />
            </div>
            {/* <div className='mt-3'>
            {campaignIconUrlBM && (
              <img src={campaignIconUrlBM} alt="Campaign Icon" style={{ width: '200px' }} />
            )}
          </div> */}
            {/* <div className='mt-3'>
              <label htmlFor="campaignIconBM">Campaign Icon (BM):</label><br></br>
              <input type="file" id="campaignIconBM" accept="image/*" onChange={handleCampaignIconBMChange} required />
            </div> */}
              <div className="form-group mt-3">
            <label htmlFor="campaignIconBM" className='contest-label m-0'>Contest Key Visual:</label><br></br>
								<div className="form-control"
										style={{
										border: "3px solid #67acde",
										borderRadius: "16px",
                    width: "50%"
									}}>
									<div style={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between'}}>
                  {campaignIconUrlBM && (
                <img src={campaignIconUrlBM} alt="Campaign Icon" style={{ width: '200px' }} />
                  )}
										<div style={{border: "1px solid black",}} className="image-upload-box" onClick={() => document.getElementById('campaignIconBM').click()}>
											+
										</div>
									</div>
									<input type="file" name="campaignIconBM" id="campaignIconBM" className="form-control" accept="image/*" onChange={handleCampaignIconBMChange}
										required style={{
											border: "2.6402px solid black",
											borderRadius: "15.8412px",
											display: "none",
										}} />
								</div>
							</div>
            <div>
            <label htmlFor="stepsBM" className='contest-label'>Steps To Participate (BM):</label><br></br>
              {stepsBM.map((stepBM, index) => (
                <div key={index}>
                  <h6 style={{color: '#647dcc', fontWeight: '600'}}>Step {stepBM.orderBM}</h6>
                  <input
                  className="form-control vinda-form-input steps-instruction"
                    type="text"
                    value={stepBM.instructionBM}
                    onChange={(e) => handleStepChangeBM(index, 'instructionBM', e.target.value)}
                    placeholder="Instruction (BM)"
                  /><br></br>
                  <input
                  className="form-control vinda-form-input steps-description"
                    type="text"
                    value={stepBM.descriptionBM}
                    onChange={(e) => handleStepChangeBM(index, 'descriptionBM', e.target.value)}
                    placeholder="Description (BM)"
                  />
                  {/* <input
                  className="form-control vinda-form-input"
                    type="number"
                    value={stepBM.orderBM}
                    onChange={(e) => handleStepChangeBM(index, 'orderBM', parseInt(e.target.value))}
                    placeholder="Order (BM)"
                  /> */}
                </div>
              ))}
               <p className='contest-label' onClick={handleAddStepBM} style={{textAlign: 'center', width: '50%', color: '#fdac39'}}>+ {steps.length ? 'Add More Steps' : 'Add Step'}</p>
            </div>
            <Loader component={<button className='mt-3 submit-contest' type="submit">Complete</button>} />
          </form>

         </div>
			</div>
		</div>
  );
};


const mapDispatchToProps = dispatch => {
  return {
    addContest: (data, callback) => dispatch(addContest(data, callback))
  };
};

export default connect(null, mapDispatchToProps)(AddContest);