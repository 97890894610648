import { useEffect, useState } from "react";
import moment from "moment";
import { Redirect } from "react-router-dom";
// assets
// Components
import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import { Spinner } from "react-bootstrap";
//Redux
import { connect } from "react-redux";
import { trackOrder } from "../../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

function OrderTracking({ location, user, history, pageReducer, trackOrder }) {
	const [trackingData, setTrackingData] = useState([]);
	const { t } = useTranslation();

	const processAddress = (line1, line2, line3, city, state, postcode, country) => {
		let text = "";
		text += `${line1}, `;
		if (line2) text += `${line2}, `;
		if (line3) text += `${line3}, `;
		text += `${city}, `;
		text += `${state}, `;
		text += `${postcode}, `;
		text += `${country}`;
		return text;
	};

	const handleMobileCode = num => {
		if (num && num.length > 8 && num[0].toString() !== "0") {
			return "+";
		} else {
			return "+6";
		}
	};

	useEffect(() => {
		if (location.orderId && user && location.type) {
			trackOrder({ id: location.orderId }, data => setTrackingData(data));
		} else {
			history.push("/dashboard");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const trackingClassName = val => {
		// NEW, PROCESSING, DELIVERING, DELIVERED
		const trackingValues = trackingData.map(x => x.state);

		if (val === "PROCESSING") {
			if (trackingValues.includes("DELIVERING")) {
				return "active";
			} else if (trackingValues.includes(val)) {
				return "pending";
			} else {
				return "";
			}
		} else if (val === "DELIVERING") {
			if (trackingValues.includes("DELIVERED")) {
				return "active";
			} else if (trackingValues.includes(val)) {
				return "pending";
			} else {
				return "";
			}
		} else if (val === "DELIVERED") {
			if (trackingValues.includes(val)) {
				return "active";
			}
		} else {
			return "";
		}
	};

	const trackingDescription = val => {
		if (val === "PROCESSING") {
			if (trackingData.filter(x => x.state === "PROCESSING")[0]) {
				return (
					<span className={`orderTrackTextSub ${trackingClassName("PROCESSING")}`}>
						We are preparing your order. <br />{" "}
						<strong>{moment(new Date(trackingData.filter(x => x.state === "PROCESSING")[0].time)).format("Do MMM YYYY")}</strong>
					</span>
				);
			} else {
				return null;
			}
		} else if (val === "DELIVERING") {
			if (trackingData.filter(x => x.state === "DELIVERING")[0]) {
				return (
					<span className={`orderTrackTextSub ${trackingClassName("DELIVERING")}`}>
						Your order was being shipped on <br />{" "}
						<strong>{moment(new Date(trackingData.filter(x => x.state === "DELIVERING")[0].time)).format("Do MMM YYYY")}</strong>
					</span>
				);
			} else {
				return null;
			}
		} else if (val === "DELIVERED") {
			if (trackingData.filter(x => x.state === "DELIVERED")[0]) {
				return (
					<span className={`orderTrackTextSub ${trackingClassName("DELIVERED")}`}>
						Your order was delivered on <br />{" "}
						<strong>{moment(new Date(trackingData.filter(x => x.state === "DELIVERED")[0].time)).format("Do MMM YYYY")}</strong>
					</span>
				);
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	if (!location.orderId || !user || !location.type) {
		return <Redirect to="/dashboard" />;
	} else {
		return (
			<div id="order-tracking">
				<Header />
				<div className="content">
					<ContentHeader />
					<ul className="addToCartContainer">
						{user.redemptions
							.filter(x => x.id === location.orderId)[0]
							.orderItems.map(item => (
								<li key={item.id} className="addToCartProductBox">
									<div className="addToCartProductImageBox">
										{item.itemImage ? (
											<img src={item.itemImage[pageReducer.language]} alt="item" className="img-fluid addToCartProductImage" />
										) : null}
									</div>

									<div className="addToCartProductContent">
										<h5 className="dbc-title">{item.itemName[pageReducer.language]}</h5>
										<div className="addToCartProductDetail">
											<div className="addToCartPoint">
												<img src= "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star-2.png" alt="point star" className="img-fluid" />
												<h6 className="m-0 p-0 ms-2">
													{item.points} {t("points")}
												</h6>
											</div>

											<div className="addToCartQuantityController">
												<h5 className="dbc-title m-0 p-0 mx-3" style={{ color: "black" }}>
													x{item.quantity}
												</h5>
											</div>
										</div>
									</div>
								</li>
							))}
					</ul>

					<div className="tracking-content" style={{ paddingBottom: "100px" }}>
						<div className="address-container">
							<h5 className="m-0 p-0 confirm-title fw-bold">{t("delivaddr")}</h5>

							<div className="my-3">
								<p className="m-0 p-0 fw-bold">{user.name}</p>
								<p className="m-0 p-0">
									{handleMobileCode(user.number)}
									{user.number}
								</p>
								<p className="m-0 p-0 mt-1" style={{ lineHeight: "1em" }}>
									{processAddress(
										user.redemptions.filter(x => x.id === location.orderId)[0].address.line1,
										user.redemptions.filter(x => x.id === location.orderId)[0].address.line2,
										user.redemptions.filter(x => x.id === location.orderId)[0].address.line3,
										user.redemptions.filter(x => x.id === location.orderId)[0].address.city,
										user.redemptions.filter(x => x.id === location.orderId)[0].address.state,
										user.redemptions.filter(x => x.id === location.orderId)[0].address.postcode,
										user.redemptions.filter(x => x.id === location.orderId)[0].address.country
									)}
								</p>
							</div>
						</div>

						{new Date(user.redemptions.filter(x => x.id === location.orderId)[0].createdAt) > new Date(2022, 2, 17) ? (
							<div className="orderTrackBox tracking-container">
								<section className="orderTrackBox">
									<div className="d-flex align-items-center">
										<h5 className="m-0 p-0 confirm-title fw-bold">TRACK YOUR ORDER</h5>

										{pageReducer.isLoading ? <Spinner variant="primary" animation="border" className="m-0 p-0 ms-3" /> : null}
									</div>

									<div className="orderTrack">
										<div className="orderTrackStep">
											<div className="orderTrackStatus">
												<span className="orderTrackStatusDot active"></span>
												<span className="orderTrackStatusLine active"></span>
											</div>
											<div className="orderTrackText">
												<p className="orderTrackTextStat active">Order Placed</p>
												<span className="orderTrackTextSub active">
													You have made your order on <br />
													<strong>
														{moment(new Date(user.redemptions.filter(x => x.id === location.orderId)[0].createdAt)).format("Do MMM YYYY")}
													</strong>
												</span>
											</div>
										</div>
										<div className="orderTrackStep">
											<div className="orderTrackStatus">
												<span className={`orderTrackStatusDot ${trackingClassName("PROCESSING")}`}></span>
												<span className={`orderTrackStatusLine ${trackingClassName("PROCESSING")}`}></span>
											</div>
											<div className="orderTrackText">
												<p className={`orderTrackTextStat ${trackingClassName("PROCESSING")}`}> Order Processed </p>
												{trackingDescription("PROCESSING")}
												{/* <span className={`orderTrackTextSub ${trackingClassName("PROCESSING")}`}>We are preparing your order.</span> */}
											</div>
										</div>
										{/* <div className="orderTrackStep">
										<div className="orderTrackStatus">
											<span className={`orderTrackStatusDot ${trackingClassName("DELIVERING")}`}></span>
											<span className={`orderTrackStatusLine ${trackingClassName("DELIVERING")}`}></span>
										</div>
										<div className="orderTrackText">
											<p className={`orderTrackTextStat ${trackingClassName("DELIVERING")}`}> Preparing to Ship </p>
										</div>
									</div> */}
										<div className="orderTrackStep">
											<div className="orderTrackStatus">
												<span className={`orderTrackStatusDot ${trackingClassName("DELIVERING")}`}></span>
												<span className={`orderTrackStatusLine ${trackingClassName("DELIVERING")}`}></span>
											</div>
											<div className="orderTrackText">
												<p className={`orderTrackTextStat ${trackingClassName("DELIVERING")}`}> Order has been Shipped </p>
												{trackingDescription("DELIVERING")}
											</div>
										</div>
										<div className="orderTrackStep">
											<div className="orderTrackStatus">
												<span className={`orderTrackStatusDot ${trackingClassName("DELIVERED")}`}></span>
												{/* <span className="orderTrackStatusLine"></span> */}
											</div>
											<div className="orderTrackText">
												<p className={`orderTrackTextStat ${trackingClassName("DELIVERED")}`}> Order Received</p>
												{trackingDescription("DELIVERED")}
											</div>
										</div>
									</div>
								</section>
							</div>
						) : null}
					</div>

					<Footer />
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		pageReducer: state.pageReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		trackOrder: (data, callback) => dispatch(trackOrder(data, callback))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderTracking);
