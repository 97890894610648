import { useMemo } from "react";
// import { Link } from 'react-router-dom'
// Components
import Header from "../../component/dashboard/Header";
import Footer from "../../component/dashboard/Footer";
import ContentHeader from "../../component/dashboard/ContentHeader";
//Redux
import { connect } from "react-redux";
import queryString from "query-string";

function ViewNews({ news, location, language }) {
	const id = queryString.parse(location.search).news;

	const renderNews = useMemo(() => {
		if (news && news.length) {
			const thisNews = news.filter(news => news.id === id)[0];
			if (thisNews) {
				return (
					<div>
						<div className="newsBox">
							<h1 className="addToCartTitle">{thisNews.title[language]}</h1>
						</div>

						<div className="newsBox">
							<div className="detailNewsBox">
								<img src={thisNews.image[language]} alt={thisNews.title[language]} className="dashboardNewsImage"></img>
							</div>
						</div>
						<div className="newsBox">
							<p className="dashboardNewsDesc">{thisNews.description[language]}</p>
						</div>
					</div>
				);
			}
			return null;
		}
		return null;
	}, [news, id, language]);
	// const renderNews = () => {
	//     if(news && news.length){
	//         const thisNews = news.filter(news=>news.id===id)[0];
	//         if (thisNews) {

	//         }
	//         // return news.filter(news=>news.id===id).map(news=> {
	//         //     return (
	//         //         <div>
	//         //             <div className="newsBox">
	//         //                 <h1 className="addToCartTitle">{news.title}</h1>
	//         //             </div>
	//         //             <div className="newsBox">
	//         //                 <div className="detailNewsBox">
	//         //                     <img src={news.image} alt={news.title} className="dashboardNewsImage"></img>
	//         //                 </div>
	//         //             </div>
	//         //             <div className="newsBox">
	//         //                 <p className="dashboardNewsDesc">{news.description}</p>
	//         //             </div>
	//         //         </div>
	//         //     )
	//         // })
	//     }

	//     return null;
	// }
	return (
		<div>
			<Header />
			<div className="content" style={{ paddingBottom: "150px" }}>
				<ContentHeader />
				{renderNews}
			</div>
			<Footer />
		</div>
	);
}

const mapStateToProps = state => {
	return {
		news: state.userReducer.news,
		language: state.pageReducer.language
	};
};

export default connect(mapStateToProps, null)(ViewNews);
