// import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
// Components
import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
// Assets
// import { AiOutlineSearch } from "react-icons/ai";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Accordion from "../../../component/dashboard/Accordion";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function TierHelpCenter() {
	const { t } = useTranslation();
	return (
		<div>
			<Header />
			<div className="content" style={{ paddingBottom: "150px" }}>
				<ContentHeader title={t("tierHelpCenter")} />
				<div className="desktop-only text-center fat-stroke">
					<h1 className="dbc-title" style={{ color: "white", marginTop: "30px" }}>
						{t("tierHelpCenter")}
					</h1>
				</div>

				<div className="userProfileContainer mt-0">
					<Accordion simple={true} title={t("tierHead1")}>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine")}</strong></p>
						<p>{t("tierLine1")}</p>
						<p>{t("tierLine2")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine3")}</strong></p>
							<p>{t("tierLine4")}</p>
							<p>{t("tierLine5")}</p>
							<p>{t("tierLine6")}</p>
						{/* <p style={{fontWeight: "bold"}}><strong>{t("tierLine7")}</strong></p> */}
						<p><span style={{fontWeight: "bold"}}>{t("tierLine7a")}</span>{t("tierLine7")}<span style={{fontWeight: "bold"}}>{t("tierLine7b")}</span>{t("tierLine7c")}</p>
						<p><span style={{fontWeight: "bold"}}>{t("tierLine8a")}</span>{t("tierLine8")}</p>
						<p><span style={{fontWeight: "bold"}}>{t("tierLine9a")}</span>{t("tierLine9")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine10")}</strong></p>
							<p>{t("tierLine11")}</p>
							<p>{t("tierLine12")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine13")}</strong></p>
							<p>{t("tierLine14")}</p>
							<p>{t("tierLine15")}</p>
							<p>{t("tierLine16")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine17")}</strong></p>
							<p>{t("tierLine18")}</p>
							<p>{t("tierLine19")}</p>
							<p>{t("tierLine20")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine21")}</strong></p>
							<p>{t("tierLine22")}</p>
							<p>{t("tierLine23")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine24")}</strong></p>
							<p>{t("tierLine25")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine26")}</strong></p>
							<p>{t("tierLine27")}</p>
							<p>{t("tierLine28")}</p>
							<p><span style={{fontWeight: "bold"}}>{t("tierLine29a")}</span>{t("tierLine29")}</p>
						<p>{t("tierLine30")}</p>
						{/* //tierLine30 donne */}
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine31")}</strong></p>
						<p>{t("tierLine32")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine33")}</strong></p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine34")}</strong></p>
						<p>{t("tierLine35")}</p>
						<p>{t("tierLine36")}</p>
						<p>{t("tierLine37")}</p>
						<p>{t("tierLine38")}<span style={{fontWeight: "bold"}}>{t("tierLine38a")}</span>{t("tierLine38b")}</p>
						<p>{t("tierLine39")}</p>
						<p>{t("tierLine40")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine41")}</strong></p>
						<p>{t("tierLine42")}</p>
						<p>{t("tierLine43")}</p>
						<p>{t("tierLine44")}</p>
						<p>{t("tierLine45")}<span style={{fontWeight: "bold"}}>{t("tierLine45a")}</span>{t("tierLine45b")}</p>
						<p>{t("tierLine46")}</p>
						<p>{t("tierLine47")}</p>
						<p>{t("tierLine48")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine49")}</strong></p>
						<p>{t("tierLine50")}</p>
						<p>{t("tierLine51")}<span style={{fontWeight: "bold"}}>{t("tierLine51a")}</span>{t("tierLine51b")}</p>
						<p>{t("tierLine52")}</p>
						<p>{t("tierLine53")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine54")}</strong></p>
						<p>{t("tierLine55")}</p>
						<p>{t("tierLine56")}<span style={{fontWeight: "bold"}}>{t("tierLine56a")}</span>{t("tierLine56b")}</p>
						<p>{t("tierLine57")}</p>
						<p>{t("tierLine58")}</p>
						<p>{t("tierLine59")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine60")}</strong></p>
						<p>{t("tierLine61")}</p>
						<p>{t("tierLine62")}<span style={{fontWeight: "bold"}}>{t("tierLine62a")}</span>{t("tierLine62b")}</p>
						<p>{t("tierLine63")}</p>
						<p>{t("tierLine64")}<span style={{fontWeight: "bold"}}>{t("tierLine64a")}</span>{t("tierLine64b")}</p>
						<p>{t("tierLine65")}</p>
						<p>{t("tierLine66")}</p>
						<p>{t("tierLine67")}</p>
						<p>{t("tierLine68")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine69")}</strong></p>
						<p>{t("tierLine70")}</p>
						<p>{t("tierLine71")}<span style={{fontWeight: "bold"}}>{t("tierLine71a")}</span>{t("tierLine71b")}</p>
						<p>{t("tierLine72")}</p>
						<p>{t("tierLine73")}<span style={{fontWeight: "bold"}}>{t("tierLine73a")}</span>{t("tierLine73b")}</p>
						<p>{t("tierLine74")}</p>
						<p>{t("tierLine75")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine76")}</strong></p>
						<p>{t("tierLine77")}</p>
						<p>{t("tierLine78")}<span style={{fontWeight: "bold"}}>{t("tierLine78a")}</span>{t("tierLine78b")}</p>
						<p>{t("tierLine79")}</p>
						<p>{t("tierLine80")}</p>
						<p>{t("tierLine81")}</p>
						<p>{t("tierLine82")}</p>
						<p>{t("tierLine83")}<span style={{fontWeight: "bold"}}>{t("tierLine83a")}</span>{t("tierLine83b")}</p>
						<p>{t("tierLine84")}</p>
						<p>{t("tierLine85")}</p>
					</Accordion>
					<Accordion simple={true} title={t("tierHead2")}>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine86")}</strong></p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine87")}</strong></p>
						<p>{t("tierLine88")}</p>
						<p>{t("tierLine89")}</p>
						<p>{t("tierLine90")}</p>
						<p>{t("tierLine91")}</p>
						<p>{t("tierLine92")}</p>
						<p>{t("tierLine93")}</p>
						<p>{t("tierLine94")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine95")}</strong></p>
						<p>{t("tierLine96")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine97")}</strong></p>
						<p>{t("tierLine98")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine99")}</strong></p>
						<p>{t("tierLine100")}</p>
						<p>{t("tierLine101")}<Link to="/tier">{t("tierLine101a")}</Link>{t("tierLine101b")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine102")}</strong></p>
						<p>{t("tierLine103")}</p>
						<p>{t("tierLine104")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine105")}</strong></p>
						<p>{t("tierLine106")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine107")}</strong></p>
						<p>{t("tierLine108")}</p>
						<p style={{fontWeight: "bold"}}><strong>{t("tierLine109")}</strong></p>
						<p>{t("tierLine110")}<Link to="/tier">{t("tierLine110a")}</Link>{t("tierLine110b")}</p>
					</Accordion>
				</div>
				<div style={{padding: "0 5vw", marginTop: "40px"}}>
				<Link to="/tierHistory"  style={{ textDecoration: "none" }}><div className="tier-benefits mt-3">{t("viewTierHistory")}</div></Link>
				<hr style={{ border: "none", borderBottom: "1px solid black", bottom: "-5px", width: "100%" }} />
				<Link to="/tier"  style={{ textDecoration: "none" }}><div className="tier-benefits mt-3">{t("viewTieringPrivileges")}</div></Link>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default TierHelpCenter;
