import { useState, useEffect } from "react";
// import {Link} from 'react-router-dom';
import { Modal } from "react-bootstrap";
// Components
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Loader from "../../../component/Loader";
import DatePicker from "react-datepicker";
// Assets
// import cart from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/cart.png";
import { addBabyInfo, editBabyInfo, getBrandInfo, clearError } from "../../../redux/actions/userActions";
//Redux
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const AddBabyInfo = ({
	getBrandInfo,
	brandData,
	addBabyInfo,
	editBabyInfo,
	pageReducer,
	clearError,
	history,
	user
}) => {
	const { t } = useTranslation();
	const location = useLocation();
	const [data, setData] = useState({
		// pregnancyStage: "",
		birthDate: location.state && location.state.babyData ? new Date(location.state.babyData.birthDate) : new Date(),
		gender: location.state && location.state.babyData ? location.state.babyData.gender : "",
		brandId: location.state && location.state.babyData ? location.state.babyData.brandId : "",
		formatId: location.state && location.state.babyData ? location.state.babyData.formatId : "",
		sizeId: location.state && location.state.babyData ? location.state.babyData.sizeId : "",
		name: location.state && location.state.babyData ? location.state.babyData.name : "",
		babyId: location.state ? location.state.babyId : null
	});

	const [success, setSuccess] = useState(false);

	useEffect(() => {
		if (!brandData) getBrandInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = e => {
		if (e.target.name === "brandId") {
			setData({
				...data,
				brandId: e.target.value,
				formatId: "",
				sizeId: ""
			});
		} else if (e.target.name === "formatId") {
			setData({
				...data,
				formatId: e.target.value,
				sizeId: ""
			});
		} else {
			setData({ ...data, [e.target.name]: e.target.value });
		}
	};

	const handleSubmit = e => {
		e.preventDefault();
		const { birthDate, gender, brandId, formatId, sizeId, name } = data;
		if (birthDate && gender && brandId && formatId && sizeId && name) {
			const sendThis = {
				id: user.id,
				babyInfo: data
			};

			if (data.babyId) {
				editBabyInfo(sendThis, () => setSuccess(true));
			} else {
				addBabyInfo(sendThis, () => setSuccess(true));
			}
		} else {
			console.log({ birthDate, gender, brandId, formatId, sizeId, name });
			alert("Please fill in all the fields");
		}
	};

	return (
		<div>
			<Header />
			<div className="content">
				<ContentHeader />

				<div className="text-center">
					<h1 className="dbc-title">{t("babyinfo")}</h1>
				</div>

				<form onSubmit={handleSubmit} className="vinda-form" style={{ paddingBottom: "100px" }}>
					<div className="form-group vinda-form-group">
						<label htmlFor="name" className="vinda-form-label">
							{t("name")}
						</label>
						<input
							onChange={handleChange}
							type="text"
							name="name"
							id="name"
							className="form-control vinda-form-input fw-bold no-border"
							placeholder={t("fullname")}
							defaultValue={location.state && location.state.babyData ? location.state.babyData.name : ""}
							required
						/>
					</div>

					<div className="form-group vinda-form-group">
						<label htmlFor="birthDate" className="vinda-form-label">
							{t("dobchild")}
						</label>
						{/* <input type="date" name="childDob" id="childDob" className="form-control vinda-form-input" value={data.childDob} onChange={handleChange} required /> */}
						<DatePicker
							selected={data.birthDate}
							onChange={date => setData({ ...data, birthDate: date })}
							id="birthDate"
							name="birthDate"
							className="form-control vinda-form-input no-border fw-bold"
							placeholderText={t("date")}
							showYearDropdown
							showMonthDropdown
							dropdownMode="select"
							dateFormat="dd/MM/yyyy"
							required
						/>
					</div>

					<div className="form-group vinda-form-group">
						<label htmlFor="gender" className="vinda-form-label">
							{t("childgender")}
						</label>
						<select
							name="gender"
							id="gender"
							className="form-select vinda-form-input no-border fw-bold"
							value={data.gender}
							onChange={handleChange}
							required
						>
							<option selected disabled value="">
								{t("gender")}
							</option>
							<option value="male">{t("male")}</option>
							<option value="female">{t("female")}</option>
						</select>
					</div>

					<div className="form-group vinda-form-group">
						<label htmlFor="brandId" className="vinda-form-label">
							{t("babydiaper")}
						</label>
						<select
							name="brandId"
							id="brandId"
							className="form-select vinda-form-input no-border fw-bold"
							value={data.brandId}
							onChange={handleChange}
							required
						>
							<option disabled value="">
								{t("brand")}
							</option>
							{brandData && brandData.length
								? brandData.map(brand => (
										<option key={brand.id} value={brand.id}>
											{brand.name}
										</option>
								  ))
								: null}
						</select>
					</div>

					<div className="form-group vinda-form-group">
						<label htmlFor="formatId" className="vinda-form-label">
							{t("diaperformat")}
						</label>
						<select
							name="formatId"
							id="formatId"
							className="form-select vinda-form-input no-border fw-bold"
							value={data.formatId}
							onChange={handleChange}
							required
						>
							<option disabled value="">
								{t("format")}
							</option>

							{brandData && brandData.length
								? brandData.filter(brand => brand.id === data.brandId)[0]
									? brandData
											.filter(brand => brand.id === data.brandId)[0]
											.formats.map(format => (
												<option key={format.id} value={format.id}>
													{format.name.toUpperCase()}
												</option>
											))
									: null
								: null}
						</select>
					</div>

					<div className="form-group vinda-form-group">
						<label htmlFor="sizeId" className="vinda-form-label">
							{t("diapersize")}
						</label>
						<select
							name="sizeId"
							id="sizeId"
							className="form-select vinda-form-input no-border fw-bold"
							value={data.sizeId}
							onChange={handleChange}
							required
						>
							<option disabled value="">
								{t("size")}
							</option>
							{brandData && brandData.length
								? brandData.filter(brand => brand.id === data.brandId)[0]
									? brandData
											.filter(brand => brand.id === data.brandId)[0]
											.sizes.filter(size => size.brandId === data.brandId && size.formatId === data.formatId)
											.map(size => (
												<option key={size.id} value={size.id}>
													{size.name.toUpperCase()} ({size.description})
												</option>
											))
									: null
								: null}
						</select>
					</div>

					<div className="form-group vinda-form-group text-center mt-5">
						<Loader component={<input type="submit" value="SAVE" className="vinda-form-submit btn" />} />
					</div>
				</form>
			</div>
			<Modal show={pageReducer.error} onHide={() => clearError()} centered>
				<Modal.Body className="text-center">
					<div>
						<p className="addToCartTitle">{t("error")}</p>
						<p className="mt-3 pickSampleModalText">{pageReducer.error}</p>
					</div>

					<div>
						<button className="pickAddressConfirmButton" onClick={() => clearError()}>
							OKAY
						</button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={success}
				onHide={() => {
					setSuccess(false);
					history.push("/viewbabyinfo");
				}}
				centered
			>
				<Modal.Body className="text-center">
					<div>
						<p className="addToCartTitle">{t("done")}!</p>
						<p className="mt-3 pickSampleModalText">{t("babysaved")}.</p>
					</div>

					<div>
						<button
							className="pickAddressConfirmButton"
							onClick={() => {
								setSuccess(false);
								history.push("/viewbabyinfo");
							}}
						>
							OKAY
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		brandData: state.userReducer.brand,
		pageReducer: state.pageReducer,
		user: state.userReducer.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		addBabyInfo: (data, callback) => dispatch(addBabyInfo(data, callback)),
		editBabyInfo: (data, callback) => dispatch(editBabyInfo(data, callback)),
		getBrandInfo: () => dispatch(getBrandInfo()),
		clearError: () => dispatch(clearError())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBabyInfo);
