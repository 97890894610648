import { MdLogout } from "react-icons/md";

const AdminTopbar = () => {
	return (
		<nav className="navbar p-2">
			<button onClick={() => console.log("Logout")} className="btn btn-light ms-auto">
				<MdLogout />
			</button>
		</nav>
	);
};

export default AdminTopbar;
