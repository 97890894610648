import React, { useState } from "react";
// import {Link} from 'react-router-dom';
// Components
import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import moment from "moment";
//Redux
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

function ViewPoints({ user }) {
	const { t } = useTranslation();
	const [isPage, setPage] = useState("earn");

	const renderColor = num => {
		if (num < 0) {
			return {
				color: "red"
			};
		}
	};

	const renderList = () => {
		let userPoints = user.points;
		if (isPage === "earn") {
			userPoints = user.points.filter(point => point.points >= 0);
		} else {
			userPoints = user.points.filter(point => point.points < 0);
		}

		return userPoints.map(point => (
			<div key={point.id} className="pointHistoryRecordHold">
				<div>
					<h6 className="m-0 p-0 pointHistoryRecordDate">{moment(new Date(point.createdAt)).format("DD MMM YYYY")}</h6>
					<h5 className="m-0 p-0 mt-2 pointHistoryRecordItem">{point.source}</h5>
				</div>
				<h5 className="m-0 p-0 pointHistoryRecordPoint" style={renderColor(point.points)}>
					{point.points}
				</h5>
			</div>
		));
	};

	return (
		<div>
			<Header />
			<div className="content pb-5">
				<ContentHeader />
				<div className="text-center">
					<h1 className="dbc-title">{t("pointshistory")}</h1>
				</div>

				<div className="pointShopTopButtonContainer">
					<div className={`pointShopTopButton ${isPage === "earn" ? "active" : ""}`} onClick={() => setPage("earn")}>
						<p className="m-0 p-0">{t("pointsearned")}</p>
					</div>
					<div className={`pointShopTopButton ${isPage === "used" ? "active" : ""}`} onClick={() => setPage("used")}>
						<p className="m-0 p-0">{t("pointsused")}</p>
					</div>
				</div>

				<div style={{ marginBottom: "150px", marginTop: "25px", padding: "0 5vw" }}>{renderList()}</div>
			</div>
			<Footer />
		</div>
	);
}

const mapStateToProps = state => {
	return {
		user: state.userReducer.user
	};
};

export default connect(mapStateToProps)(ViewPoints);
