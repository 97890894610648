import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import QueryString from "query-string";
import { Redirect } from "react-router-dom";
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Loader from "../../../component/Loader";
import { resetPassword, clearError } from "../../../redux/actions/userActions";
import { useTranslation } from "react-i18next";
import ProgressBar from 'react-bootstrap/ProgressBar';

const ResetPassword = ({ history, match, location, pageReducer, resetPassword, clearError }) => {
	const { user, datems } = QueryString.parse(location.search);
	const { t } = useTranslation();
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);

	const [passwordStrength, setPasswordStrength] = useState(0); // 0: Very Weak, 1: Weak, 2: Good, 3: Strong

	useEffect(() => {
		if (!user || !datems) {
			history.push(`/${pageReducer.language}`);
		} else {
			const thisDate = new Date(parseInt(datems)).getDate();
			const todayDate = new Date().getDate();

			const thisMonth = new Date(parseInt(datems)).getMonth();
			const todayMonth = new Date().getMonth();

			const thisYear = new Date(parseInt(datems)).getFullYear();
			const todayYear = new Date().getFullYear();

			if (thisDate !== todayDate || thisMonth !== todayMonth || thisYear !== todayYear) {
				setError("Link expired, please proceed to forgot password and request for a new link.");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = e => {
		e.preventDefault();
		if (password && confirmPassword && password === confirmPassword && user && datems) {
			resetPassword({ id: user, password, datems }, () => setSuccess(true));
		}
	};

	const closeModal = () => {
		setError(null);
		history.push(`/${pageReducer.language}/signin`);
	};

	const getPasswordStrength = (password) => {
		const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
	  
		if (password.length < 6) {
		  return 0; // Very Weak
		} else if (password.length < 10) {
		  return 1; // Weak
		} else if (strongRegex.test(password)) {
		  return 3; // Strong
		} else {
		  return 2; // Good
		}
	  };
	  

	const getPasswordStrengthColor = () => {
		switch (passwordStrength) {
		  case 0:
			return 'red'; // Very Weak
		  case 1:
			return 'red'; // Weak
		  case 2:
			return 'red'; // Good
		  case 3:
			return 'red'; // Strong
		  default:
			return 'transparent';
		}
	  };

	  const getPasswordStrengthText = (strength) => {
        switch (strength) {
            case 0:
                return t("ssoPasswordValidation");
            case 1:
                return t("ssoPasswordValidation");
            case 2:
                return t("ssoPasswordValidation");
            case 3:
                return '';
        }
    };



	if (!user || !datems) {
		return <Redirect to={`/${pageReducer.language}`} />;
	} else {
		return (
			<div id="reset-password-page">
				<Header history={history} match={match} location={location} />
				<div className="content">
					<ContentHeader />

					<div className="text-center">
						<h1 className="dbc-title">{t("resetTitle")}</h1>
					</div>

					<form className="forgot-password-container" onSubmit={handleSubmit}>
						<div className="form-group">
							<label htmlFor="password">{t("enternewpwd")}</label>
							<input
								type="password"
								id="password"
								name="password"
								className="form-control"
								value={password}
								onChange={e => {
									setPassword(e.target.value);
									const strength = getPasswordStrength(e.target.value);
									setPasswordStrength(strength);
								}}
								required
							/>
						</div>
						{ password ?
								(<div style={{marginTop: '10px'}}>
								<ProgressBar
									variant={getPasswordStrengthColor()}
									now={(passwordStrength + 1) * 25}
									label={`${(passwordStrength + 1) * 25}%`}
									style={{ width: '30%', alignSelf: 'flex-end' }}
								/>
								<div style={{ color: getPasswordStrengthColor(), textAlign: 'left', fontSize: '10px' }}>
								{getPasswordStrengthText(passwordStrength)}
								</div>
								</div>) : null}
						<div className="form-group mt-3">
							<label htmlFor="confirmPassword">{t("confirmnewpwd")}</label>
							<input
								type="password"
								id="confirmPassword"
								name="confirmPassword"
								className="form-control"
								value={confirmPassword}
								onChange={e => setConfirmPassword(e.target.value)}
								required
							/>
						</div>
						<div className="mt-3 form-group">
							<Loader component={<input type="submit" value={t("updatemypwd")} className="btn btn-primary" />} />
						</div>
					</form>
				</div>
				<Modal show={error} onHide={closeModal} centered>
					<Modal.Body className="text-center">
						<div>
							<p className="addToCartTitle">{t("error")}</p>
							<p className="mt-3 pickSampleModalText">{error}</p>
						</div>

						<div>
							<button className="pickAddressConfirmButton" onClick={closeModal}>
								OKAY
							</button>
						</div>
					</Modal.Body>
				</Modal>

				<Modal show={pageReducer.error} onHide={() => clearError()} centered>
					<Modal.Body className="text-center">
						<div>
							<p className="addToCartTitle">{t("error")}</p>
							<p className="mt-3 pickSampleModalText">{pageReducer.error}</p>
						</div>

						<div>
							<button className="pickAddressConfirmButton" onClick={() => clearError()}>
								OKAY
							</button>
						</div>
					</Modal.Body>
				</Modal>

				<Modal show={success} onHide={closeModal} centered>
					<Modal.Body className="text-center">
						<div>
							<p className="addToCartTitle">{t("succ")}</p>
							<p className="mt-3 pickSampleModalText">{t("successresetmsg")}</p>
						</div>

						<div>
							<button className="pickAddressConfirmButton" onClick={closeModal}>
								{t("returntologin")}
							</button>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		pageReducer: state.pageReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		resetPassword: (data, callback) => dispatch(resetPassword(data, callback)),
		clearError: () => dispatch(clearError())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
