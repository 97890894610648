import { Link } from "react-router-dom";
// Components
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Footer from "../../../component/dashboard/Footer";
// Assets
// Icons
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
// Redux
import { connect } from "react-redux";
import { editCart } from "../../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

const Cart = ({ cart, user, editCart, history, language, location }) => {
	const { t } = useTranslation();
	const handleChange = (type, item) => {
		const currentBalance = user.totalPoints - Object.values(cart).reduce((a, b) => a + b.points * b.quantity, 0);
		const { id, points, quantity } = item;

		console.log(location.type);

		let copyCart = { ...cart };
		if (type === "add") {
			if (currentBalance >= points) {
				copyCart = {
					...copyCart,
					[id]: {
						...copyCart[id],
						quantity: copyCart[id].quantity + 1
					}
				};
				window.dataLayer.push({ ecommerce: null });
				window.dataLayer.push({
					event: "add_to_cart",
					redemption_category: copyCart[id].categories[0] === "Special Deals" ? "Special Deals" : copyCart[id].categories[0] === "Baby Product" ? "Baby Product" : "Pregnant Offer",
					ecommerce: {
					currency: "MYR", 
					value: copyCart[id].points,
					items:
					[ 
						{
						item_id: copyCart[id].itemId, 
						item_name: copyCart[id].itemName ? copyCart[id].itemName.en : null , 
						item_category: copyCart[id].categories[0] === "Special Deals" ? "Special Deals" : copyCart[id].categories[0] === "Baby Product" ? "Baby Product" : "Pregnant Offer",
						item_brand: copyCart[id].itemBrand,
						price: copyCart[id].points,
						quantity: 1
						}
					]
				}
				});
			}
		} else {
			if (quantity - 1) {
				// still have remaining
				copyCart = {
					...copyCart,
					[id]: {
						...copyCart[id],
						quantity: copyCart[id].quantity - 1
					}
				};
			} else {
				// is 0, delete the property
				window.dataLayer.push({ ecommerce: null });
				window.dataLayer.push({
					event: "remove_from_cart",
					redemption_category: copyCart[id].categories[0] === "Special Deals" ? "Special Deals" : copyCart[id].categories[0] === "Baby Product" ? "Baby Product" : "Pregnant Offer",
					ecommerce: {
					currency: "MYR", 
					value: copyCart[id].points,
					items:
					[ 
						{
						item_id: copyCart[id].itemId, 
						item_name: copyCart[id].itemName ? copyCart[id].itemName.en : null , 
						item_category: copyCart[id].categories[0] === "Special Deals" ? "Special Deals" : copyCart[id].categories[0] === "Baby Product" ? "Baby Product" : "Pregnant Offer",
						item_brand: copyCart[id].itemBrand,
						price: copyCart[id].points,
						quantity: 1
						}
					]
				}
				});
				delete copyCart[id];
			}
		}
		editCart(copyCart);
	};

	const handleConfirm = () => {
		// calculate and check again
		const { totalPoints } = user;
		const redeemedPoints = Object.values(cart).reduce((a, b) => a + b.points * b.quantity, 0);
		const cartItems = [];

		window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object

		Object.values(cart).forEach(item => {
			cartItems.push({
				item_id: item.itemId,
				item_name: item.itemName ? item.itemName.en : item.itemName.en,
				item_category: location.type === "item" && item.categories && item.categories[0] === "Special Deals" ? "Special Deals" : location.type === "donate" ? "Share a Little Comfort" : location.type === "voucher" ? "EVouchers" : location.type === "pregnant" ? "Pregnant Offer" : "Baby Product",
				item_brand: location.data.itemBrand,
				price: item.points,
				quantity: item.quantity
			});
		});

		const redemptionCategories = cartItems.map(item => item.item_category).join(', ');

		window.dataLayer.push({
			event: "begin_checkout",
			redemption_category: redemptionCategories,
			ecommerce: {
				currency: "MYR",
				value: redeemedPoints,
				items: cartItems
			}
		});
	
		if (totalPoints >= redeemedPoints && location.type === "item") {
			history.push({ pathname: "/viewAddress", type: "order", data: { cartItems, ...location.data } });
		}
		else if (totalPoints >= redeemedPoints && location.type === "pregnant") {
			history.push({ pathname: "/viewAddress", type: "pregnant", data: { cartItems, ...location.data } });
		}
	};

	return (
		<div id="cart-page">
			<Header />
			<div className="content">
				<ContentHeader title="Cart" />

				<ul className="addToCartContainer">
					{Object.values(cart).map(item => (
						<li key={item.id} className="addToCartProductBox">
							<div className="addToCartProductImageBox">
								<img src={item.itemImage[language]} alt="item" className="img-fluid addToCartProductImage" />
							</div>

							<div className="addToCartProductContent">
								<h5 className="dbc-title">{item.itemName[language]}</h5>
								<div className="addToCartProductDetail">
									<div className="addToCartPoint">
										<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star-2.png" alt="point star" className="img-fluid" />
										<h6 className="m-0 p-0 ms-2">
											{item.points}
											{t("points")}
										</h6>
									</div>

									<div className="addToCartQuantityController">
										<div className="arrow-container" onClick={() => handleChange("minus", item)}>
											<AiFillCaretLeft className="m-0 p-0" />
										</div>
										<h5 className="dbc-title m-0 p-0 mx-3" style={{ color: "black" }}>
											{item.quantity}
										</h5>
										<div className="arrow-container" onClick={() => handleChange("add", item)}>
											<AiFillCaretRight className="m-0 p-0" />
										</div>
									</div>
								</div>
							</div>
						</li>
					))}

					<div className="text-end mt-2">
						<Link style={{ textDecoration: "none" }} to="/pointshop">
							<p className="addToCartText fw-bold">+ {t("additem")}</p>
						</Link>
					</div>
				</ul>

				<div className="calculation-box mt-5">
					<div className="row m-0 p-0">
						<div className="col col-10 m-0 p-0">
							<h5 className="m-0 p-0 calculation-title">{t("totalredeem")}</h5>
						</div>
						<div className="col col-2 m-0 p-0 text-center">
							<h5 className="m-0 p-0 calculation-value">{Object.values(cart).reduce((a, b) => a + b.points * b.quantity, 0)}</h5>
						</div>
					</div>
					<div className="row m-0 p-0 mt-2">
						<div className="col col-10 m-0 p-0">
							<h5 className="m-0 p-0 calculation-title">{t("mybalpts")}</h5>
						</div>
						<div className="col col-2 m-0 p-0 text-center">
							<h5 className="m-0 p-0 calculation-value">{user.totalPoints - Object.values(cart).reduce((a, b) => a + b.points * b.quantity, 0)}</h5>
						</div>
					</div>
				</div>
			</div>

			<div className="text-center my-5">
				<button type="button" className="btn confirm-button" onClick={handleConfirm}>
					{t("confirm")}
				</button>
			</div>

			<div className="desktop-only">
				<Footer />
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		cart: state.userReducer.cart,
		user: state.userReducer.user,
		language: state.pageReducer.language
	};
};

const mapDispatchToProps = dispatch => {
	return {
		editCart: data => dispatch(editCart(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
