import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Components
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Footer from "../../../component/dashboard/Footer";
import { Modal } from "react-bootstrap";
//Redux
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const ViewAddress = ({ user, history, location }) => {
	const { t } = useTranslation();
	const [selected, setSelected] = useState("");
	const [show, setShow] = useState(false);

	const { data } = location;
	const cartItems = data ? data.cartItems : [];

	useEffect(() => {
		if (user && user.addresses && user.addresses.length) {
			setSelected(user.addresses.filter(address => address.active && address.active === true)[0].id);
		} else {
			setShow(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleConfirm = () => {
		if (selected) {
			const thisAddress = user.addresses.filter(
				address => address.id === selected && address.active && address.active === true
			)[0];
			if (location.type === "order") {
				const redemptionCategories = cartItems.map(item => item.item_category).join(", ");

				window.dataLayer.push({ ecommerce: null });
				// Assuming cartItems is an object with keys representing item IDs
				const itemsArray = Object.values(cartItems).map(item => ({
					item_id: item.item_id,
					item_name: item.item_name,
					item_category: item.item_category === "Special Deals" ? "Special Deals" : "Baby Product",
					item_brand: item.item_brand,
					price: item.price,
					quantity: item.quantity
				}));

				window.dataLayer.push({
					event: "add_shipping_info",
					redemption_category: redemptionCategories,
					ecommerce: {
						currency: "MYR",
						value: itemsArray.reduce((total, item) => total + item.price * item.quantity, 0), // Calculate total value
						items: itemsArray
					}
				});
				history.push({
					pathname: "/order/confirm",
					address: thisAddress,
					data: { cartItems, ...location.data },
					type: "item"
				});
			} else if (location.type === "pregnant") {
				window.dataLayer.push({ ecommerce: null });
				// Assuming cartItems is an object with keys representing item IDs
				const itemsArray = Object.values(cartItems).map(item => ({
					item_id: item.item_id,
					item_name: item.item_name,
					item_category: "Pregnant Offer",
					item_brand: item.item_brand,
					price: item.price,
					quantity: item.quantity
				}));

				window.dataLayer.push({
					event: "add_shipping_info",
					redemption_category: "Pregnant Offer", // Assuming Special Deals is the default category
					ecommerce: {
						currency: "MYR",
						value: itemsArray.reduce((total, item) => total + item.price * item.quantity, 0), // Calculate total value
						items: itemsArray
					}
				});
				history.push({
					pathname: "/order/pregnant/confirm",
					address: thisAddress,
					data: { cartItems, ...location.data },
					type: "pregnant"
				});
			} else if (location.type === "sample") {
				window.dataLayer.push({ ecommerce: null });
				window.dataLayer.push({
					event: "add_shipping_info",
					redemption_category:
						location.type === "item" && location.data.categories[0] === "eb96d625-28e9-442b-afa8-aed772b33299"
							? "Special Deals"
							: location.type === "donate"
							? "Share a Little Comfort"
							: location.type === "voucher"
							? "EVouchers"
							: location.type === "pregnant"
							? "Pregnant Offer"
							: "Baby Product",
					ecommerce: {
						currency: "MYR",
						value: location.data.points,
						items: [
							{
								item_id: location.data.itemId,
								item_name: location.data.itemName.en,
								item_category:
									location.type === "item" && location.data.categories[0] === "eb96d625-28e9-442b-afa8-aed772b33299"
										? "Special Deals"
										: location.type === "donate"
										? "Share a Little Comfort"
										: location.type === "voucher"
										? "EVouchers"
										: location.type === "pregnant"
										? "Pregnant Offer"
										: "Baby Product",
								item_brand: location.data.itemBrand,
								price: location.data.points,
								quantity: 1
							}
						]
					}
				});
				history.push({ pathname: "/freesample/confirm", address: thisAddress, item: location.data });
			} else if (location.type === "user") history.push("/editprofile");
			else history.push("/editprofile");
		}
	};

	const processAddress = (line1, line2, line3, city, state, postcode, country) => {
		let text = "";
		text += `${line1}, `;
		if (line2) text += `${line2}, `;
		if (line3) text += `${line3}, `;
		text += `${city}, `;
		text += `${state}, `;
		text += `${postcode}, `;
		text += `${country}`;
		return text;
	};

	const handleMobileCode = num => {
		if (num && num.length > 8 && num[0].toString() !== "0") {
			return "+";
		} else {
			return "+6";
		}
	};

	return (
		<div id="pick-address-page">
			<Header />
			<div className="content">
				<ContentHeader />

				<div className="text-center">
					<h1 className="dbc-title">
						{location.type === "order" || location.type === "sample" || location.type === "pregnant"
							? t("delivery")
							: t("myaddress")}
					</h1>
					{location.type === "order" || location.type === "sample" || location.type === "pregnant" ? (
						<h6 className="pickAddressText">{t("chooseaddress")}</h6>
					) : null}
				</div>

				<div className="pickAddressHolder">
					{user && user.addresses && user.addresses.length
						? user.addresses
								.filter(address => address.active && address.active === true)
								.map(address => (
									<div key={address.id} className="pickAddressContainer" onClick={() => setSelected(address.id)}>
										<div className="pickAddressRadioButtonContainer">
											{location.type === "order" || location.type === "sample" || location.type === "pregnant" ? (
												<input
													type="radio"
													name="address"
													className="form-check-input"
													checked={selected === address.id}
													readOnly
												/>
											) : null}
										</div>

										<div className="p-2">
											<h5 className="m-0 p-0">{address.label ? address.label.toUpperCase() : "HOME"}</h5>
											<h6 className="m-0 p-0">
												{user.name} ({handleMobileCode(user.number)}
												{user.number})
											</h6>
											<p className="m-0 p-0">
												{processAddress(
													address.line1,
													address.line2,
													address.line3,
													address.city,
													address.state,
													address.postcode,
													address.country
												)}
											</p>
										</div>
									</div>
								))
						: null}

					<div className="text-end">
						<Link
							style={{ textDecoration: "none" }}
							to={{ pathname: "/addAddress", type: location.type || "user", data: location.data, cartItems: cartItems }}
						>
							<p style={{ textAlign: "center" }} className="addToCartText fw-bold">
								+ {t("addaddress")}
							</p>
						</Link>
					</div>
				</div>

				<div className="text-center my-5">
					<button type="button" className="btn confirm-button" onClick={handleConfirm}>
						{location.type !== "user" ? "PROCEED" : "BACK"}
					</button>
				</div>

				<div className="desktop-only">
					<Footer />
				</div>
			</div>

			<Modal show={show} onHide={() => setShow(false)} centered>
				<Modal.Body className="text-center">
					<div>
						<p className="pickSampleModalText">{t("newAddress")}</p>
					</div>

					<div>
						<button className="pickAddressConfirmButton" onClick={() => setShow(false)}>
							OKAY
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user
	};
};

export default connect(mapStateToProps, null)(ViewAddress);
