import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { getJourney } from "../../redux/actions/userActions";
import TreeImg from "../../../src/assets/Tree.png"

function JoinUs( { journey, getJourney, language} ) {
	const { t } = useTranslation();
	const history = useHistory();

	useEffect(() => {
		if(!journey) getJourney();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleClick() {
		history.push('/wishingtree/donate');
	  }

	return (
        
            <div className="join-us-background" style={{ 
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '20px',
				borderRadius: '10px',
                marginTop: '50px'
				}}>
				<div style={{ 
					flexBasis: '40%', 
					maxWidth: '40%',
					margin: '0 10px 0 0'
				}}>
					<img src={TreeImg} alt="image" style={{ 
					maxWidth: '100%', 
					height: 'auto'
					}} />
				</div>
				<div style={{ 
					flexBasis: '55%', 
					maxWidth: '55%',
					display: 'flex', 
					flexDirection: 'column', 
					justifyContent: 'center'
				}}>
					<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/club-logo-white.png" alt="square-image" className="join-us-img" />
					<h2 className="wishing-tree-title" style={{textAlign: "center"}}>Wishing Tree</h2>

					{journey && journey.length ? (
   						 journey.map(x => (
					<p className="generous-point" style={{color: "#0162BF", paddingRight: "20px", paddingLeft: "20px", textAlign: "center"}}>{x.description[language]}</p>
						))
						) : (
						<></>
					)}
					
					<button style={{ 
					backgroundColor: '#FEA30B', 
					color: 'white', 
					padding: '10px 20px', 
					borderRadius: '10px', 
					border: 'none', 
					fontSize: '18px', 
					fontFamily: 'VAGRounded'
					}}
					onClick={handleClick}
                    >{t("joinNowBtn")}</button>
				</div>
				</div>
	);
}

// export default Journey;

const mapDispatchToProps = dispatch => {
	return {
		getJourney: data => dispatch(getJourney(data)),
	};
};

const mapStateToProps = state => {
	return {
		journey: state.userReducer.journey,
		language: state.pageReducer.language,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinUs);

