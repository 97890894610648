import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { IS_LOADING, IS_LOADED } from "../../../../redux/types";

const BrandAssign = ({ loading, loaded, history, location }) => {
	const [show, setShow] = useState(false);
	const [newFormat, setNewFormat] = useState("");
	const [formatChoice, setFormatChoice] = useState([]);
	const [format, setFormat] = useState("");
	const [size, setSize] = useState([]);

	useEffect(() => {
		loading();
		axios
			.get("/drypers-baby-club/api/admin/fetch/dashboard/format")
			.then(res => {
				setFormatChoice(res.data);
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addNewFormat = () => {
		if (newFormat) {
			const name = newFormat.toLowerCase();
			loading();
			axios
				.post("/drypers-baby-club/api/admin/setting/format/add", { name })
				.then(res => {
					setFormatChoice([...formatChoice, name]);
					setNewFormat("");
					setShow(false);
					loaded();
				})
				.catch(err => {
					loaded();
					alert(err.response.data.error);
				});
		} else {
			setShow(false);
		}
	};

	const handleCheckbox = e => {
		const { value } = e.target;
		if (size.includes(value)) setSize(size.filter(x => x !== value));
		else setSize([...size, value]);
	};

	const isChecked = val => {
		if (size.includes(val)) return true;
		else return false;
	};

	const handleSubmit = e => {
		e.preventDefault();

		if (format && size.length) {
			const sizeTemplate = ["nb", "s", "m", "l", "xl", "xxl"];
			const sortFunc = (a, b) => sizeTemplate.indexOf(a) - sizeTemplate.indexOf(b);
			const sortedSize = size.sort(sortFunc);
			const body = {
				format,
				size: sortedSize,
				id: location.data.id
			};

			loading();
			axios
				.post("/drypers-baby-club/api/admin/setting/brand/assign", body)
				.then(res => {
					loaded();
					history.push("/admin/brand/dashboard");
				})
				.catch(err => {
					loaded();
					alert(err.response.data.error);
				});
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Assign Format/Sizes</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="format">Format</label>
								<button type="button" className="btn btn-info ms-3" onClick={() => setShow(true)}>
									Add Format Choice
								</button>
								<select id="format" name="format" className="form-select" value={format} onChange={e => setFormat(e.target.value)}>
									<option disabled value="">
										Select Format
									</option>

									{formatChoice && formatChoice.length
										? formatChoice.map(choice => (
												<option key={choice} value={choice} disabled={location.data.format.map(x => x.name).includes(choice)}>
													{choice.toUpperCase()}
												</option>
										  ))
										: null}
								</select>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="size">Available Sizes</label>

								<div>
									<div className="form-check form-check-inline">
										<input
											type="checkbox"
											className="form-check-input"
											id="nb"
											name="nb"
											value="nb"
											onChange={handleCheckbox}
											checked={isChecked("nb")}
										/>
										<label htmlFor="nb">NB</label>
									</div>

									<div className="form-check form-check-inline">
										<input
											type="checkbox"
											className="form-check-input"
											id="s"
											name="s"
											value="s"
											onChange={handleCheckbox}
											checked={isChecked("s")}
										/>
										<label htmlFor="s">S</label>
									</div>

									<div className="form-check form-check-inline">
										<input
											type="checkbox"
											className="form-check-input"
											id="m"
											name="m"
											value="m"
											onChange={handleCheckbox}
											checked={isChecked("m")}
										/>
										<label htmlFor="m">M</label>
									</div>

									<div className="form-check form-check-inline">
										<input
											type="checkbox"
											className="form-check-input"
											id="l"
											name="l"
											value="l"
											onChange={handleCheckbox}
											checked={isChecked("l")}
										/>
										<label htmlFor="l">L</label>
									</div>

									<div className="form-check form-check-inline">
										<input
											type="checkbox"
											className="form-check-input"
											id="xl"
											name="xl"
											value="xl"
											onChange={handleCheckbox}
											checked={isChecked("xl")}
										/>
										<label htmlFor="xl">XL</label>
									</div>

									<div className="form-check form-check-inline">
										<input
											type="checkbox"
											className="form-check-input"
											id="xxl"
											name="xxl"
											value="xxl"
											onChange={handleCheckbox}
											checked={isChecked("xxl")}
										/>
										<label htmlFor="xxl">XXL</label>
									</div>
								</div>
							</div>

							<div className="form-group mt-3 text-center">
								<Loader component={<input type="submit" className="btn btn-primary form-control" value="SUBMIT" />} />
							</div>
						</form>
					</div>
				</div>
			</div>

			<Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
				<Modal.Header>
					<Modal.Title>Add Format Choice</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div className="form-group">
						<label htmlFor="newFormat">New Format</label>
						<input
							type="text"
							id="newFormat"
							name="newFormat"
							placeholder="eg: pants/tape/open"
							className="form-control"
							value={newFormat}
							onChange={e => setNewFormat(e.target.value)}
							required
						/>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<button type="button" className="btn btn-secondary" onClick={() => setShow(false)}>
						Cancel
					</button>

					<Loader
						component={
							<button type="button" className="btn btn-primary" onClick={addNewFormat}>
								Confirm
							</button>
						}
					/>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(BrandAssign);
