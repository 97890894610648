import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Goodies = () => {
	const { t } = useTranslation();
	return (
		<div id="dashboard-goodies">
			<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/dashboard/blue-star-icon.png" alt="blue heart" className="img-fluid blueheart" />
			<h1 className="dashboard-goodies-title">{t("moregoodies")}</h1>

			<div className="dashboard-goodies-container">
				<Link className="dashboard-goodies-item" to="/picksample" style={{ textDecoration: "none" }}>
					<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-free-sample.png" alt="" className="img-fluid dashboard-goodies-image" />

					<div className="dashboard-goodies-description">
						<p className="m-0 p-0">
							{t("getfreecap")}
							{/* <br /> {t("samplescap")} */}
						</p>
					</div>
				</Link>
				<Link className="dashboard-goodies-item" to="/referandearn" style={{ textDecoration: "none" }}>
					<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-dbc-forum.png" alt="" className="img-fluid dashboard-goodies-image" />

					<div className="dashboard-goodies-description">
						<p className="m-0 p-0">
							{t("refercap")} &amp;
							<br /> {t("berewardcap")}
						</p>
					</div>
				</Link>
				<Link className="dashboard-goodies-item" to="/quest" style={{ textDecoration: "none" }}>
					<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-my-quest.png" alt="" className="img-fluid dashboard-goodies-image" />

					<div className="dashboard-goodies-description">
						<p className="m-0 p-0">{t("questcap")}</p>
					</div>
				</Link>
				<Link className="dashboard-goodies-item" to="/faq/how-to-collect-points" style={{ textDecoration: "none" }}>
					<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star-2.png" alt="" className="img-fluid dashboard-goodies-image" />

					<div className="dashboard-goodies-description">
						<p className="m-0 p-0">
							{t("waystocap")}
							{/* <br /> {t("collectpointscap")} */}
						</p>
					</div>
				</Link>
			</div>
		</div>
	);
};

export default Goodies;
