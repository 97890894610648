import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
// Components
import Header from "../../../component/dashboard/Header";
import Explore from "../../../component/dashboard/Explore";
import NeverBe from "../../../component/dashboard/NeverBe";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import ItemPreview from "../../../component/dashboard/ItemPreview";
import Footer from "../../../component/dashboard/Footer";
import Loader from "../../../component/Loader";
// Assets
//Redux
import { connect } from "react-redux";
import { addToCart, redeemVouc, clearError, donatePoints } from "../../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

function RewardDetail({
	history,
	location,
	user,
	addToCart,
	cart,
	redeemVouc,
	pageReducer,
	clearError,
	donatePoints,
	allCat
}) {
	const { t } = useTranslation();
	const [show, setShow] = useState(null);
	const [voucherConfirm, setVoucherConfirm] = useState(false);
	const [donateSuccess, setDonateSuccess] = useState(false);

	useEffect(() => {
		const dom = document.querySelector("#detail-container");
		if (dom) {
			dom.scrollIntoView({ behavior: "smooth" });
		}
	}, [location.data]);

	useEffect(() => {
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: "view_item",
			redemption_category:
				location.type === "item" && location.data.categories[0] === "eb96d625-28e9-442b-afa8-aed772b33299"
					? "Special Deals"
					: location.type === "donate"
					? "Share a Little Comfort"
					: location.type === "voucher"
					? "EVouchers"
					: location.type === "pregnant"
					? "Pregnant Offer"
					: "Baby Product",
			ecommerce: {
				currency: "MYR",
				value: location.data.points,
				items: [
					{
						item_id: location.data.itemId,
						item_name:
							location.type === "voucher"
								? location.data.name
								: location.data.itemName && !location.data.categories && location.data.remaining
								? location.data.itemName.en
								: location.data.itemName && !location.data.categories
								? location.data.itemName
								: location.data.itemName.en,
						item_category:
							location.type === "item" && location.data.categories[0] === "eb96d625-28e9-442b-afa8-aed772b33299"
								? "Special Deals"
								: location.type === "donate"
								? "Share a Little Comfort"
								: location.type === "voucher"
								? "EVouchers"
								: location.type === "pregnant"
								? "Pregnant Offer"
								: "Baby Product",
						item_brand: location.data.itemBrand,
						price: location.data.points,
						quantity: 1
					}
				]
			}
		});
	}, []);

	const redeemNow = () => {
		const { data, type } = location;

		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: "add_to_cart",
			redemption_category:
				location.type === "item" && location.data.categories[0] === "eb96d625-28e9-442b-afa8-aed772b33299"
					? "Special Deals"
					: location.type === "donate"
					? "Share a Little Comfort"
					: location.type === "voucher"
					? "EVouchers"
					: location.type === "pregnant"
					? "Pregnant Offer"
					: "Baby Product",
			ecommerce: {
				currency: "MYR",
				value: location.data.points,
				items: [
					{
						item_id: location.data.itemId,
						item_name:
							location.type === "voucher"
								? location.data.name
								: location.data.itemName && !location.data.categories && location.data.remaining
								? location.data.itemName.en
								: location.data.itemName && !location.data.categories
								? location.data.itemName
								: location.data.itemName.en,
						item_category:
							location.type === "item" && location.data.categories[0] === "eb96d625-28e9-442b-afa8-aed772b33299"
								? "Special Deals"
								: location.type === "donate"
								? "Share a Little Comfort"
								: location.type === "voucher"
								? "EVouchers"
								: location.type === "pregnant"
								? "Pregnant Offer"
								: "Baby Product",
						item_brand: location.data.itemBrand,
						price: location.data.points,
						quantity: 1
					}
				]
			}
		});

		let error = null;

		if (type === "item") {
			const currentBalance = user.totalPoints - Object.values(cart).reduce((a, b) => a + b.points * b.quantity, 0);
			if (!data.active) {
				error = "This item is not available for redemption, please select another item";
			} else if (!data.remaining || data.remaining <= 0) {
				error = "Out of stock";
			} else if (!user) {
				error = "User not found, please refresh and try again";
			} else if (!user.totalPoints || user.totalPoints < data.points || currentBalance < data.points) {
				error = t("insuffpoints");
			}

			if (!error) {
				const obj = {
					id: data.id,
					quantity: 1,
					itemName: data.itemName,
					points: data.points,
					itemImage: data.itemImage,
					stock: data.remaining,
					itemId: data.itemId,
					itemBrand: data.itemBrand,
					categories: allCat.filter(cat => data.categories.includes(cat.id)).map(cat => cat.name)
				};
				addToCart(obj);
				history.push({ pathname: "/order/addtocart", type: location.type, data: location.data });
			} else {
				setShow(error);
			}
		} else if (type === "pregnant") {
			const currentBalance = user.totalPoints - Object.values(cart).reduce((a, b) => a + b.points * b.quantity, 0);
			if (!data.active) {
				error = "This item is not available for redemption, please select another item";
			} else if (!data.remaining || data.remaining <= 0) {
				error = "Out of stock";
			} else if (!user) {
				error = "User not found, please refresh and try again";
			} else if (!user.totalPoints || user.totalPoints < data.points || currentBalance < data.points) {
				error = t("insuffpoints");
			}

			if (!error) {
				const obj = {
					id: data.id,
					quantity: 1,
					itemName: data.itemName,
					points: data.points,
					itemImage: data.itemImage,
					itemBrand: data.itemBrand,
					stock: data.remaining,
					itemId: data.itemId
				};
				addToCart(obj);
				history.push({ pathname: "/order/addtocart", type: location.type, data: location.data });
			} else {
				setShow(error);
			}
		} else if (type === "donate") {
			setVoucherConfirm(true);
		} else {
			if (!data.active) {
				error = t("thisitem");
			} else if (!data.available) {
				error = t("outofstock");
			} else if (!user) {
				error = t("usernotfound");
			} else if (!user.totalPoints || user.totalPoints < data.points) {
				error = t("insuffpoints");
			}

			if (!error) {
				setVoucherConfirm(true);
			} else {
				setShow(error);
			}
		}
	};

	const handleVoucherConfirm = () => {
		const { id } = user;
		if (location.type === "voucher") {
			const voucherId = location.data.id;
			const data = {
				id,
				voucherId
			};

			window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object
			window.dataLayer.push({
				event: "purchase",
				redemption_category:
					location.type === "item" && location.data.categories[0] === "eb96d625-28e9-442b-afa8-aed772b33299"
						? "Special Deals"
						: location.type === "donate"
						? "Share a Little Comfort"
						: location.type === "voucher"
						? "EVouchers"
						: location.type === "pregnant"
						? "Pregnant Offer"
						: "Baby Product",
				ecommerce: {
					transaction_id: voucherId, // Transaction ID (can be alphanumeric, depends on Transaction ID on the website)
					currency: "MYR", // represents points but will use MYR as currency for convenience on reports as Google Ads linked to GA4 is reported in "MYR"
					value: location.data.points, // points value
					items: [
						{
							item_id: location.data.itemId, //Product ID (can be alphanumeric, depends on product's ID  on the website)
							item_name:
								location.type === "voucher"
									? location.data.name
									: location.data.itemName
									? location.data.itemName
									: location.data.itemName.en,
							item_category:
								location.type === "item" && location.data.categories[0] === "eb96d625-28e9-442b-afa8-aed772b33299"
									? "Special Deals"
									: location.type === "donate"
									? "Share a Little Comfort"
									: location.type === "voucher"
									? "EVouchers"
									: location.type === "pregnant"
									? "Pregnant Offer"
									: "Baby Product",
							item_brand: location.data.itemBrand,
							price: location.data.points,
							quantity: 1
						}
					]
				}
			});

			redeemVouc(data, resId => history.push({ pathname: "/voucherorder", data: resId }));
		} else {
			const donateId = location.data.id;
			const data = {
				id,
				donateId
			};
			window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object
			window.dataLayer.push({
				event: "purchase",
				redemption_category:
					location.type === "item" && location.data.categories[0] === "eb96d625-28e9-442b-afa8-aed772b33299"
						? "Special Deals"
						: location.type === "donate"
						? "Share a Little Comfort"
						: location.type === "voucher"
						? "EVouchers"
						: location.type === "pregnant"
						? "Pregnant Offer"
						: "Baby Product",
				ecommerce: {
					transaction_id: donateId, // Transaction ID (can be alphanumeric, depends on Transaction ID on the website)
					currency: "MYR", // represents points but will use MYR as currency for convenience on reports as Google Ads linked to GA4 is reported in "MYR"
					value: location.data.points, // points value
					items: [
						{
							item_id: location.data.itemId, //Product ID (can be alphanumeric, depends on product's ID  on the website)
							item_name: location.data.itemName ? location.data.itemName : location.data.itemName.en,
							item_category:
								location.type === "item" && location.data.categories[0] === "eb96d625-28e9-442b-afa8-aed772b33299"
									? "Special Deals"
									: location.type === "donate"
									? "Share a Little Comfort"
									: location.type === "voucher"
									? "EVouchers"
									: location.type === "pregnant"
									? "Pregnant Offer"
									: "Baby Product",
							item_brand: location.data.itemBrand,
							price: location.data.points,
							quantity: 1
						}
					]
				}
			});

			donatePoints(data, () => setDonateSuccess(true));
		}
		setVoucherConfirm(false);
	};

	if (!location.data) {
		return <Redirect to="/pointshop" />;
	} else {
		return (
			<div id="reward-detail">
				<Header />
				<div className="content">
					<ContentHeader title={t("pointshoplow")} />

					<div id="detail-container">
						<div className="detail-img-container">
							<img
								src={
									location.type === "voucher"
										? location.data.imageUrl
										: location.type === "item" || location.type === "pregnant"
										? location.data.itemImage[pageReducer.language]
										: location.data.itemImage
								}
								alt="item"
								className="img-fluid detail-img"
							/>
						</div>

						<div className="detail-data-container">
							<div className="detail-data">
								<h2 className="detail-title">
									{location.type === "voucher"
										? location.data.name
										: location.type === "item" || location.type === "pregnant"
										? location.data.itemName[pageReducer.language]
										: location.data.itemName}
								</h2>
								<div className="detail-point">
									<img
										src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star-2.png"
										alt="point"
										className="img-fluid"
									/>
									<h3 className="m-0 p-0 point ms-2">{location.data.points} PT</h3>
								</div>
							</div>

							<div className="detail-description">
								{location.type !== "voucher" ? (
									<p className="m-0 p-0 item-description">
										{location.type === "item" || location.type === "pregnant"
											? location.data.description[pageReducer.language]
											: t("donateDescription")}
									</p>
								) : location.type === "voucher" && location.data.name !== "Pinkfong Bounce Wonderland Ticket" ? (
									<ul className="text-start">
										<li>
											{t("validuntil")} 31/12/{new Date().getFullYear()}
										</li>
										<li>
											{t("applicable")} <span className="fw-bold text-decoration-underline">{t("allprod")}</span>{" "}
											{t("purchat")}{" "}
											<span
												className="link-primary fw-bold text-decoration-underline"
												onClick={() => (window.location.href = "https://www.vindamall.com")}
											>
												www.vindamall.com
											</span>
										</li>
										<li>{t("eachvouc")}</li>
										<li>{t("nonreturn")}</li>
										<li>{t("freeshiip")}</li>
									</ul>
								) : 
								<div>
									<p style={{textAlign: 'left'}}>{t("pinkFongDetails1")}</p>
									<p style={{textAlign: 'left'}}>{t("pinkFongDetails2")}</p>
									<p style={{textAlign: 'left'}}>{t("pinkFongDetails3")}</p>
									<ul className="text-start">
										<li>{t("pinkFongDetails4")}</li>
										<li>{t("pinkFongDetails5")}</li>
										<li>{t("pinkFongDetails6")}</li>
										<li>{t("pinkFongDetails7")}</li>
										<li>{t("pinkFongDetails8")}<span style={{cursor: 'pointer'}} className="link-primary fw-bold text-decoration-underline" onClick={() => (window.location.href = "https://www.ticket2u.com.my/event/33221/bounce-wonderland")}>{t("pinkFongDetails8a")}</span>{t("pinkFongDetails8b")}</li>
										<li>{t("pinkFongDetails9")}</li>
										<li>{t("pinkFongDetails10")}</li>
									</ul>
									<p style={{textAlign: 'left', margin: '0'}}>{t("pinkFongStep4")}</p>
									<p style={{textAlign: 'left', margin: '0'}}>{t("pinkFongStep5")}</p>
									<p style={{textAlign: 'left', margin: '0'}}>{t("pinkFongStep6")}</p>
									<p style={{textAlign: 'left', margin: '0'}}>{t("pinkFongStep7")}</p>
								</div>
							}

								<div className="text-center mt-5">
									<Loader
										component={
											<button type="button" className="btn redeem-button" onClick={redeemNow}>
												{location.type === "donate" ? t("donateNow") : t("redeemnowcap")}
											</button>
										}
									/>
								</div>
							</div>
						</div>
					</div>

					<div id="detail-desktop">
						<ItemPreview history={history} title={t("moreinps")} />
						<Explore />
						<NeverBe />
						<Footer />
					</div>
				</div>

				<Modal show={show} onHide={() => setShow(null)} centered>
					<Modal.Body className="text-center">
						<div>
							<p className="addToCartTitle">{t("error")}</p>
							<p className="mt-3 pickSampleModalText">{show}</p>
						</div>

						<div>
							<button className="pickAddressConfirmButton" onClick={() => setShow(null)}>
								OKAY
							</button>
						</div>
					</Modal.Body>
				</Modal>

				<Modal centered show={voucherConfirm} onHide={() => setVoucherConfirm(false)}>
					<Modal.Body>
						<div className="text-center">
							<img
								style={{ width: "40%" }}
								alt="Cart_icon"
								src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/cart.png"
							></img>
							<h6 className="addToCartTitle">{location.type === "donate" ? t("donateNow") : t("redeemnowcap")}?</h6>
							<p className="pickSampleModalText">{t("confirmRedeemDesc")}</p>

							<Loader
								component={
									<div className="text-center">
										<Button
											style={{ backgroundColor: "#FEA30B", width: "100%", marginTop: "0.5rem" }}
											onClick={handleVoucherConfirm}
										>
											{t("yescap")}
										</Button>
										<Button
											style={{ backgroundColor: "#223C96", width: "100%", marginTop: "0.5rem" }}
											onClick={() => setVoucherConfirm(false)}
										>
											{t("maybecap")}
										</Button>
									</div>
								}
							/>
						</div>
					</Modal.Body>
				</Modal>

				<Modal show={pageReducer.error} onHide={() => clearError()} centered>
					<Modal.Body className="text-center">
						<div>
							<p className="addToCartTitle">{t("error")}</p>
							<p className="mt-3 pickSampleModalText">{pageReducer.error}</p>
						</div>

						<div>
							<button className="pickAddressConfirmButton" onClick={() => clearError()}>
								OKAY
							</button>
						</div>
					</Modal.Body>
				</Modal>

				<Modal
					centered
					show={donateSuccess}
					onHide={() => {
						setDonateSuccess(false);
						history.push("/dashboard");
					}}
				>
					<Modal.Body>
						<div className="text-center">
							<img
								style={{ width: "40%" }}
								alt="Cart_icon"
								src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/cart.png"
							></img>
							<h6 className="addToCartTitle">Thank You!</h6>
							<p className="pickSampleModalText">
								You have donated your points successfully, we are grateful for your support
							</p>

							<Loader
								component={
									<div className="text-center">
										<button
											className="pickAddressConfirmButton"
											onClick={() => {
												setDonateSuccess(false);
												history.push("/dashboard");
											}}
										>
											OKAY
										</button>
									</div>
								}
							/>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		cart: state.userReducer.cart,
		pageReducer: state.pageReducer,
		allCat: state.userReducer.catalogue.categories
	};
};

const mapDispatchToProps = dispatch => {
	return {
		addToCart: data => dispatch(addToCart(data)),
		redeemVouc: (data, callback) => dispatch(redeemVouc(data, callback)),
		clearError: () => dispatch(clearError()),
		donatePoints: (data, callback) => dispatch(donatePoints(data, callback))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardDetail);
