import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { addNews } from "../../../../redux/actions/adminActions";

const AddNews = ({ addNews, history }) => {
	const [data, setData] = useState({
		link: "",
		title: "",
		description: "",
		bmTitle: "",
		bmDescription: "",
		showingTo: "",
	});

	const [file, setFile] = useState(null);
	const [bmFile, setBmFile] = useState(null);
	// const [radioOption, setRadioOption] = useState(null);
	const [radioOption, setRadioOption] = useState("A"); 

	const handleChange = e => {
		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleRadioChange = (event) => {
		setRadioOption(event.target.value);
	  };

	const handleImage = e => setFile(e.target.files[0]);
	const handleBmImage = e => setBmFile(e.target.files[0]);

	const handleSubmit = e => {
		e.preventDefault();
		if ( file ) {
			const formData = new FormData();
			formData.append("link", data.link);
			formData.append("title", data.title);
			formData.append("description", data.description);
			formData.append("file", file);
			formData.append("bmTitle", data.bmTitle);
			formData.append("bmDescription", data.bmDescription);
			formData.append("bmFile", bmFile);
			formData.append("showingTo", data.showingTo)
			addNews(formData, err => {
				if (err) alert(err);
				else history.push("/admin/news/dashboard");
			});
		} else {
			alert("Please fill in all the fields in the form");
		}
	};

	const renderForm = () => {
		if (radioOption === "A") {
		  return (
			 <form onSubmit={handleSubmit}>

					<div className="form-group">
						<label htmlFor="title">Title</label>
						<input
							type="text"
							id="title"
							name="title"
							className="form-control"
							placeholder="News Title"
							value={data.title}
							onChange={handleChange}
							required
						/>
					</div>

					<div className="form-group mt-3">
						<label htmlFor="description">Description</label>
						<textarea
							name="description"
							id="description"
							className="form-control"
							placeholder="News Description"
							value={data.description}
							onChange={handleChange}
							required
						></textarea>
					</div>

					<div className="form-group mt-3">
						<label htmlFor="file">Image</label>
						<input type="file" name="file" id="file" className="form-control" accept="image/*" onChange={handleImage} required />
					</div>

					<div className="form-group mt-3">
								<label htmlFor="showingTo">Showing To?</label>
								<select id="showingTo" name="showingTo" className="form-select" value={data.showingTo} onChange={handleChange} required>
									<option value="" disabled>
										Select user stage
									</option>
									<option value="All">All stage</option>
									<option value="pregnant">Pregnant</option>
									<option value="trying">Trying</option>
									<option value="oneChild">oneChild</option>
									<option value="moreChild">moreChild</option>
								</select>
							</div>

					<hr className="my-5" />

					<div className="form-group">
						<label htmlFor="bmTitle">Title (Bahasa)</label>
						<input
							type="text"
							id="bmTitle"
							name="bmTitle"
							className="form-control"
							placeholder="News Title"
							value={data.bmTitle}
							onChange={handleChange}
							required
						/>
					</div>

					<div className="form-group mt-3">
						<label htmlFor="bmDescription">Description (Bahasa)</label>
						<textarea
							name="bmDescription"
							id="bmDescription"
							className="form-control"
							placeholder="News Description"
							value={data.bmDescription}
							onChange={handleChange}
							required
						></textarea>
					</div>

					<div className="form-group mt-3">
						<label htmlFor="bmFile">Image (BM)</label>
						<input type="file" name="bmFile" id="bmFile" className="form-control" accept="image/*" onChange={handleBmImage} required />
					</div>

					<div className="form-group mt-5 text-center">
						<Loader component={<input type="submit" value="SUBMIT" className="form-control btn btn-primary" />} />
					</div>
				</form>
		  );
		} else if (radioOption === "B") {
		  return (
			<form onSubmit={handleSubmit}>

						<div className="form-group">
								<label htmlFor="link">Link</label>
								<input
									type="text"
									id="link"
									name="link"
									className="form-control"
									placeholder="News Link"
									value={data.link}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="file">Image</label>
								<input type="file" name="file" id="file" className="form-control" accept="image/*" onChange={handleImage} required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="showingTo">Showing To?</label>
								<select id="showingTo" name="showingTo" className="form-select" value={data.showingTo} onChange={handleChange} required>
									<option value="" disabled>
										Select user stage
									</option>
									<option value="All">All stage</option>
									<option value="pregnant">Pregnant</option>
									<option value="trying">Trying</option>
									<option value="oneChild">oneChild</option>
									<option value="moreChild">moreChild</option>
								</select>
							</div>

							<hr className="my-5" />

							<div className="form-group mt-3">
								<label htmlFor="bmFile">Image (BM)</label>
								<input type="file" name="bmFile" id="bmFile" className="form-control" accept="image/*" onChange={handleBmImage} required />
							</div>

							<div className="form-group mt-5 text-center">
								<Loader component={<input type="submit" value="SUBMIT" className="form-control btn btn-primary" />} />
							</div>
			</form>
		  );
		} 
		else {
		  return null;
		}
	  };

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Add News</h3>

					<div className="mt-5">
					<label htmlFor="radioA">Normal News</label>
					<input type="radio" name="radioOption" id="radioA" value="A" checked={radioOption === "A"} onChange={handleRadioChange} /><br></br>
					<label htmlFor="radioB">Link News</label>
					<input type="radio" name="radioOption" id="radioB" value="B" checked={radioOption === "B"} onChange={handleRadioChange} /><br></br>

					{renderForm()}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		addNews: (data, callback) => dispatch(addNews(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(AddNews);
