import React, { useEffect, useState } from "react";
import "./MysteryBoxHome.css";
import mysteryBoxHomeTitle from "../../../assets/newMysteryBoxHomeTitle.png";
import kotakMisteriRumahTajuk from "../../../assets/kotakMisteriRumahTajuk.png";
import uploadLogo from "../../../assets/uploadLogo.png";
import clickLogo from "../../../assets/clickLogo.png";
import { NavLink, useHistory } from "react-router-dom";
import keyInLogo from "../../../assets/keyInLogo.png";
import giftBox from "../../../assets/giftBox.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import MysteryBoxPrize from "../../../component/mysteryBox/MysteryBoxPrize";
import MysteryBoxHowToWin from "../../../component/mysteryBox/MysteryBoxHowToWin";
import { Modal } from "react-bootstrap";
import congrats from "../../../assets/newCongrats.png";
import tahniah from "../../../assets/tahniah.png";
import barrierGiftBox from "../../../assets/barrierGiftBox.png";
import headerGiftBox from "../../../assets/newHeaderGiftbox.png";
import Spinner from "react-bootstrap/Spinner";
import backButton from "../../../assets/BackButton.png";
import desktopWhiteBarrier from "../../../assets/desktopWhiteBarrier.png";
import desktopScrollContainer from "../../../assets/desktopScrollContainer.png";
import {
  getMysteryBox,
  redeemMysteryBox,
} from "../../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../component/dashboard/Header";
import { useTranslation } from "react-i18next";

function MysteryBoxHome() {
  const { t } = useTranslation();
  const history = useHistory();
  const [showMysteryBoxModal, setShowMysteryBoxModal] = useState(false);
  const [key, setKey] = useState("prize");
  const [mysteryBoxCount, setMysteryBoxCount] = useState(0);
  const [mysteryBoxRedeemCount, setMysteryBoxRedeemCount] = useState(0);
  const dispatch = useDispatch();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [rewardWon, setRewardWon] = useState({
    points: 0,
    imageUrl: "",
    title: "",
    type: "",
  });
  const closeMysteryBoxModal = () => {
    setShowMysteryBoxModal(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await dispatch(getMysteryBox());
        if (res) {
          setMysteryBoxCount(res.data?.availableEntries);
          setIsDataLoaded(true);
          setMysteryBoxRedeemCount(res.data?.usedEntries);
        }
      } catch (error) {
        console.error(error);
        console.error(error.response);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    if (mysteryBoxCount > 0) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [isDataLoaded]);

  const [openNowDisabled, setOpenNowDisabled] = useState(false);
  const [openNowModalDisabled, setOpenNowModalDisabled] = useState(false);
  const [openNowLoading, setOpenNowLoading] = useState(false);
  const [openNowLoadingModal, setOpenNowLoadingModal] = useState(false);
  let mysteryBoxCampaignId = useSelector(
    (state) => state.userReducer.campaignId
  );

  const voucherName = {
    enVinda: "RM15 Vindamall eVoucher",
    bmVinda: "RM15 Vindamall eBaucar",
    enAeon: "RM10 AEON Voucher",
    bmAeon: "RM10 AEON Baucar",
  }

  const language = useSelector((state) => state?.pageReducer?.language);

  const goBack = () => {
    // Navigate back one step in history
    history.goBack();
  };

  const handleOpenNow = () => {
    setOpenNowLoading(true);
    setOpenNowDisabled(true);
    (async () => {
      try {
        const campaignId = mysteryBoxCampaignId;
        const res2 = await dispatch(
          redeemMysteryBox({ campaignId: campaignId })
        );
        if (res2) {
          setRewardWon({
            points: res2.data?.type === "POINTS" ? +res2.data?.name : "",
            imageUrl: res2.data?.image,
            title: res2.data?.name,
            type: res2.data?.type,
          });
          setShowMysteryBoxModal(true);
          setOpenNowLoading(false);
          setOpenNowDisabled(false);
          setMysteryBoxCount(mysteryBoxCount - 1);
          setMysteryBoxRedeemCount(mysteryBoxRedeemCount + 1);
        } else {
          setOpenNowLoading(false);
          setOpenNowDisabled(false);
          console.error("Failed to redeem reward");
        }
      } catch (error) {
        console.error(error);
        console.error(error.response);
        setOpenNowLoading(false);
        setOpenNowDisabled(false);
      }
    })();
  };

  const handleOpenNowModal = () => {
    setOpenNowLoadingModal(true);
    setOpenNowModalDisabled(true);
    (async () => {
      try {
        const campaignId = mysteryBoxCampaignId;
        const res2 = await dispatch(
          redeemMysteryBox({ campaignId: campaignId })
        );
        if (res2) {
          setRewardWon({
            points: res2.data?.type === "POINTS" ? +res2.data?.name : "",
            imageUrl: res2.data?.image,
            title: res2.data?.name,
            type: res2.data?.type,
          });
          setShowMysteryBoxModal(true);
          setOpenNowLoadingModal(false);
          setOpenNowModalDisabled(false);
          setMysteryBoxCount(mysteryBoxCount - 1);
          setMysteryBoxRedeemCount(mysteryBoxRedeemCount + 1);
        } else {
          setOpenNowLoadingModal(false);
          setOpenNowModalDisabled(false);
          console.error("Failed to redeem reward");
        }
      } catch (error) {
        console.error(error);
        console.error(error.response);
        setOpenNowLoadingModal(false);
        setOpenNowModalDisabled(false);
      }
    })();
  };
  return (
    <div className="mystery-box-home-background">
      <div className="actual-header-mystery-box">
        <Header></Header>
      </div>
      <img
        onClick={() => goBack()}
        className="mystery-box-back-button"
        src={backButton}
        alt="back-button"
      ></img>
      <header className="mystery-box-home-header">
        <img
          src={headerGiftBox}
          className="mystery-box-home-header-giftbox"
          alt="header-giftbox"
        ></img>
        <img
          src={language === "en" ? mysteryBoxHomeTitle : kotakMisteriRumahTajuk}
          className="mystery-box-home-header-title"
          alt="mystery-box-home-title"
        ></img>
        <div className="mystery-box-home-header-description">
          {t("mysteryHomeTitleDesc")}
          <NavLink
            to="/tnc"
            className="mystery-box-home-header-term-and-condition"
          >
            {t("tncWord")}
          </NavLink>
        </div>
        <img
          src={barrierGiftBox}
          className="mystery-box-home-barrier-giftbox"
          alt="barrier-giftbox"
        ></img>
        <div className="mystery-box-home-barrier">
          <div className="mystery-box-home-body-title-container">
            <div className="mystery-box-home-body-title-double">
              <h3 className="mystery-box-home-body-title">
                {t("entryEarned")}
              </h3>
              <h3 className="mystery-box-home-body-title">
                {t("forGrandPrize")}
              </h3>
            </div>
            <div className="mystery-box-home-body-coin-logo">
              {mysteryBoxRedeemCount}
            </div>
          </div>
        </div>
        <div className="mystery-box-home-body-inside-header">
          <div className="mystery-box-home-body-scroll-container">
            <img
              src={desktopScrollContainer}
              className="mystery-box-home-body-scroll-container-background"
              alt="desktop-scroll-container"
            ></img>
            <div className="mystery-box-home-body-scroll-tabs-container">
              <Tabs
                className="mb-3 mystery-box-home-body-scroll-tabs"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab
                  eventKey="prize"
                  title={t("prize")}
                  className="mystery-box-home-body-scroll-tab-item"
                >
                  <MysteryBoxPrize />
                </Tab>
                <Tab
                  eventKey="howtowin"
                  title={t("howToWin")}
                  className="mystery-box-home-body-scroll-tab-item"
                >
                  <MysteryBoxHowToWin />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>

        <div className="mystery-box-home-body-scroll-desktop-button">
          <NavLink
            to="/scancode"
            className="mystery-box-home-footer-button desktop-navigate-button"
          >
            <img
              src={keyInLogo}
              alt="upload-logo"
              className="mystery-box-home-footer-button-logo"
            ></img>
            <p className="mystery-box-home-footer-button-text">
              {t("keyInUniqueCode")}
            </p>
            <img
              src={clickLogo}
              alt="click-logo"
              className="mystery-box-home-footer-button-logo"
            ></img>
          </NavLink>
          <NavLink
            to="/uploadReceipt"
            className="mystery-box-home-footer-button desktop-navigate-button"
          >
            <img
              src={uploadLogo}
              alt="upload-logo"
              className="mystery-box-home-footer-button-logo"
            ></img>
            <p className="mystery-box-home-footer-button-text">
              {t("uploadBabyCare")}
            </p>
            <img
              src={clickLogo}
              alt="click-logo"
              className="mystery-box-home-footer-button-logo"
            ></img>
          </NavLink>
        </div>
      </header>
      <div className="mystery-box-home-body">
        <div className="mystery-box-home-body-scroll-container">
          <img
            src={desktopScrollContainer}
            className="mystery-box-home-body-scroll-container-background"
            alt="desktop-scroll-container"
          ></img>
          <div className="mystery-box-home-body-scroll-tabs-container">
            <Tabs
              className="mb-3 mystery-box-home-body-scroll-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab
                eventKey="prize"
                title={t("prize")}
                className="mystery-box-home-body-scroll-tab-item"
              >
                <MysteryBoxPrize />
              </Tab>
              <Tab
                eventKey="howtowin"
                title={t("howToWin")}
                className="mystery-box-home-body-scroll-tab-item"
              >
                <MysteryBoxHowToWin />
              </Tab>
            </Tabs>
          </div>
          {key === "prize" ? (
            <div className="mystery-box-home-body-scroll-desktop-button">
              <NavLink
                to="/scancode"
                className="mystery-box-home-footer-button desktop-navigate-button"
              >
                <img
                  src={keyInLogo}
                  alt="upload-logo"
                  className="mystery-box-home-footer-button-logo"
                ></img>
                <p className="mystery-box-home-footer-button-text">
                  {t("keyInUniqueCode")}
                </p>
                <img
                  src={clickLogo}
                  alt="click-logo"
                  className="mystery-box-home-footer-button-logo"
                ></img>
              </NavLink>
              <NavLink
                to="/uploadReceipt"
                className="mystery-box-home-footer-button desktop-navigate-button"
              >
                <img
                  src={uploadLogo}
                  alt="upload-logo"
                  className="mystery-box-home-footer-button-logo"
                ></img>
                <p className="mystery-box-home-footer-button-text">
                  {t("uploadBabyCare")}
                </p>
                <img
                  src={clickLogo}
                  alt="click-logo"
                  className="mystery-box-home-footer-button-logo"
                ></img>
              </NavLink>
            </div>
          ) : null}
        </div>
      </div>
      <footer className="mystery-box-home-footer">
        <div className="mystery-box-home-circle-background"></div>
        <NavLink
          to="/scancode"
          className="mystery-box-home-footer-button navigate-button"
        >
          <img
            src={keyInLogo}
            alt="upload-logo"
            className="mystery-box-home-footer-button-logo"
          ></img>
          <p className="mystery-box-home-footer-button-text">
            {t("keyInUniqueCode")}
          </p>
          <img
            src={clickLogo}
            alt="click-logo"
            className="mystery-box-home-footer-button-logo"
          ></img>
        </NavLink>
        <NavLink
          to="/uploadReceipt"
          className="mystery-box-home-footer-button navigate-button"
        >
          <img
            src={uploadLogo}
            alt="upload-logo"
            className="mystery-box-home-footer-button-logo"
          ></img>
          <p className="mystery-box-home-footer-button-text">
            {t("uploadBabyCare")}
          </p>
          <img
            src={clickLogo}
            alt="click-logo"
            className="mystery-box-home-footer-button-logo"
          ></img>
        </NavLink>
        <div className="mystery-box-to-be-opened">
          <img
            src={giftBox}
            className="mystery-box-to-be-opened-image"
            alt="gift-box"
          ></img>
          <div className="mystery-box-to-be-opened-description">
            <p className="mystery-box-to-be-opened-count">
              {t("youHave")} {mysteryBoxCount} {t("myBoxOpen")}
            </p>
            <p className="mystery-box-already-opened-count">
              {t("youHaveOpened")} {mysteryBoxRedeemCount} {t("myBox")}
            </p>
            <Button
              variant="secondary"
              className={`mystery-box-home-footer-button ${
                openNowDisabled || mysteryBoxCount < 1
                  ? ""
                  : "flickering-button"
              }`}
              disabled={openNowDisabled || mysteryBoxCount < 1}
              onClick={handleOpenNow}
            >
              <p className="mystery-box-home-footer-button-text">
                {language === "en" ? "OPEN NOW" : "BUKA SEKARANG"}
              </p>
              {openNowLoading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : null}
            </Button>
          </div>
        </div>
      </footer>
      <Modal
        show={showMysteryBoxModal}
        onHide={closeMysteryBoxModal}
        className="mystery-box-modal"
        backdrop="static"
        centered
      >
        <Modal.Body className="text-center mystery-box-modal-body">
          <Modal.Header
            closeButton
            className="mystery-box-modal-close-button"
          ></Modal.Header>
          <div className="mystery-box-modal-content">
            <img
              src={language == "en" ? congrats : tahniah}
              className="mystery-box-modal-reward-congrats"
              alt="reward-congrats"
            ></img>
            <div className="mystery-box-modal-reward-container">
              {rewardWon.points ? (
                <div className="mystery-box-modal-win-points-container">
                  <h3 className="mystery-box-modal-win-points">
                    +{rewardWon.points}
                  </h3>
                  <h3 className="mystery-box-modal-win-points-label">
                    {language === "en" ? "points" : "mata ganjaran"}
                  </h3>
                </div>
              ) : null}
            </div>
          </div>
          <div className="mystery-box-modal-reward-container-background"></div>
          <div className="mystery-box-modal-orb-of-light"></div>
          {rewardWon.type !== "POINTS" && (
            <img
              src={rewardWon.imageUrl}
              className="mystery-box-modal-win-image"
              alt="reward-won"
            ></img>
          )}
          <h3 className="mystery-box-modal-reward-description">
            {rewardWon.type === "POINTS" || rewardWon.title === "RM5 TnG PIN" ? rewardWon.title : language === "en" ? (rewardWon.title === "RM10 AEON Voucher" ? voucherName.enAeon : voucherName.enVinda) :  (rewardWon.title === "RM10 AEON Voucher" ? voucherName.bmAeon : voucherName.bmVinda)}{" "}
            {rewardWon.type === "POINTS"
              ? language === "en"
                ? "points"
                : "mata ganjaran"
              : null}
          </h3>
          <div className="mystery-box-modal-multi-button-container">
            <Button
              className="mystery-box-modal-button"
              variant="secondary"
              onClick={handleOpenNowModal}
              disabled={openNowModalDisabled || mysteryBoxCount < 1}
            >
              {t("openAnotherBox")}
              {openNowLoadingModal ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : null}
            </Button>
            <NavLink
              className="mystery-box-modal-button"
              to={{
                pathname: "/pointshop",
                state: { origin: "mysterybox" },
              }}
            >
              {t("viewMyReward")}
            </NavLink>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MysteryBoxHome;
