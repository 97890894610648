import { useState, useEffect, useMemo } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { connect } from "react-redux";
import { MdAddCircleOutline } from "react-icons/md";
import axios from "axios";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import Loader from "../../../component/Loader";
import { newsDashboardColumns } from "../../../config/mdbColumns";
import { Modal } from "react-bootstrap";

const NewsDashboard = ({ loading, loaded, history, token }) => {
	const [data, setData] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [id, setId] = useState(null);
	const handleShow = () => setShowModal(true);
	const handleClose = () => setShowModal(false);

	useEffect(() => {
		loading();
		axios
			.get("/drypers-baby-club/api/news/fetch", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			})
			.then(res => {
				setData(res.data);
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const truncateText = text => (text && text.length > 40 ? text.substr(0, 40) + "..." : text);

	const handleActive = id => {
		axios
			.post(
				"/drypers-baby-club/api/admin/setting/news/show",
				{ id },
				{
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			)
			.then(() => {
				setData(
					data.map(x => {
						if (x.id !== id) {
							return x;
						} else {
							return {
								...x,
								active: !x.active
							};
						}
					})
				);
			})
			.catch(err => alert(err.response.data.error));
	};

	const handleRemove = (id) => {
		handleShow();
		axios
		  .delete(`/drypers-baby-club/api/admin/setting/news/remove/${id}`, {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  })
		  .then(() => {
			setData(data.filter((x) => x.id !== id));
		  })
		  .catch((err) => alert(err.response.data.error));
	  };
	
	const tableData = useMemo(() => {
		return {
			columns: newsDashboardColumns,
			rows: data.map(news => {
				return {
					...news,
					image: <img src={news.image.en} alt="news" className="img-fluid" style={{ height: "80px", width: "auto" }} />,
					title: news.title.en,
					description: truncateText(news.description.en),
					bmTitle: news.title.bm,
					bmDescription: truncateText(news.description.bm),
					bmImage: <img src={news.image.bm} alt="news" className="img-fluid" style={{ height: "80px", width: "auto" }} />,
					link: news.link,
					createdAt: new Date(news.createdAt).toLocaleString("en-GB"),
					updatedAt: new Date(news.updatedAt).toLocaleString("en-GB"),
					active: (
						<input
							type="checkbox"
							checked={news.active}
							value={news.active}
							onChange={() => handleActive(news.id)}
							className="form-check-input"
						/>
					),
					edit: (
						<button type="button" className="btn btn-info" onClick={() => history.push({ pathname: "/admin/news/edit", data: news })}>
							Edit
						</button>
					),
					delete: (
						<button type="button" className="btn btn-info" onClick={() => { setShowModal(true); setId(news.id); }}>
						  Delete
						</button>
					  ),
					  
				};
			})
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>News Dashboard</h3>

					<button type="button" className="mt-3 btn btn-primary" onClick={() => history.push("/admin/news/add")}>
						<MdAddCircleOutline className="me-2" />
						Add News
					</button>

					<div className="mt-5 card">
						<div className="card-header">
							<h5 className="card-title">News</h5>
						</div>

						<div className="card-body text-center">
							<Loader component={<MDBDataTableV5 data={tableData} striped hover responsive bordered />} />
						</div>
					</div>
				</div>
			</div>


		<Modal show={showModal} onHide={handleClose} dialogClassName="modal-90w" centered>
        <Modal.Body className="text-center">
          <div>
            <h1 style={{ paddingLeft: "30px", paddingRight: "30px", fontSize: "20px" }} className="dashboardNewsTitle">
              Are you sure you want to delete this News?
            </h1>
			<button
			type="button"
			className="btn btn-info"
			onClick={() => {
				handleRemove(id);
				handleClose();
			}}
			>
			Yes
			</button>
            <button
              style={{ marginLeft: "10px" }}
              type="button"
              className="btn btn-info"
              onClick={handleClose}
            >
              No
            </button>
          </div>
        </Modal.Body>
       </Modal>

		</div>
	);
};

const mapStateToProps = state => {
	return {
		token: state.userReducer.token
	};
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsDashboard);
