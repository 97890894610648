import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OrderHistory = ({ user, language }) => {
	const { t } = useTranslation();
	return (
		<div id="order-history-container">
			{user && user.redemptions && user.redemptions.length
				? user.redemptions.map(redemption => (
						<Link
							key={redemption.id}
							className="order-history-item"
							to={{ pathname: "/order/track", orderId: redemption.id, type: "exist" }}
							style={{ textDecoration: "none", color: "#223C96" }}
						>
							<div className="info-container">
								<p className="m-0 p-0">
									{t("orderid")}: {redemption.id.split("-")[0]}
								</p>
								<p className="m-0 p-0">{t("prodpurc")}:</p>
								<ul>
									{redemption.orderItems.map(item => (
										<li key={item.id} className="order-history-orderItems">
											<p className="m-0 p-0">
												{item.itemName[language]} x{item.quantity}
											</p>
											<p className="m-0 p-0">
												{item.points}
												{t("pts")}
											</p>
										</li>
									))}
								</ul>
							</div>
							<div className="date-container">
								<p className="m-0 p-0">{t("orderdate")}</p>
								<p>{new Date(redemption.createdAt).toLocaleString("en-GB")}</p>
							</div>
						</Link>
				  ))
				: null}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		language: state.pageReducer.language
	};
};

export default connect(mapStateToProps)(OrderHistory);
