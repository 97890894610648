import { useEffect } from "react";
import Header from "../../../component/dashboard/Header";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const SignupSuccess = ({ history, match, location, language }) => {
	const {
		uuid,
		name,
		number,
		email,
		dob,
		gender,
		race,
		pregnancyStage,
		state,
		referCode,
		edb,
		sampleName,
		children,
		sampleSizeId,
		orderId,
		uniqueToken
	} = location.userObj;

	useEffect(() => {
		const currentDate = new Date();
		const futureDate = new Date(currentDate.getFullYear() + 7999, 11, 31); // Expires on December 31, 9999

		document.cookie = `registrationToken=${uniqueToken}; expires=${futureDate.toUTCString()}; path=/`;
	}, []);

	useEffect(() => {
		if (!location.success) history.push(`/${language}/signin`);
		else {
			window.dataLayer.push({
				userId: uuid,
				event: "conversion",
				eventProps: {
					send_to: "AW-773450053/f6g6CNn5ga4DEMXS5_AC"
				},
				gtm: {
					uniqueEventId: 13292
				}
			});

			window.Insider.track("events", [
				{
					event_name: "free_sample_request",
					// timestamp: new Date().toISOString(),
					event_params: {
						// product_id: location.item.itemId,
						// name: location.item.itemName.en,
						// taxonomy: [location.item.brand, location.item.format],
						size: sampleSizeId,
						// stock: location.item.remaining,
						// product_image_url: location.item.itemImage.en
						custom: {
							order_number: orderId,
							product_name: sampleName
						}
					}
				}
			]);

			// window.Insider.track("events", [
			// 	{
			// 		event_name: "Conversion",
			// 		timestamp: new Date().toISOString(),
			// 		event_params: {}
			// 	}
			// ]);

			const beautifyNumber = number => {
				if (number[0] !== "0") {
					// is not 0
					return "+" + number;
				} else {
					// is starting with 0
					return "+6" + number;
				}
			};

			const bd = new Date(dob);
			const year = bd.getFullYear();
			const month = (bd.getMonth() + 1).toString().length === 1 ? "0" + (bd.getMonth() + 1) : bd.getMonth() + 1;
			const day = bd.getDate().toString().length === 1 ? "0" + bd.getDate() : bd.getDate();
			const birthday = `${year}-${month}-${day}`;
			const duedate = edb || null;
			// const yearedb = db.getFullYear();
			// const monthedb = (db.getMonth() + 1).toString().length === 1 ? "0" + (db.getMonth() + 1) : db.getMonth() + 1;
			// const dayedb = db.getDate().toString().length === 1 ? "0" + db.getDate() : db.getDate();
			// const momduedate = `${yearedb}-${monthedb}-${dayedb}`;
			const transaction_count = 0;
			const has_transacted = false;
			const gdpr_optin = true;
			const email_optin = true;
			const phone_number = beautifyNumber(number);
			const sms_optin = true;
			const whatsapp_optin = true;
			const returning = false;
			const custom = {
				pregnancy_stage: pregnancyStage,
				race,
				user_code: referCode,
				point_balance: 0,
				state
			};

			if (duedate) {
				const db = new Date(duedate).toISOString();
				custom.mom_due_date = db;
			}

			if (sampleName && sampleSizeId && orderId) {
				custom.c_sample_request = sampleName;
			}

			const today = new Date();

			if (children) {
				for (let i = 0; i < children.length; i++) {
					const babyInfo = children[i];

					const num = i + 1;
					const dobKey = `baby_dob_${num}`;
					const ageKey = `baby_age_${num}`;

					const babyDob = new Date(babyInfo.childDob).toISOString();
					const babyAge = parseInt((today - new Date(babyInfo.childDob)) / (1000 * 60 * 60 * 24 * 30));
					custom[dobKey] = babyDob;
					custom[ageKey] = babyAge;
				}
			}

			window.insider_object = window.insider_object || {};
			window.insider_object.user = {
				uuid,
				gender,
				birthday,
				has_transacted,
				transaction_count,
				gdpr_optin,
				name,
				email,
				email_optin,
				phone_number,
				sms_optin,
				whatsapp_optin,
				language,
				returning,
				custom
			};
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const { t } = useTranslation();
	if (!location.success) return <Redirect to={`/${language}/signin`} />;
	else {
		return (
			<div>
				<Header history={history} match={match} location={location} />

				<div className="content" style={{ paddingBottom: "100px" }}>
					<img
						src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/landingPage/headerImg.png"
						alt="header"
						className="img-fluid"
						style={{ width: "100%", height: "auto" }}
					/>

					<div className="my-5">
						<div className="text-center m-auto px-4" style={{ maxWidth: "500px" }}>
							<h1 className="fw-bold dbc-title">{t("thankyouRegister")}</h1>
							<h3 className="my-5">{t("youcanlogin")}</h3>
						</div>

						<div className="vinda-form my-5">
							<div className="vinda-form-group text-center form-group">
								<button
									type="button"
									id="after-register-button"
									className="vinda-form-submit btn"
									onClick={() => history.push(`/${language}/signin`)}
								>
									OKAY
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		language: state.pageReducer.language
	};
};

export default connect(mapStateToProps)(SignupSuccess);
