import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
// Components
import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
//Assets
//Redux
import { connect } from "react-redux";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import { useTranslation } from "react-i18next";

const PickSample = ({ sample, user, history }) => {
	const { t } = useTranslation();
	const [redeemedModal, setRedeemedModal] = useState(false);
	const [feedbackModal, setFeedbackModal] = useState(false);
	const [acknowledge, setAcknowledge] = useState(false);

	useEffect(() => {
		if (!user.allowRequest && !user.promptFeedback) setRedeemedModal(true);
		else if (!user.allowRequest && user.promptFeedback) setFeedbackModal(true);
		else if (!user.allowRequest) setRedeemedModal(true);
		else if (user.promptFeedback) setFeedbackModal(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selectBrand = (id, brandImage) => {
		if (!user.allowRequest && !user.promptFeedback) setRedeemedModal(true);
		else if (!user.allowRequest && user.promptFeedback) setFeedbackModal(true);
		else if (!user.allowRequest) setRedeemedModal(true);
		else if (user.promptFeedback) setFeedbackModal(true);
		else history.push({ pathname: "/picksize", brandId: id, brandImage });
	};

	const closeFeedback = () => {
		setFeedbackModal(false);
		setAcknowledge(false);
	};

	const handleAnswer = received => history.push({ pathname: "/freesample/feedback", received });

	return (
		<div id="pick-sample">
			<Header />
			<div className="content">
				<ContentHeader title={t("freesamplelow")} />

				<div className="text-center">
					<h3 className="dbc-title">{t("getyourdrypers")}</h3>
				</div>

				<div className="pickSampleProductSlider">
					{sample &&
					sample.brands &&
					sample.data &&
					sample.availableData &&
					sample.availableData.brands &&
					sample.availableData.brands.length &&
					sample.brands.length
						? sample.brands
								.filter(brand => sample.availableData.brands.includes(brand.id))
								.map(brand => (
									<div key={brand.id} className="pickSampleProductWidthSet" onClick={() => selectBrand(brand.id, brand.brandImage)}>
										<div className="brand-img-container">
											<img src={brand.brandImage} alt="brand" className="img-fluid brand-img" />
										</div>

										<div className="brand-name-container">
											<h4 className="m-0 p-0 dbc-title">{brand.name}</h4>
										</div>
									</div>
								))
						: null}
				</div>

				<div className="freesample-tnc">
					<h3 className="fw-bold">{t("drysamplereq")}</h3>
					<p>
						{t("offerschild")}
						<br />
						{t("tryiton")}
					</p>
					<h4>
						{t("terms")} &amp; {t("condition")}
					</h4>
					<ul>
						<li>{t("onlyonesample")}</li>
						<li>{t("additional")}</li>
						{/* <li>{t("multiple")}</li> */}
						<li>{t("validaddress")}</li>
						<li>{t("incompleteentries")}</li>
						<li>{t("timetaken")}</li>
						<li>{t("righttopostpone")}</li>
					</ul>
				</div>
				<Footer />
			</div>

			<Modal show={redeemedModal} onHide={() => setRedeemedModal(false)} centered>
				<Modal.Body className="text-center">
					<div>
						<p className="addToCartTitle">{t("sorry")}!</p>
						<p className="mt-3 pickSampleModalText">{t("youalreadyre")}</p>
					</div>

					<div>
						<button className="pickAddressConfirmButton" onClick={() => setRedeemedModal(false)}>
							OKAY
						</button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal centered show={feedbackModal} onHide={closeFeedback}>
				<Modal.Body>
					{!acknowledge ? (
						<div className="text-center">
							<img className="img-fluid text-center" src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/asset-12-3.png" alt="love"></img>
							<br />
							<h1 className="addToCartTitle">{t("yourfeedback")}</h1>
							<p className="pickSampleModalText">{t("itseems")}</p>
							<div>
								<button className="pickAddressConfirmButton" onClick={() => setAcknowledge(true)}>
									{t("sharefeedback")}
								</button>
							</div>
						</div>
					) : (
						<div className="text-center">
							<img style={{ width: "40%" }} src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/Group-22075.png" alt="askimg"></img>
							<h6 className="addToCartTitle">{t("haveyoureceived")}</h6>
							<Button style={{ backgroundColor: "#FEA30B", width: "100%", marginTop: "0.5rem" }} onClick={() => handleAnswer(true)}>
								{t("nextButton")}
							</Button>
							{/* <Button style={{ backgroundColor: "#FEA30B", width: "100%", marginTop: "0.5rem" }} onClick={() => handleAnswer(false)}>
								{t("no")}
							</Button> */}
						</div>
					)}
				</Modal.Body>
			</Modal>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		sample: state.userReducer.samples
	};
};

export default connect(mapStateToProps)(PickSample);
