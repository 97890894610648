import { IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, SELECT_LANG, SHOW_USERID } from "../types";
import i18n from "../../config/i18n";

const initState = {
	isLoading: false,
	error: null,
	language: localStorage.getItem("language") || "en",
	userId: null,
	retailers: [],
	skus: []
};

const pageReducer = (state = initState, action) => {
	switch (action.type) {
		case IS_LOADING:
			return {
				...state,
				isLoading: true
			};

		case IS_LOADED:
			return {
				...state,
				isLoading: false
			};

		case SHOW_ERROR:
			return {
				...state,
				error: action.payload
			};

		case SHOW_USERID:
			return {
				...state,
				userId: action.payload
			};

		case CLEAR_ERROR:
			return {
				...state,
				error: null,
				userId: null
			};
		case SELECT_LANG:
			localStorage.setItem("language", action.payload);
			i18n.changeLanguage(action.payload);
			return {
				...state,
				language: action.payload
			};

		case "SET_RETAILERS":
			return {
				...state,
				retailers: action.payload
			};

		case "SET_SKUS":
			return {
				...state,
				skus: action.payload
			};

		default:
			return state;
	}
};

export default pageReducer;
