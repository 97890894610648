import React from "react";
import prizeStand from "../../assets/prizeStand.png";
import "./MysteryBoxComponent.css";
import { useTranslation } from "react-i18next";
import multiPrize from "../../assets/multiPrize.png"
import grandPrize from "../../assets/grandPrize.png"

function MysteryBoxPrize() {
  const { t } = useTranslation();
  return (
    <div className="prize-component-container">
      <div className="prize-component-container-full">
        <div className="prize-component-first-half">
          <h3 className="prize-component-grand-prize-text">
            {t("myBoxGrandPrize")}
          </h3>
          <div className="prize-image-container">
          <img
              src={grandPrize}
              className="prize-component-grand-prize-image"
              alt="grand-prize"
            ></img>
            <img
              src={prizeStand}
              className="prize-stand"
              alt="prize-stand"
            ></img>
          </div>
          <p className="grand-prize-component-single-description">
            Dyson Vacuum
          </p>
        </div>
        <div className="prize-component-second-half">
          <h3 className="prize-component-grand-prize-text">
            {t("FiveWinnerWin")}
          </h3>
          <div className="prize-image-container">
          <img
            src={multiPrize}
            className="prize-component-multi-prize-image"
            alt="multi-mystery-box"
          ></img>
          <img
            src={prizeStand}
            className="prize-stand"
            alt="prize-stand"
          ></img>
          </div>
          <footer className="prize-component-footer">
            <div className="prize-component-descriptions">
              <p className="prize-component-single-description">
                Drypers DryPantz
              </p>
              <p className="prize-component-single-description">
                {t("aeonVoucher")}
              </p>
              <p className="prize-component-single-description">
                {t("petronasGiftCard")}
              </p>
              <p className="prize-component-single-description">Shopee RM50</p>
            </div>
          </footer>
        </div>
      </div>
      <h3 className="prize-component-and-more-message">
        {t("myBoxAndMore")}
        <span className="prize-component-and-more-message-highlight">
          {t("mysteryBox")}
        </span>{" "}
        {t("prizeToReveal")}
      </h3>
    </div>
  );
}

export default MysteryBoxPrize;
