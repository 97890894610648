import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getRewards, getVoucher, getDonates, getPregnant } from "../../redux/actions/userActions";
import { useTranslation } from "react-i18next";
import pregnantIcon from "../../../src/assets/PregnantMum.png"
import babyIcon from "../../../src/assets/Babyproduct.png"
import voucherIcon from "../../../src/assets/Evouchers.png"
import specialIcon from "../../../src/assets/SpecialDeals.png"
import shareIcon from "../../../src/assets/ShareLittleComfort.png"
import shareWhite from "../../../src/assets/shareWhite.svg"
import voucherWhite from  "../../../src/assets/voucherWhite.svg"
import babyWhite from  "../../../src/assets/babyWhite.svg"
import specialWhite from  "../../../src/assets/specialWhite.svg"
import pregnantWhite from  "../../../src/assets/pregnantWhite.svg"
// import { Modal } from 'react-bootstrap'

const Catalog = ({ catalog, voucher, getRewards, getVoucher, user, getDonates, donates, language, getPregnant, pregnant }) => {
	const { t } = useTranslation();
	const [filter, setFilter] = useState("all");

	//Today Date
	const today = new Date();
	
	//User Expected Date of Birth
	// const userEdb = new Date(user.edb);
	// const [notification, setNotification] = useState(true)
	//After 6Months
	const ebd = new Date(user.edb);
	const after6Months = new Date(ebd.setMonth(ebd.getMonth() + 6));

	useEffect(() => {
		if (!catalog) getRewards();
		if (!voucher) getVoucher();
		if (!donates) getDonates();
		if (!pregnant) getPregnant();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const truncateText = text => (text && text.length > 40 ? text.substr(0, 40) + "..." : text);

	const handleBabyItem = (item) => {
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
		  event: "select_item",
		  redemption_category: item.categories[0] === "eb96d625-28e9-442b-afa8-aed772b33299" ? "Special Deals" : "Baby Product", 
		  ecommerce: {
			items: [
			  {
				item_id: item.itemId,  
				item_name: item.itemName.en,     
				item_category: item.categories[0] === "eb96d625-28e9-442b-afa8-aed772b33299" ? "Special Deals" : "Baby Product",
				item_brand: item.itemBrand,
				price: item.points,  
				quantity: 1
			  }
			]
		  }
		});
	  };

	  const handleDonateItem = (item) => {
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
		  event: "select_item",
		  redemption_category: "Share a Little Comfort", 
		  ecommerce: {
			items: [
			  {
				item_id: item.itemId,  
				item_name: item.itemName,     
				item_category: "Share a Little Comfort",
				item_brand: item.itemBrand,
				price: item.points,  
				quantity: 1
			  }
			]
		  }
		});
	  };

	  const handleVoucherItem = (item) => {
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
		  event: "select_item",
		  redemption_category: "EVouchers", 
		  ecommerce: {
			items: [
			  {
				item_id: item.itemId,  
				item_name: item.name,     
				item_category: "EVouchers",
				item_brand: item.itemBrand,
				price: item.points,  
				quantity: 1
			  }
			]
		  }
		});
	  };

	  const handlePregnantItem = (item) => {
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
		  event: "select_item",
		  redemption_category: "Pregnant Offer", 
		  ecommerce: {
			items: [
			  {
				item_id: item.itemId,  
				item_name: item.itemName.en,     
				item_category: "Pregnant Offer",
				item_brand: item.itemBrand,
				price: item.points,  
				quantity: 1
			  }
			]
		  }
		});
	  };

	  const trackViewedItems = (categoryId) => {
		console.log(categoryId);
		const items = [];
	  
		catalog.data.forEach((item) => {
		  const matchingCategory = item.categories.find((category) => category === categoryId);
	  
		  if (matchingCategory) {
			items.push({
			  item_id: item.id,
			  item_name: item.itemName.en,
			  item_category: categoryId === "f7f65be2-bdd3-408c-856f-f9faef7ff2b3" ? "Baby Product" : "Special Deals",
			  item_brand: item.itemBrand,
			  price: item.points,
			  quantity: 1,
			});
		  }
		});
	  
		window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object
		window.dataLayer.push({
		  event: "view_item_list",
		  redemption_category: categoryId === "f7f65be2-bdd3-408c-856f-f9faef7ff2b3" ? "Baby Product" : "Special Deals",
		  ecommerce: {
			items,
		  },
		});
	  };
	  
	  
	  const trackViewedItemsPregnant = () => {
		const items = [];
	  
		pregnant.data.forEach((item) => {
		  if (item.remaining > 0 && item.active === true) {
			items.push({
			  item_id: item.id,
			  item_name: item.itemName.en,
			  item_brand: item.itemBrand,
			  price: item.points,
			  quantity: 1,
			});
		  }
		});
	  
		window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object
		window.dataLayer.push({
		  event: "view_item_list",
		  redemption_category: "Pregnant Offer", 
		  ecommerce: {
			items,
		  },
		});
	  };

	  const trackViewedVoucher = () => {
		const items = [];
	  
		voucher.forEach((item) => {
			items.push({
			  item_id: item.id,
			  item_name: item.name,
			  item_brand: item.itemBrand,
			  price: item.points,
			  quantity: 1,
			});
		});
	  
		window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object
		window.dataLayer.push({
		  event: "view_item_list",
		  redemption_category: "EVouchers", 
		  ecommerce: {
			items,
		  },
		});
	  };

	  const trackViewedDonate = () => {
		const items = [];
	  
		donates.forEach((item) => {
			items.push({
			  item_id: item.id,
			  item_name: item.itemName,
			  item_brand: item.itemBrand,
			  price: item.points,
			  quantity: 1,
			});
		});
	  
		window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object
		window.dataLayer.push({
		  event: "view_item_list",
		  redemption_category: "Share a Little Comfort", 
		  ecommerce: {
			items,
		  },
		});
	  };



	  
	  
	  

	const renderItems = () => {
		if (filter === "all") {
			return (
				<>
					{catalog && catalog.data && catalog.data.length
						? catalog.data
								.filter(x => x.remaining > 0 && x.active === true)
								.map(x => (
									<Link
										to={{ pathname: "/rewarddetail", data: x, type: "item" }}
										key={x.id}
										className="catalog-item"
										style={{ textDecoration: "none" }}
										onClick={() => handleBabyItem(x)}
									>
										<div className="catalog-item-img-container">
											<img src={x.itemImage[language]} alt="item" className="catalog-item-img img-fluid" />
										</div>
										<p className="m-auto p-0 catalog-item-text itemName">{truncateText(x.itemName[language])}</p>
										<p className="m-0 p-0 catalog-item-text itemPoint">
											{x.points} {t("pointscap")}
										</p>
									</Link>
								))
						: null}

					{donates && donates.length
						? donates
								.sort((a, b) => a.points - b.points)
								.map(x => (
									<Link
										to={{ pathname: "/rewarddetail", data: x, type: "donate" }}
										key={x.id}
										className="catalog-item"
										style={{ textDecoration: "none" }}
										onClick={() => handleDonateItem(x)}
									>
										<div className="catalog-item-img-container">
											<img src={x.itemImage} alt="item" className="catalog-item-img img-fluid" />
										</div>
										<p className="m-auto p-0 catalog-item-text itemName">{truncateText(x.itemName)}</p>
										<p className="m-0 p-0 catalog-item-text itemPoint">
											{x.points} {t("pointscap")}
										</p>
									</Link>
								))
						: null}

					{voucher && voucher.length
						? voucher
								.sort((a, b) => a.value - b.value)
								.filter(x => x.available > 0)
								.map(x => (
									<Link
										to={{ pathname: "/rewarddetail", data: x, type: "voucher" }}
										key={x.id}
										className="catalog-item"
										style={{ textDecoration: "none" }}
										onClick={() => handleVoucherItem(x)}
									>
										<div className="catalog-item-img-container">
											<img src={x.imageUrl} alt="item" className="catalog-item-img img-fluid" />
										</div>
										<p className="m-auto p-0 catalog-item-text itemName">{truncateText(x.name)}</p>
										<p className="m-0 p-0 catalog-item-text itemPoint">
											{x.points} {t("pointscap")}
										</p>
									</Link>
								))
						: null}

					{pregnant && pregnant.data && pregnant.data.length && user.pregnancyStage === "pregnant" && today <= after6Months
						? pregnant.data
								.filter(x => x.remaining > 0 && x.active === true)
								.map(x => (
									<Link
										to={{ pathname: "/rewarddetail", data: x, type: "pregnant" }}
										key={x.id}
										className="catalog-item"
										style={{ textDecoration: "none" }}
										onClick={() => handlePregnantItem(x)}
									>
										<div className="catalog-item-img-container">
											<img src={x.itemImage[language]} alt="item" className="catalog-item-img img-fluid" />
										</div>
										<p className="m-auto p-0 catalog-item-text itemName">{truncateText(x.itemName[language])}</p>
										<p className="m-0 p-0 catalog-item-text itemPoint">
											{x.points} {t("pointscap")}
										</p>
									</Link>
								))
						: null}
				</>
			);
		} else if (filter === "voucher") {
			if (voucher && voucher.length) {
				return voucher
					.sort((a, b) => a.value - b.value)
					.filter(x => x.available > 0)
					.map(x => (
						<Link
							to={{ pathname: "/rewarddetail", data: x, type: "voucher" }}
							key={x.id}
							className="catalog-item"
							style={{ textDecoration: "none" }}
							onClick={() => handleVoucherItem(x)}
						>
							<div className="catalog-item-img-container">
								<img src={x.imageUrl} alt="item" className="catalog-item-img img-fluid" />
							</div>
							<p className="m-auto p-0 catalog-item-text itemName">{truncateText(x.name)}</p>
							<p className="m-0 p-0 catalog-item-text itemPoint">
								{x.points} {t("pointscap")}
							</p>
						</Link>
					));
			}
		} else if (filter === "donate") {
			if (donates && donates.length) {
				return donates
					.sort((a, b) => a.points - b.points)
					.map(x => (
						<Link
							to={{ pathname: "/rewarddetail", data: x, type: "donate" }}
							key={x.id}
							className="catalog-item"
							style={{ textDecoration: "none" }}
							onClick={() => handleDonateItem(x)}
						>
							<div className="catalog-item-img-container">
								<img src={x.itemImage} alt="item" className="catalog-item-img img-fluid" />
							</div>
							<p className="m-auto p-0 catalog-item-text itemName">{truncateText(x.itemName)}</p>
							<p className="m-0 p-0 catalog-item-text itemPoint">
								{x.points} {t("pointscap")}
							</p>
						</Link>
					));
			}
		} else if (filter === "pregnant") {
			if (pregnant && pregnant.data && pregnant.data.length) {
				return pregnant.data
					.filter(x => x.remaining > 0 && x.active === true)
					.map(x => (
						<Link
							to={{ pathname: "/rewarddetail", data: x, type: "pregnant" }}
							key={x.id}
							className="catalog-item"
							style={{ textDecoration: "none" }}
							onClick={() => handlePregnantItem(x)}
						>
							<div className="catalog-item-img-container">
								<img src={x.itemImage[language]} alt="item" className="catalog-item-img img-fluid" />
							</div>
							<p className="m-auto p-0 catalog-item-text itemName">{truncateText(x.itemName[language])}</p>
							<p className="m-0 p-0 catalog-item-text itemPoint">
								{x.points} {t("pointscap")}
							</p>
						</Link>
					));
			}
		} else {
			if (catalog && catalog.data && catalog.data.length) {
				return catalog.data
					.filter(x => x.categories.includes(filter) && x.remaining > 0 && x.active === true)
					.map(x => (
						<Link
							to={{ pathname: "/rewarddetail", data: x, type: "item" }}
							key={x.id}
							className="catalog-item"
							style={{ textDecoration: "none" }}
							onClick={() => handleBabyItem(x)}
						>
							<div className="catalog-item-img-container">
								<img src={x.itemImage[language]} alt="item" className="catalog-item-img img-fluid" />
							</div>
							<p className="m-auto p-0 catalog-item-text itemName">{truncateText(x.itemName[language])}</p>
							<p className="m-0 p-0 catalog-item-text itemPoint">
								{x.points} {t("pointscap")}
							</p>
						</Link>
					));
			}
		}
	};

	return (
		<div id="catalog">
			<div className="selection-container">
				<div className="user-point">
					<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star-2.png" alt="point" className="img-fluid" />
					<h3 className="m-0 p-0 ms-2">{user && user.totalPoints ? user.totalPoints : 0}</h3>
				</div>
				<div className="categories-container">
					<div className={`category ${filter === "all" ? "active" : ""}`} onClick={() => setFilter("all")}>
						<p className="m-0 p-0">{t("all")}</p>
					</div>
					{user.pregnancyStage === "pregnant" && today <= after6Months ? (
						<div className={`category ${filter === "pregnant" ? "active" : ""}`} style={{animation: "fadeEffect 1s steps(5, start) infinite"}} onClick={() => {setFilter("pregnant");trackViewedItemsPregnant(); }}>
							{ filter ==="pregnant" ? 
								( <img style={{height: "35px", paddingRight: "5px"}} src={pregnantWhite} />  ) :
								( <img style={{height: "35px", paddingRight: "5px"}} src={pregnantIcon} /> )
							}<p className="m-0 p-0">
							
							{t("pregnantOffer")}</p>
						</div>
					) : null}

					<div className={`category ${filter === "donate" ? "active" : ""}`} onClick={() => {setFilter("donate");trackViewedDonate(); }}>
						{ filter ==="donate" ? 
								( <img style={{height: "25px"}} src={shareWhite} />  ) :
								( <img style={{height: "25px"}} src={shareIcon} /> )
							}<p className="m-0 p-0">
							
							Share a Little Comfort</p>
					</div>
					<div className={`category ${filter === "voucher" ? "active" : ""}`} onClick={() => {setFilter("voucher");trackViewedVoucher(); }}>
						{ filter ==="voucher" ? 
								( <img style={{height: "20px", paddingRight: "5px"}} src={voucherWhite} />  ) :
								( <img style={{height: "30px", paddingRight: "5px"}} src={voucherIcon} />)
							}<p className="m-0 p-0">
						
							{t("evouchers")}</p>
					</div>
					{catalog && catalog.categories && catalog.categories.length
						? catalog.categories.map(x => (
								<div key={x.id} className={`category ${filter === x.id ? "active" : ""}`} onClick={() => {setFilter(x.id);trackViewedItems(x.id);}}>
									{x.name === "Baby Product" ? 
										<div>
											{filter === x.id ?
											(<img style={{height: "25px", paddingRight: "5px"}} src={babyWhite} />) :
											(<img style={{height: "25px", paddingRight: "5px"}} src={babyIcon} />)
											}
											{t("babyproduct")}
										</div> :
										<div>
											{ filter === x.id ?
											(<img style={{height: "20px", paddingRight: "5px"}} src={specialWhite} />) :
											(<img style={{height: "20px", paddingRight: "5px"}} src={specialIcon} />)
											}
											{t(x.name)}
										</div> }
								</div>
						  ))
						: null}
				</div>
			</div>

			<div className="catalog-item-container">{renderItems()}</div>

			{/* <Modal show={notification} onHide={() => setNotification(false)} centered>
				<Modal.Body className="text-justify" style={{ whiteSpace: "pre-line" }}>
					<p className="text-center fw-bold">{t("announcement")}</p>
					<p>{t("notification")}<a href="my.drypersbabyclub@vinda.com">my.drypersbabyclub@vinda.com</a></p>
				</Modal.Body>
			</Modal> */}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		catalog: state.userReducer.catalogue,
		voucher: state.userReducer.voucher,
		user: state.userReducer.user,
		donates: state.userReducer.donate,
		language: state.pageReducer.language,
		pregnant: state.userReducer.pregnant
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getRewards: () => dispatch(getRewards()),
		getVoucher: () => dispatch(getVoucher()),
		getDonates: () => dispatch(getDonates()),
		getPregnant: () => dispatch(getPregnant())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Catalog);
