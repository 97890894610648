import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { editWishingTree } from "../../../../redux/actions/adminActions";

const EditWishingTree = ({ history, location, editWishingTree }) => {
	const [data, setData] = useState({
        name: location.data.name,
        wishes: location.data.wishes.en,
        bmWishes: location.data.wishes.bm,
	});
	const handleChange = e => {
		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleSubmit = e => {
		e.preventDefault();
		const { name, wishes, bmWishes } = data;
		if (name && wishes && bmWishes ) {
				const sendThis = {
					id: location.data.id,
					name,
                    wishes,
                    bmWishes
				};
				console.log(sendThis);
				editWishingTree(sendThis, err => {
					if (err) alert(err);
					else {
						history.push("/admin/wishingtree/dashboard");
					}
				});
		} else {
			alert("Empty field detected");
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Edit Wishes</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="name">Name</label>
								<input
									type="text"
									id="name"
									name="name"
									className="form-control"
									placeholder="Wishes name"
									value={data.name}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="wishes">Wishes</label>
								<textarea
									name="wishes"
									id="wishes"
									className="form-control"
									placeholder="Wishes"
									value={data.wishes}
									onChange={handleChange}
									required
								></textarea>
							</div>
                            
                            <div className="form-group mt-3">
								<label htmlFor="bmWishes">BM Wishes</label>
								<textarea
									name="bmWishes"
									id="bmWishes"
									className="form-control"
									placeholder="Wishes"
									value={data.bmWishes}
									onChange={handleChange}
									required
								></textarea>
							</div>

							<div className="form-group mt-5 text-center">
								<Loader component={<input type="submit" value="SUBMIT" className="form-control btn btn-primary" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		editWishingTree: (data, callback) => dispatch(editWishingTree(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(EditWishingTree);
