import { useState, useEffect } from "react";
import axios from "axios";
// import {Link} from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
// Components
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Footer from "../../../component/dashboard/Footer";
import Loader from "../../../component/Loader";
// Assets
import { addAddress } from "../../../redux/actions/userActions";
// import areaData from "../../../config/areaData.json";
// import { states, postcodes, cities } from "../../../config/dataStandardize";
//Redux
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

function AddAddress({ history, addAddress, user, location }) {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);
	const [data, setData] = useState({
		name: user.name,
		number: user.number,
		line1: "",
		line2: "",
		line3: "",
		city: "",
		state: "",
		postcode: "",
		country: "Malaysia",
		label: ""
	});
	// const [states, setStates] = useState([]);
	const [postcodes, setPostcodes] = useState([]);
	const [cities, setCities] = useState([]);
	const [dropdown, setDropdown] = useState({
		states: [],
		postcodes: [],
		cities: []
	});
	const [areaData, setAreaData] = useState([]);

	const cartItems = location.cartItems;

	useEffect(() => {
		axios
			.get("https://mystatic123.s3.ap-southeast-1.amazonaws.com/areaData.json")
			.then(res => {
				setAreaData(res.data);
			})
			.catch(console.error);
	}, []);

	useEffect(() => {
		axios
			.get("https://mystatic123.s3.ap-southeast-1.amazonaws.com/dataStandardize.json")
			.then(res => {
				// console.log(res.data);
				setDropdown({
					states: res.data.states.sort((a, b) => {
						if (a.label > b.label) return 1;
						if (a.label < b.label) return -1;
						return 0;
					}),
					postcodes: res.data.postcodes,
					cities: res.data.cities
				});
				// setStates(res.data.states);
				setPostcodes(res.data.postcodes);
				setCities(res.data.cities);
			})
			.catch(console.error);
	}, []);

	useEffect(() => {
		// when user changed state
		let isMounted = true;
		if (data.state && isMounted) {
			const thisCities = areaData
				.filter(x => x.state === data.state)
				.map(x => x.city)
				.filter((x, y, z) => z.indexOf(x) === y);
			const filteredCities = cities.filter(x => thisCities.includes(x.value));

			if (filteredCities.length === 1) {
				isMounted &&
					setData({
						...data,
						city: filteredCities[0].value,
						postcode: ""
					});
			} else {
				isMounted &&
					setData({
						...data,
						city: "",
						postcode: ""
					});
			}
			isMounted &&
				setDropdown({
					...dropdown,
					cities: filteredCities
				});
		}

		return () => (isMounted = false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.state]);

	useEffect(() => {
		// when user changed city
		let isMounted = true;
		if (data.city && isMounted) {
			const thisPostcodes = areaData
				.filter(x => x.state === data.state && x.city === data.city)
				.map(x => x.postcode)
				.filter((x, y, z) => z.indexOf(x) === y);
			const filteredPostcodes = postcodes.filter(x => thisPostcodes.includes(x.value));

			if (filteredPostcodes.length === 1) {
				isMounted &&
					setData({
						...data,
						postcode: filteredPostcodes[0].value
					});
			} else {
				isMounted &&
					setData({
						...data,
						postcode: ""
					});
			}
			isMounted &&
				setDropdown({
					...dropdown,
					postcodes: filteredPostcodes
				});
		}
		return () => (isMounted = false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.city]);

	const handleChange = e => setData({ ...data, [e.target.name]: e.target.value });

	const handleSubmit = e => {
		e.preventDefault();
		const { line1, city, state, postcode, label } = data;
		if (line1 && city && state && postcode && label) setShow(true);
		else alert("Please fill in line1, city, state, postcode and label");
	};

	const handleMobileCode = num => {
		if (num && num.length > 8 && num[0].toString() !== "0") {
			return "+";
		} else {
			return "+6";
		}
	};

	const handleNext = e => {
		const { line1, line2, line3, city, state, postcode, country, label } = data;
		if (line1 && city && state && postcode && label) {
			const address = {
				line1,
				line2,
				line3,
				city,
				state,
				postcode,
				country,
				label
			};
			const { id } = user;
			const sendThis = {
				id,
				address
			};

			// push to different path //
			addAddress(sendThis, () => {
				if (location.type === "order") history.push({ pathname: "/viewAddress", type: location.type, data: { cartItems, ...location.data } });
				else if (location.type === "sample") history.push({ pathname: "/viewAddress", type: location.type, data: location.data });
				else if (location.type === "user") history.push({ pathname: "/viewAddress", type: location.type });
				else history.push({ pathname: "/viewAddress", type: location.type });
			});
		} else alert("Please fill in line1, city, state, postcode and label");
	};

	return (
		<div id="add-address">
			<Header />
			<div className="content">
				<ContentHeader />

				<div className="text-center">
					<h1 className="dbc-title m-0 p-0">{t("delivery")}</h1>
					<p className="m-0 p-0">{t("fillinaddress")}</p>
				</div>

				<form onSubmit={handleSubmit} className="vinda-form mb-5">
					<div className="form-group vinda-form-group">
						<label htmlFor="name" className="vinda-form-label">
							{t("name")}
						</label>
						<input
							type="text"
							name="name"
							id="name"
							className="form-control vinda-form-input"
							placeholder={t("fullname")}
							value={data.name}
							disabled
							readOnly
							required
						/>
					</div>

					<div className="form-group vinda-form-group">
						<label htmlFor="number" className="vinda-form-label">
							{t("contactdetails")}
						</label>

						<div className="input-group">
							<span className="input-group-text">{handleMobileCode(data.number)}</span>
							<input
								type="text"
								name="number"
								id="number"
								className="form-control vinda-form-input"
								placeholder={t("phonenumber")}
								value={data.number}
								disabled
								readOnly
								required
							/>
						</div>
					</div>

					<div className="form-group vinda-form-group">
						<label htmlFor="address" className="vinda-form-label">
							{t("address")}
						</label>
						<textarea
							name="line1"
							id="line1"
							className="form-control vinda-form-input"
							placeholder={t("address")}
							value={data.line1}
							onChange={handleChange}
							required
						></textarea>
						{/* <input
							type="text"
							name="line1"
							id="line1"
							className="form-control vinda-form-input"
							placeholder={t("houseno")}
							value={data.line1}
							onChange={handleChange}
							required
						/> */}
					</div>

					{/* <div className="form-group vinda-form-group">
						<label htmlFor="address" className="vinda-form-label">
							{t("addrline2")}
						</label>
						<input
							type="text"
							name="line2"
							id="line2"
							className="form-control vinda-form-input"
							placeholder={t("streetname")}
							value={data.line2}
							onChange={handleChange}
							required
						/>
						<input
							type="text"
							name="line3"
							id="line3"
							className="form-control mt-2"
							placeholder={t("streetname")}
							value={data.line3}
							onChange={handleChange}
						/>
					</div> */}

					<div className="row">
						<div className="form-group vinda-form-group col me-1">
							<label htmlFor="state" className="vinda-form-label">
								{t("state")}
							</label>
							{/* <input type="text" name="state" id="state" className="form-control vinda-form-input" placeholder="State" value={data.state} onChange={handleChange} required /> */}
							<Select
								options={dropdown.states}
								onChange={e => setData({ ...data, state: e.value })}
								placeholder={t("state")}
								value={dropdown.states.filter(x => x.value === data.state)}
								required
							/>
						</div>
						<div className="form-group vinda-form-group col ms-1">
							<label htmlFor="city" className="vinda-form-label">
								{t("city")}
							</label>
							{/* <input type="text" name="city" id="city" className="form-control vinda-form-input" placeholder="City" value={data.city} onChange={handleChange} required /> */}
							<Select
								options={dropdown.cities}
								onChange={e => setData({ ...data, city: e.value })}
								placeholder={t("city")}
								value={dropdown.cities.filter(x => x.value === data.city)}
								isDisabled={!data.state}
								required
							/>
						</div>
					</div>

					<div className="row">
						<div className="form-group vinda-form-group col me-1">
							<label htmlFor="postcode" className="vinda-form-label">
								{t("postcode")}
							</label>
							{/* <input type="text" name="postcode" id="postcode" className="form-control vinda-form-input" placeholder="Postcode" value={data.postcode} onChange={handleChange} maxLength="5" required /> */}
							<Select
								options={dropdown.postcodes}
								onChange={e => setData({ ...data, postcode: e.value })}
								placeholder={t("postcode")}
								value={dropdown.postcodes.filter(x => x.value === data.postcode)}
								isDisabled={!data.state || !data.city}
								required
							/>
						</div>
						<div className="form-group vinda-form-group col ms-1">
							<label htmlFor="country" className="vinda-form-label">
								{t("country")}
							</label>
							<input
								type="text"
								name="country"
								id="country"
								className="form-control vinda-form-input"
								placeholder={t("country")}
								value={data.country}
								disabled
								readOnly
								required
							/>
						</div>
					</div>

					<div className="form-group vinda-form-group">
						<label htmlFor="label" className="vinda-form-label">
							{t("label")}
						</label>
						<input
							type="text"
							name="label"
							id="label"
							className="form-control vinda-form-input"
							placeholder={t("labellong")}
							value={data.label}
							onChange={handleChange}
							required
						/>
					</div>

					<div className="form-group vinda-form-group text-center">
						<Loader component={<input type="submit" value="CONFIRM" className="vinda-form-submit btn" />} />
					</div>
				</form>

				<div className="desktop-only">
					<Footer />
				</div>
			</div>

			<Modal backdrop="static" keyboard={false} centered show={show} onHide={() => setShow(false)}>
				<Modal.Body>
					<div className="text-center">
						<img style={{ width: "40%" }} alt="Cart_icon" src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/cart.png"></img>
						<h6 className="addToCartTitle">{t("savethisaddress")}</h6>
						<p className="pickSampleModalText">{t("areyousure")}</p>

						<Loader
							component={
								<div className="text-center">
									<Button style={{ backgroundColor: "#FEA30B", width: "100%", marginTop: "0.5rem" }} onClick={handleNext}>
										{t("save")}
									</Button>
									<Button style={{ backgroundColor: "#FEA30B", width: "100%", marginTop: "0.5rem" }} onClick={() => setShow(false)}>
										{t("edit")}
									</Button>
								</div>
							}
						/>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		addAddress: (data, callback) => dispatch(addAddress(data, callback))
	};
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		loading: state.userReducer.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAddress);
