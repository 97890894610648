import { useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Loader from "../../../component/Loader";
import { forgotPassword, clearError } from "../../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

const ForgotPassword = ({ history, match, pageReducer, forgotPassword, clearError, location }) => {
	const { t } = useTranslation();
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const [success, setSuccess] = useState(false);

	const emailChecker = event => {
		setEmail(event.target.value.toLowerCase());
		var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (regex.test(event.target.value)) {
			setEmailError("");
		} else {
			setEmailError(t("emailerror"));
		}
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (!emailError && email) forgotPassword({ email: email.toLowerCase(), language: pageReducer.language }, () => setSuccess(true));
	};

	const postSuccess = () => {
		setSuccess(false);
		history.push(`/${pageReducer.language}/signin`);
	};

	return (
		<div id="forgot-password-page">
			<Header history={history} match={match} location={location} />
			<div className="content">
				<ContentHeader />
				<div className="text-center">
					<h1 className="dbc-title">{t("fp")}?</h1>
				</div>

				<div className="forgot-password-container">
					<p style={{ userSelect: "pointer" }}>{t("fptext")}</p>

					<form onSubmit={handleSubmit}>
						<div className="form-group">
							<label htmlFor="email">{t("emailaddress")}</label>
							<input
								type="email"
								id="email"
								name="email"
								className="form-control"
								placeholder={t("email")}
								onChange={emailChecker}
								value={email}
								required
							/>
							{emailError ? <span style={{ color: "red" }}>{emailError}</span> : null}
						</div>

						<div className="form-group mt-3">
							<Loader component={<input type="submit" value={t("sendmemypwd")} className="btn btn-primary" />} />
						</div>
					</form>
				</div>
			</div>

			<Modal show={pageReducer.error} onHide={() => clearError()} centered>
				<Modal.Body className="text-center">
					<div>
						<p className="addToCartTitle">{t("error")}</p>
						<p className="mt-3 pickSampleModalText">{pageReducer.error}</p>
					</div>

					<div>
						<button className="pickAddressConfirmButton" onClick={() => clearError()}>
							OKAY
						</button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal show={success} onHide={postSuccess} centered>
				<Modal.Body className="text-center">
					<div>
						<p className="addToCartTitle">{t("tq")}</p>
						<p className="mt-3 pickSampleModalText">
							{t("weSent")} {email}
							<br />
							{t("checkSpam")}
						</p>
					</div>

					<div>
						<button className="pickAddressConfirmButton" onClick={postSuccess}>
							{t("return")}
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		pageReducer: state.pageReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		forgotPassword: (data, callback) => dispatch(forgotPassword(data, callback)),
		clearError: () => dispatch(clearError())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
