import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import { useTranslation } from "react-i18next";
import { getQuest } from "../../../redux/actions/userActions";
import questUser from "../../../assets/questUser.png"
import questCopy from "../../../assets/questCopy.png"
import {AiOutlineCheck} from 'react-icons/ai'
import { Link } from "react-router-dom";

const Quest = ({ quest, getQuest, language, id }) => {
	const { t } = useTranslation();

	useEffect(() => {
		if (!quest) getQuest({id});
	  }, []);

	  useEffect(() => {
		if (quest) getQuest({id});
	  }, []);

	//   console.log(quest);
	//   console.log(id);
	
	return (
		<div>
			<Header />
			<div className="content" style={{ paddingBottom: "150px" }}>
				<ContentHeader title={t("myQuest")} />
				<div className="text-center desktop-only">
					<h3 className="dbc-title">{t("myQuest")}</h3>
					<h6 style={{fontFamily: "VAGRounded", fontSize: "14px", fontWeight: "300", color: "#0062BF", textAlign: "center"}} className="mt-2">{t("earnMoreQuest")}</h6>
				</div>

				<h6 style={{fontFamily: "VAGRounded", fontSize: "14px", fontWeight: "300", color: "#0062BF", textAlign: "center"}} className="earnMoreQuest mt-2">{t("earnMoreQuest")}</h6>
				<div className="QuestMainContainer mt-5">
				{quest && quest.length ? (
						quest.map((x) => (
							x.quests.map((questItem, index) => (
							<div key={index} className="QuestContainer">
							{/* <p className="updateYourQuest">{x.title[language]}</p> */}
							{/* Progress bar */}
							<div
								// className="mt-4"
								style={{
								paddingLeft: "20px",
								paddingRight: "20px",
								paddingBottom: "20px",
								paddingTop: "20px"
								}}
							>
								<div className="QuestSmallContainer" key={index}>
									<img style={{ marginRight: "10px" }} src={questUser} />
									<Link style={{textDecoration: "none"}} to={{pathname: questItem.path, isDesktop: true }}>
									<div>
									<h6 className="questHeader">{questItem.title[language]}</h6>
									{/* <h6 className="questSubHeader">{questItem.description[language]}</h6> */}
									</div>
									</Link>
									{questItem.completed ? (
									<div
									className="questPoints"
									style={{
									fontSize: "25px",
									textAlign: "center",
									fontFamily: "VAGRounded",
									color: "white",
									fontWeight: "700",
									display: "flex",
									justifyContent: "center",
									alignItems: "center"
									}}
								>
									<AiOutlineCheck />
								</div>
									) : (
									<div
										className="questPoints"
										style={{
										// fontSize: "25px",
										textAlign: "center",
										fontFamily: "VAGRounded",
										color: "white",
										fontWeight: "700",
										display: "flex",
										justifyContent: "center",
										alignItems: "center"
										}}
									>
										{/* {questItem.points}pts */}
										{questItem.points === 0 ? "2x pts" : `${questItem.points}pts`}
									</div>
									)}
								</div>
								
							</div>
							</div>
							))
						))
						) : (

						<></>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		getQuest: data => dispatch(getQuest(data))
	};
};

const mapStateToProps = state => {
	return {
		quest: state.userReducer.quest,
		language: state.pageReducer.language,
		id: state.userReducer.user.id
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Quest);
