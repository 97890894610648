const tokenConfig = getState => {
	const token = getState().userReducer.token;
	const config = {
		headers: {
			Authorization: "Bearer token"
		}
	};

	if (token) config.headers["Authorization"] = "Bearer " + token;
	return config;
};

export default tokenConfig;
