import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";

import { addBrand } from "../../../../redux/actions/adminActions";

const AddBrand = ({ addBrand, history }) => {
	const [data, setData] = useState({
		name: "",
		description: ""
	});

	const [file, setFile] = useState(null);

	const handleChange = e => {
		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleUpload = e => {
		setFile(e.target.files[0]);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const { name, description } = data;
		if (name && description && file) {
			const formData = new FormData();
			formData.append("name", name);
			formData.append("description", description);
			formData.append("file", file);
			addBrand(formData, err => {
				if (err) alert(err);
				else history.push("/admin/brand/dashboard");
			});
		} else {
			alert("Please fill in all the input fields");
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Add Brand</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="file">Brand Image</label>
								<input type="file" id="file" name="file" className="form-control" onChange={handleUpload} accept="image/*" required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="name">Name</label>
								<input type="text" name="name" id="name" className="form-control" placeholder="Brand name" value={data.name} onChange={handleChange} required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="description">Description</label>
								<textarea id="description" name="description" className="form-control" placeholder="Brand Description" value={data.description} onChange={handleChange} required></textarea>
							</div>

							<div className="form-group mt-5 text-center">
								<Loader component={<input type="submit" value="SUBMIT" className="btn btn-primary form-control" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		addBrand: (data, callback) => dispatch(addBrand(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(AddBrand);
