import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { editJourney } from "../../../../redux/actions/adminActions";

const EditJourney = ({ history, location, editJourney }) => {
	const [data, setData] = useState({
        home: location.data.home,
        children: location.data.children,
        points: location.data.points,
        product: location.data.product,
		description: location.data.description.en,
		descriptionBM: location.data.description.bm,
		progressPoints: location.data.progressPoints,
	});

	const handleChange = e => {
		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleSubmit = e => {
		e.preventDefault();
		const { home, children, points, product, description, descriptionBM, progressPoints } = data;
		if (home && children && points && product && description && descriptionBM && progressPoints) {
			// if (file && bmFile) {
				// have image
				const formData = new FormData();
				formData.append("id", location.data.id);
				formData.append("home", home);
				formData.append("children", children);
				formData.append("points", points);
				formData.append("product", product);
				formData.append("description", description);
				formData.append("descriptionBM", descriptionBM);
				formData.append("progressPoints", progressPoints);
				editJourney(formData, err => {
					if (err) alert(err);
					else {
						history.push("/admin/journey/dashboard");
					}
				});
			// } else {
			// 	// no image
			// 	const sendThis = {
			// 		id: location.data.id,
			// 		home,
            //         children,
            //         points,
            //         product
			// 	};
			// 	console.log(sendThis);
			// 	editJourney(sendThis, err => {
			// 		if (err) alert(err);
			// 		else {
			// 			history.push("/admin/journey/dashboard");
			// 		}
			// 	});
			// }
		} else {
			alert("Empty field detected");
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Edit Journey</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="home">No. Of Homes Visited</label>
								<input
									type="text"
									id="home"
									name="home"
									className="form-control"
									placeholder="home"
									value={data.home}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="children">No. Of Children Reaches Out</label>
								<textarea
									name="children"
									id="children"
									className="form-control"
									placeholder="children"
									value={data.children}
									onChange={handleChange}
									required
								></textarea>
							</div>

                            <div className="form-group mt-3">
								<label htmlFor="points">Total Points Collected</label>
								<textarea
									name="points"
									id="points"
									className="form-control"
									placeholder="points"
									value={data.points}
									onChange={handleChange}
									required
								></textarea>
							</div>

                            <div className="form-group mt-3">
								<label htmlFor="product">No. Of Product Donated</label>
								<textarea
									name="product"
									id="product"
									className="form-control"
									placeholder="product"
									value={data.product}
									onChange={handleChange}
									required
								></textarea>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="description">Description</label>
								<textarea
									name="description"
									id="description"
									className="form-control"
									placeholder="description"
									value={data.description}
									onChange={handleChange}
									required
								></textarea>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="descriptionBM">Description</label>
								<textarea
									name="descriptionBM"
									id="descriptionBM"
									className="form-control"
									placeholder="descriptionBM"
									value={data.descriptionBM}
									onChange={handleChange}
									required
								></textarea>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="progressPoints">Progress Bar Points</label>
								<textarea
									name="progressPoints"
									id="progressPoints"
									className="form-control"
									placeholder="progressPoints"
									value={data.progressPoints}
									onChange={handleChange}
									required
								></textarea>
							</div>


							<div className="form-group mt-5 text-center">
								<Loader component={<input type="submit" value="SUBMIT" className="form-control btn btn-primary" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		editJourney: (data, callback) => dispatch(editJourney(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(EditJourney);
