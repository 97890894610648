import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
// import Loader from "../../../../component/Loader";

// import { addBrand } from "../../../../redux/actions/adminActions";

const MassCreditDetails = ({ location }) => {
	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>
						<strong>Points Credit Details</strong>
					</h3>

					<div className="mt-5 text-center">
						<table className="table table-bordered table-striped table-hover">
							<thead>
								<tr>
									<th scope="col">Email</th>
									<th scope="col">Description</th>
									<th scope="col">Points</th>
								</tr>
							</thead>

							<tbody>
								{location && location.details
									? location.details.map(x => (
											<tr key={x.id}>
												<th scope="row" style={!x.userId ? { color: "red", fontWeight: "bold" } : {}}>
													{x.email}
												</th>
												<td>{x.description}</td>
												<td>{x.points}</td>
											</tr>
									  ))
									: null}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MassCreditDetails;
