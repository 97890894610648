import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { generateCodes } from "../../../../redux/actions/adminActions";

const GenerateCodes = ({ history, location, generateCodes }) => {
	const [data, setData] = useState({
		quantity: 0,
		points: 0,
		brand: "",
		format: "",
		size: ""
	});

	const handleChange = e => {
		if (e.target.id === "brand") {
			setData({
				...data,
				brand: e.target.value,
				size: "",
				format: ""
			});
		} else if (e.target.id === "format") {
			setData({
				...data,
				[e.target.id]: e.target.value,
				size: ""
			});
		} else {
			setData({
				...data,
				[e.target.id]: e.target.value
			});
		}
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (data.quantity && data.points && data.brand && data.size && data.format) {
			const brandId = data.brand;
			const sizeId = data.size;
			const formatId = data.format;
			const quantity = data.quantity;
			const points = data.points;

			const sendThis = {
				brandId,
				sizeId,
				formatId,
				quantity,
				points
			};

			generateCodes(sendThis, err => {
				if (err) alert(err);
				else history.push("/admin/scancode/dashboard");
			});
		} else {
			alert("Please fill in all the fields in the form");
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Generate Codes</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="brand">Brand</label>
								<select name="brand" id="brand" className="form-select" value={data.brand} onChange={handleChange} required>
									<option disabled value="">
										Select Brand
									</option>

									{location.brands.map(brand => (
										<option key={brand.id} value={brand.id}>
											{brand.name}
										</option>
									))}
								</select>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="format">Format</label>
								<select name="format" id="format" className="form-select" value={data.format} onChange={handleChange} required>
									<option value="" disabled>
										Select Format
									</option>
									{data.brand
										? location.brands
												.filter(brand => brand.id === data.brand)[0]
												.formats.map(format => (
													<option key={format.id} value={format.id}>
														{format.name.toUpperCase()}
													</option>
												))
										: null}
								</select>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="size">Size</label>
								<select name="size" id="size" className="form-select" value={data.size} onChange={handleChange} required>
									<option value="" disabled>
										Select Size
									</option>
									{data.brand && data.format
										? location.brands
												.filter(brand => brand.id === data.brand)[0]
												.sizes.filter(size => size.brandId === data.brand && size.formatId === data.format)
												.map(size => (
													<option key={size.id} value={size.id}>
														{size.name.toUpperCase()}
													</option>
												))
										: null}
								</select>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="quantity">Quantity</label>
								<input type="number" name="quantity" id="quantity" className="form-control" value={data.quantity} onChange={handleChange} required min={0} />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="points">Points</label>
								<input type="number" name="points" id="points" className="form-control" value={data.points} onChange={handleChange} required min={0} />
							</div>

							<div className="form-group text-center mt-5">
								<Loader component={<input type="submit" value="GENERATE" className="form-control btn btn-primary" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		generateCodes: (data, callback) => dispatch(generateCodes(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(GenerateCodes);
