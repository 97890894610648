// import { useState } from "react";
import { connect } from "react-redux";
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Footer from "../../../component/dashboard/Footer";
import { useTranslation } from "react-i18next";
import { checkSession, getContest } from "../../../redux/actions/userActions";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";

const Contest = ({ checkSession, getContest, contest, language }) => {
	const { t } = useTranslation();
	const history = useHistory();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => checkSession(), []);

    useEffect(() => {
		if(!contest) getContest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contest]);

	const today = new Date()
	// const year = today.getFullYear();
	// const month = String(today.getMonth() + 1).padStart(2, '0');
	// const day = String(today.getDate()).padStart(2, '0');
	// const todayDateOnly = `${year}-${month}-${day}`;

	return (
		<div>
			<Header />
			<div className="content">
				<ContentHeader title="CONTEST" />
				<h1 className="campaign-title">{t("contest")}</h1>
				<h4 className="campaign-sub-title">{t("questsToCollect")}</h4>

				<div className="campaign-desktop-activity-container">
                {contest &&
                     contest
					 .sort((a, b) => {
					   const endDateA = new Date(a.endDate);
					   const endDateB = new Date(b.endDate);
					   const startDateA = new Date(a.startDate);
					   const startDateB = new Date(b.startDate);
			   
					   if (today <= endDateA && today <= endDateB) {
						 return endDateA - endDateB; // Sort ongoing contests by end date
					   } else if (today > startDateA && today <= endDateA) {
						 return -1; // Ongoing contests before not started
					   } else if (today > startDateB && today <= endDateB) {
						 return 1; // Not started contests after ongoing contests
					   } else {
						 return endDateA - endDateB; // Sort ended contests by end date
					   }
					 })
					 .map((item) => (
					today > new Date(item.endDate) ? (
					<div className="campaign-desktop-activity-box">
						<div style={{ position: "relative" }}>
							<div style={{ filter: "blur(3px)" }}>
								<div className="campaign-desktop-activity-box-top">
									<img
										src={item.image[language]}
										alt="activity one"
										className="campaign-desktop-activity-image"
									/>
								</div>
								<div className="campaign-desktop-activity-box-bottom">
								<h1 className="campaign-title" style={{margin: "0", textAlign: "left", fontSize: "18px", color: "white"}}>{item.name[language]}</h1>
								<h5 style={{ whiteSpace: "pre-line", textAlign: "left" }}>{item.description[language]}</h5>
									<h5 style={{ whiteSpace: "pre-line", textAlign: "right" }}>{item.startDate} to {item.endDate}</h5>
								</div>
							</div>
							<div className="campaign-mobile-activity-box-blur text-center">
								<img
									src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/campaign/checked.png"
									alt="lock"
									className="campaign-mobile-activity-blur-topright-image"
								/>
								<div style={{ width: "70%", margin: "50px auto 0" }}>
									<img
										src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star.png"
										alt="pointer star"
										className="campaign-mobile-activity-blur-center-image"
									/>
									<h1 className="campaign-mobile-activity-blur-center-text">Ended</h1>
								</div>
							</div>
						</div>
					</div>
					) : today < new Date(item.startDate) ? (
					<div className="campaign-desktop-activity-box">
					<div style={{ position: "relative" }}>
						<div style={{ filter: "blur(3px)" }}>
							<div className="campaign-desktop-activity-box-top">
								<img
									src={item.image[language]}
									alt="activity one"
									className="campaign-desktop-activity-image"
								/>
							</div>
							<div className="campaign-desktop-activity-box-bottom">
							<h1 className="campaign-title" style={{margin: "0", textAlign: "left", fontSize: "18px", color: "white"}}>{item.name[language]}</h1>
							<h5 style={{ whiteSpace: "pre-line", textAlign: "left" }}>{item.description[language]}</h5>
								<h5 style={{ whiteSpace: "pre-line", textAlign: "right" }}>{item.startDate} to {item.endDate}</h5>
							</div>
						</div>
						<div className="campaign-mobile-activity-box-blur text-center">
							<div style={{ width: "70%", margin: "50px auto 0" }}>
								<h1 className="campaign-mobile-activity-blur-center-text">Not Started</h1>
							</div>
						</div>
					</div>
					</div>
					) : item.active === false ? (
						null
						) : (
					<div className="campaign-desktop-activity-box">
					<Link to={{ pathname: "/activity", state: { contest: item } }} style={{ textDecoration: "none" }}>
					<div style={{ position: "relative" }}>
							<div className="campaign-desktop-activity-box-top">
								<img
									src={item.image[language]}
									alt="activity one"
									className="campaign-desktop-activity-image"
								/>
							</div>
							<div className="campaign-desktop-activity-box-bottom">
							<h1 className="campaign-title" style={{margin: "0", textAlign: "left", fontSize: "18px", color: "white"}}>{item.name[language]}</h1>
							<h5 style={{ whiteSpace: "pre-line", textAlign: "left" }}>{item.description[language]}</h5>
								<h5 style={{ whiteSpace: "pre-line", textAlign: "right" }}>{item.startDate} to {item.endDate}</h5>
							</div>
					</div>
					</Link>
					</div>
					)
                    ))}
				</div>

				<div className="campaign-mobile-activity">
				<div className="campaign-mobile-activity-container">
                {contest &&
                    contest
					.sort((a, b) => {
					const endDateA = new Date(a.endDate);
					const endDateB = new Date(b.endDate);
					const startDateA = new Date(a.startDate);
					const startDateB = new Date(b.startDate);
			
					if (today <= endDateA && today <= endDateB) {
						return endDateA - endDateB; // Sort ongoing contests by end date
					} else if (today > startDateA && today <= endDateA) {
						return -1; // Ongoing contests before not started
					} else if (today > startDateB && today <= endDateB) {
						return 1; // Not started contests after ongoing contests
					} else {
						return endDateA - endDateB; // Sort ended contests by end date
					}
					})
					.map((item) => (
						
						today > new Date(item.endDate) ? (
						<div className="campaign-mobile-activity-block">
						{/* <Link to="/activityone" style={{ textDecoration: "none" }}> */}
						<div className="campaign-mobile-activity-box">
							<div style={{ filter: "blur(3px)" }}>
								<img
								    src={item.image[language]}
									alt="activity one"
									className="campaign-mobile-activity-image"
								/>
								{/* <div className="campaign-desktop-activity-box-bottom">
								<h1 className="campaign-title" style={{margin: "0", textAlign: "left", fontSize: "18px", color: "white"}}>{item.name[language]}</h1>
								<h5 style={{ whiteSpace: "pre-line", textAlign: "left" }}>{item.description[language]}</h5>
									<h5 style={{ whiteSpace: "pre-line", textAlign: "right" }}>{item.startDate} to {item.endDate}</h5>
								</div> */}
								<div>
								<h4 className="campaign-mobile-activity-number">{item.name[language]}</h4>
								<h5 className="campaign-mobile-activity-description">{item.description[language]}</h5>
								<h6 className="campaign-mobile-activity-period">{item.startDate} to {item.endDate}</h6>
							</div>
							</div>
							<div className="campaign-mobile-activity-box-blur">
								<img
									src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/campaign/checked.png"
									alt="lock"
									className="campaign-mobile-activity-blur-topright-image"
								/>
								<div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "70%" }}>
									<img
										src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star.png"
										alt="pointer star"
										className="campaign-mobile-activity-blur-center-image"
									/>
									<h1 className="campaign-mobile-activity-blur-center-text">Ended</h1>
								</div>
							</div>
						</div>
						{/* </Link> */}
					</div>
						) : today < new Date(item.startDate) ? (
							<div className="campaign-mobile-activity-block">
							{/* <Link to="/activityone" style={{ textDecoration: "none" }}> */}
							<div className="campaign-mobile-activity-box">
								<div style={{ filter: "blur(3px)" }}>
									<img
										src={item.image[language]}
										alt="activity one"
										className="campaign-mobile-activity-image"
									/>
									{/* <div className="campaign-desktop-activity-box-bottom">
									<h1 className="campaign-title" style={{margin: "0", textAlign: "left", fontSize: "18px", color: "white"}}>{item.name[language]}</h1>
									<h5 style={{ whiteSpace: "pre-line", textAlign: "left" }}>{item.description[language]}</h5>
										<h5 style={{ whiteSpace: "pre-line", textAlign: "right" }}>{item.startDate} to {item.endDate}</h5>
									</div> */}
									<div>
								<h4 className="campaign-mobile-activity-number">{item.name[language]}</h4>
								<h5 className="campaign-mobile-activity-description">{item.description[language]}</h5>
								<h6 className="campaign-mobile-activity-period">{item.startDate} to {item.endDate}</h6>
							</div>
								</div>
								<div className="campaign-mobile-activity-box-blur">
									{/* <img
										src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/campaign/checked.png"
										alt="lock"
										className="campaign-mobile-activity-blur-topright-image"
									/> */}
									<div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "70%" }}>
										{/* <img
											src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star.png"
											alt="pointer star"
											className="campaign-mobile-activity-blur-center-image"
										/> */}
										<h1 className="campaign-mobile-activity-blur-center-text">Not Started</h1>
									</div>
								</div>
							</div>
							{/* </Link> */}
							{/* <div>
									<h4 className="campaign-mobile-activity-number">{t("activityThree")}</h4>
									<h4 className="campaign-mobile-activity-period">{t("activityThreePeriod")}</h4>
								</div> */}
						</div>
						) : item.active === false ? (
							null
							) : (
						<div className="campaign-mobile-activity-block">
						<Link to={{ pathname: "/activity", state: { contest: item } }} style={{ textDecoration: "none" }}>
							<div className="campaign-mobile-activity-box">
								<img
									src={item.image[language]}
									alt="activity one"
									className="campaign-mobile-activity-image"
								/>
							</div>
							{/* <div className="campaign-desktop-activity-box-bottom">
							<h1 className="campaign-title" style={{margin: "0", textAlign: "left", fontSize: "18px", color: "white"}}>{item.name[language]}</h1>
							<h5 style={{ whiteSpace: "pre-line", textAlign: "left" }}>{item.description[language]}</h5>
								<h5 style={{ whiteSpace: "pre-line", textAlign: "right" }}>{item.startDate} to {item.endDate}</h5>
							</div> */}
							<div>
								<h4 className="campaign-mobile-activity-number">{item.name[language]}</h4>
								<h5 className="campaign-mobile-activity-description">{item.description[language]}</h5>
								<h6 className="campaign-mobile-activity-period">{item.startDate} to {item.endDate}</h6>
							</div>
						</Link>
					</div>
					)
                    ))}
				</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
        contest: state.userReducer.contest,
		language: state.pageReducer.language
	};
};

const mapDispatchToProps = dispatch => {
	return {
		checkSession: () => dispatch(checkSession()),
        getContest: data => dispatch(getContest(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Contest);
