import { useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import axios from "axios";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import { IS_LOADING, IS_LOADED } from "../../../../redux/types";
import Loader from "../../../../component/Loader";

const UploadVoucher = ({ history, location, loading, loaded }) => {
	const [csv, setCsv] = useState(null);
	const [totalVouchers, setTotal] = useState(0);
	const [vouchers, setVouchers] = useState([]);
	const [uploaded, setUploaded] = useState(false);

	const handleFile = e => {
		if (!uploaded) {
			const file = e.target.files[0];
			// if (file.type === "text/csv") {
			const formData = new FormData();
			formData.append("file", file);
			loading();
			axios
				.post("/drypers-baby-club/api/admin/fetch/dashboard/voucher/upload/check", formData)
				.then(res => {
					setCsv(file);
					setTotal(res.data.totalVouchers);
					setVouchers(res.data.csvData);
					setUploaded(true);
					loaded();
				})
				.catch(err => {
					alert(err.response.data.error);
					setCsv(null);
					setUploaded(false);
					setTotal(0);
					setVouchers([]);
					loaded();
				});
			// } else {
			// 	alert("Please upload csv file type only, you can download the template in voucher batch details page.");
			// }
		}
	};

	const handleCancel = () => {
		setCsv(null);
		setUploaded(false);
		setTotal(0);
		setVouchers([]);
	};

	const handleConfirm = () => {
		const formData = new FormData();
		formData.append("file", csv);
		formData.append("id", location.state.id);
		loading();
		axios
			.post("/drypers-baby-club/api/admin/setting/voucher/upload", formData)
			.then(res => {
				history.push({ pathname: "/admin/voucher/batch/details", state: location.state ? location.state : null });
			})
			.catch(err => {
				alert(err.response.data.error);
				loaded();
			});
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Upload Voucher Batch</h3>

					<ul className="mt-5 list-group">
						<li className="list-group-item">
							Voucher ID: <strong>{location.state && location.state.data ? location.state.data.itemId : ""}</strong>
						</li>

						<li className="list-group-item">
							Voucher Name: <strong>{location.state && location.state.data ? location.state.data.name : ""}</strong>
						</li>

						<li className="list-group-item">
							Voucher Value: <strong>RM{location.state && location.state.data ? location.state.data.value.toFixed(2) : ""}</strong>
						</li>

						<li className="list-group-item">
							Points: <strong>{location.state && location.state.data ? location.state.data.points : ""}</strong>
						</li>
					</ul>

					<div className="form-group mt-5">
						<label>
							<strong>Upload CSV File</strong>
						</label>
						<input type="file" id="file" name="file" className="form-control" accept=".csv" onChange={handleFile} />
					</div>
				</div>
			</div>

			<Modal show={uploaded} backdrop="static" keyboard={false} centered>
				<Modal.Header>Confirm Vouchers Upload</Modal.Header>
				<Modal.Body>
					<div style={{ maxHeight: "450px", overflow: "auto" }}>
						<p>
							Total Vouchers: <strong>{totalVouchers}</strong>
						</p>
						<ul className="list-group">
							{vouchers.map((voucher, ind) => (
								<li key={ind} className="list-group-item">
									{voucher}
								</li>
							))}
						</ul>
					</div>
				</Modal.Body>
				<Loader
					component={
						<Modal.Footer>
							<button type="button" className="btn btn-secondary" onClick={handleCancel}>
								Cancel
							</button>
							<button type="button" className="btn btn-primary" onClick={handleConfirm}>
								Upload
							</button>
						</Modal.Footer>
					}
				/>
			</Modal>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(UploadVoucher);
