import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import upgrade from "../../../assets/upgradeImage.png";
import downgrade from "../../../assets/downgradeImage.png";
import maintained from "../../../assets/maintained.png";
import Gold from "../../../assets/GoldDiapers.svg";
import Platinum from "../../../assets/PlatinumDiapers.svg";
import Diamond from "../../../assets/DiamondDiapers.svg";
import tierArrow from "../../../assets/tierArrow.png"

const TierHistory = ({ user, language }) => {
  const { t } = useTranslation();
  const highTierPoints = user.tierSetting[0].highTierPoints;
  const nextTier = user.tierSetting[0].nextTier;
	const tierPercentage = (user.totalPointsInPastYear / highTierPoints) * 100;

  const userReset = user.tierResetDate;
  const dateComponents = userReset.split('-');
  const userResetFormatted = `${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`;

  const pointToGo = highTierPoints - user.totalPointsInPastYear + 1;

  let points = user.totalPoints;
	let formattedTotalPoint = points.toLocaleString(); // This adds commas to the number

  return (
    <div>
      <Header />
      <div className="content" style={{ paddingBottom: "150px" }}>
        <ContentHeader title={t("tier")} />
        <div>
        <div
          className="dashboardProfileContainer"
          style={{
            boxShadow: "0px 3px 7px rgba(0, 0, 0, 0.31)",
            borderRadius: "10px",
            width: "90%",
            margin: "auto",
            padding: "10px",
            display: "block",
            height: "auto"
          }}
        >
          <div style={{display: "flex"}}>
          <div className="dashboardProfileContent">
            <div className="dashboardProfile" style={{display: "contents"}}>
              <div style={{display: "flex"}}>
              <div className="user-profile-container">
                <h3 id="username" className="username">
                  {user.name.toUpperCase()}
                </h3>
                {/* <h2 id="username" className="username desktop">
                  {t("welcome")}, {user.name.split(" ")[0].toUpperCase()}!
                </h2> */}
                {/* <Link to="/tierHistory">
                  <h3 id="user-tier" className="username">
                    {user.tier.toUpperCase()} TIER
                  </h3>
                </Link> */}
                {/* <p className="user-milestone">100 MORE POINTS TO PLATINUM</p> */}
                {/* <div className="desktop-user-info">
                  <img
                    src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star.png"
                    alt="point star"
                    className="img-fluid"
                  />
                  <h3>{user.name}</h3>
                  <div className="dot-separator"></div>
                  <h3>{user.tier.toUpperCase()}</h3>
                </div> */}
              </div>

              <Link
                className="user-point-container"
                to="/pointhistory"
                style={{ textDecoration: "none" }}
              >
                <p style={{textAlign: "right"}} className="user-point-label">{t("yourpoints")}</p>
                <h4 id="user-point">{formattedTotalPoint}</h4>
              </Link>
              </div>

              <div className="tier" style={{width: "100%", height: "100%", margin: "0", marginTop: "10px"}}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={user.tier === "Diamond" ? Diamond : user.tier === "Platinum" ? Platinum : Gold} style={{ width: "25px", marginRight: "5px" }} />
                <div className="tier-title">
                  {user.tier}
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={user.tier === "Platinum" && nextTier === "Diamond" ? Diamond : user.tier === "Diamond" && nextTier === "Diamond" ? null : nextTier === "Platinum" ? Platinum : Gold} style={{ width: "25px", marginRight: "5px" }} />
                {/* <img style={{marginLeft: "10px"}} src={tierArrow} /> */}
                <div className="tier-title">
                  {user.tier === "Platinum" && nextTier === "Diamond" ? "Diamond" : user.tier === "Diamond" && nextTier === "Diamond" ? null : nextTier === "Platinum" ? "Platinum" : "Gold"}
                </div>
              </div>
            </div>
            <ProgressBar now={user.tier === "Diamond" ? 100 : tierPercentage} />
							{ user.tier !== "Diamond" ? (
							<div class="upgrade-date-text" style={{textAlign: "initial", marginTop: "10px"}}>{pointToGo} {t("pointReach")} {nextTier} {t("tierBy")} {userResetFormatted} </div>
							) : 
              <div class="upgrade-date-text" style={{textAlign: "initial", marginTop: "10px"}}>{t("tierHighest")}{userResetFormatted}</div>
							}
              </div>
            </div>
          </div>
          </div>
				</div>
        </div>

        <div className="tier-container" style={{boxShadow: "none"}}>
          <div style={{textAlign: "center", marginBottom: "20px"}}>
            {/* <div
              className="tier-benefits"
              style={{ justifyContent: "center", display: "flex" }}
            >
              {t("tierStatus")}
            </div> */}
            {/* { user.tier !== "Diamond" ?
           ( <div class="tier-description">{pointToGo} {t("pointReach")} {nextTier} by 4/8/2023 </div> ):
           null
            } */}
          </div>
          <div>
            <div className="tier-benefits" style={{ justifyContent: "center", display: "flex" }}>{t("memberTierHistory")}</div>
            <div>
            {user.tierHistory.slice().reverse().map((tier) => {
              const originalDate = tier.tierChangeDate;
              const dateObject = new Date(originalDate);
              const day = dateObject.getUTCDate().toString().padStart(2, '0');
              const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
              const year = dateObject.getUTCFullYear();

              const formattedDate = `${day}-${month}-${year}`;
              // console.log(tier.tierStatus[language]);

              return (
                <div className="history-container" key={tier.tierChangeDate}>
                  <div>
                    <img
                      src={tier.tierStatus[language] === "Upgraded" || tier.tierStatus[language] === "Dinaikkan" ? upgrade : tier.tierStatus[language] === "Downgraded" || tier.tierStatus[language] === "Diturunkan" ? downgrade : maintained}
                      alt="image"
                    />
                  </div>
                  <div>
                    { tier.tierStatus[language] !== "None" && tier.tierStatus[language] !== "Tiada" ? 
                    (<div>
                      <div className="membership-text">
                      {t("tierUpgradeFrom")} {tier.tierStatus[language]} {t("tierFrom")} {tier.tierChange[language]}
                    </div>
                    <div className="upgrade-date-text">
                      {tier.tierStatus[language]} on {formattedDate}
                    </div>
                    </div>) :
                    (<div>
                      <div className="membership-text">
                    {t("tier")} {tier.tierChange[language]} {t("tierAt")} {user.tier}
                  </div>
                  <div className="upgrade-date-text">
                    {tier.tierChange[language]} on {formattedDate}
                  </div>
                    </div>)
                    }
                  </div>
                </div>
              );
            })}

            </div>
          </div>
          <Link to="/tier" style={{ textDecoration: "none" }}><div className="tier-benefits">{t("viewTieringPrivileges")}</div></Link>
          <hr style={{ border: "none", borderBottom: "1px solid black", bottom: "-5px", width: "100%" }} />
          <Link to="/tierHelpCenter" style={{ textDecoration: "none" }}><div className="tier-benefits">{t("tierHelpCenter")}</div></Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    language: state.pageReducer.language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TierHistory);
