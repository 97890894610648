import { useState, useEffect, useMemo } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { connect } from "react-redux";
import axios from "axios";
import { MdAddCircleOutline } from "react-icons/md";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import Loader from "../../../component/Loader";
import { codeDashboardColumns } from "../../../config/mdbColumns";

const CodeDashboard = ({ history, loading, loaded }) => {
	const [data, setData] = useState([]);
	const [dataCode, setDataCode] = useState([]);
	const [brands, setBrands] = useState([]);
	const [currentDate, setCurrentDate] = useState("");
	const [newDate, setNewDate] = useState(0);
	const [query, setQuery] = useState("");
	const [queryData, setQueryData] = useState([]);
	const [status, setStatus] = useState("Generating");

	useEffect(() => {
		loading();
		axios
			.get("/drypers-baby-club/api/admin/fetch/dashboard/code/batch")
			.then(res => {
				setData(res.data.batches);
				setBrands(res.data.brands);
				setCurrentDate(res.data.doublePointsDate.date);
				setNewDate(parseInt(res.data.doublePointsDate.date));
				setStatus(res.data.generatingStatus);
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleActive = e => {
		loading();
		axios
			.post("/drypers-baby-club/api/admin/setting/hide/batchCode", { id: e.target.id })
			.then(res => {
				setDataCode(dataCode.map(item => (item.id === e.target.id ? { ...item, active: !item.active } : item)));
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
	};

	const dataTable = useMemo(() => {
		return {
			columns: codeDashboardColumns,
			rows: data.map(batch => {
				return {
					id: batch.id,
					brand: batch.brand.name,
					format: batch.format.name,
					size: batch.size.name,
					createdAt: new Date(batch.createdAt).toLocaleString("en-GB"),
					points: batch.points,
					quantity: batch.quantity,
					details:
						batch.status && batch.status === "generating" ? (
							<p className="m-0 p-0">Generating</p>
						) : (
							<button
								className="btn btn-primary"
								onClick={() =>
									history.push({
										pathname: "/admin/scancode/details",
										state: batch.id,
										details: { brand: batch.brand.name, format: batch.format.name, size: batch.size.name }
									})
								}
							>
								Details
							</button>
						),
					download: batch.fileUrl ? (
						<a href={batch.fileUrl} type="button" className="btn btn-info" download target="_blank" rel="noreferrer">
							Download
						</a>
					) : (
						"Not Available"
					),
					showItem: <input type="checkbox" id={batch.id} name={batch.id}  onChange={handleActive} checked={batch.active} />
				};
			})
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const updateDate = () => {
		if (parseInt(newDate) !== parseInt(currentDate) && parseInt(newDate) > 0 && parseInt(newDate) < 32) {
			loading();
			axios
				.post("/drypers-baby-club/api/admin/setting/doublePointsDate/edit", { newDate })
				.then(res => {
					setCurrentDate(res.data.date);
					loaded();
				})
				.catch(err => {
					loaded();
					alert(err.response.data.error);
				});
		}
	};

	const handleQuery = () => {
		if (query && query.length === 10) {
			loading();
			axios
				.post("/drypers-baby-club/api/admin/fetch/dashboard/code/detail", { query })
				.then(res => {
					setQueryData(res.data);
					loaded();
				})
				.catch(err => {
					loaded();
					alert(err.response.data.error);
				});
		}
	};

	const queryTableData = useMemo(() => {
		return {
			columns: [
				{ label: "Email", field: "email" },
				{ label: "BatchID", field: "batchId" },
				{ label: "Code", field: "code" },
				{ label: "Status", field: "scanned" },
				{ label: "Generated Date", field: "createdAt" },
				{ label: "Scanned Date", field: "updatedAt" }
			],
			rows: queryData
		};
	}, [queryData]);

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Scan Code Dashboard</h3>

					<div className="mt-5">
						<h1>Double Points Date: - {currentDate} -</h1>
						<div className="form-group">
							<label htmlFor="newDate">Update Double Points Date</label>

							<div className="d-flex">
								<input
									type="number"
									id="newDate"
									name="newDate"
									className="form-control"
									style={{ maxWidth: "400px" }}
									value={newDate}
									onChange={e => setNewDate(e.target.value)}
									min={1}
									max={31}
								/>
								<button type="button" className="btn btn-primary" onClick={updateDate}>
									UPDATE
								</button>
							</div>
						</div>
					</div>

					<div className="mt-5">
						<p className="m-0 p-0">
							Status: <strong>{status}</strong>
						</p>
					</div>

					<div className="mt-5 card">
						<div className="card-header d-flex justify-content-between">
							<h5 className="card-title">Code Batch</h5>

							<button
								type="button"
								className="btn btn-primary"
								onClick={() => history.push({ pathname: "/admin/scancode/generate", brands })}
							>
								<MdAddCircleOutline className="me-2" />
								Generate New Codes
							</button>
						</div>

						<div className="card-body text-center">
							<Loader component={<MDBDataTableV5 data={dataTable} striped hover responsive bordered />} />
						</div>
					</div>

					<div className="mt-5">
						<h3>Query Code</h3>
					</div>

					<div className="d-flex" style={{ width: "500px" }}>
						<input
							type="text"
							className="form-control"
							placeholder="Unique Code"
							id="query"
							name="query"
							value={query}
							onChange={e => setQuery(e.target.value.toUpperCase())}
							maxLength="10"
							minLength="10"
						/>
						<button type="button" className="btn btn-primary" onClick={handleQuery}>
							Search
						</button>
					</div>
					<div className="text-center">
						<Loader component={<MDBDataTableV5 data={queryTableData} striped hover responsive bordered />} />
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(CodeDashboard);
