import React, { useEffect, useState, useMemo } from "react";
import { MDBDataTableV5 } from "mdbreact";
import axios from "axios";
import { connect } from "react-redux";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";
import Loader from "../../../component/Loader";
import { activityColumns } from "../../../config/mdbColumns";
// import DatePicker from "react-datepicker";
// import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

function AdminActivity({ loading, loaded }) {
	const [data, setData] = useState([]);
	const [filterdata, setFilterData] = useState([]);
	const [img, setImg] = useState("");
	const [selected, setSelect] = useState("");
	const [activity, setActivity] = useState([]);
	const [load, setLoad] = useState(false);
	useEffect(() => {
		loading();

		axios
			.post("/drypers-baby-club/api/admin/report/allActivity")
			.then(res => {
				setActivity(res.data);
				fetchActivity(res.data[0].id);
			})
			.catch(err => {
				alert(err.response.data.error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const buttonStyle = val => {
		if (val === selected) {
			return { backgroundColor: "#154387", color: "white", minWidth: "100px", border: "1px black solid", marginRight: "5px" };
		} else {
			return { backgroundColor: "white", minWidth: "100px", border: "1px black solid", marginRight: "5px" };
		}
	};

	const fetchActivity = num => {
		loading();
		setLoad(true);

		axios
			.post("/drypers-baby-club/api/admin/report/activity", { activityNum: num })
			.then(res => {
				setData(res.data);
				setFilterData(res.data.filter(y => y.status === "pending"));
				setSelect("pending");
				loaded();
				setLoad(false);
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
	};

	const handleChangeStatus = (selectedStatus, entryData) => {
		loading();

		axios
			.post("/drypers-baby-club/api/admin/report/updateActivity", {
				status: selectedStatus,
				entryid: entryData.id
			})
			.then(res => {
				fetchActivity(entryData.activityName);
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
	};

	const returnStatus = entryData => {
		if (entryData.status === "pending") {
			return (
				<div>
					<button
						className="btn btn-primary me-2"
						style={{ fontSize: "12px" }}
						onClick={() => handleChangeStatus("consolidation", entryData)}
					>
						change to consolation
					</button>
					<button className="btn btn-primary me-2" style={{ fontSize: "12px" }} onClick={() => handleChangeStatus("winner", entryData)}>
						change to winner
					</button>
				</div>
			);
		} else if (entryData.status === "consolidation") {
			return (
				<div>
					<button className="btn btn-primary me-2" style={{ fontSize: "12px" }} onClick={() => handleChangeStatus("pending", entryData)}>
						change to pending
					</button>
					<button className="btn btn-primary me-2" style={{ fontSize: "12px" }} onClick={() => handleChangeStatus("winner", entryData)}>
						change to winner
					</button>
				</div>
			);
		} else if (entryData.status === "winner") {
			return (
				<div>
					<button
						className="btn btn-primary me-2"
						style={{ fontSize: "12px" }}
						onClick={() => handleChangeStatus("consolidation", entryData)}
					>
						change to consolidation
					</button>
					<button className="btn btn-primary me-2" style={{ fontSize: "12px" }} onClick={() => handleChangeStatus("pending", entryData)}>
						change to pending
					</button>
				</div>
			);
		}
	};

	const tableData = useMemo(
		() => ({
			columns: activityColumns,
			rows: filterdata.map(data => {
				return {
					...data,
					screenshot: <img src={data.screenshot} alt="screenshot" style={{ maxHeight: "150px" }} onClick={() => setImg(data.screenshot)} />,
					receipt: <img src={data.receipt} alt="receipt" style={{ maxHeight: "150px" }} onClick={() => setImg(data.receipt)} />,
					status: returnStatus(data)
				};
			})
		}),
		[filterdata]
	);

	const handleFilterTable = status => {
		setSelect(status);
		setFilterData(data.filter(y => y.status === status));
	};

	return (
		<div>
			<div className="admin-page">
				<AdminSidebar />
				<div className="admin-body">
					<AdminTopbar />

					<div className="admin-content">
						{/* contents */}
						<h3>Contest Management</h3>

						<div className="mt-2">

							<select id="activity" name="activity" onChange={e => fetchActivity(e.target.value)} disabled={load}>
								{activity.map(activity => {
									return (
										<option key={activity.id} id={activity.id} value={activity.id}>
											{activity.name}
										</option>
									);
								})}
							</select>

							{load && <Spinner animation="grow" variant="primary" />}

							<div className="mt-5">
								<button
									key="pending"
									type="button"
									className="btn"
									style={buttonStyle("pending")}
									onClick={() => handleFilterTable("pending")}
								>
									Not Shortlisted
								</button>

								<button
									key="consolidation"
									type="button"
									className="btn"
									style={buttonStyle("consolidation")}
									onClick={() => handleFilterTable("consolidation")}
								>
									Consolation Prize
								</button>

								<button
									key="winner"
									type="button"
									className="btn"
									style={buttonStyle("winner")}
									onClick={() => handleFilterTable("winner")}
								>
									Grand Prize Winner
								</button>
							</div>

							<div className="card-body text-center">
								<Loader component={<MDBDataTableV5 data={tableData} searchTop searchBottom={false} striped hover responsive bordered />} />
							</div>

							<div>
								<CSVLink
									headers={tableData.columns}
									data={tableData.rows}
									filename="scanReport.csv"
									target="_blank"
									className="btn btn-primary"
								>
									Download as CSV
								</CSVLink>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal show={img ? true : false} onHide={() => setImg("")} centered size="xl">
				<Modal.Body className="text-center">
					<img src={img} alt="ss" className="img-fluid" style={{ width: "100%" }} />
				</Modal.Body>
			</Modal>
		</div>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(AdminActivity);
