import { useState } from "react";
import Header from "../../component/dashboard/Header";
import Footer from "../../component/dashboard/Footer";
import ContentHeader from "../../component/dashboard/ContentHeader";

import { useTranslation } from "react-i18next";

const ProductsInfo = () => {
	const { t } = useTranslation();

	const [selected, setSelected] = useState("skinature");

	const buttonStyle = val => {
		if (val === selected) return { backgroundColor: "#fea30b", color: "white" };
		return {};
	};

	const data = {
		skinature: [
			{
				category: "Jumbo",
				points: "220 Points",
				items: ["https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/skinature/skinatureJumboNb.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/skinature/skinatureJumboS.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/skinature/skinatureJumboM.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/skinature/skinatureJumboL.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/skinature/skinatureJumboXl.png"]
			}
		],
		touch: [
			{
				category: "Jumbo",
				points: "120 Points",
				items: ["https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/touch/touchJumboS.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/touch/touchJumboM.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/touch/touchJumboL.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/touch/touchJumboXl.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/touch/touchJumboXxl.png"]
			},
			{
				category: "Mega",
				points: "190 Points",
				items: ["https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/touch/touchMegaNb.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/touch/touchMegaS.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/touch/touchMegaM.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/touch/touchMegaL.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/touch/touchMegaXl.png"]
			}
		],
		wwd: [
			{
				category: "Jumbo",
				points: "120 Points",
				items: ["https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/wwd/wwdJumboNb.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/wwd/wwdJumboS.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/wwd/wwdJumboM.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/wwd/wwdJumboL.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/wwd/wwdJumboXl.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/wwd/wwdJumboXxl.png"]
			},
			{
				category: "Mega",
				points: "170 Points",
				items: ["https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/wwd/wwdMegaNb.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/wwd/wwdMegaS.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/wwd/wwdMegaM.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/wwd/wwdMegaL.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/wwd/wwdMegaXl.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/wwd/wwdMegaXxl.png"]
			}
		],
		drypantz: [
			{
				category: "Jumbo",
				points: "120 Points",
				items: ["https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/drypantz/drypantzJumboS.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/drypantz/drypantzJumboM.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/drypantz/drypantzJumboL.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/drypantz/drypantzJumboXl.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/drypantz/drypantzJumboXxl.png"]
			},
			{
				category: "Mega",
				points: "150 Points",
				items: ["https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/drypantz/drypantzMegaS.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/drypantz/drypantzMegaM.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/drypantz/drypantzMegaL.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/drypantz/drypantzMegaXl.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/drypantz/drypantzMegaXxl.png"]
			}
		],
		classicPantz: [
			{
				category: "Mega",
				points: "120 Points",
				items: ["https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/classicPantz/classicPantzMegaS.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/classicPantz/classicPantzMegaM.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/classicPantz/classicPantzMegaL.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/classicPantz/classicPantzMegaXl.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/classicPantz/classicPantzMegaXxl.png"]
			}
		],
		classic: [
			{
				category: "Mega",
				points: "120 Points",
				items: ["https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/classic/classicMegaNb.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/classic/classicMegaS.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/classic/classicMegaM.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/classic/classicMegaL.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/classic/classicMegaXl.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/classic/classicMegaXxl.png"]
			}
		],
		skinatureOpen: [
			{
				category: "Jumbo",
				points: "220 Points",
				items: ["https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/skinatureOpen/skinatureOpenJumboNb.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/skinatureOpen/skinatureOpenJumboS.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/skinatureOpen/skinatureOpenJumboM.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/skinatureOpen/skinatureOpenJumboL.png", "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/productsInfo/skinatureOpen/skinatureOpenJumboXl.png"]
			}
		]
	};

	return (
		<div id="products-info-page">
			<Header />
			<div className="content" style={{ paddingBottom: "150px" }}>
				<ContentHeader />
				<div className="text-center">
					<h1 className="dbc-title">{t("participatingdryper")}</h1>
				</div>

				<div className="products-selections-container">
					<button
						type="button"
						className="btn products-selection"
						onClick={() => setSelected("skinature")}
						style={buttonStyle("skinature")}
					>
						Skinature by Drypers
					</button>
					<button type="button" className="btn products-selection" onClick={() => setSelected("touch")} style={buttonStyle("touch")}>
						Drypers Touch
					</button>
					<button type="button" className="btn products-selection" onClick={() => setSelected("wwd")} style={buttonStyle("wwd")}>
						Drypers Wee Wee Dry
					</button>
					<button type="button" className="btn products-selection" onClick={() => setSelected("drypantz")} style={buttonStyle("drypantz")}>
						Drypers DryPantz
					</button>
					<button
						type="button"
						className="btn products-selection"
						onClick={() => setSelected("classicPantz")}
						style={buttonStyle("classicPantz")}
					>
						Drypers Classic Pantz
					</button>
					<button type="button" className="btn products-selection" onClick={() => setSelected("classic")} style={buttonStyle("classic")}>
						Drypers Classic
					</button>
					<button
						type="button"
						className="btn products-selection"
						onClick={() => setSelected("skinatureOpen")}
						style={buttonStyle("skinatureOpen")}
					>
						Drypers Skinature Open
					</button>
				</div>

				<div>
					{data[selected].map(x => (
						<div className="products-info-container" key={x.category}>
							<h5>
								{x.category}: {x.points}
							</h5>

							<div className="products-info-images-container">
								{x.items.map(y => (
									<div key={y} className="products-info-image">
										<img src={y} alt={y} className="img-fluid" />
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default ProductsInfo;
