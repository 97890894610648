import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
//import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookLogin from "@greatsumini/react-facebook-login";
import GoogleLogin from "react-google-login";
import { AiFillFacebook, AiFillGoogleCircle } from "react-icons/ai";
import { authenticateUser, clearError, selectLanguage } from "../../../redux/actions/userActions";
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import { useTranslation } from "react-i18next";

const Signin = ({ authenticateUser, clearError, pageReducer, history, match, selectLanguage, location }) => {
	const { t } = useTranslation();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [emailError, setEmailError] = useState("");
	const [reset, setReset] = useState(false);
	// const [notification, setNotification] = useState(true);

	useEffect(() => {
		if (match.params.language !== "en" && match.params.language !== "bm") {
			history.push(`/${pageReducer.language}/signin`);
		} else {
			if (pageReducer.language !== match.params.language) selectLanguage(match.params.language);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [ssoModal, setSsoModal] = useState(false);

	const closeSso = () => setSsoModal(false);

	useEffect(() => {
		// Check if the 'ssoPopup' key exists in localStorage
		const hasSeenSsoModal = localStorage.getItem("ssoPopup");

		// If 'ssoPopup' is not set in localStorage, show the modal
		if (!hasSeenSsoModal) {
			setSsoModal(true);

			// Set 'ssoPopup' in localStorage to track that the user has seen the modal
			localStorage.setItem("ssoPopup", "true");
		}
	}, []);

	const emailChecker = event => {
		setEmail(event.target.value.toLowerCase());
		var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (regex.test(event.target.value)) {
			setEmailError("");
		} else {
			setEmailError(t("emailerror"));
		}
	};

	const handleFBLogin = user => {
		console.log("handle fb login");
		if (user.email)
			authenticateUser({ email: user.email, password, socialLogin: true, language: pageReducer.language || "en" });
		else {
			alert("Permission denied to acquire email from Facebook.");
		}
	};

	const handleFBProfile = user => {
		console.log("handle fb profile");
		if (user.email)
			authenticateUser({ email: user.email, password, socialLogin: true, language: pageReducer.language || "en" });
	};

	const handleGoogleLogin = user => {
		if (user.profileObj.email)
			authenticateUser({
				email: user.profileObj.email,
				password,
				socialLogin: true,
				language: pageReducer.language || "en"
			});
	};

	const handleLoginFailure = err => {
		console.error(err);
	};

	const handleChange = event => setPassword(event.target.value);

	const forgotPassword = () => history.push("/password/forgot");

	const handleSubmit = e => {
		e.preventDefault();
		if (email && password) {
			authenticateUser(
				{ email: email.toLowerCase(), password, socialLogin: false, language: pageReducer.language || "en" },
				() => setReset(true)
			);
		}
	};

	const handleResetPassword = () => {
		clearError();
		const resetPasswordLink = `https://drypers.com.my/drypers-baby-club/password/reset?user=${
			pageReducer.userId
		}&datems=${Date.now()}`;
		window.location.href = resetPasswordLink;
	};
	return (
		<div id="login-page">
			<Header history={history} match={match} location={location} />
			<div className="content">
				<ContentHeader />

				<h1 className="addToCartTitle">{t("welcome")}</h1>

				{/* <div style={{ padding: "50px 5vw", maxWidth: "1000px", margin: "auto" }}>
					<h5 style={{ whiteSpace: "pre-line", margin: "auto", lineHeight: "1.5em" }}>{t("comingSoon")}</h5>
				</div> */}

				<form onSubmit={handleSubmit} className="vinda-form">
					<div className="form-group vinda-form-group">
						<label htmlFor="email" className="vinda-form-label">
							{t("email")}
						</label>
						<input
							type="email"
							className="form-control vinda-form-input"
							id="email"
							name="email"
							placeholder={t("email")}
							onChange={e => emailChecker(e)}
							value={email}
							autoComplete="email"
							required
						/>
						<span style={{ color: "red" }}>{emailError}</span>
					</div>

					<div className="form-group vinda-form-group">
						<label htmlFor="password" className="vinda-form-label">
							{t("password")}
						</label>
						<input
							type="password"
							className="form-control vinda-form-input"
							id="password"
							name="password"
							placeholder={t("password")}
							onChange={handleChange}
							value={password}
							autoComplete="current-password"
							required
						/>
					</div>

					<div className="text-end px-3 fw-bold" style={{ color: "#FEA30B", pointerEvents: "auto" }}>
						<span style={{ pointerEvents: "auto", userSelect: "none", cursor: "pointer" }} onClick={forgotPassword}>
							{t("forgetpassword")}
						</span>
					</div>

					<div className="vinda-form-group text-center">
						{pageReducer.isLoading ? (
							<Spinner animation="border" variant="primary" />
						) : (
							<input type="submit" value={t("logincap")} className="btn vinda-form-submit" />
						)}
					</div>
				</form>
			</div>

			{/* <div className="mt-3" id="fancy-div">
				<div className="div-line"></div>
				<p>{t("orconnectusing")}</p>
				<div className="div-line"></div>
			</div> */}

			<div className="mt-4 text-center choose-auth-container">
				{/* <FacebookLogin
					appId={process.env.REACT_APP_FACEBOOK_APPID}
					render={renderProps => (
						<button
							type="button"
							id="facebook-button"
							className="choose-auth-button mb-2"
							onClick={renderProps.onClick}
							disabled={renderProps.disabled}
						>
							<AiFillFacebook className="me-3 p-0 m-0 auth-icon" />
							Facebook
						</button>
					)}
					onFail={handleLoginFailure}
					scope="email,public_profile,pages_user_gender"
					fields="name,email,gender"
					onSuccess={handleFBLogin}
					onProfileSuccess={handleFBProfile}
				/> */}
				<FacebookLogin
					appId={process.env.REACT_APP_FACEBOOK_APPID}
					render={renderProps => (
						<button
							type="button"
							id="facebook-button"
							className="choose-auth-button mb-2"
							onClick={renderProps.onClick}
							disabled={renderProps.disabled}
						>
							<AiFillFacebook className="me-3 p-0 m-0 auth-icon" />
							Facebook
						</button>
					)}
					textButton="Facebook"
					callback={handleFBLogin}
					onFailure={handleLoginFailure}
					scope="email,public_profile,user_gender,user_birthday"
					fields="name,email,gender,birthday"
					// disableMobileRedirect={false}
				/>

				{/* <GoogleLogin
					clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
					render={renderProps => (
						<button type="button" id="google-button" className="choose-auth-button" onClick={renderProps.onClick} disabled={renderProps.disabled}>
							<AiFillGoogleCircle className="me-3 m-0 p-0 auth-icon" />
							Google
						</button>
					)}
					buttonText="Google"
					onSuccess={handleGoogleLogin}
					onFailure={handleLoginFailure}
				/> */}
			</div>

			<div className="my-5 text-center fw-bold">
				<p style={{ color: "#223C96" }}>
					{t("donthaveaccount")}{" "}
					<Link style={{ color: "#FEA30B", textDecoration: "none" }} to="/">
						{t("signup")}
					</Link>
				</p>
			</div>

			<Modal show={pageReducer.error} onHide={() => clearError()} centered>
				<Modal.Body className="text-center">
					<div>
						<p className="addToCartTitle">
							{pageReducer.error === "You've entered the wrong password"
								? t("error")
								: pageReducer.error === "This user is not registered. Please head to sign up page."
								? null
								: pageReducer.error ===
								  "You have already registered as a Drypers Baby Club member. Please log in to continue."
								? null
								: pageReducer.error === "Please log in with Vindamall password."
								? t("error")
								: pageReducer.error === "This email address is not registered. Please proceed to sign up page."
								? null
								: pageReducer.error === "Please check your password."
								? t("error")
								: pageReducer.error ===
								  "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character (@$!%*?&)."
								? null
								: pageReducer.error === "Password not strong enough. Please reset your password."
								? t("passwordNotStrongHeader")
								: pageReducer.error === "Token expired, please login again"
								? t("error")
								: t("error")}
						</p>
						<p className="mt-3 pickSampleModalText">
							{pageReducer.error === "You've entered the wrong password"
								? t("wrongpwd")
								: pageReducer.error === "This user is not registered. Please head to sign up page."
								? t("notRegistered")
								: pageReducer.error ===
								  "You have already registered as a Drypers Baby Club member. Please log in to continue."
								? t("ssoExist")
								: pageReducer.error === "Please log in with Vindamall password."
								? t("ssoVindamallPassword")
								: pageReducer.error === "This email address is not registered. Please proceed to sign up page."
								? t("ssoNotRegistered")
								: pageReducer.error === "Please check your password."
								? t("ssoCheckPassword")
								: pageReducer.error ===
								  "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character (@$!%*?&)."
								? t("ssoPasswordValidation")
								: pageReducer.error === "Password not strong enough. Please reset your password."
								? t("passwordNotStrong")
								: pageReducer.error ===
								  "The login details are incorrect or your account has been locked temporarily. Please try again after 30 minutes."
								? t("wrongPasswordGeneral")
								: pageReducer.error === "Internal Error"
								? t("internalError")
								: pageReducer.error}
						</p>
					</div>

					<div>
						{pageReducer.error === "Password not strong enough. Please reset your password." ? null : (
							<button className="pickAddressConfirmButton" onClick={() => clearError()}>
								OKAY
							</button>
						)}
						{pageReducer.error === "Password not strong enough. Please reset your password." ? (
							<button className="pickAddressConfirmButton" onClick={() => handleResetPassword()}>
								{t("resetTitle")}
							</button>
						) : null}
					</div>
				</Modal.Body>
			</Modal>

			<Modal show={reset} onHide={() => setReset(false)} centered>
				<Modal.Body className="text-center">
					<div>
						<p className="addToCartTitle">{t("resetTitle")}</p>
						<p className="mt-3 pickSampleModalText">{t("resetDescription")}</p>
						<p className="mt-3 pickSampleModalText fw-bold">
							{t("weSent")} {email}
							<br />
							{t("checkSpam")}
						</p>
					</div>

					<div>
						<button className="pickAddressConfirmButton" onClick={() => setReset(false)}>
							OKAY
						</button>
					</div>
				</Modal.Body>
			</Modal>

			{/* <Modal show={ssoModal} onHide={closeSso} dialogClassName="modal-90w" centered>
			<Modal.Header closeButton>
        </Modal.Header>
			<Modal.Body className="text-center">

			<div>
				<h1 style={{ paddingLeft: "30px", paddingRight: "30px" }} className="dashboardNewsTitle">
					{t("ssoUpdate")}
				</h1>
				<h2 style={{ fontSize: "18px", color: 'black', paddingLeft: "10px", paddingRight: "10px", paddingTop: "20px" }}
					className="UpdateText">
					{t("ssoUpdate2")}
				</h2>
				<h2 style={{ fontSize: "18px", color: 'black', paddingLeft: "10px", paddingRight: "10px", paddingTop: "20px" }}
					className="UpdateText">
					{t("ssoUpdate3")}
				</h2>
			</div>
			</Modal.Body>
			</Modal> */}

			{/* <Modal show={notification} onHide={() => setNotification(false)} centered>
				<Modal.Body className="text-justify" style={{ whiteSpace: "pre-line" }}>
					<div className="text-end">
						<CloseButton onClick={() => setNotification(false)} />
					</div>
					<p className="text-center fw-bold">{t("announcement")}</p>
					<p>{t("notification")}</p>
				</Modal.Body>
			</Modal> */}
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		authenticateUser: (data, callback) => dispatch(authenticateUser(data, callback)),
		clearError: () => dispatch(clearError()),
		selectLanguage: language => dispatch(selectLanguage(language))
	};
};

const mapStateToProps = state => {
	return {
		pageReducer: state.pageReducer
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
