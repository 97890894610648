import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
// Components
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Footer from "../../../component/dashboard/Footer";
import Loader from "../../../component/Loader";
// Assets
//Redux
import { connect } from "react-redux";
import { sendOrder, clearError } from "../../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

function ConfirmOrder({ user, cart, history, location, sendOrder, pageReducer, clearError }) {
	const [showConfirm, setShowConfirm] = useState(false);
	const { t } = useTranslation();

	const { data } = location;
	const cartItems = data.cartItems;

	// useEffect(() => {
	// 	window.Insider.eventManager.dispatch("init-manager:re-initialize");
	// }, [showConfirm, pageReducer.isLoading]);

	const handleConfirm = () => {
		const items = Object.values(cart);
		const data = {
			id: user.id,
			addressId: location.address.id,
			items
		};
		setShowConfirm(false);

		const handleTransaction = transactionId => {
			const redemptionCategories = cartItems.map(item => item.item_category).join(", ");
			const itemsArray = Object.values(cartItems).map(item => ({
				item_id: item.item_id,
				item_name: item.item_name,
				item_category: item.item_category,
				item_brand: item.item_brand,
				price: item.price,
				quantity: item.quantity
			}));

			window.dataLayer.push({
				ecommerce: null
			});

			window.dataLayer.push({
				event: "purchase",
				redemption_category: redemptionCategories,
				ecommerce: {
					transaction_id: transactionId,
					currency: "MYR",
					value: itemsArray.reduce((total, item) => total + item.price * item.quantity, 0), // Calculate total value
					items: itemsArray
				}
			});
		};

		sendOrder(data, (resId, event_group_id) => {
			let trackData = [];
			const transactionId = event_group_id;
			console.log(transactionId);
			for (let i = 0; i < items.length; i++) {
				const item = items[i];
				for (let j = 0; j < item.quantity; j++) {
					const event_name = "pointshop_redemption";
					// const timestamp = new Date().toISOString();
					const event_params = {
						// product_id: item.itemId,
						// name: item.itemName.en,
						// taxonomy: item.categories,
						// currency: "points",
						// unit_price: item.points,
						// stock: item.stock,
						// product_image_url: item.itemImage.en
						custom: {
							product_name: item.itemName.en,
							point_used: item.points,
							event_group_id
						}
					};

					trackData.push({
						event_name,
						// timestamp,
						event_params
					});
				}
			}
			window.Insider.track("events", trackData);
			handleTransaction(transactionId);
			history.push({ pathname: "/order/track", orderId: resId, type: "new" });
		});
	};

	const processAddress = (line1, line2, line3, city, state, postcode, country) => {
		let text = "";
		text += `${line1}, `;
		if (line2) text += `${line2}, `;
		if (line3) text += `${line3}, `;
		text += `${city}, `;
		text += `${state}, `;
		text += `${postcode}, `;
		text += `${country}`;
		return text;
	};
	const handleMobileCode = num => {
		if (num && num.length > 8 && num[0].toString() !== "0") {
			return "+";
		} else {
			return "+6";
		}
	};

	if (!Object.values(cart).length || !location.address) {
		return <Redirect to="/pointshop" />;
	} else {
		return (
			<div id="order-confirm">
				<Header />
				<div className="content">
					<ContentHeader />

					<ul className="addToCartContainer">
						{Object.values(cart).map(item => (
							<li key={item.id} className="addToCartProductBox">
								<div className="addToCartProductImageBox">
									<img
										src={item.itemImage[pageReducer.language]}
										alt="item"
										className="img-fluid addToCartProductImage"
									/>
								</div>

								<div className="addToCartProductContent">
									<h5 className="dbc-title">{item.itemName[pageReducer.language]}</h5>
									<div className="addToCartProductDetail">
										<div className="addToCartPoint">
											<img
												src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star-2.png"
												alt="point star"
												className="img-fluid"
											/>
											<h6 className="m-0 p-0 ms-2">
												{item.points} {t("points")}
											</h6>
										</div>

										<div className="addToCartQuantityController">
											<h5 className="dbc-title m-0 p-0 mx-3" style={{ color: "black" }}>
												x{item.quantity}
											</h5>
										</div>
									</div>
								</div>
							</li>
						))}
					</ul>

					<div className="mt-4 address-container pb-5">
						<h3 className="m-0 p-0 confirm-title fw-bold">DELIVERY ADDRESS</h3>

						<div className="my-4">
							<p className="m-0 p-0 fw-bold">{user.name}</p>
							<p className="m-0 p-0">
								{handleMobileCode(user.number)}
								{user.number}
							</p>
							<p className="m-0 p-0 mt-1" style={{ lineHeight: "1em" }}>
								{processAddress(
									location.address.line1,
									location.address.line2,
									location.address.line3,
									location.address.city,
									location.address.state,
									location.address.postcode,
									location.address.country
								)}
							</p>
						</div>

						<div className="my-5 button-container px-5 text-center">
							<Loader
								component={
									<>
										<button type="button" className="btn form-control" onClick={() => history.push("/order/addtocart")}>
											EDIT
										</button>
										<button type="button" className="btn form-control mt-3" onClick={() => setShowConfirm(true)}>
											CONFIRM
										</button>
									</>
								}
							/>
						</div>
					</div>

					<div className="desktop-only">
						<Footer />
					</div>
				</div>

				<Modal centered show={showConfirm} onHide={() => setShowConfirm(false)}>
					<Modal.Body>
						<div className="text-center">
							<img
								style={{ width: "40%" }}
								alt="Cart_icon"
								src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/cart.png"
							></img>
							<h6 className="addToCartTitle">Confirm Order?</h6>
							<p className="pickSampleModalText">Are you sure you want to confirm order placement?</p>

							<Loader
								component={
									<div className="text-center">
										<Button
											style={{ backgroundColor: "#FEA30B", width: "100%", marginTop: "0.5rem" }}
											onClick={() => setShowConfirm(false)}
										>
											Cancel
										</Button>
										<Button
											style={{ backgroundColor: "#FEA30B", width: "100%", marginTop: "0.5rem" }}
											onClick={handleConfirm}
										>
											Confirm
										</Button>
									</div>
								}
							/>
						</div>
					</Modal.Body>
				</Modal>

				<Modal show={pageReducer.error} onHide={() => clearError()} centered>
					<Modal.Body className="text-center">
						<div>
							<p className="addToCartTitle">Error</p>
							<p className="mt-3 pickSampleModalText">{pageReducer.error}</p>
						</div>

						<div>
							<button className="pickAddressConfirmButton" onClick={() => clearError()}>
								OKAY
							</button>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		cart: state.userReducer.cart,
		pageReducer: state.pageReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		sendOrder: (data, callback) => dispatch(sendOrder(data, callback)),
		clearError: () => dispatch(clearError())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOrder);
