import React, { useEffect, useMemo, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import axios from "axios";
import { connect } from "react-redux";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
// import { AiOutlinePlus } from "react-icons/ai";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";
import Loader from "../../../component/Loader";
import { pointhistoryColumns } from "../../../config/mdbColumns";
import DatePicker from "react-datepicker";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";

function PointHistory({ loading, loaded, history }) {
	const [data, setData] = useState([]);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [query, setQuery] = useState("");

	useEffect(() => {
		loading();
		const registered = {
			startDate,
			endDate
		};
		axios
			.post("/drypers-baby-club/api/admin/report/point", registered)
			.then(res => {
				setData(res.data);
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFilter = () => {
		if (endDate === "" || startDate === "") {
			alert("Please fill up both field.");
		} else {
			loading();
			const registered = {
				startDate,
				endDate
			};
			axios
				.post("/drypers-baby-club/api/admin/report/point", registered)
				.then(res => {
					setData(res.data);
					loaded();
				})
				.catch(err => {
					loaded();
					alert(err.response.data.error);
				});
		}
	};

	const handleReset = () => {
		setStartDate(new Date());
		setEndDate(new Date());
		loading();
		axios
			.post("/drypers-baby-club/api/admin/report/point")
			.then(res => {
				setData(res.data);
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
	};

	const handleQuery = () => {
		if (query) {
			loading();
			axios
				.post("/drypers-baby-club/api/admin/report/point/user", { query: query.toLowerCase() })
				.then(res => {
					setData(res.data);
					loaded();
				})
				.catch(err => {
					loaded();
					alert(err.response.data.error);
				});
		}
	};

	const tableData = useMemo(
		() => ({
			columns: pointhistoryColumns,
			rows: data.map(data => {
				return {
					...data,
					button: (
						<button type="button" className="btn btn-primary" onClick={() => history.push({ pathname: "/admin/userreport", data })}>
							Details
						</button>
					)
				};
			})
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data]
	);

	return (
		<div>
			<div className="admin-page">
				<AdminSidebar />
				<div className="admin-body">
					<AdminTopbar />

					<div className="admin-content">
						{/* contents */}
						<h3>Point History</h3>

						<div className="mt-2">
							<div className="admin-date flex-container">
								<DatePicker selected={startDate} onChange={date => setStartDate(date)} isClearable placeholderText="Start Date" />
								<DatePicker selected={endDate} onChange={date => setEndDate(date)} isClearable placeholderText="End Date" />
								<Button className="mr-2" onClick={() => handleFilter()}>
									Filter
								</Button>
								<Button onClick={() => handleReset()}>Reset</Button>
							</div>
							<div className="d-flex ms-4" style={{ width: "500px" }}>
								<input
									type="text"
									className="form-control"
									placeholder="Email / Phone Number"
									id="query"
									name="query"
									value={query}
									onChange={e => setQuery(e.target.value.toLowerCase())}
								/>
								<button type="button" className="btn btn-primary" onClick={handleQuery}>
									Search
								</button>
							</div>

							<div className="card-body text-center">
								<Loader component={<MDBDataTableV5 data={tableData} searchTop searchBottom={false} striped hover responsive bordered />} />
							</div>

							<div>
								<CSVLink
									headers={tableData.columns.filter(x => x.field !== "button")}
									data={tableData.rows}
									filename="pointHistoryReport.csv"
									className="btn btn-primary"
								>
									Download as CSV
								</CSVLink>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(PointHistory);
