import { useState, useEffect } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { connect } from "react-redux";
import { MdAddCircleOutline } from "react-icons/md";
import axios from "axios";
import { itemDashboardColumns } from "../../../config/mdbColumns";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import Loader from "../../../component/Loader";

const ItemDashboard = ({ loading, loaded, history }) => {
	const [selected, setSelect] = useState("");
	const [category, setCategory] = useState([]);
	const [data, setData] = useState([]);

	useEffect(() => {
		loading();
		axios
			.get("/drypers-baby-club/api/admin/fetch/dashboard/item")
			.then(res => {
				setCategory(res.data.category);
				setData(res.data.data);
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const buttonStyle = val => {
		if (val === selected) {
			return { backgroundColor: "#154387", color: "white", minWidth: "100px", border: "1px black solid", marginRight: "5px" };
		} else {
			return { backgroundColor: "white", minWidth: "100px", border: "1px black solid", marginRight: "5px" };
		}
	};

	const handleActive = e => {
		loading();
		axios
			.post("/drypers-baby-club/api/admin/setting/hide/item", { id: e.target.id })
			.then(res => {
				setData(data.map(item => (item.id === e.target.id ? { ...item, active: !item.active } : item)));
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
	};

	const tableData = {
		columns: itemDashboardColumns,
		rows: selected
			? data
					.filter(x => x.categories.includes(selected))
					.map(x => {
						return {
							...x,
							itemImage: <img src={x.itemImage.en} alt="item" className="img-fluid" style={{ height: "80px", width: "auto" }} />,
							itemName: x.itemName.en,
							description: x.description.en,
							bmName: x.itemName.bm,
							bmDescription: x.description.bm,
							bmItemImage: <img src={x.itemImage.bm} alt="item" className="img-fluid" style={{ height: "80px", width: "auto" }} />,
							itemBrand: x.itemBrand,
							edit: (
								<button
									type="button"
									className="btn btn-info"
									onClick={() => history.push({ pathname: "/admin/item/edit", category, item: x })}
								>
									Edit
								</button>
							),
							showItem: <input type="checkbox" id={x.id} name={x.id} onChange={handleActive} checked={x.active} />
						};
					})
			: data.map(x => {
					return {
						...x,
						itemImage: <img src={x.itemImage.en} alt="item" className="img-fluid" style={{ height: "80px", width: "auto" }} />,
						itemName: x.itemName.en,
						description: x.description.en,
						bmName: x.itemName.bm,
						bmDescription: x.description.bm,
						bmItemImage: <img src={x.itemImage.bm} alt="item" className="img-fluid" style={{ height: "80px", width: "auto" }} />,
						itemBrand: x.itemBrand,
						edit: (
							<button
								type="button"
								className="btn btn-info"
								onClick={() => history.push({ pathname: "/admin/item/edit", category, item: x })}
							>
								Edit
							</button>
						),
						showItem: <input type="checkbox" id={x.id} name={x.id} onChange={handleActive} checked={x.active} />
					};
			  })
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					{/* contents */}
					<h3>Item Dashboard</h3>
					<p className="m-0 p-0">
						<small>
							<strong>
								* Does not need to add 'voucher' and 'share a little comfort' category as these will be handled in another tab.
							</strong>
						</small>
					</p>
					<p className="m-0 p-0">
						<small>
							<strong>* To manage voucher, go to 'Vouchers' tab</strong>
						</small>
					</p>
					<p className="m-0 p-0">
						<small>
							<strong>* To manage share a little comfort point donation, go to 'Share a Little Comfort' tab</strong>
						</small>
					</p>

					<div className="mt-5">
						<button type="button" className="btn" style={buttonStyle("")} onClick={() => setSelect("")}>
							All
						</button>

						{category.map(x => (
							<button key={x.id} type="button" className="btn" style={buttonStyle(x.id)} onClick={() => setSelect(x.id)}>
								{x.name}
							</button>
						))}
					</div>

					<div className="mt-5 card">
						<div className="card-header d-flex justify-content-between">
							<h5 className="card-title">Items</h5>
							<div>
								<button className="btn btn-primary me-2" onClick={() => history.push({ pathname: "/admin/item/add", category })}>
									<MdAddCircleOutline className="me-2" />
									New Item
								</button>
							</div>
						</div>

						<div className="card-body text-center">
							<Loader component={<MDBDataTableV5 data={tableData} striped hover responsive bordered />} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(ItemDashboard);
