import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { CLEAR_ERROR } from "../redux/types";

const ErrorAlert = ({ error, clearError, color }) => {
	if (error) {
		return (
			<Alert variant={color || "warning"} onClose={() => clearError()} dismissible className="m-0">
				<strong>{error}</strong>
			</Alert>
		);
	} else {
		return null;
	}
};

const mapStateToProps = state => {
	return {
		error: state.pageReducer.error
	};
};

const mapDispatchToProps = dispatch => {
	return {
		clearError: () => dispatch({ type: CLEAR_ERROR })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorAlert);
