import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";

import { editBrand } from "../../../../redux/actions/adminActions";

const EditBrand = ({ location, editBrand, history }) => {
	const [data, setData] = useState({
		name: location.data.name,
		description: location.data.description
	});

	const [file, setFile] = useState(null);

	const handleChange = e => {
		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleUpload = e => {
		setFile(e.target.files[0]);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const { name, description } = data;
		const id = location.data.id;
		if (file) {
			const formData = new FormData();
			formData.append("name", name);
			formData.append("description", description);
			formData.append("file", file);
			formData.append("id", id);

			editBrand(formData, err => {
				if (err) alert(err);
				else history.push("/admin/brand/dashboard");
			});
		} else {
			const body = { name, description, id };
			editBrand(body, err => {
				if (err) alert(err);
				else history.push("/admin/brand/dashboard");
			});
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Edit Brand</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="file">
									Brand Image{" "}
									<small>
										<strong>*Upload to change current image</strong>
									</small>
								</label>
								<input type="file" id="file" name="file" className="form-control" onChange={handleUpload} accept="image/*" />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="name">Name</label>
								<input type="text" name="name" id="name" className="form-control" placeholder="Brand name" value={data.name} onChange={handleChange} required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="description">Description</label>
								<textarea id="description" name="description" className="form-control" placeholder="Brand Description" value={data.description} onChange={handleChange} required></textarea>
							</div>

							<div className="form-group mt-5 text-center">
								<Loader component={<input type="submit" value="EDIT" className="btn btn-primary form-control" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		editBrand: (data, callback) => dispatch(editBrand(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(EditBrand);
