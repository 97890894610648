import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { addDonateItem } from "../../../../redux/actions/adminActions";

const AddDonate = ({ addDonateItem, history }) => {
	const [data, setData] = useState({
		itemId: "",
		itemName: "",
		points: 0
	});
	const [file, setFile] = useState(null);

	const handleChange = e => setData({ ...data, [e.target.name]: e.target.value });

	const handleImage = e => setFile(e.target.files[0]);

	const handleSubmit = e => {
		e.preventDefault();

		const { itemId, itemName, points } = data;
		if (itemId && itemName && points && points > 0 && file) {
			const formData = new FormData();
			formData.append("itemId", itemId);
			formData.append("itemName", itemName);
			formData.append("points", points);
			formData.append("file", file);

			addDonateItem(formData, err => {
				if (err) alert(err);
				else history.push("/admin/donate/dashboard");
			});
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Add Item</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="itemId">
									Item ID <small>Article No</small>
								</label>
								<input type="text" id="itemId" name="itemId" className="form-control" placeholder="Item ID" value={data.itemId} onChange={handleChange} required />
							</div>

							<div className="form-group">
								<label htmlFor="itemName">Item Name</label>
								<input type="text" id="itemName" name="itemName" className="form-control" placeholder="Item Name" value={data.itemName} onChange={handleChange} required />
							</div>

							<div className="form-group">
								<label htmlFor="points">Donation Points</label>
								<input type="number" id="points" name="points" className="form-control" value={data.points} onChange={handleChange} min={1} required />
							</div>

							<div className="form-group mt-3">
								<label htmlFor="itemImage">Item Image</label>
								<input type="file" name="file" id="file" className="form-control" onChange={handleImage} accept="image/*" required />
							</div>

							<div className="form-group mt-3 text-center">
								<Loader component={<input type="submit" value="SUBMIT" className="form-control btn btn-primary" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		addDonateItem: (data, callback) => dispatch(addDonateItem(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(AddDonate);
