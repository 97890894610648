import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
import ContentHeader from "../../../component/dashboard/WishingTreeHeader";
import {
  getWishingTree,
  donateWisher,
  getJourney,
} from "../../../redux/actions/userActions";
import ProgressBar from "react-bootstrap/ProgressBar";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import bigTree from "../../../assets/Tree.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import i18n from "i18next";
import Loader from "../../../component/Loader";
import comingsoon from "../../../assets/wishingSoon.png";

// todo
// 1 . get all 400 array of images
// 2. map them into 5 objects with key slideOne: 5 imgages
// 3. after eadh scroll slide get the corespoinding slide

import { useTranslation } from "react-i18next";

const WishingTree = ({
  user,
  wishingtree,
  getWishingTree,
  donateWisher,
  getJourney,
  journey,
  language,
}) => {
  const { t } = useTranslation();
  const [wishingTree, setWishingTree] = useState([]);

  useEffect(() => {
    if (!wishingtree) getWishingTree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [totalPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    if (wishingtree && wishingtree.length) {
      let data = [];
      let pageData = [];
      for (let j = 0; j < wishingtree.length; j++) {
        const wish = wishingtree[j];
        pageData.push(wish);

        if (pageData.length === 5) {
          data.push(pageData);
          pageData = [];
        }
      }
      if (pageData.length) data.push(pageData);
      setWishingTree(data);
      setTotalPoints(
        wishingtree.reduce((total, wish) => {
          return total + wish.points;
        }, 0)
      );
    }
  }, [wishingtree]);
  const history = useHistory();

  const [visible, setVisible] = useState([]);
  const [doneModal, setDoneModal] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [journeyPoint, setJourneyPoint] = useState(0);
  // const [progressbarPercent, setProgressbarPercent] = useState(0);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    if (!journey) getJourney();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (journey && journey[0]) {
      const journeyPoint = journey[0].progressPoints;
      // console.log(journeyPoint);
      // const progressbarPercent = (journeyPoint / 200000) * 100;
      setJourneyPoint(journeyPoint);
      // setProgressbarPercent(progressbarPercent);
    }
  }, [journey]);

  const manuallyPoints = parseInt(totalPoints) + parseInt(journeyPoint);
  const formattedPoints = manuallyPoints.toLocaleString("en-US");
  const progressPoints2 = manuallyPoints;

  const progressbarPercent = (progressPoints2 / 200000) * 100;

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const showModal = (index, value, id) => {
    const newVisible = [...visible];
    newVisible[index] = value;
    setSelectedId(id); // Set the selected ID
    setVisible(newVisible);
  };

  const showDoneModal = (index, value, id) => {
    const newDoneModal = [...doneModal];
    newDoneModal[index] = value;
    setSelectedId(id); // Set the selected ID
    setDoneModal(newDoneModal);
  };

  // const handleVoucherConfirm = (wisherId) => {
  //   if (!user) {
  //     history.push('en/signin');
  //   } else {
  //     const id = user.id;
  //     const data = {
  //       id,
  //       wisherId,
  //       selectedValue,
  //     };
  //     console.log(data);
  //     donateWisher(data);
  //     setVisible([...visible].fill(false));
  //     showDoneModal(0, true, selectedValue); //
  //   }
  // };

  const handleVoucherConfirm = async (wisherId) => {
    if (!user) {
      history.push("/en/signin");
    } else {
      const id = user.id;
      const data = {
        id,
        wisherId,
        selectedValue,
      };
      console.log(data);

      try {
        const response = await donateWisher(data);
        console.log(response);
        if (response.status === 200) {
          showDoneModal(0, true, wisherId, selectedValue);
        } else {
          alert("error");
        }
      } catch (error) {
        console.error(error);
        // alert("Insufficient Points");
        alert(i18n.t("insuffipoints"));
      }
      setVisible([...visible].fill(false));
    }
  };

  return (
    <div>
      <BrowserView>
        <div className="wishingtree-background">
          <div className="wishingtree-bigtree">
            <Header />
            <div className="content3" style={{ minHeight: "1100px" }}>
              {/* <ContentHeader title="WISHING TREE" /> */}
              {/* <div className="overlay">
    <div className="comingsoon-div">
      <img src={comingsoon} />
      <h3 style={{color: "white"}}>{t("thankyoudonate")}</h3>
      <h5 style={{color: "white"}}>{t("willbeback")}</h5>
    </div> */}
              {/* <div style={{height: "100vh"}}></div> */}
              <div classname="wishing-slider">
                {wishingTree && wishingTree.length ? (
                  <Slider
                    dots={true}
                    infinite={true}
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    className="abe"
                  >
                    {wishingTree.map((pageData, index) => (
                      <div className="tree-container-a" key={index}>
                        {pageData.map((x, newIndex) => (
                          <div
                            className="card-container-desktop each-tree"
                            key={newIndex}
                          >
                            <div className="card-kid-desktop">
                              <div className="card_img-desktop">
                                <img src={x.image} alt="user-image" />
                              </div>
                              <div className="card_info">
                                <h2 className="wisher-name-desktop mt-3">
                                  {x.name}
                                </h2>
                                <h2 className="wisher-wish-desktop mt-2">
                                  {t("Iam")} {x.name}. {t("Iamyears")} {x.age}{" "}
                                  {t("yearsold")}. {t("iwish")}{" "}
                                  <strong>{x.wishes[language]}</strong>
                                </h2>
                                <div
                                  className="donate-button-desktop mt-3"
                                  onClick={() => showModal(index, true, x.id)}
                                >
                                  {t("donatenowwishes")}
                                </div>
                              </div>
                            </div>

                            <Modal
                              show={visible[index] && selectedId === x.id}
                              dialogClassName="modal-90w"
                              onHide={() => showModal(index, false)}
                              centered
                            >
                              <div className="modal_img">
                                <img src={x.image} alt="user-image" />
                              </div>
                              <Modal.Body className="text-center">
                                <p className="wisher-modal-title">
                                  {t("confirmdonate")}
                                </p>
                                <p className="wisher-modal-description">
                                  {t("yourkindgesture")} {x.name} {t("togeta")}.{" "}
                                  {t("thankyoufor")}
                                </p>
                                <div style={{ position: "relative" }}>
                                  <select
                                    value={selectedValue}
                                    onChange={handleDropdownChange}
                                    style={{
                                      backgroundColor: "#FEA30B",
                                      color: "white",
                                      borderRadius: "10px",
                                      border: "none",
                                      fontSize: "16px",
                                      fontFamily: "VAGRounded",
                                      textAlign: "center",
                                      width: "50%",
                                      height: "40px",
                                      // "-webkit-appearance": "none"
                                    }}
                                  >
                                    <option value="">
                                      {t("donatenowwishes")}
                                    </option>
                                    {Array.from(
                                      { length: 50 },
                                      (_, i) => i + 1
                                    ).map((num) => (
                                      <option key={num} value={num * 100}>
                                        {num * 100} {t("pointswishes")}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                {/* <div className="submit-button mt-2" onClick={() => handleVoucherConfirm(x.id)}>
                {t("submitwishes")}
                </div> */}

                                <div className="form-group vinda-form-group text-center">
                                  <Loader
                                    component={
                                      <div
                                        className="submit-button mt-2"
                                        onClick={() =>
                                          handleVoucherConfirm(x.id)
                                        }
                                      >
                                        {t("submitwishes")}
                                      </div>
                                    }
                                  />
                                </div>
                                <div
                                  className="back-button mt-2"
                                  style={{ width: "50%", height: "30px" }}
                                  onClick={() => showModal(index, false)}
                                >
                                  {t("backwishes")}
                                </div>
                              </Modal.Body>
                            </Modal>

                            <Modal
                              show={doneModal[index] && selectedId === x.id}
                              dialogClassName="modal-90w"
                              onHide={() => showDoneModal(index, false)}
                              centered
                            >
                              <div className="modal_img">
                                <img src={x.image} alt="user-image" />
                              </div>
                              <Modal.Body className="text-center">
                                <p className="wisher-modal-title">
                                  {t("thanksforyour")}
                                </p>
                                <p className="wisher-modal-description">
                                  {t("donationof")} {selectedValue}{" "}
                                  {t("hadbeendeduct")}
                                </p>
                                <p className="wisher-modal-description">
                                  {t("weappreciate")}
                                </p>
                                <div
                                  onClick={() => showDoneModal(index, false)}
                                  className="donate-button"
                                  style={{ width: "50%", height: "30px" }}
                                >
                                  {t("done")}
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                        ))}
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <></>
                )}
              </div>
              {/* </div> */}
            </div>

            <div
              style={{
                width: "35vw",
                top: "calc(100vh - 110px)",
                backgroundColor: "white",
                padding: "10px",
                textAlign: "center",
                borderRadius: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                marginLeft: "81%",
                marginBottom: "20px",
                paddingBottom: "10px",
              }}
            >
              <h1
                style={{ color: "#0162BF", fontSize: "18px" }}
                className="dbc-title"
              >
                {t("wishingtreemission")}
              </h1>
              <p
                style={{
                  color: "#0162BF",
                  fontSize: "13px",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                }}
              >
                {t("yourGenerousPoint")}
              </p>
              <ProgressBar now={progressbarPercent} visuallyHidden />
              <p style={{ color: "#0162BF", fontSize: "13px" }}>
                <strong>{formattedPoints}</strong> {t("donatedfrom")} 200,000{" "}
                {t("donatepoints")}
              </p>
            </div>
            <div style={{ position: "relative", zIndex: "0" }}>
              <Footer />
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="wishingtree-background"
          style={{ zIndex: "-2", minHeight: "100vh" }}
        >
          <img
            style={{
              position: "fixed",
              width: "100%",
              zIndex: "0",
              marginTop: "65%",
            }}
            src={bigTree}
          />
          <div>
            <Header />
            <div className="content3" style={{ paddingBottom: "150px" }}>
              <ContentHeader title="WISHING TREE" />

              {/* <div className="mobile-overlay"> */}

              <div
                style={{
                  padding: "10px",
                  marginTop: "-50px",
                  position: "relative",
                }}
              >
                {wishingtree && wishingtree.length ? (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      marginTop: "50px",
                      marginRight: "30px",
                      zIndex: "-9999",
                    }}
                  >
                    {wishingtree.map((x, index) => (
                      // <div className="card-container" key={index} style={{  display: "inline-block" }}>
                      <div
                        className="card-container"
                        key={index}
                        style={{
                          display: "inline-block",
                          // zIndex: "-999",
                        }}
                      >
                        <div className="card-kid">
                          <div className="card_img">
                            <img src={x.image} alt="user-image" />
                          </div>
                          <div className="card_info">
                            <h2 className="wisher-name mt-3">{x.name}</h2>
                            <h2 className="wisher-wish mt-2">
                              {t("Iam")} {x.name}. {t("Iamyears")} {x.age}{" "}
                              {t("yearsold")}. {t("iwish")}{" "}
                              <strong>{x.wishes[language]}</strong>
                            </h2>
                            <div
                              className="donate-button mt-3"
                              onClick={() => showModal(index, true, x.id)}
                            >
                              {t("donatenowwishes")}
                            </div>
                          </div>
                        </div>

                        <Modal
                          show={visible[index]}
                          dialogClassName="modal-90w"
                          onHide={() => showModal(index, false)}
                          centered
                        >
                          <div className="modal_img">
                            <img src={x.image} alt="user-image" />
                          </div>
                          <Modal.Body className="text-center">
                            <p className="wisher-modal-title">
                              {t("confirmdonate")}
                            </p>
                            <p className="wisher-modal-description">
                              {t("yourkindgesture")} {x.name} {t("togeta")}.{" "}
                              {t("thankyoufor")}
                            </p>
                            <div style={{ position: "relative" }}>
                              <select
                                value={selectedValue}
                                onChange={handleDropdownChange}
                                style={{
                                  backgroundColor: "#FEA30B",
                                  color: "white",
                                  borderRadius: "10px",
                                  border: "none",
                                  fontSize: "16px",
                                  fontFamily: "VAGRounded",
                                  textAlign: "center",
                                  textAlignLast: "center",
                                  justifyContent: "center",
                                  width: "50%",
                                  height: "40px",
                                  // "-webkit-appearance": "none"
                                }}
                              >
                                <option value="">{t("donatenowwishes")}</option>
                                {Array.from(
                                  { length: 50 },
                                  (_, i) => i + 1
                                ).map((num) => (
                                  <option key={num} value={num * 100}>
                                    {num * 100} {t("pointswishes")}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {/* <div className="submit-button mt-2" onClick={() => handleVoucherConfirm(x.id)}>
              {t("submitwishes")}
							</div> */}

                            <div className="form-group vinda-form-group text-center">
                              <Loader
                                component={
                                  <div
                                    className="submit-button mt-2"
                                    onClick={() => handleVoucherConfirm(x.id)}
                                  >
                                    {t("submitwishes")}
                                  </div>
                                }
                              />
                            </div>
                            <div
                              className="back-button mt-2"
                              style={{ width: "50%", height: "30px" }}
                              onClick={() => showModal(index, false)}
                            >
                              {t("backwishes")}
                            </div>
                          </Modal.Body>
                        </Modal>

                        <Modal
                          show={doneModal[index]}
                          dialogClassName="modal-90w"
                          onHide={() => showDoneModal(index, false)}
                          centered
                        >
                          <div className="modal_img">
                            <img src={x.image} alt="user-image" />
                          </div>
                          <Modal.Body className="text-center">
                            <p className="wisher-modal-title">
                              {t("thanksforyour")}
                            </p>
                            <p className="wisher-modal-description">
                              {t("donationof")} {selectedValue}{" "}
                              {t("hadbeendeduct")}
                            </p>
                            <p className="wisher-modal-description">
                              {t("weappreciate")}
                            </p>
                            <div
                              onClick={() => showDoneModal(index, false)}
                              className="donate-button"
                              style={{ width: "50%", height: "30px" }}
                            >
                              {t("done")}
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {/* <div className="mobile-comingsoon-div">
  <img src={comingsoon} alt="Coming Soon" />
  <h3 style={{ color: "white" }}>{t("thankyoudonate")}</h3>
  <h5 style={{ color: "white" }}>{t("willbeback")}</h5>
</div> */}
              {/* <div style={{height: "100vh"}}></div>
  </div> */}
            </div>

            <div
              style={{
                width: "90vw",
                position: "fixed",
                zIndex: "0",
                top: "calc(90vh - 215px)",
                backgroundColor: "white",
                padding: "10px",
                textAlign: "center",
                borderRadius: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                paddingBottom: "0",
              }}
            >
              <h1
                style={{ color: "#0162BF", fontSize: "18px" }}
                className="dbc-title"
              >
                {t("wishingtreemission")}
              </h1>
              <p
                style={{
                  color: "#0162BF",
                  fontSize: "14px",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                }}
              >
                {t("yourGenerousPoint")}
              </p>
              <ProgressBar now={progressbarPercent} visuallyHidden />
              <p style={{ color: "#0162BF", fontSize: "14px" }}>
                <strong>{formattedPoints}</strong> {t("donatedfrom")} 200,000{" "}
                {t("donatepoints")}
              </p>
            </div>
            <Footer />
          </div>
        </div>
      </MobileView>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWishingTree: (data) => dispatch(getWishingTree(data)),
    donateWisher: (data, callback) => dispatch(donateWisher(data, callback)),
    getJourney: (data) => dispatch(getJourney(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    wishingtree: state.userReducer.wishingtree,
    language: state.pageReducer.language,
    journey: state.userReducer.journey,
    language: state.pageReducer.language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WishingTree);
