import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
import ContentHeader from "../../../component/dashboard/WishingTreeHeader";
import Article from "../../../component/dashboard/Article";
import Blog from "../../../component/dashboard/Blog";
import Journey from "../../../component/dashboard/Journey";
import JoinUs from "../../../component/dashboard/JoinUs";
import { useTranslation } from "react-i18next";

const ProductsInfo = () => {
	const { t } = useTranslation();


	return (
		<div id="products-info-page">
			<Header />
			<div className="content2" style={{ paddingBottom: "150px" }}>
                 <ContentHeader title="SHARE A LITTLE COMFORT" />
				<div className="text-center">
					<h1 className="wishing-tree-title2">SHARE A LITTLE COMFORT</h1>
				</div>
                <Article />
				<JoinUs />
                <Journey />
                <Blog />

			</div>
			<Footer />
		</div>
	);
};

export default ProductsInfo;
