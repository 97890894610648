import { useEffect, useMemo, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import axios from "axios";
import { connect } from "react-redux";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import Loader from "../../../component/Loader";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";
import { promoterDashboardColumns } from "../../../config/mdbColumns";
import { MdAddCircleOutline } from "react-icons/md";
import { CSVLink } from "react-csv";

const PromoterDashboard = ({ loading, loaded, history }) => {
	const [data, setData] = useState([]);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");

	useEffect(() => {
		loading();
		axios
			.post("/drypers-baby-club/api/admin/fetch/dashboard/promoter")
			.then(res => {
				loaded();
				setData(res.data);
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFilter = () => {
		if (startDate && endDate) {
			loading();
			axios
				.post("/drypers-baby-club/api/admin/fetch/dashboard/promoter", { startDate, endDate })
				.then(res => {
					loaded();
					setData(res.data);
				})
				.catch(err => {
					loaded();
					alert(err.response.data.error);
				});
		}
	};

	const handleReset = () => {
		loading();
		axios
			.post("/drypers-baby-club/api/admin/fetch/dashboard/promoter")
			.then(res => {
				loaded();
				setData(res.data);
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
	};

	const tableData = useMemo(
		() => ({
			columns: promoterDashboardColumns,
			rows: data.map(x => {
				return {
					...x,
					edit: (
						<button type="button" className="btn btn-primary" onClick={() => history.push({ pathname: "/admin/promoter/edit", data: x })}>
							Edit
						</button>
					)
				};
			})
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data]
	);

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Promoters</h3>

					<button type="button" className="btn btn-primary mt-5" onClick={() => history.push("/admin/promoter/add")}>
						<MdAddCircleOutline className="me-2" />
						Add Promoter
					</button>

					<div className="mt-5 card">
						<div className="card-header">
							<h5 className="card-title">Promoters</h5>
						</div>

						<div className="d-flex" style={{ width: "50%" }}>
							<input type="date" id="startDate" name="startDate" className="form-control" value={startDate} onChange={e => setStartDate(e.target.value)} />
							<input type="date" id="endDate" name="endDate" className="form-control" value={endDate} onChange={e => setEndDate(e.target.value)} />
							<button type="button" className="btn btn-primary px-2" onClick={handleFilter}>
								Filter
							</button>
							<button type="button" className="btn btn-warning px-2" onClick={handleReset}>
								Reset
							</button>
						</div>

						<div className="card-body text-center">
							<Loader component={<MDBDataTableV5 data={tableData} striped hover responsive bordered />} />
						</div>
						<div>
							<CSVLink
								headers={tableData.columns.filter(x => x.key && x.field !== "edit")}
								data={tableData.rows}
								filename="accountReport.csv"
								className="btn btn-primary"
							>
								Download as CSV
							</CSVLink>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(PromoterDashboard);
