import React, { useEffect, useState } from "react";
import "./MysteryBoxHistory.css";
import { getMysteryBoxHistory } from "../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function MysteryBoxHistory() {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(true);

  const [mysteryBoxData, setMysteryBoxData] = useState(null);
  const [mysteryBoxDataCount, setMysteryBoxDataCount] = useState(0);
  const language = useSelector((state) => state.pageReducer.language);
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      try {
        const res = await dispatch(getMysteryBoxHistory());
        const filteredData = res.data.map((item) => {
          const inputDate = item.createdAt;
          const date = new Date(inputDate);

          const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true, // Use 12-hour format
          };

          const formattedDate = date.toLocaleString("en-GB", options);

          let voucherValue = "";
          if (item.type === "VOUCHER") {
            const str = item.name;
            const firstSpaceIndex = str.indexOf(" ");
            voucherValue = str.substring(0, firstSpaceIndex);
          }

          return {
            image: item.image,
            title:
              item.type !== "POINTS"
                ? item.name == "RM5 TnG PIN"
                  ? t("tng")
                  : item.name == "RM10 AEON Voucher"
                  ? t("aeon")
                  : item.name == "RM15 Vindamall eVoucher"
                  ? t("vinda")
                  : item.name
                : language === "en"
                ? "Point earned"
                : "Mata Ganjaran Diperoleh",
            date: formattedDate,
            value:
              item.type === "POINTS"
                ? `+${item.name} ${
                    language === "en" ? "points" : "mata ganjaran"
                  }`
                : voucherValue,
            type: item.type,
            voucher: item.voucher ? item.voucher : null,
          };
        });
        setMysteryBoxDataCount(filteredData.length);
        setMysteryBoxData(filter ? filteredData.slice(0, 3) : filteredData);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [filter, dispatch, language]);

  return (
    <div className="mystery-box-history-container">
      <div className="mystery-box-history-background"></div>
      <div className="mystery-box-history-item-list">
        {mysteryBoxData && mysteryBoxData.length > 0 ? (
          mysteryBoxData.map((item, index) => (
            <div className="mystery-box-history-item" key={index}>
              <img
                src={item.image}
                className="mystery-item-image"
                alt="mystery-item"
              ></img>
              <div className="mystery-item-title-and-date">
                <p className="mystery-item-title">{item.title}</p>
                {item.voucher && (
                  <p className="mystery-item-title">{item.voucher.code}</p>
                )}
                <p className="mystery-item-date">{item.date}</p>
                {item.voucher && (
                  <p className="mystery-item-exp-date">exp: 05/09/2025</p>
                )}
              </div>
              <div className="mystery-item-value-container">
                <p className="mystery-item-value">{item.value}</p>
              </div>
            </div>
          ))
        ) : (
          <h4 style={{ position: "relative" }}>Empty History</h4>
        )}
        {mysteryBoxDataCount > 3 ? (
          <button
            onClick={() => setFilter(false)}
            className={`mystery-box-history-button ${
              !filter ? "invisible-button" : null
            }`}
          >
            {language === "en" ? "See more" : "Lihat lagi"}
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default MysteryBoxHistory;
