import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { editBlog } from "../../../../redux/actions/adminActions";

const EditBlog = ({ history, location, editBlog }) => {
	const [data, setData] = useState({
		title: location.data.title,
		description: location.data.description.en,
		descriptionBm: location.data.description.bm,
        venue: location.data.venue
	});

	const handleChange = e => {
		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleSubmit = e => {
		e.preventDefault();
		const { title, description, descriptionBm, venue } = data;
		if (title && description && descriptionBm && venue) {
		
				const sendThis = {
					id: location.data.id,
					title,
					description,
					descriptionBm,
					venue
				};
				console.log(sendThis);
				editBlog(sendThis, err => {
					if (err) alert(err);
					else {
						history.push("/admin/blog/dashboard");
					}
				});
		} else {
			alert("Empty field detected");
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Edit Blog</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="title">Title</label>
								<input
									type="text"
									id="title"
									name="title"
									className="form-control"
									placeholder="Blog Title"
									value={data.title}
									onChange={handleChange}
									required
								/>
							</div>

                            <div className="form-group">
								<label htmlFor="venue">Venue</label>
								<input
									type="text"
									id="venue"
									name="venue"
									className="form-control"
									placeholder="Blog venue"
									value={data.venue}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="description">Description</label>
								<textarea
									name="description"
									id="description"
									className="form-control"
									placeholder="Blog Description"
									value={data.description}
									onChange={handleChange}
									required
								></textarea>
							</div>

                            <div className="form-group mt-3">
								<label htmlFor="descriptionBm">Description BM</label>
								<textarea
									name="descriptionBm"
									id="descriptionBm"
									className="form-control"
									placeholder="Blog descriptionBm"
									value={data.descriptionBm}
									onChange={handleChange}
									required
								></textarea>
							</div>



							<div className="form-group mt-5 text-center">
								<Loader component={<input type="submit" value="SUBMIT" className="form-control btn btn-primary" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		editBlog: (data, callback) => dispatch(editBlog(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(EditBlog);
