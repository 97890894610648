import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { addArticle } from "../../../../redux/actions/adminActions";

const AddArticle = ({ addArticle, history }) => {
	const [data, setData] = useState({
		title: "",
		titleBM: "",
		description: "",
		bmTitle: "",
		descriptionBM: "",
	});

	const [file, setFile] = useState(null);

	const handleChange = e => {
		setData({
			...data,
			[e.target.id]: e.target.value
		});
	};

	const handleImage = e => setFile(e.target.files[0]);

	const handleSubmit = e => {
		e.preventDefault();
		if (data.title && data.description && file && data.descriptionBM && data.titleBM) {
			const formData = new FormData();
			formData.append("title", data.title);
			formData.append("titleBM", data.titleBM)
			formData.append("description", data.description);
			formData.append("descriptionBM", data.descriptionBM);
			formData.append("file", file);
			addArticle(formData, err => {
				if (err) alert(err);
				else history.push("/admin/article/dashboard");
			});
		} else {
			alert("Please fill in all the fields in the form");
		}
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Add Article</h3>

					<div className="mt-5">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="title">Title</label>
								<input
									type="text"
									id="title"
									name="title"
									className="form-control"
									placeholder="Article Title"
									value={data.title}
									onChange={handleChange}
									required
								/>
							</div>

						<div className="form-group">
						    	<label htmlFor="title">Title BM</label>
							<input
									type="text"
									id="titleBM"
									name="titleBM"
									className="form-control"
									placeholder="Title BM"
									value={data.titleBM}
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="description">Description</label>
								<textarea
									name="description"
									id="description"
									className="form-control"
									placeholder="Article Description"
									value={data.description}
									onChange={handleChange}
									required
								></textarea>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="descriptionBM">Description BM</label>
								<textarea
									name="descriptionBM"
									id="descriptionBM"
									className="form-control"
									placeholder="Article descriptionBM"
									value={data.descriptionBM}
									onChange={handleChange}
									required
								></textarea>
							</div>

							<div className="form-group mt-3">
								<label htmlFor="file">Image</label>
								<input type="file" name="file" id="file" className="form-control" accept="image/*" onChange={handleImage} required />
							</div>

							<div className="form-group mt-5 text-center">
								<Loader component={<input type="submit" value="SUBMIT" className="form-control btn btn-primary" />} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		addArticle: (data, callback) => dispatch(addArticle(data, callback))
	};
};

export default connect(null, mapDispatchToProps)(AddArticle);
